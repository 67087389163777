export const solvisControlsMock = {
  textual_content_faults: {
    content: [
      {
        text: "The Crustal fault system can be filtered on specific faults. Select more than one fault to get the union of all ruptures on those faults.",
      },
    ],
  },
  textual_content_locations: {
    content: [
      {
        text: "Filter on location from a list of population centres. Select a Location and a Radius to get all ruptures that pass within a desired distance of a population centre. Select multiple locations to get only the ruptures that are near all locations selected (the intersection of all ruptures).",
      },
    ],
  },
  textual_content_animation: {
    content: [
      {
        text: "See the Technical Info page for details on the animation sorting options.",
      },
    ],
  },
  textual_content_rate: {
    content: [
      {
        text: 'Rates use a logarithmic scale i.e. 1e-4 == 0.0001 or a 1/10,000 and this number represents the **probability of exceedance** (PoE) in a single year. \n\nThe rate filter controls display the value of the power **N** in the formula **1/1e-N/yr**. \n\ne.g. setting the lower (left most) rate control to **-6** means "show me just those ruptures having a PoE of at least **1/1e-6**".\n',
      },
    ],
  },
  SOLVIS_get_radii_set: {
    radii: [10000, 20000, 30000, 40000, 50000, 100000],
  },
  SOLVIS_get_location_list: {
    locations: [
      {
        name: "Auckland",
        location_id: "AKL",
      },
      {
        name: "Blenheim",
        location_id: "BHE",
      },
      {
        name: "Christchurch",
        location_id: "CHC",
      },
      {
        name: "Dunedin",
        location_id: "DUD",
      },
      {
        name: "Gisborne",
        location_id: "GIS",
      },
      {
        name: "Greymouth",
        location_id: "GMN",
      },
      {
        name: "Hawera",
        location_id: "HAW",
      },
      {
        name: "Hamilton",
        location_id: "HLZ",
      },
      {
        name: "Invercargill",
        location_id: "IVC",
      },
      {
        name: "Kaikoura",
        location_id: "KBZ",
      },
      {
        name: "Kerikeri",
        location_id: "KKE",
      },
      {
        name: "Levin",
        location_id: "LVN",
      },
      {
        name: "Mount Cook",
        location_id: "MON",
      },
      {
        name: "Masterton",
        location_id: "MRO",
      },
      {
        name: "Napier",
        location_id: "NPE",
      },
      {
        name: "New Plymouth",
        location_id: "NPL",
      },
      {
        name: "Nelson",
        location_id: "NSN",
      },
      {
        name: "Palmerston North",
        location_id: "PMR",
      },
      {
        name: "Te Anau",
        location_id: "TEU",
      },
      {
        name: "Timaru",
        location_id: "TIU",
      },
      {
        name: "Tokoroa",
        location_id: "TKZ",
      },
      {
        name: "Thames",
        location_id: "TMZ",
      },
      {
        name: "Tauranga",
        location_id: "TRG",
      },
      {
        name: "Taupo",
        location_id: "TUO",
      },
      {
        name: "Rotorua",
        location_id: "ROT",
      },
      {
        name: "Whakatane",
        location_id: "WHK",
      },
      {
        name: "Franz Josef",
        location_id: "WHO",
      },
      {
        name: "Wellington",
        location_id: "WLG",
      },
      {
        name: "Westport",
        location_id: "WSZ",
      },
      {
        name: "Whanganui",
        location_id: "ZWG",
      },
      {
        name: "Whangarei",
        location_id: "WRE",
      },
      {
        name: "Turangi",
        location_id: "ZTR",
      },
      {
        name: "Otira",
        location_id: "ZOT",
      },
      {
        name: "Haast",
        location_id: "ZHT",
      },
      {
        name: "Hanmer Springs",
        location_id: "ZHS",
      },
      {
        name: "Queenstown",
        location_id: "ZQN",
      },
    ],
  },
  SOLVIS_get_parent_fault_names: [
    "Acton",
    "Aka Aka",
    "Akatarawa",
    "Akatore",
    "Albury",
    "Alexander",
    "Alfredton North",
    "Alfredton South",
    "Alpine: Caswell",
    "Alpine: Caswell - South George",
    "Alpine: George landward",
    "Alpine: George seaward",
    "Alpine: George to Jacksons",
    "Alpine: Jacksons to Kaniere",
    "Alpine: Kaniere to Springs Junction",
    "Alpine: Nancy",
    "Alpine: Resolution - Charles",
    "Alpine: Resolution - Dagg",
    "Alpine: Resolution - Five Fingers",
    "Alpine: Springs Junction to Tophouse",
    "AmberBlyth",
    "Aotea - Evans Bay",
    "Ararata",
    "Ariel Bank",
    "Ariel Bank 2",
    "Ariel East",
    "Awahokomo",
    "Awakeri",
    "Awamoa",
    "Awanui",
    "Awatere: Northeast 1",
    "Awatere: Northeast 2",
    "Awatere: Southwest",
    "Backbone",
    "Balmoral",
    "Barefell",
    "Barn",
    "Barrier Range",
    "Beacon Hill",
    "Beaumont River",
    "Ben McLeod",
    "Bidwill",
    "Big Ben",
    "Billys Ridge",
    "Black Hill",
    "Blue Lake",
    "Blue Mountain",
    "Boby Stream",
    "BooBoo",
    "Braemar",
    "Brothers",
    "Browning Pass",
    "Calypso 2",
    "Campbell Bank",
    "Campbell Hills",
    "Cape Egmont: Central",
    "Cape Egmont: North",
    "Cape Egmont: South",
    "Cape Foulwind 1",
    "Cape Foulwind 2",
    "Cape Foulwind 4",
    "Cardrona - Hawea",
    "Carterton",
    "Caswell 211",
    "Caswell High 1",
    "Caswell High 10",
    "Caswell High 3",
    "Caswell High 4",
    "Caswell High 5",
    "Caswell High 67",
    "Caswell High 8",
    "Caswell High 9",
    "Catherine",
    "CenPeg1n2",
    "Central Balleny",
    "Central Wedge 1 & 2 & 3",
    "Central Wedge 4 - South Wedge 411",
    "Chamberlain",
    "Chancet",
    "Cheeseman",
    "Clarence: Central",
    "Clarence: Northeast",
    "Clarence: Southwest",
    "Clear Hills - Waikopiro",
    "Clifton",
    "Cloudy 1",
    "Cloudy 2",
    "Cloudy 3",
    "Cluden",
    "Conway Trough East",
    "Conway Trough West",
    "Cross Eden",
    "Dalgety",
    "Darran",
    "Dingle",
    "Doctors",
    "Double Hill",
    "Dreyers Rock",
    "Dromedary",
    "Drury",
    "Dry River - Huangarua: 1",
    "Dry River - Huangarua: 2",
    "Dry River - Huangarua: 3",
    "Dryburgh",
    "Dunback Hill",
    "Dunstan",
    "EarthquakeFlat - Tumunui",
    "Edgecumbe 1987",
    "Edgecumbe Coastal",
    "Edith",
    "Elliott",
    "Esk North",
    "Esk South",
    "Falstone",
    "Farewell",
    "Fern Gully",
    "Fidget",
    "Fisherman 1",
    "Fisherman 2",
    "Fisherman 3",
    "Five Fingers",
    "Flat Stream - Glenpark",
    "Flaxmore - Waimea - Tahunanui",
    "Fowlers",
    "Fox Peak",
    "Gable End North",
    "Gable End South",
    "Galloway",
    "Garibaldi",
    "Garvie",
    "Gibson",
    "Gimmerburn",
    "Glenbrook",
    "Goodger",
    "Grampian",
    "Grandview",
    "Green Island",
    "Greendale",
    "Hanmer",
    "Hariki",
    "Hauroko: North",
    "Hauroko: South",
    "Hawkdun",
    "Hawke Bay 1",
    "Hawke Bay 4",
    "Hawke Bay 5 & 11",
    "Hawke Bay 8",
    "Hawkswood",
    "Hedgehope",
    "High Peak - Sheffield",
    "Hillfoot",
    "Himatangi",
    "Hohonu: South",
    "Hokonui",
    "Hollyford",
    "Honeycomb",
    "Hope: Conway",
    "Hope: Hanmer NW",
    "Hope: Hanmer SE",
    "Hope: Hanmer SW",
    "Hope: Hope River",
    "Hope: Hurunui",
    "Hope: Kakapo-2-Hamner",
    "Hope: Seaward",
    "Hope: Taramakau",
    "Hope: Te Rapa",
    "Hopkins",
    "Hororata",
    "Horse Flat",
    "Houtunui",
    "Hump Ridge",
    "Hundalee",
    "Hunter Valley",
    "Hunters",
    "Hurunui Peak",
    "Hutt Peel",
    "Huxley",
    "Hyde North",
    "Hyde South - The Twins",
    "Ihaia",
    "Inangahua",
    "Inglewood",
    "Irishman Creek",
    "Jordan",
    "Kahurangi",
    "Kaiapo",
    "Kaikorai",
    "Kaikoura - Mangamaunu",
    "Kairakau 2",
    "Kairakau North",
    "Kairakau South",
    "Kaiwara North",
    "Kaiwara South",
    "Kakapo",
    "Kaumingi",
    "Kaweka",
    "Kekerengu 1",
    "Kekerengu Bank",
    "Kelly",
    "Kerepehi Awaiti",
    "Kerepehi Elstow",
    "Kerepehi Offshore",
    "Kerepehi Okoroire",
    "Kerepehi Te Poi",
    "Kerepehi Waitoa",
    "Kereu",
    "Kidnappers Ridge",
    "Kiri",
    "Kirkliston",
    "Klondyke Moor",
    "Knowles Top",
    "Kohaihai",
    "Kohaihai South",
    "Kongahau",
    "Kotare - Moutuhora",
    "Kowai - View Hill",
    "Kututaruhe",
    "Lachlan 1 & 2",
    "Lachlan 3",
    "Lake Heron",
    "Lake Onslow",
    "Leader: Central",
    "Leader: North",
    "Leader: South",
    "Leedstown",
    "Lees Valley",
    "Leithfield - NCVI - NCVII",
    "Leonard Mound",
    "Levin Anticline",
    "Lindis River",
    "Little Hillfoot",
    "Little Valley",
    "Livingstone",
    "Logan Burn",
    "London Hill",
    "Long Valley",
    "Lowry",
    "Lyell",
    "Lyell South",
    "Madagascar",
    "Madden Banks",
    "Mahia 2",
    "Maimai",
    "Maimai: North",
    "Maimai: South",
    "Makuri - Waewaepa",
    "Maleme",
    "Manahi 1",
    "Manahi 2",
    "Mangatangi",
    "Marlborough Slope 11",
    "Marlborough Slope 2",
    "Marlborough Slope 3",
    "Marlborough Slope 4",
    "Marlborough Slope 9",
    "Martinborough",
    "Mascarin",
    "Masterton",
    "Mataikona",
    "Mataikona East",
    "Matata",
    "Maunga",
    "Maungati",
    "Maungatua - North Taieri",
    "McKerchar",
    "Mid Dome",
    "Middle Range",
    "Milford Basin 5 to George R2",
    "Mohaka: North",
    "Mohaka: South",
    "Mokonui Northeast",
    "Mokonui Southwest",
    "Monowai",
    "Moonlight North",
    "Moonlight South",
    "Moonshine",
    "Mossburn",
    "Motatapu",
    "Motu",
    "Motu River",
    "Motu o Kura East",
    "Motu o Kura North",
    "Motu o Kura Ridge",
    "Motunau",
    "Moumahaki - Okaia 4",
    "Mt Arden",
    "Mt Culverden",
    "Mt Lawry",
    "Mt Stewart",
    "Mt Sutton",
    "Murphys Creek",
    "NW Cardrona North",
    "NW Cardrona South",
    "Needles",
    "Needles East 2",
    "Nevis",
    "Ngatoro South 2",
    "Ngatoro South 5",
    "Nichols Rock",
    "Norfolk",
    "North Branch",
    "North Canterbury Shelf 11",
    "North Canterbury Shelf 2",
    "North Canterbury Shelf 4",
    "North Mernoo E2-1",
    "North Mernoo K2",
    "North Mernoo K3",
    "Northern Ohariu",
    "Nukumuru - Waitotara 1-6",
    "Oaonui",
    "Ohae 1",
    "Ohae 2",
    "Ohae 3",
    "Ohariu",
    "Ohariu South 1",
    "Ohariu South 2",
    "Ohena 2",
    "Ohena 3",
    "Ohena 4",
    "Ohiwa 1",
    "Okaia 3",
    "Okaia 5",
    "Okupe 1",
    "Old Man",
    "Omarama Saddle",
    "OmihiLwrHuru",
    "Onepoto",
    "Opape 1",
    "Opawa",
    "Opotiki 2",
    "Opotiki 3",
    "Opouawe - Uruti",
    "Orakeikorako",
    "Oruawharo - Mangatoro",
    "Ostler North",
    "Ostler South",
    "Otaheke North",
    "Otaheke South",
    "Otaki Forks: 1",
    "Otaki Forks: 2",
    "Otanomomo",
    "Otara East 1",
    "Otara East 2",
    "Otara West 1",
    "Otara West 2",
    "Otara West 3",
    "Otaraia",
    "Pa Valley",
    "Paddys Ridge",
    "Paeroa",
    "Pahaua",
    "Pahiatua",
    "Pakarae",
    "Palliser - Kaiwhata",
    "Pancake",
    "Paparoa West",
    "Paparoa West: North",
    "Papatea",
    "Parihaka",
    "Patoka - Rangiora",
    "Pegasus Bay",
    "Pihama",
    "Pisa",
    "Pohangina",
    "Point Gibson",
    "Pokeno",
    "Pongaroa - Weber",
    "Poroutawhao",
    "Porters Pass",
    "Poulter",
    "Poutu",
    "Poverty Bay",
    "Pukekohe",
    "Pukerua - Shepherds Gully: 1",
    "Pukerua - Shepherds Gully: 2",
    "Pukerua - Shepherds Gully: 3",
    "Puketerata",
    "Puketerata North",
    "Pylap",
    "Raggedy - Blackstone",
    "Rahotu",
    "Rakaia",
    "Ranfurly",
    "Rangefront",
    "Rangitaiki 1",
    "Rangitaiki 2",
    "Rangitikei Offshore 1",
    "Rapuwai",
    "Raukumara 16",
    "Raukumara 17",
    "Raukumara 18",
    "Raukumara 20",
    "Raukumara 21",
    "Raukumara 24",
    "Raukumara 25",
    "Raukumara 9",
    "Rauoterangi",
    "Raurimu: South",
    "Redcliffe",
    "Repongaere",
    "Ridge Road - Okaia 2",
    "Riversdale",
    "Riversdale North",
    "Roaring Lion",
    "Roaring Meg",
    "Rotoitipakau",
    "Ruahine: Central",
    "Ruahine: North",
    "Ruahine: South",
    "Ruataniwha",
    "Ruatoria South 3",
    "Ruatoria South 4",
    "Ruby Bay - Moutere",
    "Sailor",
    "Saunders Road",
    "Settlement",
    "Shepherds Gully - Mana",
    "Skippers",
    "Sliver Stream - Merton",
    "Snares",
    "Solander",
    "South Wedge 1",
    "South Wedge 2",
    "South Wedge 3-6-10",
    "South Wedge 5",
    "Spey - Mica Burn",
    "Springbank",
    "Spylaw",
    "St Stephens",
    "Starvation - Ashley",
    "StoneJug",
    "Stonewall",
    "Sugarloaf",
    "Surville",
    "Takapu",
    "Taonui",
    "Tauranga Trough East 2",
    "Tauranga Trough West 2",
    "Tauru",
    "Te Anau",
    "Te Arawa 1",
    "Te Arawa 2",
    "Te Arawa 3",
    "Te Heka",
    "Te Ikaarongamai",
    "Te Puninga",
    "Te Rapa 1",
    "Te Rapa 2",
    "Te Tatua o Wairere",
    "Te Whaiti North",
    "Te Whaiti South",
    "Teviot",
    "The Humps",
    "Timaru Creek",
    "Tin Hut",
    "Tirohanga",
    "Titri Central",
    "Titri North",
    "Titri South",
    "Tokomaru",
    "Torlesse",
    "Tuakana 6",
    "Tuakana 7",
    "Tuapeka",
    "Tuatoru 1",
    "Tuatoru 2",
    "Tukituki Thrust",
    "Turi: Central",
    "Turi: North",
    "Turi: South",
    "Tutaki",
    "Upper Slope",
    "Uruti Basin",
    "Uruti Basin 2",
    "Uruti East - PorWest 1",
    "Uruti North",
    "Uruti Point",
    "Uruti Ridge 2",
    "Vernon 1",
    "Vernon 2",
    "Vernon 3",
    "Vernon 4",
    "Virginia",
    "Volkner 3",
    "Volkner 4",
    "Waewaepa",
    "Waihemo",
    "Waihi South",
    "Waihi West",
    "Waikaia",
    "Waikaka",
    "Waikuku",
    "Waimana - Waikaremoana",
    "Waimana: North",
    "Waimana: South",
    "Waimarama 1 & 2",
    "Waimarama 3 & 4",
    "Waimate",
    "Waimea Offshore",
    "Waiohau: North",
    "Waiohau: South",
    "Waiotahi: North",
    "Waiotahi: South",
    "Waipiata",
    "Waipukaka",
    "Waipukurau - Poukawa",
    "Wairarapa: 1",
    "Wairarapa: 2",
    "Wairarapa: 3",
    "Wairarapa: Needles",
    "Wairata",
    "Wairau",
    "Wairau 2",
    "Wairau 3",
    "Wairoa North",
    "Waitahuna 1",
    "Waitahuna 2",
    "Waitaki",
    "Waitangi",
    "Waitawhiti",
    "Waitotara 10 & 11",
    "Waitotara 8 & 9",
    "Wakamarama",
    "Wakamarama Offshore",
    "Wakarara",
    "Wanaka",
    "Warea",
    "Waverley - Okaia 1",
    "Wellington Hutt Valley: 1",
    "Wellington Hutt Valley: 2",
    "Wellington Hutt Valley: 3",
    "Wellington Hutt Valley: 4",
    "Wellington Hutt Valley: 5",
    "Wellington: Pahiatua",
    "Wellington: Tararua 1",
    "Wellington: Tararua 2",
    "Wellington: Tararua 3",
    "Wendon Valley",
    "West Balleny",
    "West Wakatipu",
    "Whakaari 4",
    "Whakatane: North",
    "Whakatane: South",
    "Whangamata West",
    "Whangamoa",
    "Wharanui",
    "Whareama Bank",
    "Wharekauhau",
    "Wheao: North",
    "Wheao: South",
    "White Creek",
    "White Creek - buried",
    "White Hill",
    "White Island 1",
    "White Island 2",
    "Whitemans Valley",
    "Woodville",
  ],
};
