export const initialResponse = {
  hazard_curves: {
    ok: true,
    locations: [
      {
        lat: -41.3,
        lon: 174.78,
        resolution: 0.01,
        code: "-41.30~174.78",
        name: "Wellington",
        key: "WLG",
      },
    ],
    curves: [
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226162851564697, 0.5225071434926885, 0.5219511689585403, 0.5205600858165771,
            0.5182110664855981, 0.5045907848122114, 0.46279612978046947, 0.41924968575270505, 0.3782781366623785,
            0.3417894871956879, 0.21570479700703785, 0.10665106940029663, 0.06444933152827789, 0.04392030435975493,
            0.032155620663523836, 0.011344565080320296, 0.005566827040802693, 0.00309845656262319,
            0.0018544364319251813, 0.0011662117386052784, 0.0007600030883115608, 0.000508461483714141,
            0.00034803334157079046, 0.00024299535639696673, 0.0001257580349044396, 0.00006942447768772335,
            0.00003907503121533839, 0.00002297484974201222, 0.000013548528376206648, 0.0000081234689617526,
            0.000005006375590532423, 0.000003131537573469268, 0.0000019699151191140397, 0.000001235642332257064,
            4.002239839540067e-7, 1.1171209324256637e-7, 2.3431448004096467e-8, 7.902218102096015e-9,
            7.853574457428181e-10, 2.0833335057091062e-12, 8.985034938291392e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504298984450783, 0.5503937150479198, 0.5500980556726425, 0.5492275273650556,
            0.5467842905991283, 0.5235184873128955, 0.4850040385979381, 0.4396621861623258, 0.3985316497109651,
            0.36201629142156466, 0.23397031659988068, 0.11939986293963711, 0.07362881657912315, 0.05074865380833566,
            0.03754902734124999, 0.01358820417705235, 0.0068623301018432906, 0.003943019645569533,
            0.0024303019211958108, 0.0015674543542228648, 0.0010450505330024343, 0.0007141580942907832,
            0.0004974377353983827, 0.00035344939888212236, 0.00018736524054239253, 0.00010524847564069246,
            0.00006159994633640041, 0.00003754431821308413, 0.000023629593101086788, 0.000015183545422866826,
            0.000009860670287908867, 0.000006629450898021361, 0.000004472033561229363, 0.0000030566369207127467,
            0.000001231373828853791, 4.892259584110548e-7, 2.103907097428248e-7, 8.851993790864891e-8,
            8.533725238377899e-9, 7.280354097360942e-11, 2.5726087926614127e-12, 7.257527911974648e-13,
            7.224221221235894e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244646117080166, 0.7244225931081795, 0.7238957680601844, 0.7235245745672844,
            0.7225874901068138, 0.7084845958940214, 0.6692476747061171, 0.6303850975689289, 0.5896692794790327,
            0.5515902059363461, 0.4060571854270251, 0.25034161026636453, 0.17380363096684248, 0.12966366419758665,
            0.10144726610824031, 0.04400648993412908, 0.026485534579810118, 0.01830900954730977, 0.01342654419775138,
            0.010224654446614715, 0.007998078194423549, 0.0064089398364320024, 0.005229040727788444,
            0.004294769490088744, 0.0029879259347740154, 0.0021265437655035467, 0.0015324015582541207,
            0.0011232450743675177, 0.0008218666973740128, 0.0006097081981262677, 0.00045811600960971055,
            0.0003484149943021597, 0.0002678341977461936, 0.00020932279855180732, 0.00011679630051659518,
            0.00006910658352559767, 0.000042158370359257624, 0.000025387864915818334, 0.000011672830832809566,
            0.000006562670861631759, 0.00000391352657891364, 0.0000024313310841295177, 0.00000155824095415813,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674635812969, 0.7000592486590735, 0.6998155335543467, 0.6991766726679614,
            0.6983726467212799, 0.6890786057071858, 0.6494615252973448, 0.6069566147635233, 0.5667901643710526,
            0.5288414653992636, 0.3848645042034303, 0.23400076427457922, 0.16083823461257937, 0.11910932895364282,
            0.09269064681452582, 0.039493636421170285, 0.02315747358920305, 0.015559271662387453, 0.01128873438777394,
            0.00855083682038666, 0.0066508212997108285, 0.005242623575216018, 0.004200211705816086, 0.0033715162046439,
            0.0022425661225489234, 0.0015279390908440947, 0.0010742022666992326, 0.0007705890930651371,
            0.0005637976011671874, 0.0004177970148432681, 0.0003133467650257772, 0.00023813710312043794,
            0.0001844276626886705, 0.0001447612194447578, 0.00008308709067136884, 0.00004834478234601465,
            0.00003159439485389548, 0.0000214170635691735, 0.000010213070043785244, 0.00000428937737340096,
            0.0000018436741813232516, 7.877957920454648e-7, 3.1014073433713797e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661437718699, 0.6216636915135251, 0.6215939520687526, 0.621286657487961, 0.6205892936609897,
            0.6194259835501446, 0.6071436298502202, 0.5663957574845311, 0.5222336555404151, 0.4809811051083862,
            0.4437496276079981, 0.3086443800868426, 0.1761889887823646, 0.11603981583983615, 0.0834776362155848,
            0.06367873496924129, 0.02571916003541444, 0.014444946528455387, 0.009295432762869076, 0.006431802431619955,
            0.0046550050723139615, 0.003474493726424447, 0.002652921755391717, 0.0020616876043879394,
            0.00162527591781676, 0.0010450477955124828, 0.0006965446992002924, 0.00047766867707860346,
            0.0003353728199183965, 0.00024022950053812438, 0.0001750994767729152, 0.0001296026224773458,
            0.00009725272791157011, 0.00007388304476040698, 0.0000567595828284793, 0.000030648567677187533,
            0.00001743891000684538, 0.000010363387083223836, 0.000006388495498097099, 0.000002650825209774428,
            0.0000012089552987203221, 5.944863058530271e-7, 3.091628207792741e-7, 1.6720649294185395e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226191408943033, 0.5225890271336867, 0.5224916454064713, 0.5221448857052813,
            0.5213995782469354, 0.5136920377618207, 0.5010847719396593, 0.48001798545793606, 0.45652786895893943,
            0.43484505932197204, 0.338980662201894, 0.21582294404228375, 0.1478822638184113, 0.10751127076791307,
            0.08179574414594931, 0.031101859602977698, 0.016465655819636934, 0.010042139199930208,
            0.0066054384603513264, 0.004547274852946606, 0.0032299359695926546, 0.0023522409905776817,
            0.0017455645623320334, 0.0013155165652653797, 0.0007723507711443913, 0.0004720669810751846,
            0.0002982028623324903, 0.000193926586505604, 0.00012885500789424733, 0.00008743383133119906,
            0.00006034052477126025, 0.00004252185321195187, 0.000030532953012401686, 0.00002223080498732689,
            0.00001028073147359516, 0.000004887804355614389, 0.000002367987794626991, 0.0000011884214663934359,
            2.7399565294405903e-7, 3.0879041310455275e-8, 3.930724967737831e-9, 1.6945933545287062e-10,
            7.920220035373404e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504301850673154, 0.5504264803279132, 0.5503878300056815, 0.5501842007389086,
            0.5499660131882609, 0.5434490387707388, 0.5207315476004353, 0.5009202934028436, 0.4783173257020599,
            0.45568623582010676, 0.35860357230279755, 0.23400762631050612, 0.1633231118154647, 0.12056852537185081,
            0.09280700015558598, 0.03671711697475851, 0.01985707590038921, 0.012316931262827537, 0.008237328614744363,
            0.005769938182481248, 0.00417565794549515, 0.0030950780935576584, 0.0023376562936186433,
            0.0017917633470229523, 0.0010943707652733238, 0.0006945712765745667, 0.0004522573296701379,
            0.0003009094996692132, 0.00020484356729222604, 0.0001420305413614873, 0.00010026145376737389,
            0.00007206806980416114, 0.000052361974710262923, 0.0000383242618102976, 0.000018368926611874023,
            0.0000092575713586796, 0.000004860033648327963, 0.0000025727459578987554, 7.081376548006919e-7,
            1.6225393574582603e-7, 4.5783348667782775e-8, 1.134074634201454e-8, 1.0159042496127313e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244649777900473, 0.7244597451601315, 0.7244247393417028, 0.7241768394319992,
            0.7238750145485863, 0.7209121259951318, 0.7012559390773777, 0.6800893883444413, 0.6596474322033873,
            0.6382453370964553, 0.5370034059383244, 0.3972719006927142, 0.30958456873770424, 0.2502655231480622,
            0.20786523489968947, 0.10442116286390972, 0.0645604671476853, 0.044657596899394615, 0.03319557079922579,
            0.025967443209497447, 0.020979820977474868, 0.01733006872517441, 0.014535990604171219, 0.01231659739457395,
            0.009149087014042418, 0.007004738942654848, 0.005481610600692532, 0.004362292526488188,
            0.0035174212363724257, 0.00285067634269536, 0.002320452811304162, 0.0019155177072517156,
            0.0015927464312373199, 0.0013347838868649562, 0.0008809352443356013, 0.000598245266853784,
            0.0004292123729835193, 0.00032210531975422494, 0.00019875687948844867, 0.00013121231934314344,
            0.0000938155147272024, 0.00006907709048353805, 0.00005203575695432445,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000670480414604, 0.70005358812806, 0.699964228252618,
            0.6996890918188506, 0.6972661738915102, 0.683828691750302, 0.6623575842050524, 0.6384459908729401,
            0.6156178696907932, 0.5152119288532241, 0.37725933983801063, 0.2916102996750582, 0.2341796377508886,
            0.1933824128954364, 0.0952807788895458, 0.058331890452679135, 0.03996252926280281, 0.029357538161144933,
            0.022616231654224085, 0.017945650038928312, 0.014620607401047757, 0.012143000086054404,
            0.010244453293078883, 0.007529513978921698, 0.00569553395135015, 0.004373232988405319,
            0.0034143148443998043, 0.002717927912756152, 0.0021994458460128587, 0.0017865986331635196,
            0.0014698037220058488, 0.001223709256067807, 0.0010266315835440747, 0.0006785405539954538,
            0.0004772589354439589, 0.0003632869675292001, 0.00028499659777780906, 0.00015055675230373028,
            0.00008369938443262314, 0.00005091038924996236, 0.00003253548346168995, 0.00002050609241930222,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661628953166, 0.621665293564439, 0.6216482806688433, 0.621579378394364, 0.6214220589811635,
            0.6211493276099582, 0.6177107234062686, 0.6022138651273112, 0.5809288183701089, 0.5579632412953628,
            0.5350774791928632, 0.4356996296333248, 0.30525022859330425, 0.22717842876706473, 0.17665195842880177,
            0.14197705893058857, 0.06415943746668387, 0.03764914690522631, 0.025082364711842258, 0.01799012666915671,
            0.01353586525135657, 0.010530417193603078, 0.008396173959382252, 0.006821791252799499, 0.00562589501308323,
            0.003959853906014806, 0.002884999203327037, 0.002157002879821078, 0.0016460269678342458,
            0.0012774021298106323, 0.0010055739220193724, 0.000801460709776736, 0.0006458229719091756,
            0.0005255598204679006, 0.00043153419834441085, 0.00027288789691861737, 0.00018006294544837322,
            0.0001231747937363556, 0.0000869121119211128, 0.00004675208714766127, 0.000027345470761787242,
            0.000017087987531262104, 0.000011244919181976848, 0.000007711618156187168,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226200781230104, 0.5226143966923523, 0.5225887721771247, 0.5225268421945025,
            0.5223801770663701, 0.5183917147527218, 0.5067586462539388, 0.49639841651928185, 0.4782845953322071,
            0.4597435551806379, 0.3762162961203949, 0.255084426377507, 0.18157208596711893, 0.1358950001200288,
            0.10550453243409702, 0.042341607190053955, 0.023210670061008985, 0.014722115806546556, 0.010102076042049676,
            0.007254182620964757, 0.005374432155745675, 0.004079857710548938, 0.0031554754741343763,
            0.0024771223412660024, 0.00158229334952565, 0.0010476922956970435, 0.0007131948380480248,
            0.0004952248141058814, 0.0003497972198448387, 0.0002519178622362801, 0.00018426200880262254,
            0.0001365530494898768, 0.00010246793195413575, 0.00007765221770583747, 0.000040412783747508243,
            0.000022095109182698135, 0.00001261670509562876, 0.0000072907495352958755, 0.000002613856045718954,
            9.88928653522514e-7, 3.6633658178292793e-7, 1.2469686014692286e-7, 2.8851771638471746e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.5504299440243442, 0.5504260864693008, 0.5503975918414635,
            0.5502597965476179, 0.5481141225761329, 0.5305558915069181, 0.5157101335264993, 0.4999715348721817,
            0.48165125239170076, 0.39590685315783786, 0.27381558945456225, 0.1983196932781468, 0.1501358847267565,
            0.11775332823916848, 0.04901710070264451, 0.0274478525164512, 0.017632212572450463, 0.012222891781080008,
            0.008892553662026105, 0.006686012359062258, 0.005151749239929722, 0.0040332612280839595,
            0.0032033490072415887, 0.002090150910944973, 0.0014131982142229875, 0.000983090466556005,
            0.000700402081566519, 0.0005086996038605358, 0.0003759684060840275, 0.00028152453236635874,
            0.00021268952874480958, 0.00016206883668924937, 0.0001247208283548074, 0.00006728581695372599,
            0.000038074677454495315, 0.000022393994212396618, 0.000013343177201119618, 0.000005105195450019548,
            0.000002040733516286516, 8.583853566453385e-7, 3.055916587557661e-7, 8.018461272740751e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244646360991227, 0.7244589755938797, 0.7244320447461051,
            0.7243143169945788, 0.7230961481364501, 0.7135104863326228, 0.6958155621577953, 0.6794035346203608,
            0.6632118430924798, 0.5778616195880407, 0.4424323688689631, 0.3510632215321738, 0.2868788000649421,
            0.23995725169462018, 0.12244048249645792, 0.07682146700499412, 0.05388036598507987, 0.04058431077322052,
            0.03213367864349195, 0.026307398824373003, 0.02209218952992575, 0.01884401117007074, 0.01625281847476557,
            0.012476218399872214, 0.009854279910627038, 0.007920243806283192, 0.006491068995823307,
            0.005408327712879704, 0.0045249621113750745, 0.003812303909175991, 0.0031809898771201135,
            0.002659566537328106, 0.002243248101703925, 0.0015204531464883104, 0.0010704695357542492,
            0.0007595236258438271, 0.0005570687362984932, 0.0003230389110352654, 0.00019965993949983064,
            0.000131916025765076, 0.00009274104186809646, 0.00006516185125160501,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000674950715742, 0.7000668528737124, 0.7000578998799916,
            0.7000315047686902, 0.698766230080918, 0.692382653465904, 0.6786331684553558, 0.6612679613891307,
            0.6426717132148433, 0.5558868824390227, 0.42150636831751215, 0.33207735026796403, 0.26992533757876747,
            0.22470629583650203, 0.11264683578443131, 0.06995023159643822, 0.048704235480437945, 0.03638640696287083,
            0.028500834119724705, 0.023047168470850535, 0.019072460429708664, 0.01609064938050036, 0.01377105572709404,
            0.010441604056588063, 0.008150072673488147, 0.006455459486127696, 0.0051871978009233, 0.004216223539693822,
            0.0034536679462346784, 0.0028541392573705124, 0.002382197286367001, 0.0019977699546434623,
            0.0016888679728559162, 0.0011316601975754456, 0.0007821507806790709, 0.0005612685475534968,
            0.0004075579601942229, 0.00023867833116756771, 0.00015845134215948707, 0.00011229960344533113,
            0.0000821607252854406, 0.00005428526650548505,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661866104946, 0.6216660879858429, 0.6216624411565996, 0.6216432374264846, 0.6215920254337233,
            0.6214933988722408, 0.6199587490038694, 0.6111667330772821, 0.5969391426634493, 0.5799733360857174,
            0.5618962934722612, 0.4747491663116797, 0.346984922244755, 0.2649551480237092, 0.20964223225256126,
            0.17060555443905917, 0.07963083034232322, 0.04762098790424315, 0.03229210862111603, 0.0235824385517914,
            0.018070995030776697, 0.014318272486470645, 0.011625321109698527, 0.009615719786011579, 0.00807023429450808,
            0.005876129534018033, 0.004421309953151408, 0.003407938309265567, 0.002676311854785686,
            0.0021335267717491847, 0.0017221090534435253, 0.0014047943479760063, 0.0011564919810937407,
            0.000959793996968126, 0.0008023161439622929, 0.0005267423848445702, 0.0003573903577499045,
            0.00024923334307006066, 0.00017794331394654616, 0.00009624358541485252, 0.00005556492788980606,
            0.000033822174417721764, 0.00002151604021889636, 0.00001420810684843074,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226201278873664, 0.5226169346745149, 0.5226000395475721, 0.5225549897790487,
            0.5224482470141321, 0.5189999950373497, 0.5064537186601015, 0.49568813389485594, 0.4764277845561122,
            0.4566229676063312, 0.36709544302949465, 0.24188440211117804, 0.16946628854808574, 0.12595320321280776,
            0.09742624236439128, 0.039528383055126994, 0.022018757665876517, 0.014132562860139749, 0.00980529819368698,
            0.007132611792386937, 0.005358085540700421, 0.004118607514253143, 0.003227269455649651,
            0.002564610094085404, 0.001679518753391127, 0.001139777467752455, 0.0007953380149421593,
            0.0005681705279559498, 0.0004148746034357882, 0.0003077995630594097, 0.0002318080102984942,
            0.000176564963475619, 0.0001356448647261869, 0.00010527705571272556, 0.00005804632108100281,
            0.00003361762263329194, 0.000020151640038208855, 0.000012274873431837884, 0.000004851558166772806,
            0.0000020654983120088805, 9.091842556311902e-7, 4.092377647868517e-7, 1.842412334756859e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.5504300903155013, 0.5504254766975698, 0.5504044648626147,
            0.5503164653824445, 0.5483484293557862, 0.5304382994783299, 0.5139787976987004, 0.49812714266979585,
            0.47865360446756905, 0.38669754580969395, 0.2599984914322545, 0.18525587954169395, 0.1388748817798723,
            0.10838623341337557, 0.045207488077471054, 0.02554190144924058, 0.016582024800953842, 0.01162679765851424,
            0.008552852926824221, 0.006500290908152615, 0.0050580473116880595, 0.004007262579035385,
            0.0032213298048214484, 0.0021529709788192337, 0.0014885364887806262, 0.0010575239589498875,
            0.0007684871494220963, 0.0005692740753828974, 0.00042848794355693383, 0.0003262267599441847,
            0.0002515494035966143, 0.00019592227773245453, 0.00015438414062778514, 0.0000881823929323552,
            0.00005276590525784975, 0.000032490480571427754, 0.0000205694505093299, 0.000008902795105161458,
            0.00000412687439675473, 0.0000019772808824671933, 0.0000010186761288905473, 4.82308483513172e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244648053380689, 0.7244573224263806, 0.7244311546346338,
            0.7243193231935913, 0.7231326592278912, 0.71407065427386, 0.6955244550684666, 0.6772551793808795,
            0.6598519919375881, 0.5681742044541993, 0.4238721113288023, 0.32896271262122784, 0.2640940284219305,
            0.2177652327145473, 0.10671004868922508, 0.06624404394102949, 0.046628074902474566, 0.03537967614652963,
            0.028218208348707384, 0.02324683437132019, 0.01959653862616585, 0.01677791479862131, 0.014527601162899407,
            0.011153613459018366, 0.00879367379442932, 0.0070425781006444145, 0.005728650021446278,
            0.004708295433432308, 0.0038986582771076824, 0.00325616404340021, 0.002744783797065652,
            0.002330243324678674, 0.001984298779442074, 0.0013510638723451063, 0.0009359577982898474,
            0.0006645400985334061, 0.00047798871182070654, 0.00026624684439173306, 0.0001587370830197952,
            0.00010040402228130866, 0.0000674308753509334, 0.00004733623907597461,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000674950715742, 0.7000667709649545, 0.7000612655854339,
            0.7000346759995317, 0.6987090367402893, 0.6920716299954426, 0.6771558713997716, 0.6592516683076799,
            0.6398817556749186, 0.5467934598261158, 0.4039028354975976, 0.31110809650056703, 0.24840700134287344,
            0.20371014442525726, 0.09835411092353558, 0.060556715157900204, 0.04236929865385248, 0.03192073681314814,
            0.025239995051519797, 0.020581463767107684, 0.017163432815072256, 0.014552027375524058,
            0.012499329145497643, 0.009498900654671067, 0.007423331168159658, 0.005906822665456904,
            0.004763450183496381, 0.003869985204058554, 0.0031720317228835413, 0.002625800030742277,
            0.0021906129256072738, 0.0018391593876340962, 0.0015546224606395734, 0.0010376494822916965,
            0.0007110777104048664, 0.0005001178979864032, 0.0003599396941393428, 0.00019878176651727135,
            0.00011515882944801468, 0.00007458761686182328, 0.000052189823090309395, 0.000037984095612420366,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216661654224257, 0.6216641275807292, 0.6216500084165597, 0.6216077015889009,
            0.6215207594568097, 0.6200273412941696, 0.6109517132692038, 0.5959906673198686, 0.5780216203781602,
            0.5587963698986333, 0.46578905537661647, 0.33130254579584234, 0.24774070001622941, 0.19305012532542296,
            0.1553609964557086, 0.07081300175194, 0.04226489359175086, 0.028794555237894648, 0.021166395514323703,
            0.016328125962097827, 0.013017080196062725, 0.01062629929042955, 0.008830624289821943, 0.007441010783358488,
            0.005452103834547859, 0.0041208764251366445, 0.0031868536766682864, 0.0025088216153508268,
            0.0020037123844880403, 0.0016196383051507739, 0.00132268342116798, 0.0010898550183601058,
            0.0009051124980541944, 0.0007570005525536885, 0.0004973055387544506, 0.0003373156799064376,
            0.00023493443473665028, 0.00016734641802553465, 0.00008979728747111631, 0.00005121456474894567,
            0.000030680390578959994, 0.000019136153970846382, 0.00001234761687941496,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226201278873664, 0.5226164183204118, 0.5225943992930919, 0.5225271142926685,
            0.5223918359579173, 0.5192849855069623, 0.5047611852402235, 0.49035910752186396, 0.4689204123959768,
            0.4465079009755187, 0.3462009939477822, 0.21660341696630775, 0.14766804945008907, 0.10771773675665453,
            0.08259225446088668, 0.03312352744779976, 0.018457585072424523, 0.011878802454786234, 0.008261070569275029,
            0.006027055355460922, 0.004540942469554713, 0.003504153419483286, 0.0027554230365827736,
            0.002196473007438491, 0.0014451565608501525, 0.0009859776440526164, 0.0006927493894161035,
            0.0004983426991711482, 0.0003660232764658098, 0.0002735737986298306, 0.00020753433168304333,
            0.00015968404179562165, 0.00012429931311704198, 0.00009775674164482841, 0.00005599883783591906,
            0.00003353519222299593, 0.000020672671728225236, 0.000013010466975344961, 0.0000055133786032433805,
            0.000002516131395322181, 0.0000012248185441432469, 6.035040414498672e-7, 2.7723808837532005e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.5504294017053988, 0.5504192986020472, 0.5503876048409112,
            0.5502694446253285, 0.5479565069802389, 0.528636582380993, 0.508728054649302, 0.4898579389917818,
            0.4676429271544374, 0.3652486418721256, 0.23288601650249233, 0.1609733864565281, 0.11853134525234808,
            0.09148648631911702, 0.037563437649967966, 0.021240329834578228, 0.01384149440780712, 0.009736335269940633,
            0.007182306794323868, 0.005474653523232455, 0.004272704683415568, 0.0033929113104172797,
            0.0027356653741586008, 0.0018443866363646024, 0.00128910344258489, 0.0009239346755210109,
            0.0006766884338784852, 0.0005046764695119765, 0.00038181884649679266, 0.00029288725467355083,
            0.00022750254499952287, 0.00017849953266783363, 0.00014142591635562358, 0.00008156991227830519,
            0.000049020327696487955, 0.000030529196916311645, 0.00001961900713047557, 0.000008810461467691872,
            0.000004313829582143969, 0.0000022034659584089815, 0.000001171227817203372, 5.929580884078689e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244637063978558, 0.7244516919085022, 0.7244094293503247,
            0.7243081530846452, 0.722593247795518, 0.7103741509259381, 0.6876360885038559, 0.6669177674981129,
            0.6459564811180384, 0.5396520798731251, 0.3854127045524678, 0.29024473010962454, 0.2280295728470149,
            0.18507126175908659, 0.08756554016237295, 0.054313952492371476, 0.03851366474945537, 0.029492858058838726,
            0.023656091089648962, 0.019550891823235363, 0.01648817886775699, 0.014106286488052698, 0.012192642767015637,
            0.009321594585180566, 0.00726171960776269, 0.0057553324061568375, 0.004623248202674331,
            0.0037541531784842657, 0.003079155854717539, 0.0025461727378486196, 0.002124227337846017,
            0.0017809843837240926, 0.0015014608472470098, 0.0010015033680326677, 0.0006901978409923858,
            0.0004865456320244073, 0.0003510798341759183, 0.00019151111238788847, 0.00011023921361726705,
            0.00006608674710336948, 0.00004102892966517668, 0.000026833223064463082,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000674572952295, 0.7000652682809478, 0.7000524853606387,
            0.6999938640337293, 0.6982228960604837, 0.688749715220325, 0.6697825584736432, 0.6478687366326901,
            0.6247704824452619, 0.5186425177031913, 0.36650378953206536, 0.2737643014339425, 0.21370610689556102,
            0.17245418970444978, 0.08053992060334479, 0.04956534501827099, 0.03493595694468687, 0.026535665303308736,
            0.02108579533894356, 0.017256377052505312, 0.014399020381139317, 0.012205954323563277, 0.010462272025115946,
            0.007892440873890932, 0.006108791425052238, 0.004820101930839012, 0.0038607542047973888,
            0.003129151579236281, 0.0025588208593322276, 0.002112210533709452, 0.0017567959143826577,
            0.001471741816909572, 0.0012398588765914766, 0.0008260360020493986, 0.0005640692471114497,
            0.00039501902036310745, 0.0002827870208132228, 0.0001537296667519646, 0.00008800061832567255,
            0.00005259942469193479, 0.00003291789704361231, 0.00002134000716386206,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216661747408057, 0.621664317521373, 0.6216487525347314, 0.6215991654737449,
            0.6214938339284889, 0.6196093829517986, 0.6082070900797276, 0.5899106300872244, 0.5684492923970792,
            0.545909544718899, 0.4409358618329964, 0.2988363021920114, 0.21639116699076738, 0.16502831473178334,
            0.1308868414887398, 0.05815819863316107, 0.0347356212797769, 0.02379087529004015, 0.017577389356677308,
            0.013611012119414874, 0.0108775178290635, 0.00889127910917865, 0.007391730037565873, 0.006226682049363842,
            0.0045530731180757605, 0.003430347898897874, 0.0026427697164550196, 0.0020720397926541566,
            0.0016480061445792557, 0.0013266238843665924, 0.0010790131575995564, 0.0008855813177220904,
            0.0007326592798137054, 0.0006104977215968967, 0.00039754155811537066, 0.0002674188000660247,
            0.00018477413506549342, 0.0001305946199847293, 0.00006899081631848502, 0.000038713163890028576,
            0.00002279266462154889, 0.000013951034293854568, 0.000008819146984941352,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226201088639462, 0.5226126892866649, 0.5225621431871608, 0.5224192497783449,
            0.5221265478118859, 0.5173587067475027, 0.49987667014886156, 0.4785613236155311, 0.451823323551197,
            0.42580299509044683, 0.31341336866819586, 0.1837893059443513, 0.12168149043459497, 0.08745315346770033,
            0.06659129995163347, 0.026601421191865024, 0.01489211846370115, 0.009598976697583014, 0.006677310848868674,
            0.004865892490642301, 0.0036601119783659674, 0.002819309211639893, 0.0022107943068015562,
            0.0017612740446884359, 0.0011564800610089306, 0.0007872689038255176, 0.0005521019325595395,
            0.0003964704534429097, 0.00029096606688704937, 0.00021701931569635086, 0.00016457567325670812,
            0.0001264267096281847, 0.00009843642903982348, 0.0000773959873947632, 0.00004431616165050533,
            0.00002652700656039845, 0.000016388336064683917, 0.000010384272384978279, 0.000004489590198136972,
            0.0000021062234302909934, 0.0000010421457021307947, 5.066162439322223e-7, 2.430903604577214e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.550427715056494, 0.55040527105713, 0.5503280286132931,
            0.55012069238531, 0.5455747541910602, 0.5204305082048749, 0.4985725338123255, 0.47279399756450063,
            0.44587466566525785, 0.3318582007485691, 0.19901659992904452, 0.13330933334713274, 0.09662315466370747,
            0.07400694823684055, 0.030240894726564722, 0.017169944724572093, 0.011212129360898968, 0.007892044199995904,
            0.005820357597114745, 0.004431082549698674, 0.0034504096771008994, 0.0027374196526663876,
            0.0022014411293870832, 0.0014754896753085879, 0.0010255756947128933, 0.0007322874630872308,
            0.0005350130904327005, 0.00039899761932009147, 0.0003026295778345797, 0.0002324411045002375,
            0.00018043941571154745, 0.00014135482797065957, 0.0001118485699498535, 0.00006471605457364594,
            0.00003911512194431382, 0.000024472139321176522, 0.000015765131836633786, 0.000007002714492561408,
            0.0000033811298891601765, 0.0000017759504301352265, 9.229061518643492e-7, 4.767124767157682e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244608617192902, 0.7244296720016533, 0.7243427731137829,
            0.7240568905086998, 0.7209671505626511, 0.7025796720326359, 0.6748855436188644, 0.6502664000110846,
            0.6244228231097766, 0.5036399871341714, 0.34329024576917533, 0.2510655945899736, 0.1934034090758162,
            0.15481876012174509, 0.07190215712374688, 0.044928040688155084, 0.032183487015007686, 0.024785364368288414,
            0.019919840735561915, 0.016428096607526567, 0.013798416337715893, 0.01175418531237247, 0.010116594221308658,
            0.007666218594580538, 0.005943257765372289, 0.00469234077770376, 0.0037554661637211927,
            0.003037710515651925, 0.0024798249621255757, 0.0020430177890795767, 0.0016953981339461472,
            0.0014159561482309643, 0.0011896754556677265, 0.0007868197487893749, 0.0005358830749259574,
            0.0003745193849367451, 0.0002672136871919209, 0.00014316432772142887, 0.00008133138807177325,
            0.000048296461525011364, 0.00002967092608893137, 0.00001883017323300873,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000670417554364, 0.7000583668102947, 0.699996590604184,
            0.6998404296843729, 0.6969817887352303, 0.681873946804818, 0.6571466941064565, 0.6297073534354674,
            0.6028856503955384, 0.482635580998632, 0.3250415466753882, 0.23574682047696938, 0.1804453862321806,
            0.1438440721176603, 0.0659797131329633, 0.040903513282044626, 0.02905042496434958, 0.022149831551754574,
            0.017594566362763922, 0.014363627247584571, 0.011949238331700151, 0.01008102562619957, 0.00860115101705496,
            0.006427961503362791, 0.0049297709168973245, 0.0038538253976582925, 0.0030557957088327,
            0.002458447403890289, 0.001996437437200048, 0.0016362479833919774, 0.0013524876813033064,
            0.0011263779811760584, 0.0009442801458888972, 0.0006230086305143106, 0.0004233662667702984,
            0.0002954238413321944, 0.00021036475343113285, 0.00011273036438642139, 0.00006386146368931507,
            0.000037922407390644786, 0.000023323811239454773, 0.00001471156525841954,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216661637513212, 0.6216614003641545, 0.6216267540321976, 0.6215253038240012,
            0.6213222417363657, 0.6181490170149695, 0.6016617287544537, 0.5776414037385407, 0.5509944824480342,
            0.5240303723426494, 0.40632194742180505, 0.26111654081257124, 0.18338697020935424, 0.13735779583056798,
            0.10778343271120557, 0.047374977131533685, 0.028464331295010047, 0.019601043276173454, 0.014522632294054566,
            0.011252311644381204, 0.008983666959580009, 0.007328166552207149, 0.0060755121518338865,
            0.005101652948778706, 0.0037043679723191094, 0.0027708174307362705, 0.0021196532920051405,
            0.001650775893492012, 0.0013046954495519458, 0.0010440939189262023, 0.0008445693810194464,
            0.0006896299056039479, 0.0005678295117664754, 0.00047104594256625634, 0.0003036864630131078,
            0.0002025216936631544, 0.00013886164478871574, 0.00009746289313422337, 0.000050843286799961934,
            0.000028197448082379978, 0.000016408795659557953, 0.00000992234895905531, 0.000006190242618185606,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226191196460916, 0.5225720321750046, 0.5223633264182238, 0.5217422250805045,
            0.5206882080359455, 0.5086423016653676, 0.48071460621875317, 0.44263582776087945, 0.40550340159309,
            0.37089981491161444, 0.24320700445378385, 0.12821119034755943, 0.08151065283794859, 0.05772952896173931,
            0.04366875678023632, 0.017459552000165933, 0.009773338231113503, 0.006260385342938717, 0.00430354424306839,
            0.0030938063284882446, 0.002296833953297825, 0.0017450729754416728, 0.0013502407446382707,
            0.0010610769632275715, 0.0006792555704347421, 0.0004524808549307927, 0.00031124889004928994,
            0.0002194619609312456, 0.0001583259709900562, 0.00011626450087495648, 0.00008670147398452583,
            0.00006560456954023304, 0.000050366930005977745, 0.00003907191870142966, 0.000021716030052083858,
            0.000012669864632203805, 0.000007606511300395585, 0.000004694521832956156, 0.0000019241893464281847,
            8.475314205426957e-7, 3.8765896510059e-7, 1.903143141974564e-7, 9.152962201543942e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504299825993612, 0.5504049313545291, 0.5502411511839116, 0.5498268669911639,
            0.5490566291516983, 0.5356719677240365, 0.5006954463364433, 0.4634318966418378, 0.42554116559998945,
            0.3904034426916687, 0.260532910627973, 0.14015435208603616, 0.08999751646471285, 0.06416489132472906,
            0.0488750628321917, 0.019976040474134135, 0.011373088900905834, 0.0074002975748870305, 0.005165492470926786,
            0.003767304065144647, 0.0028328171619867028, 0.002179491076656027, 0.001706300113941757,
            0.0013567818776371067, 0.00088581558657145, 0.0006006191293335759, 0.00041996973159435244,
            0.0003009425493604246, 0.00022014451735541068, 0.00016392069767569824, 0.00012406700438790037,
            0.000095222728208383, 0.00007391506933460265, 0.000058075749726935674, 0.00003307942311299053,
            0.00001962322396754637, 0.000012012726494872437, 0.000007593066656747408, 0.000003288540904936969,
            0.0000015511755514250966, 7.656855490306738e-7, 3.73716649636442e-7, 1.7735187213017305e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244645127852244, 0.7244316775208468, 0.7242605460496562, 0.7237170508714851,
            0.7231721481766911, 0.7139516989981091, 0.6760704648270318, 0.6396009811288533, 0.6015891491622638,
            0.5649759187179567, 0.42042031764288096, 0.26083891407868914, 0.18109783159416196, 0.13560449182364628,
            0.10699144119417606, 0.050097562718566496, 0.032143822072105394, 0.023301263096965518, 0.01789964594375496,
            0.01424696024468179, 0.011617683161118351, 0.00963640547152538, 0.008103465785360548, 0.00688061501115806,
            0.005084687311961832, 0.0038503556236561447, 0.0029744602743733612, 0.0023335441783889467,
            0.0018533671682366706, 0.0014875227750664015, 0.001205353657973039, 0.0009846748889150686,
            0.0008109738841140833, 0.0006729106738114893, 0.00043362886817882503, 0.00028908199398953717,
            0.00019840778509103399, 0.00013950895920892436, 0.00007342065198741197, 0.00004085033435822739,
            0.00002384446753352632, 0.000014397699396555962, 0.000008955264352846345,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000580039490837, 0.6999135332591843, 0.6994242736563389,
            0.6987213067937178, 0.6912495516904043, 0.6588610664847906, 0.6189817788010734, 0.5807458526388297,
            0.5445120367130115, 0.4006087822408808, 0.2450437406470993, 0.16883490302338497, 0.1257683959021605,
            0.09890376907579797, 0.04572745361359168, 0.028942891465047537, 0.02072342338798533, 0.01576958219246144,
            0.012441661744178009, 0.010066314258114066, 0.008285636321808213, 0.006922614662295612,
            0.0058408420721205775, 0.004274109162035977, 0.0032125143231666532, 0.0024662186102416025,
            0.001925328551788387, 0.0015249512460255546, 0.0012224852180111676, 0.000991026690075139,
            0.0008104345899861592, 0.0006681366439579337, 0.0005551435564748613, 0.0003577503348448941,
            0.00023792418517291103, 0.00016248231353799223, 0.00011339311965776821, 0.000058679858565758295,
            0.00003235313235865715, 0.000018625659546807623, 0.00001109308333330513, 0.000006799946344618686,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216655167315548, 0.6216279600697291, 0.6214445839051405, 0.6210107594106675,
            0.620264303418101, 0.6117059379381868, 0.5794978971274943, 0.5408909969258262, 0.5025559821560966,
            0.46660215849442244, 0.32976752523236064, 0.19147351983605793, 0.12827653429902897, 0.09396384999898914,
            0.07302036236929242, 0.03228682305844671, 0.01965439228764004, 0.013586719360207632, 0.010035509103866053,
            0.0077190897044700345, 0.006103407338613155, 0.004924476412590453, 0.004035876164965161,
            0.003349485417530296, 0.0023765978319577725, 0.0017390933115301193, 0.001303409287043192,
            0.000995962275850499, 0.0007734054056538042, 0.0006088823355732975, 0.00048508877083868536,
            0.000390517041774225, 0.0003173063050646815, 0.0002599661195538805, 0.00016295806879493133,
            0.00010603169523885209, 0.00007112386635564337, 0.000048936372675503264, 0.00002463935637288171,
            0.000013237116743893976, 0.000007475482885133719, 0.0000043886335877667365, 0.0000026559632981688756,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226199615471778, 0.522603501396363, 0.5222841781766163, 0.5209099195473719, 0.5181895538441197,
            0.5142637534448317, 0.49305221943182415, 0.4346835476891488, 0.3774506490019174, 0.32822581150379304,
            0.2867660021124113, 0.16367584135579524, 0.07908822179795304, 0.049244145056335696, 0.03463936048318489,
            0.02618107163820994, 0.0104156192705241, 0.005702143217632785, 0.003541263077412256, 0.002356086939334327,
            0.0016381966602781073, 0.001177801879960632, 0.0008678526638458098, 0.000652592275987405,
            0.000498821098537793, 0.00030322608159835614, 0.0001923551712578425, 0.0001266298861521666,
            0.00008573539035555718, 0.00005954732220536829, 0.00004228478834174372, 0.00003056219848799154,
            0.000022496261752320786, 0.000016796525606588807, 0.00001266879384201306, 0.000006523452006974663,
            0.0000034679164736513834, 0.000001901972841705376, 0.000001069997906281127, 3.457422222760858e-7,
            1.3033367383077657e-7, 5.438164153440424e-8, 1.832971829873742e-8, 7.993155692886944e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.550422051677084, 0.5501372122765807, 0.5491374734426255, 0.5465539898345544,
            0.542240062016996, 0.5136004468021745, 0.45518041584055935, 0.3972161795623515, 0.34704348116614525,
            0.3047779789956189, 0.17736253178723896, 0.08713147052430813, 0.054768756100476844, 0.03889876552029285,
            0.029595016161653536, 0.012087492511337287, 0.006762582044324024, 0.004278808551205082,
            0.0028947412113867715, 0.00204584038151342, 0.0014899764204826397, 0.0011100976029276598,
            0.0008430842209469445, 0.000650808191555353, 0.0004036210031970864, 0.00026128207948502343,
            0.0001748809619885841, 0.00012041094512418056, 0.0000850302723849109, 0.00006119263612491971,
            0.0000448306340747347, 0.00003336616519868368, 0.000025199402928844258, 0.00001927909191370504,
            0.000010326891479846307, 0.000005816905320907237, 0.0000033966108124152376, 0.0000020451254608258158,
            8.039360728772383e-7, 3.457736509117658e-7, 1.28476351446416e-7, 4.770679329357819e-8,
            1.7490104031736564e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244649952990247, 0.7244542829085276, 0.7241101937796187, 0.7231724222920938, 0.7211889996736656,
            0.718020508695717, 0.6899236174054332, 0.6295710921045188, 0.56979634542589, 0.5172434351808363,
            0.4701724029254178, 0.3130849698691998, 0.17475157830197915, 0.11703833264437927, 0.08693051719683348,
            0.06899566252627837, 0.03379825925390136, 0.021904308797466587, 0.015736320938787636, 0.011919564630092087,
            0.009349894067757294, 0.007507815452288591, 0.0061393677799045756, 0.00509484184576497,
            0.004281505501863455, 0.003110220026470338, 0.0023236594403606586, 0.0017731939250662698,
            0.0013721567598074728, 0.001078080689301712, 0.0008583763917847661, 0.0006915945669801755,
            0.0005632851406637604, 0.0004627439728832297, 0.0003832455538908443, 0.00024671095754718177,
            0.00016404701557718495, 0.00011215748033339089, 0.00007845549366650841, 0.00004072314795159018,
            0.000022429450931589342, 0.000012955001183121786, 0.000007764229900564246, 0.000004772896698445482,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000647751747476, 0.6997987273157243, 0.6987131653844247, 0.6969654896435713,
            0.6946104040400429, 0.6720241384757236, 0.6091562598404767, 0.5494063591296676, 0.4963586497960867,
            0.4501572351859717, 0.2954753885916634, 0.1627537283182332, 0.10826444536572764, 0.08008897305773437,
            0.06333705941286681, 0.0304023505227698, 0.01948198968956569, 0.013864338673540061, 0.010389426836464722,
            0.008012167468640386, 0.0063111784829215055, 0.005063355600812062, 0.004128483140420136,
            0.003413894582661387, 0.0024038822988497553, 0.0017465155167726476, 0.0012972825197407208,
            0.0009850309926597212, 0.0007610088319423287, 0.0005998837743653167, 0.00047861114904939185,
            0.00038612003367727245, 0.0003152577219561885, 0.0002595198728784216, 0.0001652356173511514,
            0.00010884644762169083, 0.00007297030584387045, 0.00005036855480502412, 0.00002565762423507751,
            0.000013957276827208042, 0.000007945872565083214, 0.000004680131718556361, 0.0000028420447431097664,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.621666082498689, 0.621651721219594, 0.6213452687088993, 0.6203157110286104, 0.6183980433212247,
            0.6156218984904561, 0.5927783986063238, 0.5318030062001946, 0.4726388628596724, 0.42089509133374525,
            0.3766979768830598, 0.2353103955875147, 0.1236868334637499, 0.08054362217927906, 0.05870584534443784,
            0.04576684110231885, 0.020709882167591974, 0.012604404972678928, 0.008589852874407016,
            0.0062184501042185664, 0.004678701860944986, 0.003617951012406453, 0.002856571561792102,
            0.0022931473326800564, 0.0018661690540263233, 0.0012773316105920978, 0.000905290210998203,
            0.0006594313400250302, 0.0004911758195917825, 0.0003727289206515762, 0.00028736285675504727,
            0.00022460238387792355, 0.00017766718295531884, 0.0001420380492032645, 0.00011463243168804907,
            0.00006949478380157109, 0.00004393488589484228, 0.000028730994695383076, 0.000019319751295067265,
            0.000009338690620289307, 0.0000048398748626838695, 0.0000026409473858048645, 0.0000014974909655414955,
            8.762151582614122e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226068495400105, 0.5222669439306481, 0.5165273324165905, 0.5071538668168728, 0.5008237264705967,
            0.4919077042389777, 0.43260702165963555, 0.327872144346957, 0.25441137710145584, 0.20413701762701328,
            0.16855767036400784, 0.08497620031145292, 0.039354330431458896, 0.024355920926260172, 0.01708201932935089,
            0.012842997277423876, 0.004788209065220877, 0.0023868432686229716, 0.0013476044191565281,
            0.0008162228718072458, 0.0005202391769796584, 0.00034372535116954155, 0.00023412992005044836,
            0.00016374155514987254, 0.00011703118637460759, 0.00006297974199006473, 0.0000360187264415087,
            0.000021610528788611187, 0.000013448996803813356, 0.000008613158739175244, 0.000005665333633889307,
            0.0000038035253952858368, 0.0000026021043675758557, 0.000001810324199989095, 0.0000012701998676229065,
            5.276778471028578e-7, 2.3057897691954178e-7, 9.833928638069978e-8, 4.21440732312206e-8,
            6.915491179704247e-9, 5.461859853284068e-10, 2.5726087926614127e-12, 9.952039192739903e-13,
            3.2795988147427124e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504220967117186, 0.5500918676600538, 0.5461499243851927, 0.5376024286402186, 0.5256941918730371,
            0.5138819646947488, 0.45397286884002847, 0.3472125925826292, 0.2724340546554596, 0.2202231309383658,
            0.18273310840009077, 0.09361877630861519, 0.04398916691083529, 0.027551690785429794, 0.0195233754550481,
            0.014793200122707515, 0.005700477494701159, 0.0029189460492353046, 0.0016823966259343671,
            0.0010404028406910887, 0.0006743932098812321, 0.0004538505030969927, 0.0003146211368087041,
            0.00022370843316005313, 0.00016244708992917036, 0.00009043608949759969, 0.000053183414550587734,
            0.00003267168873038132, 0.000020786524621043832, 0.00001363858185395768, 0.000009172039427090408,
            0.000006288587283968404, 0.000004356683577099381, 0.000003054434397934877, 0.000002170463407296114,
            9.729341190180859e-7, 4.2959239578264063e-7, 1.8972050175354041e-7, 8.8406803247413e-8,
            2.3027725504221053e-8, 4.134324216309437e-9, 1.0879741552116684e-10, 8.470668610982557e-12,
            7.257527911974648e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244489512819434, 0.7240082102867708, 0.7213822388205553, 0.7130266874940798, 0.6998819974639832,
            0.6873814088301802, 0.6296472543960511, 0.5201007260225542, 0.43614450346149697, 0.37223689949772254,
            0.3227130068811931, 0.18685766704820592, 0.09713698273013996, 0.06529567241727732, 0.04941985121952814,
            0.03984137060638194, 0.01979120293939918, 0.012487945512244511, 0.008666676912936697, 0.006374890826205148,
            0.0048375718788557795, 0.0037410704339766194, 0.002952281870236195, 0.0023606429123346944,
            0.0019157302708294383, 0.001308154795101744, 0.0009240424796401135, 0.0006709435128930359,
            0.0004972875406831557, 0.0003762631096849822, 0.00028869645755524555, 0.00022495590028202928,
            0.00017745452277735131, 0.00014189114130620992, 0.00011415220945225841, 0.00006658731398755219,
            0.0000406432793269691, 0.000025698704244818396, 0.00001671537133370963, 0.00000755067864837219,
            0.0000036855376453896684, 0.000001882229526017909, 9.735285926293358e-7, 5.214859308821929e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000643974112994, 0.6998104158385527, 0.6969625396528106, 0.6913089690356512, 0.682678936599066,
            0.6726010340111036, 0.610199398701856, 0.49914646298000376, 0.4152124148588587, 0.3520676632484339,
            0.30361669103242234, 0.17307396000471065, 0.08920771521330813, 0.059717777253718886, 0.04491910444207928,
            0.03595254966874539, 0.01750367609869416, 0.010746640499582853, 0.00717059708129215, 0.005051443682781823,
            0.0036948564225500603, 0.0027913302820963184, 0.0021601904177521954, 0.0016936309384469612,
            0.0013495652639385941, 0.0008963370650794735, 0.0006214971327225976, 0.00044474169868683714,
            0.0003268715778816797, 0.00024561649160484243, 0.0001878510986414561, 0.00014554612028935758,
            0.00011417685790767578, 0.0000906456862210076, 0.00007271808598607724, 0.000042957685322392276,
            0.000026355791932841577, 0.000016709930381513288, 0.000010859632446212616, 0.000004909397089414291,
            0.000002382875196108114, 0.0000012179119731614563, 6.440092344327324e-7, 3.513866583260139e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216476884979943, 0.6212942493101236, 0.6181075798291976, 0.611529028831222, 0.6024924792646197,
            0.5918811690452229, 0.5311806255607342, 0.4223063212687011, 0.34259795835626033, 0.28469826866998516,
            0.24162346848966715, 0.1317937412170539, 0.06556726701325155, 0.04284719588456789, 0.031539661417632085,
            0.024763244344888722, 0.0110433332918236, 0.006388310493986755, 0.004112909104031562, 0.0028183875092531617,
            0.002015073952878801, 0.0014867648596017498, 0.001124248407107426, 0.00086720797601926,
            0.0006800937408845567, 0.0004354223682593229, 0.0002909074207483813, 0.00020097095281268785,
            0.0001426621733281215, 0.00010358480327321716, 0.00007666539471154857, 0.00005768274463608137,
            0.000044027087427549375, 0.000034032236123315354, 0.000026601277763539933, 0.000014977186219410008,
            0.000008856152663405564, 0.0000054451832282860165, 0.000003456174823260791, 0.000001496990045380648,
            7.00801667768499e-7, 3.4743950164543247e-7, 1.8063336766562584e-7, 9.600619194880542e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21659161118160403, 0.13247479688936467, 0.07783494957666962, 0.05655940856085776, 0.04501708264559931,
            0.03762081206764487, 0.021254169285473197, 0.011384683274628337, 0.007455376335172197, 0.005272616948655012,
            0.003897853265902468, 0.001159768411745374, 0.00019997005501577847, 0.00005264007465364029,
            0.00001729256106015541, 0.000006527016820613867, 1.4670755479533015e-7, 2.7236023436572054e-9,
            2.0833335057091062e-12, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.23444589412497266, 0.14512645244921746, 0.08618220204270614, 0.06314721009663962, 0.05051291067297597,
            0.042427416004980935, 0.02439552833374664, 0.013418669230201083, 0.009000467837136159, 0.006508641484375764,
            0.004907368553638407, 0.001612217013677341, 0.0003204412787352951, 0.00009374076361989623,
            0.00003299430303553519, 0.000013045121237616046, 3.890603049727659e-7, 1.8468334779944223e-8,
            1.297539853339913e-10, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4169457772412231, 0.28794936524859555, 0.18553889318419517, 0.14140870745777986, 0.11650832043312034,
            0.10034230561231863, 0.06416728241435377, 0.042019162576150626, 0.03251030980346044, 0.02660848811171235,
            0.02243352166495614, 0.01156100149559472, 0.004627607771462675, 0.002347506347667502, 0.001279568571317581,
            0.0007787459063183056, 0.00014720106337140493, 0.000038228897944581064, 0.00001682978499106813,
            0.000008035016867524725, 0.000004043006579079744, 0.0000021329334954911516, 0.0000011595118394325254,
            6.06640965017391e-7, 3.216051425170008e-7, 9.961661362467567e-8, 3.4158881168089295e-8,
            1.3122925079933623e-8, 4.315102164476059e-9, 3.596777320424849e-10, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.39245792049327116, 0.2676583493119632, 0.17064472489559737, 0.12942466169309638, 0.1062943440557935,
            0.09138620556075226, 0.05812491075020921, 0.03761003719324825, 0.02872229569120904, 0.023244314602227898,
            0.0193742217508879, 0.009491731446298201, 0.003491767696730061, 0.0016159936164638186,
            0.0008766581287121943, 0.0005296352976483771, 0.00009351017396582496, 0.00002538303844057399,
            0.000007596451446212349, 0.000003018922341668784, 0.0000012624554257545384, 6.467977169410943e-7,
            2.686559241737996e-7, 1.0892132806805677e-7, 6.229132054613729e-8, 9.867878070224378e-9,
            3.2795399729224073e-10, 1.3876233495579982e-11, 7.920220035373404e-12, 7.920220035373404e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.31107811641792843, 0.20349819341100467, 0.12600203874101004, 0.09437274702857446, 0.07687578788160729,
            0.0656319449970039, 0.040418804131686706, 0.024713574209214526, 0.018077953483561005, 0.014144084445781116,
            0.011466611003239943, 0.005103300728107834, 0.0016368176074935527, 0.0007029014903725132,
            0.00035625976569068457, 0.0002017641920320593, 0.000028381191046292452, 0.000007765189837374862,
            0.000002799681039277369, 0.000001171478554902186, 5.403854407639417e-7, 2.6788326165232624e-7,
            1.3966450169058203e-7, 7.568327548585289e-8, 4.25344772268025e-8, 1.4537427839274688e-8,
            5.244324772131307e-9, 1.8825438315253867e-9, 5.86922410902404e-10, 1.1008105832921181e-10,
            1.3683194228187467e-11, 1.4765720381318537e-12, 1.161107497971139e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5224598266235109, 0.519453131378316, 0.5051202695029793, 0.49147936646965495, 0.4725155623983956,
            0.4520885698106642, 0.3594248581798744, 0.23768404656752679, 0.17194550190563454, 0.1327872385997002,
            0.10714418074536074, 0.052088358903773335, 0.02382279006309395, 0.014604870773018043, 0.01009307266645758,
            0.00744963220120821, 0.00247734613367597, 0.0010982861274063769, 0.0005547497593190941,
            0.00030425408864849805, 0.00017721107214496837, 0.00010803933925792789, 0.00006846881874185297,
            0.00004484574187344943, 0.000030175419233883823, 0.000014621769213643969, 0.000007562540771982841,
            0.000004101953113214416, 0.0000023231094155881613, 0.0000013554025601558582, 8.089895207139751e-7,
            4.893796448439413e-7, 2.941341595955179e-7, 1.7233108983909952e-7, 1.025697518406801e-7,
            2.8567031629123107e-8, 7.518273115181273e-9, 1.489720791170157e-9, 3.1480340556555575e-10,
            2.4830137945741626e-12, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.550258766722722, 0.5480213910249411, 0.5336437793078116, 0.5135425138149088, 0.49352166937502995,
            0.47318554234433663, 0.3786185500520238, 0.25441779811095844, 0.1857586207710941, 0.14417195309620498,
            0.11688673197570544, 0.05777482902103652, 0.026967896812854796, 0.016769089567975345, 0.011735255193810147,
            0.008750814845374908, 0.003039235529041956, 0.0013979976607669806, 0.0007270511179761248,
            0.00041044291848209736, 0.0002451775749170432, 0.00015345467454675648, 0.00009958160897148926,
            0.00006662977642821113, 0.00004576841050965481, 0.000022965345262621906, 0.000012213658032249114,
            0.000006858458543735578, 0.000003996650123951457, 0.000002401741662552975, 0.0000014784563851904767,
            9.404155470097026e-7, 5.925495929633391e-7, 3.7436916903492474e-7, 2.3784499264714817e-7,
            6.955873694787454e-8, 2.5615520371502498e-8, 6.431994492572812e-9, 7.130384060971551e-10,
            1.2192025167223619e-11, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.724244675716172, 0.7224486952315375, 0.7084453646988909, 0.6869327163616169, 0.6686334974311896,
            0.6480399913818703, 0.5520115697235912, 0.4144956405066832, 0.3271296858010384, 0.2683534150377869,
            0.2265488136341806, 0.12497021418435464, 0.06553898556063562, 0.04505132133953049, 0.034489316931403786,
            0.027945947912812064, 0.013831034470473853, 0.008604202003503293, 0.005788601373254396,
            0.004072526671221046, 0.0029935164705367245, 0.0022597174902923367, 0.001754659954374363,
            0.001389096946906279, 0.0011158410672382066, 0.0007491270906072044, 0.0005108190242331932,
            0.000354284351787947, 0.0002508531433657815, 0.0001817208082355032, 0.00013432441540268554,
            0.00010046359145676139, 0.00007630906824379106, 0.000058505922701714123, 0.000045340009365869194,
            0.000025140910140343336, 0.000014395484484764251, 0.000008669877938650316, 0.00000557374554504506,
            0.000002539009214763688, 0.0000012337339608954757, 6.26797786895672e-7, 3.2418411466395014e-7,
            1.670371659878711e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6999812791507412, 0.6980616747610479, 0.6880629711601677, 0.6713589778019081, 0.6511808501923034,
            0.6296307674292106, 0.5309910858017205, 0.3931948042734976, 0.3072840009495028, 0.2502148131499857,
            0.20993407556351806, 0.11440202980356858, 0.0596443132142828, 0.04060310605517084, 0.03083260198627206,
            0.024760073564084297, 0.01153765329670774, 0.006668223701405921, 0.004318785585836039, 0.003005651125115356,
            0.0021333538695389614, 0.0015596382496433252, 0.001176661030466113, 0.0009112000924573146,
            0.0007193655384662862, 0.0004709266527802078, 0.00031550652137864865, 0.0002179625027838498,
            0.00015492460820842288, 0.00011203758892334471, 0.00008248968919770383, 0.0000617203229201795,
            0.00004684628350065063, 0.00003606132795714, 0.000028165001143408172, 0.000015992151777774846,
            0.000009337803908209021, 0.000005564179909166483, 0.0000034340624961437527, 0.00000140210079280223,
            5.841880579282588e-7, 2.614590925986704e-7, 1.1719830439460566e-7, 5.243518419462134e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6214792612593757, 0.6195158352215367, 0.6083684233272632, 0.591153855143679, 0.5712932734981487,
            0.5505737741035195, 0.45418769926892316, 0.32249011258405796, 0.24479753474979568, 0.19531278916754666,
            0.1615557849546292, 0.08462637761792485, 0.04240843770032715, 0.027984907691846663, 0.02063954657064503,
            0.016143204900229104, 0.006842099743037892, 0.0037409944018267235, 0.0022930833231358653,
            0.0015066174646883254, 0.0010385892727661674, 0.000742023324041218, 0.00054516673561972,
            0.00040968993269363123, 0.00031370901314740115, 0.00019248481874341026, 0.00012391051743532413,
            0.00008281860635068846, 0.00005706315487263209, 0.00004032050471468154, 0.000029101292649787953,
            0.000021390420798480987, 0.000015974219479302584, 0.000012097595959082073, 0.000009276451661638385,
            0.00000499729892335363, 0.000002839443747225939, 0.000001684138404402482, 0.0000010353361789869416,
            4.2262752917600953e-7, 1.8603207539376229e-7, 8.624155999110805e-8, 4.057016779909197e-8,
            1.8666774483271887e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5133544497598906, 0.498911320448914, 0.4500066559543509, 0.40194815938294326, 0.358961470271088,
            0.32215704586915944, 0.20600518502343354, 0.11572916168500298, 0.07959102749968952, 0.060336622693908104,
            0.04839852865157603, 0.023555341682026967, 0.01062196582375631, 0.006263773720045911, 0.004120133445952534,
            0.0028776437053203213, 0.0007106776561710404, 0.00023837848866755252, 0.00009503234414889529,
            0.000042685551185503396, 0.0000208172543632375, 0.000010852742111057136, 0.000005975565103032243,
            0.0000034099603152137916, 0.000001997728462423359, 7.301111474048623e-7, 2.8446242806107824e-7,
            1.068562610429729e-7, 4.395811796920495e-8, 1.9385594882397425e-8, 8.018198938566085e-9,
            2.331202009209221e-9, 1.884521427797381e-10, 2.6902924332716793e-11, 3.0088154190366367e-12,
            7.257527911974648e-13, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5439702039939831, 0.5205476371399511, 0.47222454735599406, 0.4226804199221805, 0.37893714202439904,
            0.3413742832700989, 0.2214461255649862, 0.12626018421965046, 0.08753487142984182, 0.0667588452976252,
            0.05381148635321754, 0.026680541779661136, 0.012340197541522784, 0.007424537415379113, 0.004970146225581337,
            0.0035199240283539683, 0.0009291911126471493, 0.0003314454914102789, 0.00014027962453839582,
            0.00006651134224806782, 0.00003431743082527528, 0.000018831855754158558, 0.000010889495958381297,
            0.000006584791903934267, 0.000004086179436474204, 0.0000016635180188633809, 7.420746453925812e-7,
            3.38400958569148e-7, 1.6917556602561078e-7, 8.123693207018334e-8, 3.1923858466399425e-8,
            1.4328609743152754e-8, 6.244298522695146e-9, 2.6369110076799903e-9, 5.734019925540679e-10,
            2.5726087926614127e-12, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7203116402416143, 0.6973641485344323, 0.6515615882667851, 0.6033741838047058, 0.559360823137153,
            0.5197879250766285, 0.3784567009152568, 0.24212404627385484, 0.17780092633633088, 0.14086155687918162,
            0.11687516923941695, 0.06477209218181891, 0.03609102633076956, 0.025247574189178286, 0.019274523471821636,
            0.015431974684496221, 0.006906788952375267, 0.003752710831639662, 0.002291535210956563,
            0.0015072179847868221, 0.0010077162315355061, 0.0006946352350040712, 0.000492252857980641,
            0.0003611370749349024, 0.0002696508793320345, 0.00015961690888993004, 0.00009936897818399084,
            0.00006492097582908982, 0.00004436072653522374, 0.00003138600166929949, 0.000022816060458834367,
            0.000016921774047766533, 0.00001275737258377152, 0.000009749800122360597, 0.000007542539344096433,
            0.000004147825090949908, 0.0000024032507079940757, 0.0000014432165049704793, 8.945301748219947e-7,
            3.617834788416374e-7, 1.4680560522783992e-7, 6.646883687899674e-8, 3.298927819805897e-8,
            1.6900395083396802e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6966415403040054, 0.6805768466702873, 0.6315819607022485, 0.5825962261109148, 0.5378095586743644,
            0.4981322421562092, 0.3579237098354046, 0.22513847061605166, 0.16394837374978416, 0.12928263654605482,
            0.10701005167385524, 0.058952137364351585, 0.03228329831528574, 0.022221120081695415, 0.01668646616935315,
            0.013077616207761072, 0.005215785860075431, 0.0027446484776795757, 0.0016032929901394295,
            0.0010133545989959897, 0.0006851946633356471, 0.0004730110330025379, 0.0003317008150845027,
            0.00023813481232809493, 0.00017484856161037305, 0.00010099841953977794, 0.00006102102819531075,
            0.00003861659093705247, 0.000024838184639992913, 0.000016395162107215873, 0.000011203385185134171,
            0.000007519915059314721, 0.000005346384300719542, 0.000003825038988162, 0.0000027999631145025846,
            0.0000013796619668937637, 7.326979175603654e-7, 3.816308264958934e-7, 2.1868439148420293e-7,
            6.003119068509477e-8, 1.6291123117184725e-8, 3.5108871365707728e-9, 2.7076485498156444e-10,
            5.854872142663226e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6171658215224473, 0.5996838615764111, 0.5509930604787316, 0.5018607535681606, 0.45761044240582155,
            0.4188893625559419, 0.2883936277776768, 0.1740029646350439, 0.12414606403599626, 0.096572357576729,
            0.07912501132826873, 0.0419736089776499, 0.02165622202187707, 0.014220382094619101, 0.010260948639513389,
            0.007796447708693611, 0.0028326035693215963, 0.0013625679707011992, 0.000751676988559132,
            0.000451429291417681, 0.0002876305795294228, 0.00019155192131638075, 0.00013207144235455255,
            0.00009366229776413632, 0.00006799819573915543, 0.000037940108902626316, 0.000022487865125382716,
            0.000013977120514341967, 0.000009024181589918148, 0.0000060134307576841235, 0.000004116471195053466,
            0.0000028846904285184173, 0.000002062491660771055, 0.000001500388914524749, 0.000001107581591428483,
            5.475465462755337e-7, 2.8772958972325776e-7, 1.5840694951495524e-7, 9.042137180115577e-8,
            3.181788921359202e-8, 1.2506871944322583e-8, 5.575722313337648e-9, 2.765344656986623e-9,
            1.425385073615105e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5008565146293997, 0.45623809617862576, 0.3681902552409621, 0.30153867617812025, 0.2530791111526316,
            0.21706642171077456, 0.12566921176919055, 0.06822093914536405, 0.046761632089900765, 0.03546981475643569,
            0.028454329923473656, 0.013718932722627511, 0.00588651295975086, 0.0032534692568521434,
            0.0019919160189955853, 0.0012914015981824764, 0.0002256793152185907, 0.00005814469701642221,
            0.000018895606686580477, 0.000007116440582350222, 0.0000029493514825035798, 0.000001314919206297299,
            6.163176074752788e-7, 2.9318739489386774e-7, 1.4063249011013526e-7, 3.279223703689382e-8,
            7.731291828960707e-9, 1.3414095390018588e-9, 4.601119485414529e-11, 2.0833335057091062e-12,
            9.952039192739903e-13, 3.2795988147427124e-13, 3.2795988147427124e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.522854712394536, 0.47723376796355294, 0.388431134284276, 0.32057170941716967, 0.27077219090513804,
            0.23343255249132117, 0.1370922660701514, 0.0753511829519855, 0.05212907641029128, 0.03979606771555099,
            0.03211159718457098, 0.01584377305985921, 0.0070387718853299475, 0.003990127233067094, 0.002502519539704589,
            0.0016609919940827108, 0.000322052290277359, 0.00009199465947432492, 0.00003302480241905769,
            0.000013492461608200922, 0.0000060238592058939935, 0.0000028824832232082966, 0.0000014526406757628708,
            7.618044389938753e-7, 4.0878654180376846e-7, 1.2915073011043887e-7, 4.7050311589148966e-8,
            1.5160006583414543e-8, 4.726776747787653e-9, 5.026613569825145e-10, 6.13096240442701e-11,
            9.952039192739903e-13, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.69736168463809, 0.6566909831291815, 0.5687601274216694, 0.4966855004645955, 0.4392689566077883,
            0.39298855167283103, 0.2566083796143272, 0.15369880585887558, 0.1117868943963923, 0.08902864801725227,
            0.07472075297029471, 0.04385722595201851, 0.025114105797199592, 0.017424203389360755, 0.01308707166019813,
            0.010258694915039545, 0.0040122859803957064, 0.0020194016839745793, 0.0011260314378682258,
            0.00067911995374279, 0.000437043580011443, 0.0002929268290969191, 0.00020578284565481685,
            0.00014867614257597044, 0.00011059255130428933, 0.00006494305721949103, 0.0000402627502774644,
            0.000026485001247695738, 0.000018121674901577402, 0.000012756647071055066, 0.000009184341207823836,
            0.000006743936916651342, 0.000005031495259322227, 0.000003804512371918811, 0.0000029108296790703037,
            0.0000015602027763073067, 8.405189337867824e-7, 4.7240574585494244e-7, 2.7445968098227524e-7,
            1.0720720777790405e-7, 4.6764826011624905e-8, 2.1167291253121334e-8, 8.845527199596859e-9,
            2.819511957241616e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6825386888744306, 0.6360972470913129, 0.547221616712274, 0.47519547990897193, 0.4181061884688153,
            0.37261129154497474, 0.2402377475321741, 0.14239406896098453, 0.1030580049537902, 0.08182201674677608,
            0.06847857042060068, 0.03948093522843129, 0.02197005906279121, 0.014855411305945289, 0.010758850963158352,
            0.008149626405917768, 0.002925602251632459, 0.001398728580383484, 0.0007617092105400339,
            0.0004410252130293336, 0.0002713267305051731, 0.00017870277564991147, 0.00012257920893473284,
            0.00008267363587355662, 0.000057573942676758714, 0.000028958779787186455, 0.000016683298811785207,
            0.000010190050128721048, 0.000006096950731126138, 0.00000381594251830375, 0.0000024485810871111013,
            0.0000016738878753159206, 0.0000012336226165032826, 8.418456900471938e-7, 5.743979822891987e-7,
            2.28027623644561e-7, 9.859872562856253e-8, 4.305543654936628e-8, 1.532460736086705e-8,
            7.298375237496657e-10, 4.567812794675774e-11, 7.920220035373404e-12, 7.920220035373404e-12,
            7.920220035373404e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6017920531111857, 0.5561719877354421, 0.46704528740775214, 0.39695245832395604, 0.3433501426956122,
            0.3018015329386414, 0.18717193396675266, 0.10757982144474171, 0.07652192463604364, 0.0598890833799648,
            0.04945816635960736, 0.02697056453355468, 0.013875664700088915, 0.008842236361362584, 0.006160296319495762,
            0.004522385744851996, 0.001428980494464921, 0.000621394044277823, 0.0003167227421403853,
            0.0001782957541705551, 0.00010762229839345268, 0.00006846401329780365, 0.00004539216670154143,
            0.00003112451254371702, 0.000021948781875940122, 0.000011679447742214032, 0.0000066761598066859745,
            0.0000040374678236215525, 0.0000025538036986145958, 0.0000016742219572033306, 0.000001130295556901872,
            7.831255462344337e-7, 5.540973945997981e-7, 3.9890067991612853e-7, 2.9187099116679156e-7,
            1.4233636016416366e-7, 7.394353557316602e-8, 4.0679300355856904e-8, 2.3410017593776582e-8,
            9.064513716422954e-9, 3.979864361381228e-9, 1.8203112017401077e-9, 7.817032664856825e-10,
            2.645927915762051e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4673477479445716, 0.3871691790272474, 0.27523917280797444, 0.2106419525596775, 0.17027638907539305,
            0.14293935449591402, 0.0802260794563926, 0.04352946422818893, 0.02993295258700466, 0.022726314921663,
            0.01821669154289691, 0.00859033210329903, 0.0033797785639140604, 0.0016826676444217435,
            0.0009250480171101944, 0.0005402289162857249, 0.00006306969849476495, 0.000012503440611324731,
            0.000003327630968631584, 0.0000010645804953829491, 3.74505226501647e-7, 1.4344945448305708e-7,
            5.177891767363745e-8, 1.625896936567014e-8, 4.917217300004495e-9, 2.1425883289794e-10,
            2.5726087926614127e-12, 4.807265696626928e-13, 3.2795988147427124e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4878875275876391, 0.4078548824567278, 0.29432438715767134, 0.22723264964633244, 0.18474800074188477,
            0.15576450043342244, 0.08847726018115709, 0.04868143637796891, 0.033809931448532116, 0.025857608021316678,
            0.020879283114853632, 0.01014290274245359, 0.004175858186331927, 0.002162172157659835,
            0.0012387105480755804, 0.0007517631216550579, 0.00010397005573632709, 0.00002412443399062362,
            0.000007252348216076478, 0.000002486389325370751, 9.54007663130762e-7, 3.860865360567809e-7,
            1.5952291252771097e-7, 7.330949824968513e-8, 3.322631925151143e-8, 5.9110417671703885e-9,
            1.5226697680503776e-10, 1.7467138846427588e-12, 7.257527911974648e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6682513129053984, 0.592605031176842, 0.4721934221149302, 0.39005631818621767, 0.33176765687972953,
            0.28889540360991983, 0.17820084736531763, 0.10572111126247381, 0.07796481631075983, 0.06310957868902493,
            0.05374403775422183, 0.03244460377939313, 0.018065247672971716, 0.011958824876143057, 0.008575624635729159,
            0.006494729748555241, 0.002180485729156278, 0.001032643554677613, 0.0005788038743431531,
            0.00035519949431923675, 0.0002194791902536934, 0.00014253908690672784, 0.00009632389430922382,
            0.00006723054210684007, 0.00004820382922485322, 0.00002641194423291804, 0.0000154809193172456,
            0.000009533704705577684, 0.000006095353914936652, 0.000004017436363579208, 0.000002714193059194514,
            0.0000018783846091885437, 0.000001327162266751003, 9.576137226519066e-7, 7.004436933799596e-7,
            3.4029258455525735e-7, 1.795989714725721e-7, 9.891202634726426e-8, 5.64552504744853e-8,
            1.850657693758251e-8, 4.963851774064665e-9, 4.930278407755395e-11, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6477791997841544, 0.5703746353016234, 0.45051893959162786, 0.36970271435434493, 0.3130907788994563,
            0.27165890369627477, 0.16588057908832524, 0.09759142225873486, 0.07164115066578351, 0.057735882917916745,
            0.04891583487404755, 0.028725146154826353, 0.015533642179343311, 0.00999228888130355, 0.00691467905350561,
            0.005032294266966356, 0.0014766306679963456, 0.0006225744024923729, 0.0002933589960514191,
            0.00015475997206131808, 0.00009262266529179378, 0.000058823706330915275, 0.00003748493184185797,
            0.000025149867510155206, 0.000017186199833707505, 0.000009626410960361653, 0.000005813030747781121,
            0.0000032512712070387195, 0.0000018725188569712614, 0.0000012591790436999872, 7.594840099045541e-7,
            4.688218950832379e-7, 2.9235619081191544e-7, 1.8443515159560775e-7, 1.2250235592592418e-7,
            4.4166096557773926e-8, 1.1786508657962713e-8, 2.2373507491124656e-9, 1.8405743595906188e-10,
            7.920220035373404e-12, 7.920220035373404e-12, 7.920220035373404e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5678817123751964, 0.4883431218964183, 0.37111006623992354, 0.2969121396219307, 0.24724297326707778,
            0.21201498399326563, 0.12577233666517482, 0.07216939539005118, 0.05192493508811683, 0.041067744902018165,
            0.0341849542760326, 0.018806859403739485, 0.00927662788843872, 0.005593301258817796, 0.0036973666675647505,
            0.0025878238512560075, 0.000680901618370617, 0.00026066948974410654, 0.00012084964171915795,
            0.0000632680676993939, 0.0000360917355463245, 0.0000219557481074216, 0.000014044846366538382,
            0.000009356001371954277, 0.000006442410236979564, 0.0000033068342723676124, 0.0000018405656135141152,
            0.0000010878100652432683, 6.726555564819412e-7, 4.32410858480135e-7, 2.866472841397251e-7,
            1.9497808412937002e-7, 1.3558422933759278e-7, 9.630009547145537e-8, 6.957787402203582e-8,
            3.2729216557414824e-8, 1.6729821016854802e-8, 9.036833827068227e-9, 5.082880137844883e-9,
            1.6567777815349978e-9, 4.691043981109612e-10, 2.711844898400674e-11, 1.0877384663249812e-12,
            1.0877384663249812e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4163169638293348, 0.3121523309072931, 0.2024033881038793, 0.14954146374540156, 0.1190472946681678,
            0.09923345083679005, 0.05544851964536318, 0.030166841010300843, 0.020746053796369936, 0.01570319214699558,
            0.012523044656208626, 0.005607632226096022, 0.0019195216357283855, 0.0008313143853462908,
            0.0004034700310345909, 0.00021167009481698517, 0.000017125831465992716, 0.0000026636729906583154,
            5.730990331752496e-7, 1.4843280220521084e-7, 3.5727540706139154e-8, 8.82081263586798e-9,
            8.679453392453673e-10, 2.8241853300414732e-12, 8.93285445613401e-13, 3.2795988147427124e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.43720824354406246, 0.33257973807523245, 0.21876421654031913, 0.16283541416723912, 0.13029859529481735,
            0.10904887167938845, 0.06172525005904666, 0.034113644700848225, 0.023714004166679285, 0.018127934908993684,
            0.014582216465413349, 0.006794344694889832, 0.0024888642492017633, 0.0011472540558521316,
            0.0005879317515418366, 0.0003263117403636526, 0.00003451382087755104, 0.000006446813690950777,
            0.0000015303877500019575, 4.2294868030090527e-7, 1.2061106591243487e-7, 3.3002175436891434e-8,
            7.69322094917868e-9, 2.532696941059953e-9, 3.250256730424894e-10, 9.952039192739903e-13,
            3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.1663560662309465e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6246293333857912, 0.5190330187655248, 0.383358074790776, 0.303946100392748, 0.2527843974037053,
            0.21722298621090078, 0.13202137559630545, 0.07963679946685803, 0.05982973707916348, 0.049069466280624495,
            0.04213136650169445, 0.025184861806674737, 0.013181099586205764, 0.008147190050550361,
            0.0055044762202974135, 0.00396969312767101, 0.0011920751143439773, 0.0004906398171671669,
            0.00023741848528800865, 0.00012904730350596548, 0.00007631867889514984, 0.00004800572563448308,
            0.00003163935357193143, 0.00002163142748381711, 0.000015395937656759173, 0.000008322428916279346,
            0.000004567024548984833, 0.000002679977971431491, 0.0000016556384231369847, 0.0000010575148431390957,
            6.955254914231546e-7, 4.7150068427370684e-7, 3.25950972546174e-7, 2.28712978445067e-7,
            1.6272613934109614e-7, 7.300575888269378e-8, 3.365449896541861e-8, 1.5084219429084555e-8,
            6.089877713222336e-9, 3.467104381371655e-11, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6008444199577588, 0.49631707200946096, 0.3627362130834662, 0.28594047050950316, 0.2367688990559926,
            0.20279702886005832, 0.12212202472847503, 0.07310492785750072, 0.05462836680557248, 0.04452412382270232,
            0.03790549418364611, 0.02199317175567218, 0.011147650981585957, 0.00667344281916498, 0.004380825101640993,
            0.0030226452517643073, 0.000732566586758215, 0.0002810630238407562, 0.00012339639317189048,
            0.00006006430359827841, 0.000032773754537892093, 0.00002016226714185443, 0.000013281162177802841,
            0.00000886484358068746, 0.0000061392811909460346, 0.0000028279034722800134, 0.000001430908926458585,
            7.38778126230244e-7, 3.753288065899696e-7, 2.0234413056208211e-7, 1.2893512391087825e-7,
            6.688871023730059e-8, 3.251924451941335e-8, 1.508095104352236e-8, 6.625598292231416e-9,
            3.274366333627654e-10, 3.647993018773832e-11, 7.920220035373404e-12, 7.920220035373404e-12,
            7.920220035373404e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5185805613040332, 0.41304595088981944, 0.28889378119852993, 0.2223922744852517, 0.1816223932462435,
            0.15415719152954122, 0.09066617382034643, 0.05278180361545189, 0.03843945629110161, 0.03061258184255043,
            0.025552671475510987, 0.01381258597502741, 0.006329776679151046, 0.003554402162327943,
            0.0022083494173163504, 0.001464329142199599, 0.0003176284759215036, 0.00010814735928272421,
            0.000046525326368791726, 0.00002318692654673559, 0.000012784904034519253, 0.000007588991766290068,
            0.000004764733585608637, 0.0000031245419773197943, 0.0000021211979401137517, 0.0000010567626543155196,
            5.708303079181838e-7, 3.2721176339915694e-7, 1.9653785301378422e-7, 1.2245432994544716e-7,
            7.859417636093475e-8, 5.186427851253017e-8, 3.502190923521685e-8, 2.4101878099439035e-8,
            1.6888973181528674e-8, 7.262853668065552e-9, 3.271179137063704e-9, 1.442516763456332e-9,
            5.928382969570486e-10, 1.3197275762081638e-11, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3291698380058364, 0.21994720367454293, 0.13268905280255103, 0.09635434629926455, 0.0763934153829362,
            0.06368453169666398, 0.03582460123703417, 0.019536682359117343, 0.013352118381031829, 0.009959864840574862,
            0.00778488424407395, 0.003076451653311943, 0.0008133620087073411, 0.00028344904537831045,
            0.0001163916526195123, 0.00005367918937595305, 0.0000027817762755299087, 2.8181979917852604e-7,
            3.319513032555739e-8, 2.431990054334011e-9, 1.0300649222472202e-12, 7.257527911974648e-13,
            7.257527911974648e-13, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.35098103125714925, 0.2376284157722918, 0.14507146301936785, 0.10609386576292318, 0.08457190664758842,
            0.07081915879876391, 0.040385604067301094, 0.0224368321358535, 0.015557078542940509, 0.01176286671503928,
            0.00932344524209663, 0.0039023615925724495, 0.001150539357521299, 0.0004391982374744107,
            0.00019545449462147132, 0.00009596039280346447, 0.000006124970496926742, 7.260749043114245e-7,
            8.91176330679457e-8, 1.3620532257618834e-8, 1.9135939499648202e-9, 1.123949822101622e-10,
            2.263522702605769e-12, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5445559608724094, 0.4158464893716396, 0.2836423069516788, 0.2183354596294437, 0.1794564445573879,
            0.15355149471279225, 0.09421114659708717, 0.058801471754074706, 0.04510271861136149, 0.037310591211585406,
            0.03201363779023385, 0.018188032445227598, 0.008524885719968829, 0.004949538433600288,
            0.0030624101266381494, 0.002071702629535436, 0.0004773633472558049, 0.00016985967216660413,
            0.00007471779777692045, 0.00003980872495301826, 0.000024232755739039824, 0.000015632392291298203,
            0.00001039624236054415, 0.000007084467760765101, 0.000004765821986607577, 0.000002212063868300973,
            0.0000011132661045243353, 5.843096486657373e-7, 3.3517365283231015e-7, 2.0069366302166713e-7,
            1.2303795815515883e-7, 7.671197055358903e-8, 4.823584065505315e-8, 3.02935038165586e-8,
            1.8850891625454835e-8, 4.889074700642482e-9, 7.674705315707797e-11, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5205928329497104, 0.3930290663530887, 0.2651335056635099, 0.20279829103771824, 0.16601543769504734,
            0.14167151456987945, 0.08624164935694004, 0.05343830542499871, 0.040622423281920506, 0.0332363877275758,
            0.02820473920027949, 0.015587867579857619, 0.007025452041901753, 0.003850003785890439,
            0.0023840488994512463, 0.0015876975998251126, 0.00032259262315094503, 0.00011287003750323267,
            0.0000444120456282171, 0.000019359153376399057, 0.000010179887189765147, 0.000005816447130143523,
            0.0000037263141574594982, 0.0000024067554256212986, 0.000001414828375345678, 5.179844038138981e-7,
            2.1620024992508036e-7, 9.397486877560368e-8, 3.4878684829209305e-8, 1.1086185303099683e-8,
            2.066033788494792e-9, 2.745000893256133e-10, 5.48673328992777e-11, 8.28415114284553e-12,
            7.920220035373404e-12, 7.920220035373404e-12, 7.920220035373404e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.43420360525201607, 0.31322612147198153, 0.20268236243578133, 0.15221232022969164, 0.1232956131712768,
            0.10446420245801946, 0.06223682632539496, 0.03717633810209213, 0.027360011085356455, 0.02180395014367368,
            0.018106360546790726, 0.009233180255176924, 0.0037147378644321093, 0.0018769384680003033,
            0.001071622546809721, 0.0006632729368705832, 0.00011721191266523661, 0.00003657626006454203,
            0.000015081468338980009, 0.000007319587910152351, 0.000003945268067759725, 0.0000022844913971604237,
            0.0000013949443466407782, 8.879950377691993e-7, 5.842262648082766e-7, 2.7288861039778974e-7,
            1.3727375868119667e-7, 7.333854341148207e-8, 4.090910170000979e-8, 2.3763303594237486e-8,
            1.42306646263468e-8, 8.681720801382808e-9, 5.344274270398648e-9, 3.312497619578782e-9,
            2.0488255040741582e-9, 5.310928398692677e-10, 3.718308867337026e-11, 1.1501346305984417e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12,
          ],
        },
      },
    ],
  },
};

export const wellington400Response = {
  hazard_curves: {
    ok: true,
    locations: [
      {
        lat: -41.3,
        lon: 174.78,
        resolution: 0.01,
        code: "-41.30~174.78",
        name: "Wellington",
        key: "WLG",
      },
    ],
    curves: [
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226162851564697, 0.5225071434926885, 0.5219511689585403, 0.5205600858165771,
            0.5182110664855981, 0.5045907848122114, 0.46279612978046947, 0.41924968575270505, 0.3782781366623785,
            0.3417894871956879, 0.21570479700703785, 0.10665106940029663, 0.06444933152827789, 0.04392030435975493,
            0.032155620663523836, 0.011344565080320296, 0.005566827040802693, 0.00309845656262319,
            0.0018544364319251813, 0.0011662117386052784, 0.0007600030883115608, 0.000508461483714141,
            0.00034803334157079046, 0.00024299535639696673, 0.0001257580349044396, 0.00006942447768772335,
            0.00003907503121533839, 0.00002297484974201222, 0.000013548528376206648, 0.0000081234689617526,
            0.000005006375590532423, 0.000003131537573469268, 0.0000019699151191140397, 0.000001235642332257064,
            4.002239839540067e-7, 1.1171209324256637e-7, 2.3431448004096467e-8, 7.902218102096015e-9,
            7.853574457428181e-10, 2.0833335057091062e-12, 8.985034938291392e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504298984450783, 0.5503937150479198, 0.5500980556726425, 0.5492275273650556,
            0.5467842905991283, 0.5235184873128955, 0.4850040385979381, 0.4396621861623258, 0.3985316497109651,
            0.36201629142156466, 0.23397031659988068, 0.11939986293963711, 0.07362881657912315, 0.05074865380833566,
            0.03754902734124999, 0.01358820417705235, 0.0068623301018432906, 0.003943019645569533,
            0.0024303019211958108, 0.0015674543542228648, 0.0010450505330024343, 0.0007141580942907832,
            0.0004974377353983827, 0.00035344939888212236, 0.00018736524054239253, 0.00010524847564069246,
            0.00006159994633640041, 0.00003754431821308413, 0.000023629593101086788, 0.000015183545422866826,
            0.000009860670287908867, 0.000006629450898021361, 0.000004472033561229363, 0.0000030566369207127467,
            0.000001231373828853791, 4.892259584110548e-7, 2.103907097428248e-7, 8.851993790864891e-8,
            8.533725238377899e-9, 7.280354097360942e-11, 2.5726087926614127e-12, 7.257527911974648e-13,
            7.224221221235894e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244646117080166, 0.7244225931081795, 0.7238957680601844, 0.7235245745672844,
            0.7225874901068138, 0.7084845958940214, 0.6692476747061171, 0.6303850975689289, 0.5896692794790327,
            0.5515902059363461, 0.4060571854270251, 0.25034161026636453, 0.17380363096684248, 0.12966366419758665,
            0.10144726610824031, 0.04400648993412908, 0.026485534579810118, 0.01830900954730977, 0.01342654419775138,
            0.010224654446614715, 0.007998078194423549, 0.0064089398364320024, 0.005229040727788444,
            0.004294769490088744, 0.0029879259347740154, 0.0021265437655035467, 0.0015324015582541207,
            0.0011232450743675177, 0.0008218666973740128, 0.0006097081981262677, 0.00045811600960971055,
            0.0003484149943021597, 0.0002678341977461936, 0.00020932279855180732, 0.00011679630051659518,
            0.00006910658352559767, 0.000042158370359257624, 0.000025387864915818334, 0.000011672830832809566,
            0.000006562670861631759, 0.00000391352657891364, 0.0000024313310841295177, 0.00000155824095415813,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674635812969, 0.7000592486590735, 0.6998155335543467, 0.6991766726679614,
            0.6983726467212799, 0.6890786057071858, 0.6494615252973448, 0.6069566147635233, 0.5667901643710526,
            0.5288414653992636, 0.3848645042034303, 0.23400076427457922, 0.16083823461257937, 0.11910932895364282,
            0.09269064681452582, 0.039493636421170285, 0.02315747358920305, 0.015559271662387453, 0.01128873438777394,
            0.00855083682038666, 0.0066508212997108285, 0.005242623575216018, 0.004200211705816086, 0.0033715162046439,
            0.0022425661225489234, 0.0015279390908440947, 0.0010742022666992326, 0.0007705890930651371,
            0.0005637976011671874, 0.0004177970148432681, 0.0003133467650257772, 0.00023813710312043794,
            0.0001844276626886705, 0.0001447612194447578, 0.00008308709067136884, 0.00004834478234601465,
            0.00003159439485389548, 0.0000214170635691735, 0.000010213070043785244, 0.00000428937737340096,
            0.0000018436741813232516, 7.877957920454648e-7, 3.1014073433713797e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661437718699, 0.6216636915135251, 0.6215939520687526, 0.621286657487961, 0.6205892936609897,
            0.6194259835501446, 0.6071436298502202, 0.5663957574845311, 0.5222336555404151, 0.4809811051083862,
            0.4437496276079981, 0.3086443800868426, 0.1761889887823646, 0.11603981583983615, 0.0834776362155848,
            0.06367873496924129, 0.02571916003541444, 0.014444946528455387, 0.009295432762869076, 0.006431802431619955,
            0.0046550050723139615, 0.003474493726424447, 0.002652921755391717, 0.0020616876043879394,
            0.00162527591781676, 0.0010450477955124828, 0.0006965446992002924, 0.00047766867707860346,
            0.0003353728199183965, 0.00024022950053812438, 0.0001750994767729152, 0.0001296026224773458,
            0.00009725272791157011, 0.00007388304476040698, 0.0000567595828284793, 0.000030648567677187533,
            0.00001743891000684538, 0.000010363387083223836, 0.000006388495498097099, 0.000002650825209774428,
            0.0000012089552987203221, 5.944863058530271e-7, 3.091628207792741e-7, 1.6720649294185395e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226191408943033, 0.5225890271336867, 0.5224916454064713, 0.5221448857052813,
            0.5213995782469354, 0.5136920377618207, 0.5010847719396593, 0.48001798545793606, 0.45652786895893943,
            0.43484505932197204, 0.338980662201894, 0.21582294404228375, 0.1478822638184113, 0.10751127076791307,
            0.08179574414594931, 0.031101859602977698, 0.016465655819636934, 0.010042139199930208,
            0.0066054384603513264, 0.004547274852946606, 0.0032299359695926546, 0.0023522409905776817,
            0.0017455645623320334, 0.0013155165652653797, 0.0007723507711443913, 0.0004720669810751846,
            0.0002982028623324903, 0.000193926586505604, 0.00012885500789424733, 0.00008743383133119906,
            0.00006034052477126025, 0.00004252185321195187, 0.000030532953012401686, 0.00002223080498732689,
            0.00001028073147359516, 0.000004887804355614389, 0.000002367987794626991, 0.0000011884214663934359,
            2.7399565294405903e-7, 3.0879041310455275e-8, 3.930724967737831e-9, 1.6945933545287062e-10,
            7.920220035373404e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504301850673154, 0.5504264803279132, 0.5503878300056815, 0.5501842007389086,
            0.5499660131882609, 0.5434490387707388, 0.5207315476004353, 0.5009202934028436, 0.4783173257020599,
            0.45568623582010676, 0.35860357230279755, 0.23400762631050612, 0.1633231118154647, 0.12056852537185081,
            0.09280700015558598, 0.03671711697475851, 0.01985707590038921, 0.012316931262827537, 0.008237328614744363,
            0.005769938182481248, 0.00417565794549515, 0.0030950780935576584, 0.0023376562936186433,
            0.0017917633470229523, 0.0010943707652733238, 0.0006945712765745667, 0.0004522573296701379,
            0.0003009094996692132, 0.00020484356729222604, 0.0001420305413614873, 0.00010026145376737389,
            0.00007206806980416114, 0.000052361974710262923, 0.0000383242618102976, 0.000018368926611874023,
            0.0000092575713586796, 0.000004860033648327963, 0.0000025727459578987554, 7.081376548006919e-7,
            1.6225393574582603e-7, 4.5783348667782775e-8, 1.134074634201454e-8, 1.0159042496127313e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244649777900473, 0.7244597451601315, 0.7244247393417028, 0.7241768394319992,
            0.7238750145485863, 0.7209121259951318, 0.7012559390773777, 0.6800893883444413, 0.6596474322033873,
            0.6382453370964553, 0.5370034059383244, 0.3972719006927142, 0.30958456873770424, 0.2502655231480622,
            0.20786523489968947, 0.10442116286390972, 0.0645604671476853, 0.044657596899394615, 0.03319557079922579,
            0.025967443209497447, 0.020979820977474868, 0.01733006872517441, 0.014535990604171219, 0.01231659739457395,
            0.009149087014042418, 0.007004738942654848, 0.005481610600692532, 0.004362292526488188,
            0.0035174212363724257, 0.00285067634269536, 0.002320452811304162, 0.0019155177072517156,
            0.0015927464312373199, 0.0013347838868649562, 0.0008809352443356013, 0.000598245266853784,
            0.0004292123729835193, 0.00032210531975422494, 0.00019875687948844867, 0.00013121231934314344,
            0.0000938155147272024, 0.00006907709048353805, 0.00005203575695432445,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000670480414604, 0.70005358812806, 0.699964228252618,
            0.6996890918188506, 0.6972661738915102, 0.683828691750302, 0.6623575842050524, 0.6384459908729401,
            0.6156178696907932, 0.5152119288532241, 0.37725933983801063, 0.2916102996750582, 0.2341796377508886,
            0.1933824128954364, 0.0952807788895458, 0.058331890452679135, 0.03996252926280281, 0.029357538161144933,
            0.022616231654224085, 0.017945650038928312, 0.014620607401047757, 0.012143000086054404,
            0.010244453293078883, 0.007529513978921698, 0.00569553395135015, 0.004373232988405319,
            0.0034143148443998043, 0.002717927912756152, 0.0021994458460128587, 0.0017865986331635196,
            0.0014698037220058488, 0.001223709256067807, 0.0010266315835440747, 0.0006785405539954538,
            0.0004772589354439589, 0.0003632869675292001, 0.00028499659777780906, 0.00015055675230373028,
            0.00008369938443262314, 0.00005091038924996236, 0.00003253548346168995, 0.00002050609241930222,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661628953166, 0.621665293564439, 0.6216482806688433, 0.621579378394364, 0.6214220589811635,
            0.6211493276099582, 0.6177107234062686, 0.6022138651273112, 0.5809288183701089, 0.5579632412953628,
            0.5350774791928632, 0.4356996296333248, 0.30525022859330425, 0.22717842876706473, 0.17665195842880177,
            0.14197705893058857, 0.06415943746668387, 0.03764914690522631, 0.025082364711842258, 0.01799012666915671,
            0.01353586525135657, 0.010530417193603078, 0.008396173959382252, 0.006821791252799499, 0.00562589501308323,
            0.003959853906014806, 0.002884999203327037, 0.002157002879821078, 0.0016460269678342458,
            0.0012774021298106323, 0.0010055739220193724, 0.000801460709776736, 0.0006458229719091756,
            0.0005255598204679006, 0.00043153419834441085, 0.00027288789691861737, 0.00018006294544837322,
            0.0001231747937363556, 0.0000869121119211128, 0.00004675208714766127, 0.000027345470761787242,
            0.000017087987531262104, 0.000011244919181976848, 0.000007711618156187168,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226200781230104, 0.5226143966923523, 0.5225887721771247, 0.5225268421945025,
            0.5223801770663701, 0.5183917147527218, 0.5067586462539388, 0.49639841651928185, 0.4782845953322071,
            0.4597435551806379, 0.3762162961203949, 0.255084426377507, 0.18157208596711893, 0.1358950001200288,
            0.10550453243409702, 0.042341607190053955, 0.023210670061008985, 0.014722115806546556, 0.010102076042049676,
            0.007254182620964757, 0.005374432155745675, 0.004079857710548938, 0.0031554754741343763,
            0.0024771223412660024, 0.00158229334952565, 0.0010476922956970435, 0.0007131948380480248,
            0.0004952248141058814, 0.0003497972198448387, 0.0002519178622362801, 0.00018426200880262254,
            0.0001365530494898768, 0.00010246793195413575, 0.00007765221770583747, 0.000040412783747508243,
            0.000022095109182698135, 0.00001261670509562876, 0.0000072907495352958755, 0.000002613856045718954,
            9.88928653522514e-7, 3.6633658178292793e-7, 1.2469686014692286e-7, 2.8851771638471746e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.5504299440243442, 0.5504260864693008, 0.5503975918414635,
            0.5502597965476179, 0.5481141225761329, 0.5305558915069181, 0.5157101335264993, 0.4999715348721817,
            0.48165125239170076, 0.39590685315783786, 0.27381558945456225, 0.1983196932781468, 0.1501358847267565,
            0.11775332823916848, 0.04901710070264451, 0.0274478525164512, 0.017632212572450463, 0.012222891781080008,
            0.008892553662026105, 0.006686012359062258, 0.005151749239929722, 0.0040332612280839595,
            0.0032033490072415887, 0.002090150910944973, 0.0014131982142229875, 0.000983090466556005,
            0.000700402081566519, 0.0005086996038605358, 0.0003759684060840275, 0.00028152453236635874,
            0.00021268952874480958, 0.00016206883668924937, 0.0001247208283548074, 0.00006728581695372599,
            0.000038074677454495315, 0.000022393994212396618, 0.000013343177201119618, 0.000005105195450019548,
            0.000002040733516286516, 8.583853566453385e-7, 3.055916587557661e-7, 8.018461272740751e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244646360991227, 0.7244589755938797, 0.7244320447461051,
            0.7243143169945788, 0.7230961481364501, 0.7135104863326228, 0.6958155621577953, 0.6794035346203608,
            0.6632118430924798, 0.5778616195880407, 0.4424323688689631, 0.3510632215321738, 0.2868788000649421,
            0.23995725169462018, 0.12244048249645792, 0.07682146700499412, 0.05388036598507987, 0.04058431077322052,
            0.03213367864349195, 0.026307398824373003, 0.02209218952992575, 0.01884401117007074, 0.01625281847476557,
            0.012476218399872214, 0.009854279910627038, 0.007920243806283192, 0.006491068995823307,
            0.005408327712879704, 0.0045249621113750745, 0.003812303909175991, 0.0031809898771201135,
            0.002659566537328106, 0.002243248101703925, 0.0015204531464883104, 0.0010704695357542492,
            0.0007595236258438271, 0.0005570687362984932, 0.0003230389110352654, 0.00019965993949983064,
            0.000131916025765076, 0.00009274104186809646, 0.00006516185125160501,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000674950715742, 0.7000668528737124, 0.7000578998799916,
            0.7000315047686902, 0.698766230080918, 0.692382653465904, 0.6786331684553558, 0.6612679613891307,
            0.6426717132148433, 0.5558868824390227, 0.42150636831751215, 0.33207735026796403, 0.26992533757876747,
            0.22470629583650203, 0.11264683578443131, 0.06995023159643822, 0.048704235480437945, 0.03638640696287083,
            0.028500834119724705, 0.023047168470850535, 0.019072460429708664, 0.01609064938050036, 0.01377105572709404,
            0.010441604056588063, 0.008150072673488147, 0.006455459486127696, 0.0051871978009233, 0.004216223539693822,
            0.0034536679462346784, 0.0028541392573705124, 0.002382197286367001, 0.0019977699546434623,
            0.0016888679728559162, 0.0011316601975754456, 0.0007821507806790709, 0.0005612685475534968,
            0.0004075579601942229, 0.00023867833116756771, 0.00015845134215948707, 0.00011229960344533113,
            0.0000821607252854406, 0.00005428526650548505,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661866104946, 0.6216660879858429, 0.6216624411565996, 0.6216432374264846, 0.6215920254337233,
            0.6214933988722408, 0.6199587490038694, 0.6111667330772821, 0.5969391426634493, 0.5799733360857174,
            0.5618962934722612, 0.4747491663116797, 0.346984922244755, 0.2649551480237092, 0.20964223225256126,
            0.17060555443905917, 0.07963083034232322, 0.04762098790424315, 0.03229210862111603, 0.0235824385517914,
            0.018070995030776697, 0.014318272486470645, 0.011625321109698527, 0.009615719786011579, 0.00807023429450808,
            0.005876129534018033, 0.004421309953151408, 0.003407938309265567, 0.002676311854785686,
            0.0021335267717491847, 0.0017221090534435253, 0.0014047943479760063, 0.0011564919810937407,
            0.000959793996968126, 0.0008023161439622929, 0.0005267423848445702, 0.0003573903577499045,
            0.00024923334307006066, 0.00017794331394654616, 0.00009624358541485252, 0.00005556492788980606,
            0.000033822174417721764, 0.00002151604021889636, 0.00001420810684843074,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226201278873664, 0.5226169346745149, 0.5226000395475721, 0.5225549897790487,
            0.5224482470141321, 0.5189999950373497, 0.5064537186601015, 0.49568813389485594, 0.4764277845561122,
            0.4566229676063312, 0.36709544302949465, 0.24188440211117804, 0.16946628854808574, 0.12595320321280776,
            0.09742624236439128, 0.039528383055126994, 0.022018757665876517, 0.014132562860139749, 0.00980529819368698,
            0.007132611792386937, 0.005358085540700421, 0.004118607514253143, 0.003227269455649651,
            0.002564610094085404, 0.001679518753391127, 0.001139777467752455, 0.0007953380149421593,
            0.0005681705279559498, 0.0004148746034357882, 0.0003077995630594097, 0.0002318080102984942,
            0.000176564963475619, 0.0001356448647261869, 0.00010527705571272556, 0.00005804632108100281,
            0.00003361762263329194, 0.000020151640038208855, 0.000012274873431837884, 0.000004851558166772806,
            0.0000020654983120088805, 9.091842556311902e-7, 4.092377647868517e-7, 1.842412334756859e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.5504300903155013, 0.5504254766975698, 0.5504044648626147,
            0.5503164653824445, 0.5483484293557862, 0.5304382994783299, 0.5139787976987004, 0.49812714266979585,
            0.47865360446756905, 0.38669754580969395, 0.2599984914322545, 0.18525587954169395, 0.1388748817798723,
            0.10838623341337557, 0.045207488077471054, 0.02554190144924058, 0.016582024800953842, 0.01162679765851424,
            0.008552852926824221, 0.006500290908152615, 0.0050580473116880595, 0.004007262579035385,
            0.0032213298048214484, 0.0021529709788192337, 0.0014885364887806262, 0.0010575239589498875,
            0.0007684871494220963, 0.0005692740753828974, 0.00042848794355693383, 0.0003262267599441847,
            0.0002515494035966143, 0.00019592227773245453, 0.00015438414062778514, 0.0000881823929323552,
            0.00005276590525784975, 0.000032490480571427754, 0.0000205694505093299, 0.000008902795105161458,
            0.00000412687439675473, 0.0000019772808824671933, 0.0000010186761288905473, 4.82308483513172e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244648053380689, 0.7244573224263806, 0.7244311546346338,
            0.7243193231935913, 0.7231326592278912, 0.71407065427386, 0.6955244550684666, 0.6772551793808795,
            0.6598519919375881, 0.5681742044541993, 0.4238721113288023, 0.32896271262122784, 0.2640940284219305,
            0.2177652327145473, 0.10671004868922508, 0.06624404394102949, 0.046628074902474566, 0.03537967614652963,
            0.028218208348707384, 0.02324683437132019, 0.01959653862616585, 0.01677791479862131, 0.014527601162899407,
            0.011153613459018366, 0.00879367379442932, 0.0070425781006444145, 0.005728650021446278,
            0.004708295433432308, 0.0038986582771076824, 0.00325616404340021, 0.002744783797065652,
            0.002330243324678674, 0.001984298779442074, 0.0013510638723451063, 0.0009359577982898474,
            0.0006645400985334061, 0.00047798871182070654, 0.00026624684439173306, 0.0001587370830197952,
            0.00010040402228130866, 0.0000674308753509334, 0.00004733623907597461,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000674950715742, 0.7000667709649545, 0.7000612655854339,
            0.7000346759995317, 0.6987090367402893, 0.6920716299954426, 0.6771558713997716, 0.6592516683076799,
            0.6398817556749186, 0.5467934598261158, 0.4039028354975976, 0.31110809650056703, 0.24840700134287344,
            0.20371014442525726, 0.09835411092353558, 0.060556715157900204, 0.04236929865385248, 0.03192073681314814,
            0.025239995051519797, 0.020581463767107684, 0.017163432815072256, 0.014552027375524058,
            0.012499329145497643, 0.009498900654671067, 0.007423331168159658, 0.005906822665456904,
            0.004763450183496381, 0.003869985204058554, 0.0031720317228835413, 0.002625800030742277,
            0.0021906129256072738, 0.0018391593876340962, 0.0015546224606395734, 0.0010376494822916965,
            0.0007110777104048664, 0.0005001178979864032, 0.0003599396941393428, 0.00019878176651727135,
            0.00011515882944801468, 0.00007458761686182328, 0.000052189823090309395, 0.000037984095612420366,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216661654224257, 0.6216641275807292, 0.6216500084165597, 0.6216077015889009,
            0.6215207594568097, 0.6200273412941696, 0.6109517132692038, 0.5959906673198686, 0.5780216203781602,
            0.5587963698986333, 0.46578905537661647, 0.33130254579584234, 0.24774070001622941, 0.19305012532542296,
            0.1553609964557086, 0.07081300175194, 0.04226489359175086, 0.028794555237894648, 0.021166395514323703,
            0.016328125962097827, 0.013017080196062725, 0.01062629929042955, 0.008830624289821943, 0.007441010783358488,
            0.005452103834547859, 0.0041208764251366445, 0.0031868536766682864, 0.0025088216153508268,
            0.0020037123844880403, 0.0016196383051507739, 0.00132268342116798, 0.0010898550183601058,
            0.0009051124980541944, 0.0007570005525536885, 0.0004973055387544506, 0.0003373156799064376,
            0.00023493443473665028, 0.00016734641802553465, 0.00008979728747111631, 0.00005121456474894567,
            0.000030680390578959994, 0.000019136153970846382, 0.00001234761687941496,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226201278873664, 0.5226164183204118, 0.5225943992930919, 0.5225271142926685,
            0.5223918359579173, 0.5192849855069623, 0.5047611852402235, 0.49035910752186396, 0.4689204123959768,
            0.4465079009755187, 0.3462009939477822, 0.21660341696630775, 0.14766804945008907, 0.10771773675665453,
            0.08259225446088668, 0.03312352744779976, 0.018457585072424523, 0.011878802454786234, 0.008261070569275029,
            0.006027055355460922, 0.004540942469554713, 0.003504153419483286, 0.0027554230365827736,
            0.002196473007438491, 0.0014451565608501525, 0.0009859776440526164, 0.0006927493894161035,
            0.0004983426991711482, 0.0003660232764658098, 0.0002735737986298306, 0.00020753433168304333,
            0.00015968404179562165, 0.00012429931311704198, 0.00009775674164482841, 0.00005599883783591906,
            0.00003353519222299593, 0.000020672671728225236, 0.000013010466975344961, 0.0000055133786032433805,
            0.000002516131395322181, 0.0000012248185441432469, 6.035040414498672e-7, 2.7723808837532005e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.5504294017053988, 0.5504192986020472, 0.5503876048409112,
            0.5502694446253285, 0.5479565069802389, 0.528636582380993, 0.508728054649302, 0.4898579389917818,
            0.4676429271544374, 0.3652486418721256, 0.23288601650249233, 0.1609733864565281, 0.11853134525234808,
            0.09148648631911702, 0.037563437649967966, 0.021240329834578228, 0.01384149440780712, 0.009736335269940633,
            0.007182306794323868, 0.005474653523232455, 0.004272704683415568, 0.0033929113104172797,
            0.0027356653741586008, 0.0018443866363646024, 0.00128910344258489, 0.0009239346755210109,
            0.0006766884338784852, 0.0005046764695119765, 0.00038181884649679266, 0.00029288725467355083,
            0.00022750254499952287, 0.00017849953266783363, 0.00014142591635562358, 0.00008156991227830519,
            0.000049020327696487955, 0.000030529196916311645, 0.00001961900713047557, 0.000008810461467691872,
            0.000004313829582143969, 0.0000022034659584089815, 0.000001171227817203372, 5.929580884078689e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244637063978558, 0.7244516919085022, 0.7244094293503247,
            0.7243081530846452, 0.722593247795518, 0.7103741509259381, 0.6876360885038559, 0.6669177674981129,
            0.6459564811180384, 0.5396520798731251, 0.3854127045524678, 0.29024473010962454, 0.2280295728470149,
            0.18507126175908659, 0.08756554016237295, 0.054313952492371476, 0.03851366474945537, 0.029492858058838726,
            0.023656091089648962, 0.019550891823235363, 0.01648817886775699, 0.014106286488052698, 0.012192642767015637,
            0.009321594585180566, 0.00726171960776269, 0.0057553324061568375, 0.004623248202674331,
            0.0037541531784842657, 0.003079155854717539, 0.0025461727378486196, 0.002124227337846017,
            0.0017809843837240926, 0.0015014608472470098, 0.0010015033680326677, 0.0006901978409923858,
            0.0004865456320244073, 0.0003510798341759183, 0.00019151111238788847, 0.00011023921361726705,
            0.00006608674710336948, 0.00004102892966517668, 0.000026833223064463082,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000674572952295, 0.7000652682809478, 0.7000524853606387,
            0.6999938640337293, 0.6982228960604837, 0.688749715220325, 0.6697825584736432, 0.6478687366326901,
            0.6247704824452619, 0.5186425177031913, 0.36650378953206536, 0.2737643014339425, 0.21370610689556102,
            0.17245418970444978, 0.08053992060334479, 0.04956534501827099, 0.03493595694468687, 0.026535665303308736,
            0.02108579533894356, 0.017256377052505312, 0.014399020381139317, 0.012205954323563277, 0.010462272025115946,
            0.007892440873890932, 0.006108791425052238, 0.004820101930839012, 0.0038607542047973888,
            0.003129151579236281, 0.0025588208593322276, 0.002112210533709452, 0.0017567959143826577,
            0.001471741816909572, 0.0012398588765914766, 0.0008260360020493986, 0.0005640692471114497,
            0.00039501902036310745, 0.0002827870208132228, 0.0001537296667519646, 0.00008800061832567255,
            0.00005259942469193479, 0.00003291789704361231, 0.00002134000716386206,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216661747408057, 0.621664317521373, 0.6216487525347314, 0.6215991654737449,
            0.6214938339284889, 0.6196093829517986, 0.6082070900797276, 0.5899106300872244, 0.5684492923970792,
            0.545909544718899, 0.4409358618329964, 0.2988363021920114, 0.21639116699076738, 0.16502831473178334,
            0.1308868414887398, 0.05815819863316107, 0.0347356212797769, 0.02379087529004015, 0.017577389356677308,
            0.013611012119414874, 0.0108775178290635, 0.00889127910917865, 0.007391730037565873, 0.006226682049363842,
            0.0045530731180757605, 0.003430347898897874, 0.0026427697164550196, 0.0020720397926541566,
            0.0016480061445792557, 0.0013266238843665924, 0.0010790131575995564, 0.0008855813177220904,
            0.0007326592798137054, 0.0006104977215968967, 0.00039754155811537066, 0.0002674188000660247,
            0.00018477413506549342, 0.0001305946199847293, 0.00006899081631848502, 0.000038713163890028576,
            0.00002279266462154889, 0.000013951034293854568, 0.000008819146984941352,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226201088639462, 0.5226126892866649, 0.5225621431871608, 0.5224192497783449,
            0.5221265478118859, 0.5173587067475027, 0.49987667014886156, 0.4785613236155311, 0.451823323551197,
            0.42580299509044683, 0.31341336866819586, 0.1837893059443513, 0.12168149043459497, 0.08745315346770033,
            0.06659129995163347, 0.026601421191865024, 0.01489211846370115, 0.009598976697583014, 0.006677310848868674,
            0.004865892490642301, 0.0036601119783659674, 0.002819309211639893, 0.0022107943068015562,
            0.0017612740446884359, 0.0011564800610089306, 0.0007872689038255176, 0.0005521019325595395,
            0.0003964704534429097, 0.00029096606688704937, 0.00021701931569635086, 0.00016457567325670812,
            0.0001264267096281847, 0.00009843642903982348, 0.0000773959873947632, 0.00004431616165050533,
            0.00002652700656039845, 0.000016388336064683917, 0.000010384272384978279, 0.000004489590198136972,
            0.0000021062234302909934, 0.0000010421457021307947, 5.066162439322223e-7, 2.430903604577214e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.550427715056494, 0.55040527105713, 0.5503280286132931,
            0.55012069238531, 0.5455747541910602, 0.5204305082048749, 0.4985725338123255, 0.47279399756450063,
            0.44587466566525785, 0.3318582007485691, 0.19901659992904452, 0.13330933334713274, 0.09662315466370747,
            0.07400694823684055, 0.030240894726564722, 0.017169944724572093, 0.011212129360898968, 0.007892044199995904,
            0.005820357597114745, 0.004431082549698674, 0.0034504096771008994, 0.0027374196526663876,
            0.0022014411293870832, 0.0014754896753085879, 0.0010255756947128933, 0.0007322874630872308,
            0.0005350130904327005, 0.00039899761932009147, 0.0003026295778345797, 0.0002324411045002375,
            0.00018043941571154745, 0.00014135482797065957, 0.0001118485699498535, 0.00006471605457364594,
            0.00003911512194431382, 0.000024472139321176522, 0.000015765131836633786, 0.000007002714492561408,
            0.0000033811298891601765, 0.0000017759504301352265, 9.229061518643492e-7, 4.767124767157682e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244608617192902, 0.7244296720016533, 0.7243427731137829,
            0.7240568905086998, 0.7209671505626511, 0.7025796720326359, 0.6748855436188644, 0.6502664000110846,
            0.6244228231097766, 0.5036399871341714, 0.34329024576917533, 0.2510655945899736, 0.1934034090758162,
            0.15481876012174509, 0.07190215712374688, 0.044928040688155084, 0.032183487015007686, 0.024785364368288414,
            0.019919840735561915, 0.016428096607526567, 0.013798416337715893, 0.01175418531237247, 0.010116594221308658,
            0.007666218594580538, 0.005943257765372289, 0.00469234077770376, 0.0037554661637211927,
            0.003037710515651925, 0.0024798249621255757, 0.0020430177890795767, 0.0016953981339461472,
            0.0014159561482309643, 0.0011896754556677265, 0.0007868197487893749, 0.0005358830749259574,
            0.0003745193849367451, 0.0002672136871919209, 0.00014316432772142887, 0.00008133138807177325,
            0.000048296461525011364, 0.00002967092608893137, 0.00001883017323300873,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000670417554364, 0.7000583668102947, 0.699996590604184,
            0.6998404296843729, 0.6969817887352303, 0.681873946804818, 0.6571466941064565, 0.6297073534354674,
            0.6028856503955384, 0.482635580998632, 0.3250415466753882, 0.23574682047696938, 0.1804453862321806,
            0.1438440721176603, 0.0659797131329633, 0.040903513282044626, 0.02905042496434958, 0.022149831551754574,
            0.017594566362763922, 0.014363627247584571, 0.011949238331700151, 0.01008102562619957, 0.00860115101705496,
            0.006427961503362791, 0.0049297709168973245, 0.0038538253976582925, 0.0030557957088327,
            0.002458447403890289, 0.001996437437200048, 0.0016362479833919774, 0.0013524876813033064,
            0.0011263779811760584, 0.0009442801458888972, 0.0006230086305143106, 0.0004233662667702984,
            0.0002954238413321944, 0.00021036475343113285, 0.00011273036438642139, 0.00006386146368931507,
            0.000037922407390644786, 0.000023323811239454773, 0.00001471156525841954,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216661637513212, 0.6216614003641545, 0.6216267540321976, 0.6215253038240012,
            0.6213222417363657, 0.6181490170149695, 0.6016617287544537, 0.5776414037385407, 0.5509944824480342,
            0.5240303723426494, 0.40632194742180505, 0.26111654081257124, 0.18338697020935424, 0.13735779583056798,
            0.10778343271120557, 0.047374977131533685, 0.028464331295010047, 0.019601043276173454, 0.014522632294054566,
            0.011252311644381204, 0.008983666959580009, 0.007328166552207149, 0.0060755121518338865,
            0.005101652948778706, 0.0037043679723191094, 0.0027708174307362705, 0.0021196532920051405,
            0.001650775893492012, 0.0013046954495519458, 0.0010440939189262023, 0.0008445693810194464,
            0.0006896299056039479, 0.0005678295117664754, 0.00047104594256625634, 0.0003036864630131078,
            0.0002025216936631544, 0.00013886164478871574, 0.00009746289313422337, 0.000050843286799961934,
            0.000028197448082379978, 0.000016408795659557953, 0.00000992234895905531, 0.000006190242618185606,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226191196460916, 0.5225720321750046, 0.5223633264182238, 0.5217422250805045,
            0.5206882080359455, 0.5086423016653676, 0.48071460621875317, 0.44263582776087945, 0.40550340159309,
            0.37089981491161444, 0.24320700445378385, 0.12821119034755943, 0.08151065283794859, 0.05772952896173931,
            0.04366875678023632, 0.017459552000165933, 0.009773338231113503, 0.006260385342938717, 0.00430354424306839,
            0.0030938063284882446, 0.002296833953297825, 0.0017450729754416728, 0.0013502407446382707,
            0.0010610769632275715, 0.0006792555704347421, 0.0004524808549307927, 0.00031124889004928994,
            0.0002194619609312456, 0.0001583259709900562, 0.00011626450087495648, 0.00008670147398452583,
            0.00006560456954023304, 0.000050366930005977745, 0.00003907191870142966, 0.000021716030052083858,
            0.000012669864632203805, 0.000007606511300395585, 0.000004694521832956156, 0.0000019241893464281847,
            8.475314205426957e-7, 3.8765896510059e-7, 1.903143141974564e-7, 9.152962201543942e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504299825993612, 0.5504049313545291, 0.5502411511839116, 0.5498268669911639,
            0.5490566291516983, 0.5356719677240365, 0.5006954463364433, 0.4634318966418378, 0.42554116559998945,
            0.3904034426916687, 0.260532910627973, 0.14015435208603616, 0.08999751646471285, 0.06416489132472906,
            0.0488750628321917, 0.019976040474134135, 0.011373088900905834, 0.0074002975748870305, 0.005165492470926786,
            0.003767304065144647, 0.0028328171619867028, 0.002179491076656027, 0.001706300113941757,
            0.0013567818776371067, 0.00088581558657145, 0.0006006191293335759, 0.00041996973159435244,
            0.0003009425493604246, 0.00022014451735541068, 0.00016392069767569824, 0.00012406700438790037,
            0.000095222728208383, 0.00007391506933460265, 0.000058075749726935674, 0.00003307942311299053,
            0.00001962322396754637, 0.000012012726494872437, 0.000007593066656747408, 0.000003288540904936969,
            0.0000015511755514250966, 7.656855490306738e-7, 3.73716649636442e-7, 1.7735187213017305e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244645127852244, 0.7244316775208468, 0.7242605460496562, 0.7237170508714851,
            0.7231721481766911, 0.7139516989981091, 0.6760704648270318, 0.6396009811288533, 0.6015891491622638,
            0.5649759187179567, 0.42042031764288096, 0.26083891407868914, 0.18109783159416196, 0.13560449182364628,
            0.10699144119417606, 0.050097562718566496, 0.032143822072105394, 0.023301263096965518, 0.01789964594375496,
            0.01424696024468179, 0.011617683161118351, 0.00963640547152538, 0.008103465785360548, 0.00688061501115806,
            0.005084687311961832, 0.0038503556236561447, 0.0029744602743733612, 0.0023335441783889467,
            0.0018533671682366706, 0.0014875227750664015, 0.001205353657973039, 0.0009846748889150686,
            0.0008109738841140833, 0.0006729106738114893, 0.00043362886817882503, 0.00028908199398953717,
            0.00019840778509103399, 0.00013950895920892436, 0.00007342065198741197, 0.00004085033435822739,
            0.00002384446753352632, 0.000014397699396555962, 0.000008955264352846345,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000580039490837, 0.6999135332591843, 0.6994242736563389,
            0.6987213067937178, 0.6912495516904043, 0.6588610664847906, 0.6189817788010734, 0.5807458526388297,
            0.5445120367130115, 0.4006087822408808, 0.2450437406470993, 0.16883490302338497, 0.1257683959021605,
            0.09890376907579797, 0.04572745361359168, 0.028942891465047537, 0.02072342338798533, 0.01576958219246144,
            0.012441661744178009, 0.010066314258114066, 0.008285636321808213, 0.006922614662295612,
            0.0058408420721205775, 0.004274109162035977, 0.0032125143231666532, 0.0024662186102416025,
            0.001925328551788387, 0.0015249512460255546, 0.0012224852180111676, 0.000991026690075139,
            0.0008104345899861592, 0.0006681366439579337, 0.0005551435564748613, 0.0003577503348448941,
            0.00023792418517291103, 0.00016248231353799223, 0.00011339311965776821, 0.000058679858565758295,
            0.00003235313235865715, 0.000018625659546807623, 0.00001109308333330513, 0.000006799946344618686,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216655167315548, 0.6216279600697291, 0.6214445839051405, 0.6210107594106675,
            0.620264303418101, 0.6117059379381868, 0.5794978971274943, 0.5408909969258262, 0.5025559821560966,
            0.46660215849442244, 0.32976752523236064, 0.19147351983605793, 0.12827653429902897, 0.09396384999898914,
            0.07302036236929242, 0.03228682305844671, 0.01965439228764004, 0.013586719360207632, 0.010035509103866053,
            0.0077190897044700345, 0.006103407338613155, 0.004924476412590453, 0.004035876164965161,
            0.003349485417530296, 0.0023765978319577725, 0.0017390933115301193, 0.001303409287043192,
            0.000995962275850499, 0.0007734054056538042, 0.0006088823355732975, 0.00048508877083868536,
            0.000390517041774225, 0.0003173063050646815, 0.0002599661195538805, 0.00016295806879493133,
            0.00010603169523885209, 0.00007112386635564337, 0.000048936372675503264, 0.00002463935637288171,
            0.000013237116743893976, 0.000007475482885133719, 0.0000043886335877667365, 0.0000026559632981688756,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226199615471778, 0.522603501396363, 0.5222841781766163, 0.5209099195473719, 0.5181895538441197,
            0.5142637534448317, 0.49305221943182415, 0.4346835476891488, 0.3774506490019174, 0.32822581150379304,
            0.2867660021124113, 0.16367584135579524, 0.07908822179795304, 0.049244145056335696, 0.03463936048318489,
            0.02618107163820994, 0.0104156192705241, 0.005702143217632785, 0.003541263077412256, 0.002356086939334327,
            0.0016381966602781073, 0.001177801879960632, 0.0008678526638458098, 0.000652592275987405,
            0.000498821098537793, 0.00030322608159835614, 0.0001923551712578425, 0.0001266298861521666,
            0.00008573539035555718, 0.00005954732220536829, 0.00004228478834174372, 0.00003056219848799154,
            0.000022496261752320786, 0.000016796525606588807, 0.00001266879384201306, 0.000006523452006974663,
            0.0000034679164736513834, 0.000001901972841705376, 0.000001069997906281127, 3.457422222760858e-7,
            1.3033367383077657e-7, 5.438164153440424e-8, 1.832971829873742e-8, 7.993155692886944e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.550422051677084, 0.5501372122765807, 0.5491374734426255, 0.5465539898345544,
            0.542240062016996, 0.5136004468021745, 0.45518041584055935, 0.3972161795623515, 0.34704348116614525,
            0.3047779789956189, 0.17736253178723896, 0.08713147052430813, 0.054768756100476844, 0.03889876552029285,
            0.029595016161653536, 0.012087492511337287, 0.006762582044324024, 0.004278808551205082,
            0.0028947412113867715, 0.00204584038151342, 0.0014899764204826397, 0.0011100976029276598,
            0.0008430842209469445, 0.000650808191555353, 0.0004036210031970864, 0.00026128207948502343,
            0.0001748809619885841, 0.00012041094512418056, 0.0000850302723849109, 0.00006119263612491971,
            0.0000448306340747347, 0.00003336616519868368, 0.000025199402928844258, 0.00001927909191370504,
            0.000010326891479846307, 0.000005816905320907237, 0.0000033966108124152376, 0.0000020451254608258158,
            8.039360728772383e-7, 3.457736509117658e-7, 1.28476351446416e-7, 4.770679329357819e-8,
            1.7490104031736564e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244649952990247, 0.7244542829085276, 0.7241101937796187, 0.7231724222920938, 0.7211889996736656,
            0.718020508695717, 0.6899236174054332, 0.6295710921045188, 0.56979634542589, 0.5172434351808363,
            0.4701724029254178, 0.3130849698691998, 0.17475157830197915, 0.11703833264437927, 0.08693051719683348,
            0.06899566252627837, 0.03379825925390136, 0.021904308797466587, 0.015736320938787636, 0.011919564630092087,
            0.009349894067757294, 0.007507815452288591, 0.0061393677799045756, 0.00509484184576497,
            0.004281505501863455, 0.003110220026470338, 0.0023236594403606586, 0.0017731939250662698,
            0.0013721567598074728, 0.001078080689301712, 0.0008583763917847661, 0.0006915945669801755,
            0.0005632851406637604, 0.0004627439728832297, 0.0003832455538908443, 0.00024671095754718177,
            0.00016404701557718495, 0.00011215748033339089, 0.00007845549366650841, 0.00004072314795159018,
            0.000022429450931589342, 0.000012955001183121786, 0.000007764229900564246, 0.000004772896698445482,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000647751747476, 0.6997987273157243, 0.6987131653844247, 0.6969654896435713,
            0.6946104040400429, 0.6720241384757236, 0.6091562598404767, 0.5494063591296676, 0.4963586497960867,
            0.4501572351859717, 0.2954753885916634, 0.1627537283182332, 0.10826444536572764, 0.08008897305773437,
            0.06333705941286681, 0.0304023505227698, 0.01948198968956569, 0.013864338673540061, 0.010389426836464722,
            0.008012167468640386, 0.0063111784829215055, 0.005063355600812062, 0.004128483140420136,
            0.003413894582661387, 0.0024038822988497553, 0.0017465155167726476, 0.0012972825197407208,
            0.0009850309926597212, 0.0007610088319423287, 0.0005998837743653167, 0.00047861114904939185,
            0.00038612003367727245, 0.0003152577219561885, 0.0002595198728784216, 0.0001652356173511514,
            0.00010884644762169083, 0.00007297030584387045, 0.00005036855480502412, 0.00002565762423507751,
            0.000013957276827208042, 0.000007945872565083214, 0.000004680131718556361, 0.0000028420447431097664,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.621666082498689, 0.621651721219594, 0.6213452687088993, 0.6203157110286104, 0.6183980433212247,
            0.6156218984904561, 0.5927783986063238, 0.5318030062001946, 0.4726388628596724, 0.42089509133374525,
            0.3766979768830598, 0.2353103955875147, 0.1236868334637499, 0.08054362217927906, 0.05870584534443784,
            0.04576684110231885, 0.020709882167591974, 0.012604404972678928, 0.008589852874407016,
            0.0062184501042185664, 0.004678701860944986, 0.003617951012406453, 0.002856571561792102,
            0.0022931473326800564, 0.0018661690540263233, 0.0012773316105920978, 0.000905290210998203,
            0.0006594313400250302, 0.0004911758195917825, 0.0003727289206515762, 0.00028736285675504727,
            0.00022460238387792355, 0.00017766718295531884, 0.0001420380492032645, 0.00011463243168804907,
            0.00006949478380157109, 0.00004393488589484228, 0.000028730994695383076, 0.000019319751295067265,
            0.000009338690620289307, 0.0000048398748626838695, 0.0000026409473858048645, 0.0000014974909655414955,
            8.762151582614122e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226068495400105, 0.5222669439306481, 0.5165273324165905, 0.5071538668168728, 0.5008237264705967,
            0.4919077042389777, 0.43260702165963555, 0.327872144346957, 0.25441137710145584, 0.20413701762701328,
            0.16855767036400784, 0.08497620031145292, 0.039354330431458896, 0.024355920926260172, 0.01708201932935089,
            0.012842997277423876, 0.004788209065220877, 0.0023868432686229716, 0.0013476044191565281,
            0.0008162228718072458, 0.0005202391769796584, 0.00034372535116954155, 0.00023412992005044836,
            0.00016374155514987254, 0.00011703118637460759, 0.00006297974199006473, 0.0000360187264415087,
            0.000021610528788611187, 0.000013448996803813356, 0.000008613158739175244, 0.000005665333633889307,
            0.0000038035253952858368, 0.0000026021043675758557, 0.000001810324199989095, 0.0000012701998676229065,
            5.276778471028578e-7, 2.3057897691954178e-7, 9.833928638069978e-8, 4.21440732312206e-8,
            6.915491179704247e-9, 5.461859853284068e-10, 2.5726087926614127e-12, 9.952039192739903e-13,
            3.2795988147427124e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504220967117186, 0.5500918676600538, 0.5461499243851927, 0.5376024286402186, 0.5256941918730371,
            0.5138819646947488, 0.45397286884002847, 0.3472125925826292, 0.2724340546554596, 0.2202231309383658,
            0.18273310840009077, 0.09361877630861519, 0.04398916691083529, 0.027551690785429794, 0.0195233754550481,
            0.014793200122707515, 0.005700477494701159, 0.0029189460492353046, 0.0016823966259343671,
            0.0010404028406910887, 0.0006743932098812321, 0.0004538505030969927, 0.0003146211368087041,
            0.00022370843316005313, 0.00016244708992917036, 0.00009043608949759969, 0.000053183414550587734,
            0.00003267168873038132, 0.000020786524621043832, 0.00001363858185395768, 0.000009172039427090408,
            0.000006288587283968404, 0.000004356683577099381, 0.000003054434397934877, 0.000002170463407296114,
            9.729341190180859e-7, 4.2959239578264063e-7, 1.8972050175354041e-7, 8.8406803247413e-8,
            2.3027725504221053e-8, 4.134324216309437e-9, 1.0879741552116684e-10, 8.470668610982557e-12,
            7.257527911974648e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244489512819434, 0.7240082102867708, 0.7213822388205553, 0.7130266874940798, 0.6998819974639832,
            0.6873814088301802, 0.6296472543960511, 0.5201007260225542, 0.43614450346149697, 0.37223689949772254,
            0.3227130068811931, 0.18685766704820592, 0.09713698273013996, 0.06529567241727732, 0.04941985121952814,
            0.03984137060638194, 0.01979120293939918, 0.012487945512244511, 0.008666676912936697, 0.006374890826205148,
            0.0048375718788557795, 0.0037410704339766194, 0.002952281870236195, 0.0023606429123346944,
            0.0019157302708294383, 0.001308154795101744, 0.0009240424796401135, 0.0006709435128930359,
            0.0004972875406831557, 0.0003762631096849822, 0.00028869645755524555, 0.00022495590028202928,
            0.00017745452277735131, 0.00014189114130620992, 0.00011415220945225841, 0.00006658731398755219,
            0.0000406432793269691, 0.000025698704244818396, 0.00001671537133370963, 0.00000755067864837219,
            0.0000036855376453896684, 0.000001882229526017909, 9.735285926293358e-7, 5.214859308821929e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000643974112994, 0.6998104158385527, 0.6969625396528106, 0.6913089690356512, 0.682678936599066,
            0.6726010340111036, 0.610199398701856, 0.49914646298000376, 0.4152124148588587, 0.3520676632484339,
            0.30361669103242234, 0.17307396000471065, 0.08920771521330813, 0.059717777253718886, 0.04491910444207928,
            0.03595254966874539, 0.01750367609869416, 0.010746640499582853, 0.00717059708129215, 0.005051443682781823,
            0.0036948564225500603, 0.0027913302820963184, 0.0021601904177521954, 0.0016936309384469612,
            0.0013495652639385941, 0.0008963370650794735, 0.0006214971327225976, 0.00044474169868683714,
            0.0003268715778816797, 0.00024561649160484243, 0.0001878510986414561, 0.00014554612028935758,
            0.00011417685790767578, 0.0000906456862210076, 0.00007271808598607724, 0.000042957685322392276,
            0.000026355791932841577, 0.000016709930381513288, 0.000010859632446212616, 0.000004909397089414291,
            0.000002382875196108114, 0.0000012179119731614563, 6.440092344327324e-7, 3.513866583260139e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216476884979943, 0.6212942493101236, 0.6181075798291976, 0.611529028831222, 0.6024924792646197,
            0.5918811690452229, 0.5311806255607342, 0.4223063212687011, 0.34259795835626033, 0.28469826866998516,
            0.24162346848966715, 0.1317937412170539, 0.06556726701325155, 0.04284719588456789, 0.031539661417632085,
            0.024763244344888722, 0.0110433332918236, 0.006388310493986755, 0.004112909104031562, 0.0028183875092531617,
            0.002015073952878801, 0.0014867648596017498, 0.001124248407107426, 0.00086720797601926,
            0.0006800937408845567, 0.0004354223682593229, 0.0002909074207483813, 0.00020097095281268785,
            0.0001426621733281215, 0.00010358480327321716, 0.00007666539471154857, 0.00005768274463608137,
            0.000044027087427549375, 0.000034032236123315354, 0.000026601277763539933, 0.000014977186219410008,
            0.000008856152663405564, 0.0000054451832282860165, 0.000003456174823260791, 0.000001496990045380648,
            7.00801667768499e-7, 3.4743950164543247e-7, 1.8063336766562584e-7, 9.600619194880542e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21659161118160403, 0.13247479688936467, 0.07783494957666962, 0.05655940856085776, 0.04501708264559931,
            0.03762081206764487, 0.021254169285473197, 0.011384683274628337, 0.007455376335172197, 0.005272616948655012,
            0.003897853265902468, 0.001159768411745374, 0.00019997005501577847, 0.00005264007465364029,
            0.00001729256106015541, 0.000006527016820613867, 1.4670755479533015e-7, 2.7236023436572054e-9,
            2.0833335057091062e-12, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.23444589412497266, 0.14512645244921746, 0.08618220204270614, 0.06314721009663962, 0.05051291067297597,
            0.042427416004980935, 0.02439552833374664, 0.013418669230201083, 0.009000467837136159, 0.006508641484375764,
            0.004907368553638407, 0.001612217013677341, 0.0003204412787352951, 0.00009374076361989623,
            0.00003299430303553519, 0.000013045121237616046, 3.890603049727659e-7, 1.8468334779944223e-8,
            1.297539853339913e-10, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4169457772412231, 0.28794936524859555, 0.18553889318419517, 0.14140870745777986, 0.11650832043312034,
            0.10034230561231863, 0.06416728241435377, 0.042019162576150626, 0.03251030980346044, 0.02660848811171235,
            0.02243352166495614, 0.01156100149559472, 0.004627607771462675, 0.002347506347667502, 0.001279568571317581,
            0.0007787459063183056, 0.00014720106337140493, 0.000038228897944581064, 0.00001682978499106813,
            0.000008035016867524725, 0.000004043006579079744, 0.0000021329334954911516, 0.0000011595118394325254,
            6.06640965017391e-7, 3.216051425170008e-7, 9.961661362467567e-8, 3.4158881168089295e-8,
            1.3122925079933623e-8, 4.315102164476059e-9, 3.596777320424849e-10, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.39245792049327116, 0.2676583493119632, 0.17064472489559737, 0.12942466169309638, 0.1062943440557935,
            0.09138620556075226, 0.05812491075020921, 0.03761003719324825, 0.02872229569120904, 0.023244314602227898,
            0.0193742217508879, 0.009491731446298201, 0.003491767696730061, 0.0016159936164638186,
            0.0008766581287121943, 0.0005296352976483771, 0.00009351017396582496, 0.00002538303844057399,
            0.000007596451446212349, 0.000003018922341668784, 0.0000012624554257545384, 6.467977169410943e-7,
            2.686559241737996e-7, 1.0892132806805677e-7, 6.229132054613729e-8, 9.867878070224378e-9,
            3.2795399729224073e-10, 1.3876233495579982e-11, 7.920220035373404e-12, 7.920220035373404e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.31107811641792843, 0.20349819341100467, 0.12600203874101004, 0.09437274702857446, 0.07687578788160729,
            0.0656319449970039, 0.040418804131686706, 0.024713574209214526, 0.018077953483561005, 0.014144084445781116,
            0.011466611003239943, 0.005103300728107834, 0.0016368176074935527, 0.0007029014903725132,
            0.00035625976569068457, 0.0002017641920320593, 0.000028381191046292452, 0.000007765189837374862,
            0.000002799681039277369, 0.000001171478554902186, 5.403854407639417e-7, 2.6788326165232624e-7,
            1.3966450169058203e-7, 7.568327548585289e-8, 4.25344772268025e-8, 1.4537427839274688e-8,
            5.244324772131307e-9, 1.8825438315253867e-9, 5.86922410902404e-10, 1.1008105832921181e-10,
            1.3683194228187467e-11, 1.4765720381318537e-12, 1.161107497971139e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5224598266235109, 0.519453131378316, 0.5051202695029793, 0.49147936646965495, 0.4725155623983956,
            0.4520885698106642, 0.3594248581798744, 0.23768404656752679, 0.17194550190563454, 0.1327872385997002,
            0.10714418074536074, 0.052088358903773335, 0.02382279006309395, 0.014604870773018043, 0.01009307266645758,
            0.00744963220120821, 0.00247734613367597, 0.0010982861274063769, 0.0005547497593190941,
            0.00030425408864849805, 0.00017721107214496837, 0.00010803933925792789, 0.00006846881874185297,
            0.00004484574187344943, 0.000030175419233883823, 0.000014621769213643969, 0.000007562540771982841,
            0.000004101953113214416, 0.0000023231094155881613, 0.0000013554025601558582, 8.089895207139751e-7,
            4.893796448439413e-7, 2.941341595955179e-7, 1.7233108983909952e-7, 1.025697518406801e-7,
            2.8567031629123107e-8, 7.518273115181273e-9, 1.489720791170157e-9, 3.1480340556555575e-10,
            2.4830137945741626e-12, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.550258766722722, 0.5480213910249411, 0.5336437793078116, 0.5135425138149088, 0.49352166937502995,
            0.47318554234433663, 0.3786185500520238, 0.25441779811095844, 0.1857586207710941, 0.14417195309620498,
            0.11688673197570544, 0.05777482902103652, 0.026967896812854796, 0.016769089567975345, 0.011735255193810147,
            0.008750814845374908, 0.003039235529041956, 0.0013979976607669806, 0.0007270511179761248,
            0.00041044291848209736, 0.0002451775749170432, 0.00015345467454675648, 0.00009958160897148926,
            0.00006662977642821113, 0.00004576841050965481, 0.000022965345262621906, 0.000012213658032249114,
            0.000006858458543735578, 0.000003996650123951457, 0.000002401741662552975, 0.0000014784563851904767,
            9.404155470097026e-7, 5.925495929633391e-7, 3.7436916903492474e-7, 2.3784499264714817e-7,
            6.955873694787454e-8, 2.5615520371502498e-8, 6.431994492572812e-9, 7.130384060971551e-10,
            1.2192025167223619e-11, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.724244675716172, 0.7224486952315375, 0.7084453646988909, 0.6869327163616169, 0.6686334974311896,
            0.6480399913818703, 0.5520115697235912, 0.4144956405066832, 0.3271296858010384, 0.2683534150377869,
            0.2265488136341806, 0.12497021418435464, 0.06553898556063562, 0.04505132133953049, 0.034489316931403786,
            0.027945947912812064, 0.013831034470473853, 0.008604202003503293, 0.005788601373254396,
            0.004072526671221046, 0.0029935164705367245, 0.0022597174902923367, 0.001754659954374363,
            0.001389096946906279, 0.0011158410672382066, 0.0007491270906072044, 0.0005108190242331932,
            0.000354284351787947, 0.0002508531433657815, 0.0001817208082355032, 0.00013432441540268554,
            0.00010046359145676139, 0.00007630906824379106, 0.000058505922701714123, 0.000045340009365869194,
            0.000025140910140343336, 0.000014395484484764251, 0.000008669877938650316, 0.00000557374554504506,
            0.000002539009214763688, 0.0000012337339608954757, 6.26797786895672e-7, 3.2418411466395014e-7,
            1.670371659878711e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6999812791507412, 0.6980616747610479, 0.6880629711601677, 0.6713589778019081, 0.6511808501923034,
            0.6296307674292106, 0.5309910858017205, 0.3931948042734976, 0.3072840009495028, 0.2502148131499857,
            0.20993407556351806, 0.11440202980356858, 0.0596443132142828, 0.04060310605517084, 0.03083260198627206,
            0.024760073564084297, 0.01153765329670774, 0.006668223701405921, 0.004318785585836039, 0.003005651125115356,
            0.0021333538695389614, 0.0015596382496433252, 0.001176661030466113, 0.0009112000924573146,
            0.0007193655384662862, 0.0004709266527802078, 0.00031550652137864865, 0.0002179625027838498,
            0.00015492460820842288, 0.00011203758892334471, 0.00008248968919770383, 0.0000617203229201795,
            0.00004684628350065063, 0.00003606132795714, 0.000028165001143408172, 0.000015992151777774846,
            0.000009337803908209021, 0.000005564179909166483, 0.0000034340624961437527, 0.00000140210079280223,
            5.841880579282588e-7, 2.614590925986704e-7, 1.1719830439460566e-7, 5.243518419462134e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6214792612593757, 0.6195158352215367, 0.6083684233272632, 0.591153855143679, 0.5712932734981487,
            0.5505737741035195, 0.45418769926892316, 0.32249011258405796, 0.24479753474979568, 0.19531278916754666,
            0.1615557849546292, 0.08462637761792485, 0.04240843770032715, 0.027984907691846663, 0.02063954657064503,
            0.016143204900229104, 0.006842099743037892, 0.0037409944018267235, 0.0022930833231358653,
            0.0015066174646883254, 0.0010385892727661674, 0.000742023324041218, 0.00054516673561972,
            0.00040968993269363123, 0.00031370901314740115, 0.00019248481874341026, 0.00012391051743532413,
            0.00008281860635068846, 0.00005706315487263209, 0.00004032050471468154, 0.000029101292649787953,
            0.000021390420798480987, 0.000015974219479302584, 0.000012097595959082073, 0.000009276451661638385,
            0.00000499729892335363, 0.000002839443747225939, 0.000001684138404402482, 0.0000010353361789869416,
            4.2262752917600953e-7, 1.8603207539376229e-7, 8.624155999110805e-8, 4.057016779909197e-8,
            1.8666774483271887e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5133544497598906, 0.498911320448914, 0.4500066559543509, 0.40194815938294326, 0.358961470271088,
            0.32215704586915944, 0.20600518502343354, 0.11572916168500298, 0.07959102749968952, 0.060336622693908104,
            0.04839852865157603, 0.023555341682026967, 0.01062196582375631, 0.006263773720045911, 0.004120133445952534,
            0.0028776437053203213, 0.0007106776561710404, 0.00023837848866755252, 0.00009503234414889529,
            0.000042685551185503396, 0.0000208172543632375, 0.000010852742111057136, 0.000005975565103032243,
            0.0000034099603152137916, 0.000001997728462423359, 7.301111474048623e-7, 2.8446242806107824e-7,
            1.068562610429729e-7, 4.395811796920495e-8, 1.9385594882397425e-8, 8.018198938566085e-9,
            2.331202009209221e-9, 1.884521427797381e-10, 2.6902924332716793e-11, 3.0088154190366367e-12,
            7.257527911974648e-13, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5439702039939831, 0.5205476371399511, 0.47222454735599406, 0.4226804199221805, 0.37893714202439904,
            0.3413742832700989, 0.2214461255649862, 0.12626018421965046, 0.08753487142984182, 0.0667588452976252,
            0.05381148635321754, 0.026680541779661136, 0.012340197541522784, 0.007424537415379113, 0.004970146225581337,
            0.0035199240283539683, 0.0009291911126471493, 0.0003314454914102789, 0.00014027962453839582,
            0.00006651134224806782, 0.00003431743082527528, 0.000018831855754158558, 0.000010889495958381297,
            0.000006584791903934267, 0.000004086179436474204, 0.0000016635180188633809, 7.420746453925812e-7,
            3.38400958569148e-7, 1.6917556602561078e-7, 8.123693207018334e-8, 3.1923858466399425e-8,
            1.4328609743152754e-8, 6.244298522695146e-9, 2.6369110076799903e-9, 5.734019925540679e-10,
            2.5726087926614127e-12, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7203116402416143, 0.6973641485344323, 0.6515615882667851, 0.6033741838047058, 0.559360823137153,
            0.5197879250766285, 0.3784567009152568, 0.24212404627385484, 0.17780092633633088, 0.14086155687918162,
            0.11687516923941695, 0.06477209218181891, 0.03609102633076956, 0.025247574189178286, 0.019274523471821636,
            0.015431974684496221, 0.006906788952375267, 0.003752710831639662, 0.002291535210956563,
            0.0015072179847868221, 0.0010077162315355061, 0.0006946352350040712, 0.000492252857980641,
            0.0003611370749349024, 0.0002696508793320345, 0.00015961690888993004, 0.00009936897818399084,
            0.00006492097582908982, 0.00004436072653522374, 0.00003138600166929949, 0.000022816060458834367,
            0.000016921774047766533, 0.00001275737258377152, 0.000009749800122360597, 0.000007542539344096433,
            0.000004147825090949908, 0.0000024032507079940757, 0.0000014432165049704793, 8.945301748219947e-7,
            3.617834788416374e-7, 1.4680560522783992e-7, 6.646883687899674e-8, 3.298927819805897e-8,
            1.6900395083396802e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6966415403040054, 0.6805768466702873, 0.6315819607022485, 0.5825962261109148, 0.5378095586743644,
            0.4981322421562092, 0.3579237098354046, 0.22513847061605166, 0.16394837374978416, 0.12928263654605482,
            0.10701005167385524, 0.058952137364351585, 0.03228329831528574, 0.022221120081695415, 0.01668646616935315,
            0.013077616207761072, 0.005215785860075431, 0.0027446484776795757, 0.0016032929901394295,
            0.0010133545989959897, 0.0006851946633356471, 0.0004730110330025379, 0.0003317008150845027,
            0.00023813481232809493, 0.00017484856161037305, 0.00010099841953977794, 0.00006102102819531075,
            0.00003861659093705247, 0.000024838184639992913, 0.000016395162107215873, 0.000011203385185134171,
            0.000007519915059314721, 0.000005346384300719542, 0.000003825038988162, 0.0000027999631145025846,
            0.0000013796619668937637, 7.326979175603654e-7, 3.816308264958934e-7, 2.1868439148420293e-7,
            6.003119068509477e-8, 1.6291123117184725e-8, 3.5108871365707728e-9, 2.7076485498156444e-10,
            5.854872142663226e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6171658215224473, 0.5996838615764111, 0.5509930604787316, 0.5018607535681606, 0.45761044240582155,
            0.4188893625559419, 0.2883936277776768, 0.1740029646350439, 0.12414606403599626, 0.096572357576729,
            0.07912501132826873, 0.0419736089776499, 0.02165622202187707, 0.014220382094619101, 0.010260948639513389,
            0.007796447708693611, 0.0028326035693215963, 0.0013625679707011992, 0.000751676988559132,
            0.000451429291417681, 0.0002876305795294228, 0.00019155192131638075, 0.00013207144235455255,
            0.00009366229776413632, 0.00006799819573915543, 0.000037940108902626316, 0.000022487865125382716,
            0.000013977120514341967, 0.000009024181589918148, 0.0000060134307576841235, 0.000004116471195053466,
            0.0000028846904285184173, 0.000002062491660771055, 0.000001500388914524749, 0.000001107581591428483,
            5.475465462755337e-7, 2.8772958972325776e-7, 1.5840694951495524e-7, 9.042137180115577e-8,
            3.181788921359202e-8, 1.2506871944322583e-8, 5.575722313337648e-9, 2.765344656986623e-9,
            1.425385073615105e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5008565146293997, 0.45623809617862576, 0.3681902552409621, 0.30153867617812025, 0.2530791111526316,
            0.21706642171077456, 0.12566921176919055, 0.06822093914536405, 0.046761632089900765, 0.03546981475643569,
            0.028454329923473656, 0.013718932722627511, 0.00588651295975086, 0.0032534692568521434,
            0.0019919160189955853, 0.0012914015981824764, 0.0002256793152185907, 0.00005814469701642221,
            0.000018895606686580477, 0.000007116440582350222, 0.0000029493514825035798, 0.000001314919206297299,
            6.163176074752788e-7, 2.9318739489386774e-7, 1.4063249011013526e-7, 3.279223703689382e-8,
            7.731291828960707e-9, 1.3414095390018588e-9, 4.601119485414529e-11, 2.0833335057091062e-12,
            9.952039192739903e-13, 3.2795988147427124e-13, 3.2795988147427124e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.522854712394536, 0.47723376796355294, 0.388431134284276, 0.32057170941716967, 0.27077219090513804,
            0.23343255249132117, 0.1370922660701514, 0.0753511829519855, 0.05212907641029128, 0.03979606771555099,
            0.03211159718457098, 0.01584377305985921, 0.0070387718853299475, 0.003990127233067094, 0.002502519539704589,
            0.0016609919940827108, 0.000322052290277359, 0.00009199465947432492, 0.00003302480241905769,
            0.000013492461608200922, 0.0000060238592058939935, 0.0000028824832232082966, 0.0000014526406757628708,
            7.618044389938753e-7, 4.0878654180376846e-7, 1.2915073011043887e-7, 4.7050311589148966e-8,
            1.5160006583414543e-8, 4.726776747787653e-9, 5.026613569825145e-10, 6.13096240442701e-11,
            9.952039192739903e-13, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.69736168463809, 0.6566909831291815, 0.5687601274216694, 0.4966855004645955, 0.4392689566077883,
            0.39298855167283103, 0.2566083796143272, 0.15369880585887558, 0.1117868943963923, 0.08902864801725227,
            0.07472075297029471, 0.04385722595201851, 0.025114105797199592, 0.017424203389360755, 0.01308707166019813,
            0.010258694915039545, 0.0040122859803957064, 0.0020194016839745793, 0.0011260314378682258,
            0.00067911995374279, 0.000437043580011443, 0.0002929268290969191, 0.00020578284565481685,
            0.00014867614257597044, 0.00011059255130428933, 0.00006494305721949103, 0.0000402627502774644,
            0.000026485001247695738, 0.000018121674901577402, 0.000012756647071055066, 0.000009184341207823836,
            0.000006743936916651342, 0.000005031495259322227, 0.000003804512371918811, 0.0000029108296790703037,
            0.0000015602027763073067, 8.405189337867824e-7, 4.7240574585494244e-7, 2.7445968098227524e-7,
            1.0720720777790405e-7, 4.6764826011624905e-8, 2.1167291253121334e-8, 8.845527199596859e-9,
            2.819511957241616e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6825386888744306, 0.6360972470913129, 0.547221616712274, 0.47519547990897193, 0.4181061884688153,
            0.37261129154497474, 0.2402377475321741, 0.14239406896098453, 0.1030580049537902, 0.08182201674677608,
            0.06847857042060068, 0.03948093522843129, 0.02197005906279121, 0.014855411305945289, 0.010758850963158352,
            0.008149626405917768, 0.002925602251632459, 0.001398728580383484, 0.0007617092105400339,
            0.0004410252130293336, 0.0002713267305051731, 0.00017870277564991147, 0.00012257920893473284,
            0.00008267363587355662, 0.000057573942676758714, 0.000028958779787186455, 0.000016683298811785207,
            0.000010190050128721048, 0.000006096950731126138, 0.00000381594251830375, 0.0000024485810871111013,
            0.0000016738878753159206, 0.0000012336226165032826, 8.418456900471938e-7, 5.743979822891987e-7,
            2.28027623644561e-7, 9.859872562856253e-8, 4.305543654936628e-8, 1.532460736086705e-8,
            7.298375237496657e-10, 4.567812794675774e-11, 7.920220035373404e-12, 7.920220035373404e-12,
            7.920220035373404e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6017920531111857, 0.5561719877354421, 0.46704528740775214, 0.39695245832395604, 0.3433501426956122,
            0.3018015329386414, 0.18717193396675266, 0.10757982144474171, 0.07652192463604364, 0.0598890833799648,
            0.04945816635960736, 0.02697056453355468, 0.013875664700088915, 0.008842236361362584, 0.006160296319495762,
            0.004522385744851996, 0.001428980494464921, 0.000621394044277823, 0.0003167227421403853,
            0.0001782957541705551, 0.00010762229839345268, 0.00006846401329780365, 0.00004539216670154143,
            0.00003112451254371702, 0.000021948781875940122, 0.000011679447742214032, 0.0000066761598066859745,
            0.0000040374678236215525, 0.0000025538036986145958, 0.0000016742219572033306, 0.000001130295556901872,
            7.831255462344337e-7, 5.540973945997981e-7, 3.9890067991612853e-7, 2.9187099116679156e-7,
            1.4233636016416366e-7, 7.394353557316602e-8, 4.0679300355856904e-8, 2.3410017593776582e-8,
            9.064513716422954e-9, 3.979864361381228e-9, 1.8203112017401077e-9, 7.817032664856825e-10,
            2.645927915762051e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4673477479445716, 0.3871691790272474, 0.27523917280797444, 0.2106419525596775, 0.17027638907539305,
            0.14293935449591402, 0.0802260794563926, 0.04352946422818893, 0.02993295258700466, 0.022726314921663,
            0.01821669154289691, 0.00859033210329903, 0.0033797785639140604, 0.0016826676444217435,
            0.0009250480171101944, 0.0005402289162857249, 0.00006306969849476495, 0.000012503440611324731,
            0.000003327630968631584, 0.0000010645804953829491, 3.74505226501647e-7, 1.4344945448305708e-7,
            5.177891767363745e-8, 1.625896936567014e-8, 4.917217300004495e-9, 2.1425883289794e-10,
            2.5726087926614127e-12, 4.807265696626928e-13, 3.2795988147427124e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4878875275876391, 0.4078548824567278, 0.29432438715767134, 0.22723264964633244, 0.18474800074188477,
            0.15576450043342244, 0.08847726018115709, 0.04868143637796891, 0.033809931448532116, 0.025857608021316678,
            0.020879283114853632, 0.01014290274245359, 0.004175858186331927, 0.002162172157659835,
            0.0012387105480755804, 0.0007517631216550579, 0.00010397005573632709, 0.00002412443399062362,
            0.000007252348216076478, 0.000002486389325370751, 9.54007663130762e-7, 3.860865360567809e-7,
            1.5952291252771097e-7, 7.330949824968513e-8, 3.322631925151143e-8, 5.9110417671703885e-9,
            1.5226697680503776e-10, 1.7467138846427588e-12, 7.257527911974648e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6682513129053984, 0.592605031176842, 0.4721934221149302, 0.39005631818621767, 0.33176765687972953,
            0.28889540360991983, 0.17820084736531763, 0.10572111126247381, 0.07796481631075983, 0.06310957868902493,
            0.05374403775422183, 0.03244460377939313, 0.018065247672971716, 0.011958824876143057, 0.008575624635729159,
            0.006494729748555241, 0.002180485729156278, 0.001032643554677613, 0.0005788038743431531,
            0.00035519949431923675, 0.0002194791902536934, 0.00014253908690672784, 0.00009632389430922382,
            0.00006723054210684007, 0.00004820382922485322, 0.00002641194423291804, 0.0000154809193172456,
            0.000009533704705577684, 0.000006095353914936652, 0.000004017436363579208, 0.000002714193059194514,
            0.0000018783846091885437, 0.000001327162266751003, 9.576137226519066e-7, 7.004436933799596e-7,
            3.4029258455525735e-7, 1.795989714725721e-7, 9.891202634726426e-8, 5.64552504744853e-8,
            1.850657693758251e-8, 4.963851774064665e-9, 4.930278407755395e-11, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6477791997841544, 0.5703746353016234, 0.45051893959162786, 0.36970271435434493, 0.3130907788994563,
            0.27165890369627477, 0.16588057908832524, 0.09759142225873486, 0.07164115066578351, 0.057735882917916745,
            0.04891583487404755, 0.028725146154826353, 0.015533642179343311, 0.00999228888130355, 0.00691467905350561,
            0.005032294266966356, 0.0014766306679963456, 0.0006225744024923729, 0.0002933589960514191,
            0.00015475997206131808, 0.00009262266529179378, 0.000058823706330915275, 0.00003748493184185797,
            0.000025149867510155206, 0.000017186199833707505, 0.000009626410960361653, 0.000005813030747781121,
            0.0000032512712070387195, 0.0000018725188569712614, 0.0000012591790436999872, 7.594840099045541e-7,
            4.688218950832379e-7, 2.9235619081191544e-7, 1.8443515159560775e-7, 1.2250235592592418e-7,
            4.4166096557773926e-8, 1.1786508657962713e-8, 2.2373507491124656e-9, 1.8405743595906188e-10,
            7.920220035373404e-12, 7.920220035373404e-12, 7.920220035373404e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5678817123751964, 0.4883431218964183, 0.37111006623992354, 0.2969121396219307, 0.24724297326707778,
            0.21201498399326563, 0.12577233666517482, 0.07216939539005118, 0.05192493508811683, 0.041067744902018165,
            0.0341849542760326, 0.018806859403739485, 0.00927662788843872, 0.005593301258817796, 0.0036973666675647505,
            0.0025878238512560075, 0.000680901618370617, 0.00026066948974410654, 0.00012084964171915795,
            0.0000632680676993939, 0.0000360917355463245, 0.0000219557481074216, 0.000014044846366538382,
            0.000009356001371954277, 0.000006442410236979564, 0.0000033068342723676124, 0.0000018405656135141152,
            0.0000010878100652432683, 6.726555564819412e-7, 4.32410858480135e-7, 2.866472841397251e-7,
            1.9497808412937002e-7, 1.3558422933759278e-7, 9.630009547145537e-8, 6.957787402203582e-8,
            3.2729216557414824e-8, 1.6729821016854802e-8, 9.036833827068227e-9, 5.082880137844883e-9,
            1.6567777815349978e-9, 4.691043981109612e-10, 2.711844898400674e-11, 1.0877384663249812e-12,
            1.0877384663249812e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4163169638293348, 0.3121523309072931, 0.2024033881038793, 0.14954146374540156, 0.1190472946681678,
            0.09923345083679005, 0.05544851964536318, 0.030166841010300843, 0.020746053796369936, 0.01570319214699558,
            0.012523044656208626, 0.005607632226096022, 0.0019195216357283855, 0.0008313143853462908,
            0.0004034700310345909, 0.00021167009481698517, 0.000017125831465992716, 0.0000026636729906583154,
            5.730990331752496e-7, 1.4843280220521084e-7, 3.5727540706139154e-8, 8.82081263586798e-9,
            8.679453392453673e-10, 2.8241853300414732e-12, 8.93285445613401e-13, 3.2795988147427124e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.43720824354406246, 0.33257973807523245, 0.21876421654031913, 0.16283541416723912, 0.13029859529481735,
            0.10904887167938845, 0.06172525005904666, 0.034113644700848225, 0.023714004166679285, 0.018127934908993684,
            0.014582216465413349, 0.006794344694889832, 0.0024888642492017633, 0.0011472540558521316,
            0.0005879317515418366, 0.0003263117403636526, 0.00003451382087755104, 0.000006446813690950777,
            0.0000015303877500019575, 4.2294868030090527e-7, 1.2061106591243487e-7, 3.3002175436891434e-8,
            7.69322094917868e-9, 2.532696941059953e-9, 3.250256730424894e-10, 9.952039192739903e-13,
            3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.1663560662309465e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6246293333857912, 0.5190330187655248, 0.383358074790776, 0.303946100392748, 0.2527843974037053,
            0.21722298621090078, 0.13202137559630545, 0.07963679946685803, 0.05982973707916348, 0.049069466280624495,
            0.04213136650169445, 0.025184861806674737, 0.013181099586205764, 0.008147190050550361,
            0.0055044762202974135, 0.00396969312767101, 0.0011920751143439773, 0.0004906398171671669,
            0.00023741848528800865, 0.00012904730350596548, 0.00007631867889514984, 0.00004800572563448308,
            0.00003163935357193143, 0.00002163142748381711, 0.000015395937656759173, 0.000008322428916279346,
            0.000004567024548984833, 0.000002679977971431491, 0.0000016556384231369847, 0.0000010575148431390957,
            6.955254914231546e-7, 4.7150068427370684e-7, 3.25950972546174e-7, 2.28712978445067e-7,
            1.6272613934109614e-7, 7.300575888269378e-8, 3.365449896541861e-8, 1.5084219429084555e-8,
            6.089877713222336e-9, 3.467104381371655e-11, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6008444199577588, 0.49631707200946096, 0.3627362130834662, 0.28594047050950316, 0.2367688990559926,
            0.20279702886005832, 0.12212202472847503, 0.07310492785750072, 0.05462836680557248, 0.04452412382270232,
            0.03790549418364611, 0.02199317175567218, 0.011147650981585957, 0.00667344281916498, 0.004380825101640993,
            0.0030226452517643073, 0.000732566586758215, 0.0002810630238407562, 0.00012339639317189048,
            0.00006006430359827841, 0.000032773754537892093, 0.00002016226714185443, 0.000013281162177802841,
            0.00000886484358068746, 0.0000061392811909460346, 0.0000028279034722800134, 0.000001430908926458585,
            7.38778126230244e-7, 3.753288065899696e-7, 2.0234413056208211e-7, 1.2893512391087825e-7,
            6.688871023730059e-8, 3.251924451941335e-8, 1.508095104352236e-8, 6.625598292231416e-9,
            3.274366333627654e-10, 3.647993018773832e-11, 7.920220035373404e-12, 7.920220035373404e-12,
            7.920220035373404e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5185805613040332, 0.41304595088981944, 0.28889378119852993, 0.2223922744852517, 0.1816223932462435,
            0.15415719152954122, 0.09066617382034643, 0.05278180361545189, 0.03843945629110161, 0.03061258184255043,
            0.025552671475510987, 0.01381258597502741, 0.006329776679151046, 0.003554402162327943,
            0.0022083494173163504, 0.001464329142199599, 0.0003176284759215036, 0.00010814735928272421,
            0.000046525326368791726, 0.00002318692654673559, 0.000012784904034519253, 0.000007588991766290068,
            0.000004764733585608637, 0.0000031245419773197943, 0.0000021211979401137517, 0.0000010567626543155196,
            5.708303079181838e-7, 3.2721176339915694e-7, 1.9653785301378422e-7, 1.2245432994544716e-7,
            7.859417636093475e-8, 5.186427851253017e-8, 3.502190923521685e-8, 2.4101878099439035e-8,
            1.6888973181528674e-8, 7.262853668065552e-9, 3.271179137063704e-9, 1.442516763456332e-9,
            5.928382969570486e-10, 1.3197275762081638e-11, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3291698380058364, 0.21994720367454293, 0.13268905280255103, 0.09635434629926455, 0.0763934153829362,
            0.06368453169666398, 0.03582460123703417, 0.019536682359117343, 0.013352118381031829, 0.009959864840574862,
            0.00778488424407395, 0.003076451653311943, 0.0008133620087073411, 0.00028344904537831045,
            0.0001163916526195123, 0.00005367918937595305, 0.0000027817762755299087, 2.8181979917852604e-7,
            3.319513032555739e-8, 2.431990054334011e-9, 1.0300649222472202e-12, 7.257527911974648e-13,
            7.257527911974648e-13, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.35098103125714925, 0.2376284157722918, 0.14507146301936785, 0.10609386576292318, 0.08457190664758842,
            0.07081915879876391, 0.040385604067301094, 0.0224368321358535, 0.015557078542940509, 0.01176286671503928,
            0.00932344524209663, 0.0039023615925724495, 0.001150539357521299, 0.0004391982374744107,
            0.00019545449462147132, 0.00009596039280346447, 0.000006124970496926742, 7.260749043114245e-7,
            8.91176330679457e-8, 1.3620532257618834e-8, 1.9135939499648202e-9, 1.123949822101622e-10,
            2.263522702605769e-12, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5445559608724094, 0.4158464893716396, 0.2836423069516788, 0.2183354596294437, 0.1794564445573879,
            0.15355149471279225, 0.09421114659708717, 0.058801471754074706, 0.04510271861136149, 0.037310591211585406,
            0.03201363779023385, 0.018188032445227598, 0.008524885719968829, 0.004949538433600288,
            0.0030624101266381494, 0.002071702629535436, 0.0004773633472558049, 0.00016985967216660413,
            0.00007471779777692045, 0.00003980872495301826, 0.000024232755739039824, 0.000015632392291298203,
            0.00001039624236054415, 0.000007084467760765101, 0.000004765821986607577, 0.000002212063868300973,
            0.0000011132661045243353, 5.843096486657373e-7, 3.3517365283231015e-7, 2.0069366302166713e-7,
            1.2303795815515883e-7, 7.671197055358903e-8, 4.823584065505315e-8, 3.02935038165586e-8,
            1.8850891625454835e-8, 4.889074700642482e-9, 7.674705315707797e-11, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5205928329497104, 0.3930290663530887, 0.2651335056635099, 0.20279829103771824, 0.16601543769504734,
            0.14167151456987945, 0.08624164935694004, 0.05343830542499871, 0.040622423281920506, 0.0332363877275758,
            0.02820473920027949, 0.015587867579857619, 0.007025452041901753, 0.003850003785890439,
            0.0023840488994512463, 0.0015876975998251126, 0.00032259262315094503, 0.00011287003750323267,
            0.0000444120456282171, 0.000019359153376399057, 0.000010179887189765147, 0.000005816447130143523,
            0.0000037263141574594982, 0.0000024067554256212986, 0.000001414828375345678, 5.179844038138981e-7,
            2.1620024992508036e-7, 9.397486877560368e-8, 3.4878684829209305e-8, 1.1086185303099683e-8,
            2.066033788494792e-9, 2.745000893256133e-10, 5.48673328992777e-11, 8.28415114284553e-12,
            7.920220035373404e-12, 7.920220035373404e-12, 7.920220035373404e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.43420360525201607, 0.31322612147198153, 0.20268236243578133, 0.15221232022969164, 0.1232956131712768,
            0.10446420245801946, 0.06223682632539496, 0.03717633810209213, 0.027360011085356455, 0.02180395014367368,
            0.018106360546790726, 0.009233180255176924, 0.0037147378644321093, 0.0018769384680003033,
            0.001071622546809721, 0.0006632729368705832, 0.00011721191266523661, 0.00003657626006454203,
            0.000015081468338980009, 0.000007319587910152351, 0.000003945268067759725, 0.0000022844913971604237,
            0.0000013949443466407782, 8.879950377691993e-7, 5.842262648082766e-7, 2.7288861039778974e-7,
            1.3727375868119667e-7, 7.333854341148207e-8, 4.090910170000979e-8, 2.3763303594237486e-8,
            1.42306646263468e-8, 8.681720801382808e-9, 5.344274270398648e-9, 3.312497619578782e-9,
            2.0488255040741582e-9, 5.310928398692677e-10, 3.718308867337026e-11, 1.1501346305984417e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12,
          ],
        },
      },
    ],
  },
};

export const wellingtonChristchurchResponse = {
  hazard_curves: {
    ok: true,
    locations: [
      {
        lat: -41.3,
        lon: 174.78,
        resolution: 0.01,
        code: "-41.30~174.78",
        name: "Wellington",
        key: "WLG",
      },
      {
        lat: -43.53,
        lon: 172.63,
        resolution: 0.01,
        code: "-43.53~172.63",
        name: "Christchurch",
        key: "CHC",
      },
    ],
    curves: [
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226162851564697, 0.5225071434926885, 0.5219511689585403, 0.5205600858165771,
            0.5182110664855981, 0.5045907848122114, 0.46279612978046947, 0.41924968575270505, 0.3782781366623785,
            0.3417894871956879, 0.21570479700703785, 0.10665106940029663, 0.06444933152827789, 0.04392030435975493,
            0.032155620663523836, 0.011344565080320296, 0.005566827040802693, 0.00309845656262319,
            0.0018544364319251813, 0.0011662117386052784, 0.0007600030883115608, 0.000508461483714141,
            0.00034803334157079046, 0.00024299535639696673, 0.0001257580349044396, 0.00006942447768772335,
            0.00003907503121533839, 0.00002297484974201222, 0.000013548528376206648, 0.0000081234689617526,
            0.000005006375590532423, 0.000003131537573469268, 0.0000019699151191140397, 0.000001235642332257064,
            4.002239839540067e-7, 1.1171209324256637e-7, 2.3431448004096467e-8, 7.902218102096015e-9,
            7.853574457428181e-10, 2.0833335057091062e-12, 8.985034938291392e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504298984450783, 0.5503937150479198, 0.5500980556726425, 0.5492275273650556,
            0.5467842905991283, 0.5235184873128955, 0.4850040385979381, 0.4396621861623258, 0.3985316497109651,
            0.36201629142156466, 0.23397031659988068, 0.11939986293963711, 0.07362881657912315, 0.05074865380833566,
            0.03754902734124999, 0.01358820417705235, 0.0068623301018432906, 0.003943019645569533,
            0.0024303019211958108, 0.0015674543542228648, 0.0010450505330024343, 0.0007141580942907832,
            0.0004974377353983827, 0.00035344939888212236, 0.00018736524054239253, 0.00010524847564069246,
            0.00006159994633640041, 0.00003754431821308413, 0.000023629593101086788, 0.000015183545422866826,
            0.000009860670287908867, 0.000006629450898021361, 0.000004472033561229363, 0.0000030566369207127467,
            0.000001231373828853791, 4.892259584110548e-7, 2.103907097428248e-7, 8.851993790864891e-8,
            8.533725238377899e-9, 7.280354097360942e-11, 2.5726087926614127e-12, 7.257527911974648e-13,
            7.224221221235894e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244646117080166, 0.7244225931081795, 0.7238957680601844, 0.7235245745672844,
            0.7225874901068138, 0.7084845958940214, 0.6692476747061171, 0.6303850975689289, 0.5896692794790327,
            0.5515902059363461, 0.4060571854270251, 0.25034161026636453, 0.17380363096684248, 0.12966366419758665,
            0.10144726610824031, 0.04400648993412908, 0.026485534579810118, 0.01830900954730977, 0.01342654419775138,
            0.010224654446614715, 0.007998078194423549, 0.0064089398364320024, 0.005229040727788444,
            0.004294769490088744, 0.0029879259347740154, 0.0021265437655035467, 0.0015324015582541207,
            0.0011232450743675177, 0.0008218666973740128, 0.0006097081981262677, 0.00045811600960971055,
            0.0003484149943021597, 0.0002678341977461936, 0.00020932279855180732, 0.00011679630051659518,
            0.00006910658352559767, 0.000042158370359257624, 0.000025387864915818334, 0.000011672830832809566,
            0.000006562670861631759, 0.00000391352657891364, 0.0000024313310841295177, 0.00000155824095415813,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674635812969, 0.7000592486590735, 0.6998155335543467, 0.6991766726679614,
            0.6983726467212799, 0.6890786057071858, 0.6494615252973448, 0.6069566147635233, 0.5667901643710526,
            0.5288414653992636, 0.3848645042034303, 0.23400076427457922, 0.16083823461257937, 0.11910932895364282,
            0.09269064681452582, 0.039493636421170285, 0.02315747358920305, 0.015559271662387453, 0.01128873438777394,
            0.00855083682038666, 0.0066508212997108285, 0.005242623575216018, 0.004200211705816086, 0.0033715162046439,
            0.0022425661225489234, 0.0015279390908440947, 0.0010742022666992326, 0.0007705890930651371,
            0.0005637976011671874, 0.0004177970148432681, 0.0003133467650257772, 0.00023813710312043794,
            0.0001844276626886705, 0.0001447612194447578, 0.00008308709067136884, 0.00004834478234601465,
            0.00003159439485389548, 0.0000214170635691735, 0.000010213070043785244, 0.00000428937737340096,
            0.0000018436741813232516, 7.877957920454648e-7, 3.1014073433713797e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661437718699, 0.6216636915135251, 0.6215939520687526, 0.621286657487961, 0.6205892936609897,
            0.6194259835501446, 0.6071436298502202, 0.5663957574845311, 0.5222336555404151, 0.4809811051083862,
            0.4437496276079981, 0.3086443800868426, 0.1761889887823646, 0.11603981583983615, 0.0834776362155848,
            0.06367873496924129, 0.02571916003541444, 0.014444946528455387, 0.009295432762869076, 0.006431802431619955,
            0.0046550050723139615, 0.003474493726424447, 0.002652921755391717, 0.0020616876043879394,
            0.00162527591781676, 0.0010450477955124828, 0.0006965446992002924, 0.00047766867707860346,
            0.0003353728199183965, 0.00024022950053812438, 0.0001750994767729152, 0.0001296026224773458,
            0.00009725272791157011, 0.00007388304476040698, 0.0000567595828284793, 0.000030648567677187533,
            0.00001743891000684538, 0.000010363387083223836, 0.000006388495498097099, 0.000002650825209774428,
            0.0000012089552987203221, 5.944863058530271e-7, 3.091628207792741e-7, 1.6720649294185395e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226191408943033, 0.5225890271336867, 0.5224916454064713, 0.5221448857052813,
            0.5213995782469354, 0.5136920377618207, 0.5010847719396593, 0.48001798545793606, 0.45652786895893943,
            0.43484505932197204, 0.338980662201894, 0.21582294404228375, 0.1478822638184113, 0.10751127076791307,
            0.08179574414594931, 0.031101859602977698, 0.016465655819636934, 0.010042139199930208,
            0.0066054384603513264, 0.004547274852946606, 0.0032299359695926546, 0.0023522409905776817,
            0.0017455645623320334, 0.0013155165652653797, 0.0007723507711443913, 0.0004720669810751846,
            0.0002982028623324903, 0.000193926586505604, 0.00012885500789424733, 0.00008743383133119906,
            0.00006034052477126025, 0.00004252185321195187, 0.000030532953012401686, 0.00002223080498732689,
            0.00001028073147359516, 0.000004887804355614389, 0.000002367987794626991, 0.0000011884214663934359,
            2.7399565294405903e-7, 3.0879041310455275e-8, 3.930724967737831e-9, 1.6945933545287062e-10,
            7.920220035373404e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504301850673154, 0.5504264803279132, 0.5503878300056815, 0.5501842007389086,
            0.5499660131882609, 0.5434490387707388, 0.5207315476004353, 0.5009202934028436, 0.4783173257020599,
            0.45568623582010676, 0.35860357230279755, 0.23400762631050612, 0.1633231118154647, 0.12056852537185081,
            0.09280700015558598, 0.03671711697475851, 0.01985707590038921, 0.012316931262827537, 0.008237328614744363,
            0.005769938182481248, 0.00417565794549515, 0.0030950780935576584, 0.0023376562936186433,
            0.0017917633470229523, 0.0010943707652733238, 0.0006945712765745667, 0.0004522573296701379,
            0.0003009094996692132, 0.00020484356729222604, 0.0001420305413614873, 0.00010026145376737389,
            0.00007206806980416114, 0.000052361974710262923, 0.0000383242618102976, 0.000018368926611874023,
            0.0000092575713586796, 0.000004860033648327963, 0.0000025727459578987554, 7.081376548006919e-7,
            1.6225393574582603e-7, 4.5783348667782775e-8, 1.134074634201454e-8, 1.0159042496127313e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244649777900473, 0.7244597451601315, 0.7244247393417028, 0.7241768394319992,
            0.7238750145485863, 0.7209121259951318, 0.7012559390773777, 0.6800893883444413, 0.6596474322033873,
            0.6382453370964553, 0.5370034059383244, 0.3972719006927142, 0.30958456873770424, 0.2502655231480622,
            0.20786523489968947, 0.10442116286390972, 0.0645604671476853, 0.044657596899394615, 0.03319557079922579,
            0.025967443209497447, 0.020979820977474868, 0.01733006872517441, 0.014535990604171219, 0.01231659739457395,
            0.009149087014042418, 0.007004738942654848, 0.005481610600692532, 0.004362292526488188,
            0.0035174212363724257, 0.00285067634269536, 0.002320452811304162, 0.0019155177072517156,
            0.0015927464312373199, 0.0013347838868649562, 0.0008809352443356013, 0.000598245266853784,
            0.0004292123729835193, 0.00032210531975422494, 0.00019875687948844867, 0.00013121231934314344,
            0.0000938155147272024, 0.00006907709048353805, 0.00005203575695432445,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000670480414604, 0.70005358812806, 0.699964228252618,
            0.6996890918188506, 0.6972661738915102, 0.683828691750302, 0.6623575842050524, 0.6384459908729401,
            0.6156178696907932, 0.5152119288532241, 0.37725933983801063, 0.2916102996750582, 0.2341796377508886,
            0.1933824128954364, 0.0952807788895458, 0.058331890452679135, 0.03996252926280281, 0.029357538161144933,
            0.022616231654224085, 0.017945650038928312, 0.014620607401047757, 0.012143000086054404,
            0.010244453293078883, 0.007529513978921698, 0.00569553395135015, 0.004373232988405319,
            0.0034143148443998043, 0.002717927912756152, 0.0021994458460128587, 0.0017865986331635196,
            0.0014698037220058488, 0.001223709256067807, 0.0010266315835440747, 0.0006785405539954538,
            0.0004772589354439589, 0.0003632869675292001, 0.00028499659777780906, 0.00015055675230373028,
            0.00008369938443262314, 0.00005091038924996236, 0.00003253548346168995, 0.00002050609241930222,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661628953166, 0.621665293564439, 0.6216482806688433, 0.621579378394364, 0.6214220589811635,
            0.6211493276099582, 0.6177107234062686, 0.6022138651273112, 0.5809288183701089, 0.5579632412953628,
            0.5350774791928632, 0.4356996296333248, 0.30525022859330425, 0.22717842876706473, 0.17665195842880177,
            0.14197705893058857, 0.06415943746668387, 0.03764914690522631, 0.025082364711842258, 0.01799012666915671,
            0.01353586525135657, 0.010530417193603078, 0.008396173959382252, 0.006821791252799499, 0.00562589501308323,
            0.003959853906014806, 0.002884999203327037, 0.002157002879821078, 0.0016460269678342458,
            0.0012774021298106323, 0.0010055739220193724, 0.000801460709776736, 0.0006458229719091756,
            0.0005255598204679006, 0.00043153419834441085, 0.00027288789691861737, 0.00018006294544837322,
            0.0001231747937363556, 0.0000869121119211128, 0.00004675208714766127, 0.000027345470761787242,
            0.000017087987531262104, 0.000011244919181976848, 0.000007711618156187168,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226200781230104, 0.5226143966923523, 0.5225887721771247, 0.5225268421945025,
            0.5223801770663701, 0.5183917147527218, 0.5067586462539388, 0.49639841651928185, 0.4782845953322071,
            0.4597435551806379, 0.3762162961203949, 0.255084426377507, 0.18157208596711893, 0.1358950001200288,
            0.10550453243409702, 0.042341607190053955, 0.023210670061008985, 0.014722115806546556, 0.010102076042049676,
            0.007254182620964757, 0.005374432155745675, 0.004079857710548938, 0.0031554754741343763,
            0.0024771223412660024, 0.00158229334952565, 0.0010476922956970435, 0.0007131948380480248,
            0.0004952248141058814, 0.0003497972198448387, 0.0002519178622362801, 0.00018426200880262254,
            0.0001365530494898768, 0.00010246793195413575, 0.00007765221770583747, 0.000040412783747508243,
            0.000022095109182698135, 0.00001261670509562876, 0.0000072907495352958755, 0.000002613856045718954,
            9.88928653522514e-7, 3.6633658178292793e-7, 1.2469686014692286e-7, 2.8851771638471746e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.5504299440243442, 0.5504260864693008, 0.5503975918414635,
            0.5502597965476179, 0.5481141225761329, 0.5305558915069181, 0.5157101335264993, 0.4999715348721817,
            0.48165125239170076, 0.39590685315783786, 0.27381558945456225, 0.1983196932781468, 0.1501358847267565,
            0.11775332823916848, 0.04901710070264451, 0.0274478525164512, 0.017632212572450463, 0.012222891781080008,
            0.008892553662026105, 0.006686012359062258, 0.005151749239929722, 0.0040332612280839595,
            0.0032033490072415887, 0.002090150910944973, 0.0014131982142229875, 0.000983090466556005,
            0.000700402081566519, 0.0005086996038605358, 0.0003759684060840275, 0.00028152453236635874,
            0.00021268952874480958, 0.00016206883668924937, 0.0001247208283548074, 0.00006728581695372599,
            0.000038074677454495315, 0.000022393994212396618, 0.000013343177201119618, 0.000005105195450019548,
            0.000002040733516286516, 8.583853566453385e-7, 3.055916587557661e-7, 8.018461272740751e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244646360991227, 0.7244589755938797, 0.7244320447461051,
            0.7243143169945788, 0.7230961481364501, 0.7135104863326228, 0.6958155621577953, 0.6794035346203608,
            0.6632118430924798, 0.5778616195880407, 0.4424323688689631, 0.3510632215321738, 0.2868788000649421,
            0.23995725169462018, 0.12244048249645792, 0.07682146700499412, 0.05388036598507987, 0.04058431077322052,
            0.03213367864349195, 0.026307398824373003, 0.02209218952992575, 0.01884401117007074, 0.01625281847476557,
            0.012476218399872214, 0.009854279910627038, 0.007920243806283192, 0.006491068995823307,
            0.005408327712879704, 0.0045249621113750745, 0.003812303909175991, 0.0031809898771201135,
            0.002659566537328106, 0.002243248101703925, 0.0015204531464883104, 0.0010704695357542492,
            0.0007595236258438271, 0.0005570687362984932, 0.0003230389110352654, 0.00019965993949983064,
            0.000131916025765076, 0.00009274104186809646, 0.00006516185125160501,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000674950715742, 0.7000668528737124, 0.7000578998799916,
            0.7000315047686902, 0.698766230080918, 0.692382653465904, 0.6786331684553558, 0.6612679613891307,
            0.6426717132148433, 0.5558868824390227, 0.42150636831751215, 0.33207735026796403, 0.26992533757876747,
            0.22470629583650203, 0.11264683578443131, 0.06995023159643822, 0.048704235480437945, 0.03638640696287083,
            0.028500834119724705, 0.023047168470850535, 0.019072460429708664, 0.01609064938050036, 0.01377105572709404,
            0.010441604056588063, 0.008150072673488147, 0.006455459486127696, 0.0051871978009233, 0.004216223539693822,
            0.0034536679462346784, 0.0028541392573705124, 0.002382197286367001, 0.0019977699546434623,
            0.0016888679728559162, 0.0011316601975754456, 0.0007821507806790709, 0.0005612685475534968,
            0.0004075579601942229, 0.00023867833116756771, 0.00015845134215948707, 0.00011229960344533113,
            0.0000821607252854406, 0.00005428526650548505,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661866104946, 0.6216660879858429, 0.6216624411565996, 0.6216432374264846, 0.6215920254337233,
            0.6214933988722408, 0.6199587490038694, 0.6111667330772821, 0.5969391426634493, 0.5799733360857174,
            0.5618962934722612, 0.4747491663116797, 0.346984922244755, 0.2649551480237092, 0.20964223225256126,
            0.17060555443905917, 0.07963083034232322, 0.04762098790424315, 0.03229210862111603, 0.0235824385517914,
            0.018070995030776697, 0.014318272486470645, 0.011625321109698527, 0.009615719786011579, 0.00807023429450808,
            0.005876129534018033, 0.004421309953151408, 0.003407938309265567, 0.002676311854785686,
            0.0021335267717491847, 0.0017221090534435253, 0.0014047943479760063, 0.0011564919810937407,
            0.000959793996968126, 0.0008023161439622929, 0.0005267423848445702, 0.0003573903577499045,
            0.00024923334307006066, 0.00017794331394654616, 0.00009624358541485252, 0.00005556492788980606,
            0.000033822174417721764, 0.00002151604021889636, 0.00001420810684843074,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226201278873664, 0.5226169346745149, 0.5226000395475721, 0.5225549897790487,
            0.5224482470141321, 0.5189999950373497, 0.5064537186601015, 0.49568813389485594, 0.4764277845561122,
            0.4566229676063312, 0.36709544302949465, 0.24188440211117804, 0.16946628854808574, 0.12595320321280776,
            0.09742624236439128, 0.039528383055126994, 0.022018757665876517, 0.014132562860139749, 0.00980529819368698,
            0.007132611792386937, 0.005358085540700421, 0.004118607514253143, 0.003227269455649651,
            0.002564610094085404, 0.001679518753391127, 0.001139777467752455, 0.0007953380149421593,
            0.0005681705279559498, 0.0004148746034357882, 0.0003077995630594097, 0.0002318080102984942,
            0.000176564963475619, 0.0001356448647261869, 0.00010527705571272556, 0.00005804632108100281,
            0.00003361762263329194, 0.000020151640038208855, 0.000012274873431837884, 0.000004851558166772806,
            0.0000020654983120088805, 9.091842556311902e-7, 4.092377647868517e-7, 1.842412334756859e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.5504300903155013, 0.5504254766975698, 0.5504044648626147,
            0.5503164653824445, 0.5483484293557862, 0.5304382994783299, 0.5139787976987004, 0.49812714266979585,
            0.47865360446756905, 0.38669754580969395, 0.2599984914322545, 0.18525587954169395, 0.1388748817798723,
            0.10838623341337557, 0.045207488077471054, 0.02554190144924058, 0.016582024800953842, 0.01162679765851424,
            0.008552852926824221, 0.006500290908152615, 0.0050580473116880595, 0.004007262579035385,
            0.0032213298048214484, 0.0021529709788192337, 0.0014885364887806262, 0.0010575239589498875,
            0.0007684871494220963, 0.0005692740753828974, 0.00042848794355693383, 0.0003262267599441847,
            0.0002515494035966143, 0.00019592227773245453, 0.00015438414062778514, 0.0000881823929323552,
            0.00005276590525784975, 0.000032490480571427754, 0.0000205694505093299, 0.000008902795105161458,
            0.00000412687439675473, 0.0000019772808824671933, 0.0000010186761288905473, 4.82308483513172e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244648053380689, 0.7244573224263806, 0.7244311546346338,
            0.7243193231935913, 0.7231326592278912, 0.71407065427386, 0.6955244550684666, 0.6772551793808795,
            0.6598519919375881, 0.5681742044541993, 0.4238721113288023, 0.32896271262122784, 0.2640940284219305,
            0.2177652327145473, 0.10671004868922508, 0.06624404394102949, 0.046628074902474566, 0.03537967614652963,
            0.028218208348707384, 0.02324683437132019, 0.01959653862616585, 0.01677791479862131, 0.014527601162899407,
            0.011153613459018366, 0.00879367379442932, 0.0070425781006444145, 0.005728650021446278,
            0.004708295433432308, 0.0038986582771076824, 0.00325616404340021, 0.002744783797065652,
            0.002330243324678674, 0.001984298779442074, 0.0013510638723451063, 0.0009359577982898474,
            0.0006645400985334061, 0.00047798871182070654, 0.00026624684439173306, 0.0001587370830197952,
            0.00010040402228130866, 0.0000674308753509334, 0.00004733623907597461,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000674950715742, 0.7000667709649545, 0.7000612655854339,
            0.7000346759995317, 0.6987090367402893, 0.6920716299954426, 0.6771558713997716, 0.6592516683076799,
            0.6398817556749186, 0.5467934598261158, 0.4039028354975976, 0.31110809650056703, 0.24840700134287344,
            0.20371014442525726, 0.09835411092353558, 0.060556715157900204, 0.04236929865385248, 0.03192073681314814,
            0.025239995051519797, 0.020581463767107684, 0.017163432815072256, 0.014552027375524058,
            0.012499329145497643, 0.009498900654671067, 0.007423331168159658, 0.005906822665456904,
            0.004763450183496381, 0.003869985204058554, 0.0031720317228835413, 0.002625800030742277,
            0.0021906129256072738, 0.0018391593876340962, 0.0015546224606395734, 0.0010376494822916965,
            0.0007110777104048664, 0.0005001178979864032, 0.0003599396941393428, 0.00019878176651727135,
            0.00011515882944801468, 0.00007458761686182328, 0.000052189823090309395, 0.000037984095612420366,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216661654224257, 0.6216641275807292, 0.6216500084165597, 0.6216077015889009,
            0.6215207594568097, 0.6200273412941696, 0.6109517132692038, 0.5959906673198686, 0.5780216203781602,
            0.5587963698986333, 0.46578905537661647, 0.33130254579584234, 0.24774070001622941, 0.19305012532542296,
            0.1553609964557086, 0.07081300175194, 0.04226489359175086, 0.028794555237894648, 0.021166395514323703,
            0.016328125962097827, 0.013017080196062725, 0.01062629929042955, 0.008830624289821943, 0.007441010783358488,
            0.005452103834547859, 0.0041208764251366445, 0.0031868536766682864, 0.0025088216153508268,
            0.0020037123844880403, 0.0016196383051507739, 0.00132268342116798, 0.0010898550183601058,
            0.0009051124980541944, 0.0007570005525536885, 0.0004973055387544506, 0.0003373156799064376,
            0.00023493443473665028, 0.00016734641802553465, 0.00008979728747111631, 0.00005121456474894567,
            0.000030680390578959994, 0.000019136153970846382, 0.00001234761687941496,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226201278873664, 0.5226164183204118, 0.5225943992930919, 0.5225271142926685,
            0.5223918359579173, 0.5192849855069623, 0.5047611852402235, 0.49035910752186396, 0.4689204123959768,
            0.4465079009755187, 0.3462009939477822, 0.21660341696630775, 0.14766804945008907, 0.10771773675665453,
            0.08259225446088668, 0.03312352744779976, 0.018457585072424523, 0.011878802454786234, 0.008261070569275029,
            0.006027055355460922, 0.004540942469554713, 0.003504153419483286, 0.0027554230365827736,
            0.002196473007438491, 0.0014451565608501525, 0.0009859776440526164, 0.0006927493894161035,
            0.0004983426991711482, 0.0003660232764658098, 0.0002735737986298306, 0.00020753433168304333,
            0.00015968404179562165, 0.00012429931311704198, 0.00009775674164482841, 0.00005599883783591906,
            0.00003353519222299593, 0.000020672671728225236, 0.000013010466975344961, 0.0000055133786032433805,
            0.000002516131395322181, 0.0000012248185441432469, 6.035040414498672e-7, 2.7723808837532005e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.5504294017053988, 0.5504192986020472, 0.5503876048409112,
            0.5502694446253285, 0.5479565069802389, 0.528636582380993, 0.508728054649302, 0.4898579389917818,
            0.4676429271544374, 0.3652486418721256, 0.23288601650249233, 0.1609733864565281, 0.11853134525234808,
            0.09148648631911702, 0.037563437649967966, 0.021240329834578228, 0.01384149440780712, 0.009736335269940633,
            0.007182306794323868, 0.005474653523232455, 0.004272704683415568, 0.0033929113104172797,
            0.0027356653741586008, 0.0018443866363646024, 0.00128910344258489, 0.0009239346755210109,
            0.0006766884338784852, 0.0005046764695119765, 0.00038181884649679266, 0.00029288725467355083,
            0.00022750254499952287, 0.00017849953266783363, 0.00014142591635562358, 0.00008156991227830519,
            0.000049020327696487955, 0.000030529196916311645, 0.00001961900713047557, 0.000008810461467691872,
            0.000004313829582143969, 0.0000022034659584089815, 0.000001171227817203372, 5.929580884078689e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244637063978558, 0.7244516919085022, 0.7244094293503247,
            0.7243081530846452, 0.722593247795518, 0.7103741509259381, 0.6876360885038559, 0.6669177674981129,
            0.6459564811180384, 0.5396520798731251, 0.3854127045524678, 0.29024473010962454, 0.2280295728470149,
            0.18507126175908659, 0.08756554016237295, 0.054313952492371476, 0.03851366474945537, 0.029492858058838726,
            0.023656091089648962, 0.019550891823235363, 0.01648817886775699, 0.014106286488052698, 0.012192642767015637,
            0.009321594585180566, 0.00726171960776269, 0.0057553324061568375, 0.004623248202674331,
            0.0037541531784842657, 0.003079155854717539, 0.0025461727378486196, 0.002124227337846017,
            0.0017809843837240926, 0.0015014608472470098, 0.0010015033680326677, 0.0006901978409923858,
            0.0004865456320244073, 0.0003510798341759183, 0.00019151111238788847, 0.00011023921361726705,
            0.00006608674710336948, 0.00004102892966517668, 0.000026833223064463082,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000674572952295, 0.7000652682809478, 0.7000524853606387,
            0.6999938640337293, 0.6982228960604837, 0.688749715220325, 0.6697825584736432, 0.6478687366326901,
            0.6247704824452619, 0.5186425177031913, 0.36650378953206536, 0.2737643014339425, 0.21370610689556102,
            0.17245418970444978, 0.08053992060334479, 0.04956534501827099, 0.03493595694468687, 0.026535665303308736,
            0.02108579533894356, 0.017256377052505312, 0.014399020381139317, 0.012205954323563277, 0.010462272025115946,
            0.007892440873890932, 0.006108791425052238, 0.004820101930839012, 0.0038607542047973888,
            0.003129151579236281, 0.0025588208593322276, 0.002112210533709452, 0.0017567959143826577,
            0.001471741816909572, 0.0012398588765914766, 0.0008260360020493986, 0.0005640692471114497,
            0.00039501902036310745, 0.0002827870208132228, 0.0001537296667519646, 0.00008800061832567255,
            0.00005259942469193479, 0.00003291789704361231, 0.00002134000716386206,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216661747408057, 0.621664317521373, 0.6216487525347314, 0.6215991654737449,
            0.6214938339284889, 0.6196093829517986, 0.6082070900797276, 0.5899106300872244, 0.5684492923970792,
            0.545909544718899, 0.4409358618329964, 0.2988363021920114, 0.21639116699076738, 0.16502831473178334,
            0.1308868414887398, 0.05815819863316107, 0.0347356212797769, 0.02379087529004015, 0.017577389356677308,
            0.013611012119414874, 0.0108775178290635, 0.00889127910917865, 0.007391730037565873, 0.006226682049363842,
            0.0045530731180757605, 0.003430347898897874, 0.0026427697164550196, 0.0020720397926541566,
            0.0016480061445792557, 0.0013266238843665924, 0.0010790131575995564, 0.0008855813177220904,
            0.0007326592798137054, 0.0006104977215968967, 0.00039754155811537066, 0.0002674188000660247,
            0.00018477413506549342, 0.0001305946199847293, 0.00006899081631848502, 0.000038713163890028576,
            0.00002279266462154889, 0.000013951034293854568, 0.000008819146984941352,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226201088639462, 0.5226126892866649, 0.5225621431871608, 0.5224192497783449,
            0.5221265478118859, 0.5173587067475027, 0.49987667014886156, 0.4785613236155311, 0.451823323551197,
            0.42580299509044683, 0.31341336866819586, 0.1837893059443513, 0.12168149043459497, 0.08745315346770033,
            0.06659129995163347, 0.026601421191865024, 0.01489211846370115, 0.009598976697583014, 0.006677310848868674,
            0.004865892490642301, 0.0036601119783659674, 0.002819309211639893, 0.0022107943068015562,
            0.0017612740446884359, 0.0011564800610089306, 0.0007872689038255176, 0.0005521019325595395,
            0.0003964704534429097, 0.00029096606688704937, 0.00021701931569635086, 0.00016457567325670812,
            0.0001264267096281847, 0.00009843642903982348, 0.0000773959873947632, 0.00004431616165050533,
            0.00002652700656039845, 0.000016388336064683917, 0.000010384272384978279, 0.000004489590198136972,
            0.0000021062234302909934, 0.0000010421457021307947, 5.066162439322223e-7, 2.430903604577214e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504302186912917, 0.550427715056494, 0.55040527105713, 0.5503280286132931,
            0.55012069238531, 0.5455747541910602, 0.5204305082048749, 0.4985725338123255, 0.47279399756450063,
            0.44587466566525785, 0.3318582007485691, 0.19901659992904452, 0.13330933334713274, 0.09662315466370747,
            0.07400694823684055, 0.030240894726564722, 0.017169944724572093, 0.011212129360898968, 0.007892044199995904,
            0.005820357597114745, 0.004431082549698674, 0.0034504096771008994, 0.0027374196526663876,
            0.0022014411293870832, 0.0014754896753085879, 0.0010255756947128933, 0.0007322874630872308,
            0.0005350130904327005, 0.00039899761932009147, 0.0003026295778345797, 0.0002324411045002375,
            0.00018043941571154745, 0.00014135482797065957, 0.0001118485699498535, 0.00006471605457364594,
            0.00003911512194431382, 0.000024472139321176522, 0.000015765131836633786, 0.000007002714492561408,
            0.0000033811298891601765, 0.0000017759504301352265, 9.229061518643492e-7, 4.767124767157682e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244650301107136, 0.7244608617192902, 0.7244296720016533, 0.7243427731137829,
            0.7240568905086998, 0.7209671505626511, 0.7025796720326359, 0.6748855436188644, 0.6502664000110846,
            0.6244228231097766, 0.5036399871341714, 0.34329024576917533, 0.2510655945899736, 0.1934034090758162,
            0.15481876012174509, 0.07190215712374688, 0.044928040688155084, 0.032183487015007686, 0.024785364368288414,
            0.019919840735561915, 0.016428096607526567, 0.013798416337715893, 0.01175418531237247, 0.010116594221308658,
            0.007666218594580538, 0.005943257765372289, 0.00469234077770376, 0.0037554661637211927,
            0.003037710515651925, 0.0024798249621255757, 0.0020430177890795767, 0.0016953981339461472,
            0.0014159561482309643, 0.0011896754556677265, 0.0007868197487893749, 0.0005358830749259574,
            0.0003745193849367451, 0.0002672136871919209, 0.00014316432772142887, 0.00008133138807177325,
            0.000048296461525011364, 0.00002967092608893137, 0.00001883017323300873,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000670417554364, 0.7000583668102947, 0.699996590604184,
            0.6998404296843729, 0.6969817887352303, 0.681873946804818, 0.6571466941064565, 0.6297073534354674,
            0.6028856503955384, 0.482635580998632, 0.3250415466753882, 0.23574682047696938, 0.1804453862321806,
            0.1438440721176603, 0.0659797131329633, 0.040903513282044626, 0.02905042496434958, 0.022149831551754574,
            0.017594566362763922, 0.014363627247584571, 0.011949238331700151, 0.01008102562619957, 0.00860115101705496,
            0.006427961503362791, 0.0049297709168973245, 0.0038538253976582925, 0.0030557957088327,
            0.002458447403890289, 0.001996437437200048, 0.0016362479833919774, 0.0013524876813033064,
            0.0011263779811760584, 0.0009442801458888972, 0.0006230086305143106, 0.0004233662667702984,
            0.0002954238413321944, 0.00021036475343113285, 0.00011273036438642139, 0.00006386146368931507,
            0.000037922407390644786, 0.000023323811239454773, 0.00001471156525841954,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216661637513212, 0.6216614003641545, 0.6216267540321976, 0.6215253038240012,
            0.6213222417363657, 0.6181490170149695, 0.6016617287544537, 0.5776414037385407, 0.5509944824480342,
            0.5240303723426494, 0.40632194742180505, 0.26111654081257124, 0.18338697020935424, 0.13735779583056798,
            0.10778343271120557, 0.047374977131533685, 0.028464331295010047, 0.019601043276173454, 0.014522632294054566,
            0.011252311644381204, 0.008983666959580009, 0.007328166552207149, 0.0060755121518338865,
            0.005101652948778706, 0.0037043679723191094, 0.0027708174307362705, 0.0021196532920051405,
            0.001650775893492012, 0.0013046954495519458, 0.0010440939189262023, 0.0008445693810194464,
            0.0006896299056039479, 0.0005678295117664754, 0.00047104594256625634, 0.0003036864630131078,
            0.0002025216936631544, 0.00013886164478871574, 0.00009746289313422337, 0.000050843286799961934,
            0.000028197448082379978, 0.000016408795659557953, 0.00000992234895905531, 0.000006190242618185606,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226201278873664, 0.5226191196460916, 0.5225720321750046, 0.5223633264182238, 0.5217422250805045,
            0.5206882080359455, 0.5086423016653676, 0.48071460621875317, 0.44263582776087945, 0.40550340159309,
            0.37089981491161444, 0.24320700445378385, 0.12821119034755943, 0.08151065283794859, 0.05772952896173931,
            0.04366875678023632, 0.017459552000165933, 0.009773338231113503, 0.006260385342938717, 0.00430354424306839,
            0.0030938063284882446, 0.002296833953297825, 0.0017450729754416728, 0.0013502407446382707,
            0.0010610769632275715, 0.0006792555704347421, 0.0004524808549307927, 0.00031124889004928994,
            0.0002194619609312456, 0.0001583259709900562, 0.00011626450087495648, 0.00008670147398452583,
            0.00006560456954023304, 0.000050366930005977745, 0.00003907191870142966, 0.000021716030052083858,
            0.000012669864632203805, 0.000007606511300395585, 0.000004694521832956156, 0.0000019241893464281847,
            8.475314205426957e-7, 3.8765896510059e-7, 1.903143141974564e-7, 9.152962201543942e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.5504299825993612, 0.5504049313545291, 0.5502411511839116, 0.5498268669911639,
            0.5490566291516983, 0.5356719677240365, 0.5006954463364433, 0.4634318966418378, 0.42554116559998945,
            0.3904034426916687, 0.260532910627973, 0.14015435208603616, 0.08999751646471285, 0.06416489132472906,
            0.0488750628321917, 0.019976040474134135, 0.011373088900905834, 0.0074002975748870305, 0.005165492470926786,
            0.003767304065144647, 0.0028328171619867028, 0.002179491076656027, 0.001706300113941757,
            0.0013567818776371067, 0.00088581558657145, 0.0006006191293335759, 0.00041996973159435244,
            0.0003009425493604246, 0.00022014451735541068, 0.00016392069767569824, 0.00012406700438790037,
            0.000095222728208383, 0.00007391506933460265, 0.000058075749726935674, 0.00003307942311299053,
            0.00001962322396754637, 0.000012012726494872437, 0.000007593066656747408, 0.000003288540904936969,
            0.0000015511755514250966, 7.656855490306738e-7, 3.73716649636442e-7, 1.7735187213017305e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244650301107136, 0.7244645127852244, 0.7244316775208468, 0.7242605460496562, 0.7237170508714851,
            0.7231721481766911, 0.7139516989981091, 0.6760704648270318, 0.6396009811288533, 0.6015891491622638,
            0.5649759187179567, 0.42042031764288096, 0.26083891407868914, 0.18109783159416196, 0.13560449182364628,
            0.10699144119417606, 0.050097562718566496, 0.032143822072105394, 0.023301263096965518, 0.01789964594375496,
            0.01424696024468179, 0.011617683161118351, 0.00963640547152538, 0.008103465785360548, 0.00688061501115806,
            0.005084687311961832, 0.0038503556236561447, 0.0029744602743733612, 0.0023335441783889467,
            0.0018533671682366706, 0.0014875227750664015, 0.001205353657973039, 0.0009846748889150686,
            0.0008109738841140833, 0.0006729106738114893, 0.00043362886817882503, 0.00028908199398953717,
            0.00019840778509103399, 0.00013950895920892436, 0.00007342065198741197, 0.00004085033435822739,
            0.00002384446753352632, 0.000014397699396555962, 0.000008955264352846345,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000674950715742, 0.7000580039490837, 0.6999135332591843, 0.6994242736563389,
            0.6987213067937178, 0.6912495516904043, 0.6588610664847906, 0.6189817788010734, 0.5807458526388297,
            0.5445120367130115, 0.4006087822408808, 0.2450437406470993, 0.16883490302338497, 0.1257683959021605,
            0.09890376907579797, 0.04572745361359168, 0.028942891465047537, 0.02072342338798533, 0.01576958219246144,
            0.012441661744178009, 0.010066314258114066, 0.008285636321808213, 0.006922614662295612,
            0.0058408420721205775, 0.004274109162035977, 0.0032125143231666532, 0.0024662186102416025,
            0.001925328551788387, 0.0015249512460255546, 0.0012224852180111676, 0.000991026690075139,
            0.0008104345899861592, 0.0006681366439579337, 0.0005551435564748613, 0.0003577503348448941,
            0.00023792418517291103, 0.00016248231353799223, 0.00011339311965776821, 0.000058679858565758295,
            0.00003235313235865715, 0.000018625659546807623, 0.00001109308333330513, 0.000006799946344618686,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216661871968883, 0.6216655167315548, 0.6216279600697291, 0.6214445839051405, 0.6210107594106675,
            0.620264303418101, 0.6117059379381868, 0.5794978971274943, 0.5408909969258262, 0.5025559821560966,
            0.46660215849442244, 0.32976752523236064, 0.19147351983605793, 0.12827653429902897, 0.09396384999898914,
            0.07302036236929242, 0.03228682305844671, 0.01965439228764004, 0.013586719360207632, 0.010035509103866053,
            0.0077190897044700345, 0.006103407338613155, 0.004924476412590453, 0.004035876164965161,
            0.003349485417530296, 0.0023765978319577725, 0.0017390933115301193, 0.001303409287043192,
            0.000995962275850499, 0.0007734054056538042, 0.0006088823355732975, 0.00048508877083868536,
            0.000390517041774225, 0.0003173063050646815, 0.0002599661195538805, 0.00016295806879493133,
            0.00010603169523885209, 0.00007112386635564337, 0.000048936372675503264, 0.00002463935637288171,
            0.000013237116743893976, 0.000007475482885133719, 0.0000043886335877667365, 0.0000026559632981688756,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226199615471778, 0.522603501396363, 0.5222841781766163, 0.5209099195473719, 0.5181895538441197,
            0.5142637534448317, 0.49305221943182415, 0.4346835476891488, 0.3774506490019174, 0.32822581150379304,
            0.2867660021124113, 0.16367584135579524, 0.07908822179795304, 0.049244145056335696, 0.03463936048318489,
            0.02618107163820994, 0.0104156192705241, 0.005702143217632785, 0.003541263077412256, 0.002356086939334327,
            0.0016381966602781073, 0.001177801879960632, 0.0008678526638458098, 0.000652592275987405,
            0.000498821098537793, 0.00030322608159835614, 0.0001923551712578425, 0.0001266298861521666,
            0.00008573539035555718, 0.00005954732220536829, 0.00004228478834174372, 0.00003056219848799154,
            0.000022496261752320786, 0.000016796525606588807, 0.00001266879384201306, 0.000006523452006974663,
            0.0000034679164736513834, 0.000001901972841705376, 0.000001069997906281127, 3.457422222760858e-7,
            1.3033367383077657e-7, 5.438164153440424e-8, 1.832971829873742e-8, 7.993155692886944e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504302186912917, 0.550422051677084, 0.5501372122765807, 0.5491374734426255, 0.5465539898345544,
            0.542240062016996, 0.5136004468021745, 0.45518041584055935, 0.3972161795623515, 0.34704348116614525,
            0.3047779789956189, 0.17736253178723896, 0.08713147052430813, 0.054768756100476844, 0.03889876552029285,
            0.029595016161653536, 0.012087492511337287, 0.006762582044324024, 0.004278808551205082,
            0.0028947412113867715, 0.00204584038151342, 0.0014899764204826397, 0.0011100976029276598,
            0.0008430842209469445, 0.000650808191555353, 0.0004036210031970864, 0.00026128207948502343,
            0.0001748809619885841, 0.00012041094512418056, 0.0000850302723849109, 0.00006119263612491971,
            0.0000448306340747347, 0.00003336616519868368, 0.000025199402928844258, 0.00001927909191370504,
            0.000010326891479846307, 0.000005816905320907237, 0.0000033966108124152376, 0.0000020451254608258158,
            8.039360728772383e-7, 3.457736509117658e-7, 1.28476351446416e-7, 4.770679329357819e-8,
            1.7490104031736564e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244649952990247, 0.7244542829085276, 0.7241101937796187, 0.7231724222920938, 0.7211889996736656,
            0.718020508695717, 0.6899236174054332, 0.6295710921045188, 0.56979634542589, 0.5172434351808363,
            0.4701724029254178, 0.3130849698691998, 0.17475157830197915, 0.11703833264437927, 0.08693051719683348,
            0.06899566252627837, 0.03379825925390136, 0.021904308797466587, 0.015736320938787636, 0.011919564630092087,
            0.009349894067757294, 0.007507815452288591, 0.0061393677799045756, 0.00509484184576497,
            0.004281505501863455, 0.003110220026470338, 0.0023236594403606586, 0.0017731939250662698,
            0.0013721567598074728, 0.001078080689301712, 0.0008583763917847661, 0.0006915945669801755,
            0.0005632851406637604, 0.0004627439728832297, 0.0003832455538908443, 0.00024671095754718177,
            0.00016404701557718495, 0.00011215748033339089, 0.00007845549366650841, 0.00004072314795159018,
            0.000022429450931589342, 0.000012955001183121786, 0.000007764229900564246, 0.000004772896698445482,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000674950715742, 0.7000647751747476, 0.6997987273157243, 0.6987131653844247, 0.6969654896435713,
            0.6946104040400429, 0.6720241384757236, 0.6091562598404767, 0.5494063591296676, 0.4963586497960867,
            0.4501572351859717, 0.2954753885916634, 0.1627537283182332, 0.10826444536572764, 0.08008897305773437,
            0.06333705941286681, 0.0304023505227698, 0.01948198968956569, 0.013864338673540061, 0.010389426836464722,
            0.008012167468640386, 0.0063111784829215055, 0.005063355600812062, 0.004128483140420136,
            0.003413894582661387, 0.0024038822988497553, 0.0017465155167726476, 0.0012972825197407208,
            0.0009850309926597212, 0.0007610088319423287, 0.0005998837743653167, 0.00047861114904939185,
            0.00038612003367727245, 0.0003152577219561885, 0.0002595198728784216, 0.0001652356173511514,
            0.00010884644762169083, 0.00007297030584387045, 0.00005036855480502412, 0.00002565762423507751,
            0.000013957276827208042, 0.000007945872565083214, 0.000004680131718556361, 0.0000028420447431097664,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.621666082498689, 0.621651721219594, 0.6213452687088993, 0.6203157110286104, 0.6183980433212247,
            0.6156218984904561, 0.5927783986063238, 0.5318030062001946, 0.4726388628596724, 0.42089509133374525,
            0.3766979768830598, 0.2353103955875147, 0.1236868334637499, 0.08054362217927906, 0.05870584534443784,
            0.04576684110231885, 0.020709882167591974, 0.012604404972678928, 0.008589852874407016,
            0.0062184501042185664, 0.004678701860944986, 0.003617951012406453, 0.002856571561792102,
            0.0022931473326800564, 0.0018661690540263233, 0.0012773316105920978, 0.000905290210998203,
            0.0006594313400250302, 0.0004911758195917825, 0.0003727289206515762, 0.00028736285675504727,
            0.00022460238387792355, 0.00017766718295531884, 0.0001420380492032645, 0.00011463243168804907,
            0.00006949478380157109, 0.00004393488589484228, 0.000028730994695383076, 0.000019319751295067265,
            0.000009338690620289307, 0.0000048398748626838695, 0.0000026409473858048645, 0.0000014974909655414955,
            8.762151582614122e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226068495400105, 0.5222669439306481, 0.5165273324165905, 0.5071538668168728, 0.5008237264705967,
            0.4919077042389777, 0.43260702165963555, 0.327872144346957, 0.25441137710145584, 0.20413701762701328,
            0.16855767036400784, 0.08497620031145292, 0.039354330431458896, 0.024355920926260172, 0.01708201932935089,
            0.012842997277423876, 0.004788209065220877, 0.0023868432686229716, 0.0013476044191565281,
            0.0008162228718072458, 0.0005202391769796584, 0.00034372535116954155, 0.00023412992005044836,
            0.00016374155514987254, 0.00011703118637460759, 0.00006297974199006473, 0.0000360187264415087,
            0.000021610528788611187, 0.000013448996803813356, 0.000008613158739175244, 0.000005665333633889307,
            0.0000038035253952858368, 0.0000026021043675758557, 0.000001810324199989095, 0.0000012701998676229065,
            5.276778471028578e-7, 2.3057897691954178e-7, 9.833928638069978e-8, 4.21440732312206e-8,
            6.915491179704247e-9, 5.461859853284068e-10, 2.5726087926614127e-12, 9.952039192739903e-13,
            3.2795988147427124e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5504220967117186, 0.5500918676600538, 0.5461499243851927, 0.5376024286402186, 0.5256941918730371,
            0.5138819646947488, 0.45397286884002847, 0.3472125925826292, 0.2724340546554596, 0.2202231309383658,
            0.18273310840009077, 0.09361877630861519, 0.04398916691083529, 0.027551690785429794, 0.0195233754550481,
            0.014793200122707515, 0.005700477494701159, 0.0029189460492353046, 0.0016823966259343671,
            0.0010404028406910887, 0.0006743932098812321, 0.0004538505030969927, 0.0003146211368087041,
            0.00022370843316005313, 0.00016244708992917036, 0.00009043608949759969, 0.000053183414550587734,
            0.00003267168873038132, 0.000020786524621043832, 0.00001363858185395768, 0.000009172039427090408,
            0.000006288587283968404, 0.000004356683577099381, 0.000003054434397934877, 0.000002170463407296114,
            9.729341190180859e-7, 4.2959239578264063e-7, 1.8972050175354041e-7, 8.8406803247413e-8,
            2.3027725504221053e-8, 4.134324216309437e-9, 1.0879741552116684e-10, 8.470668610982557e-12,
            7.257527911974648e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7244489512819434, 0.7240082102867708, 0.7213822388205553, 0.7130266874940798, 0.6998819974639832,
            0.6873814088301802, 0.6296472543960511, 0.5201007260225542, 0.43614450346149697, 0.37223689949772254,
            0.3227130068811931, 0.18685766704820592, 0.09713698273013996, 0.06529567241727732, 0.04941985121952814,
            0.03984137060638194, 0.01979120293939918, 0.012487945512244511, 0.008666676912936697, 0.006374890826205148,
            0.0048375718788557795, 0.0037410704339766194, 0.002952281870236195, 0.0023606429123346944,
            0.0019157302708294383, 0.001308154795101744, 0.0009240424796401135, 0.0006709435128930359,
            0.0004972875406831557, 0.0003762631096849822, 0.00028869645755524555, 0.00022495590028202928,
            0.00017745452277735131, 0.00014189114130620992, 0.00011415220945225841, 0.00006658731398755219,
            0.0000406432793269691, 0.000025698704244818396, 0.00001671537133370963, 0.00000755067864837219,
            0.0000036855376453896684, 0.000001882229526017909, 9.735285926293358e-7, 5.214859308821929e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7000643974112994, 0.6998104158385527, 0.6969625396528106, 0.6913089690356512, 0.682678936599066,
            0.6726010340111036, 0.610199398701856, 0.49914646298000376, 0.4152124148588587, 0.3520676632484339,
            0.30361669103242234, 0.17307396000471065, 0.08920771521330813, 0.059717777253718886, 0.04491910444207928,
            0.03595254966874539, 0.01750367609869416, 0.010746640499582853, 0.00717059708129215, 0.005051443682781823,
            0.0036948564225500603, 0.0027913302820963184, 0.0021601904177521954, 0.0016936309384469612,
            0.0013495652639385941, 0.0008963370650794735, 0.0006214971327225976, 0.00044474169868683714,
            0.0003268715778816797, 0.00024561649160484243, 0.0001878510986414561, 0.00014554612028935758,
            0.00011417685790767578, 0.0000906456862210076, 0.00007271808598607724, 0.000042957685322392276,
            0.000026355791932841577, 0.000016709930381513288, 0.000010859632446212616, 0.000004909397089414291,
            0.000002382875196108114, 0.0000012179119731614563, 6.440092344327324e-7, 3.513866583260139e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6216476884979943, 0.6212942493101236, 0.6181075798291976, 0.611529028831222, 0.6024924792646197,
            0.5918811690452229, 0.5311806255607342, 0.4223063212687011, 0.34259795835626033, 0.28469826866998516,
            0.24162346848966715, 0.1317937412170539, 0.06556726701325155, 0.04284719588456789, 0.031539661417632085,
            0.024763244344888722, 0.0110433332918236, 0.006388310493986755, 0.004112909104031562, 0.0028183875092531617,
            0.002015073952878801, 0.0014867648596017498, 0.001124248407107426, 0.00086720797601926,
            0.0006800937408845567, 0.0004354223682593229, 0.0002909074207483813, 0.00020097095281268785,
            0.0001426621733281215, 0.00010358480327321716, 0.00007666539471154857, 0.00005768274463608137,
            0.000044027087427549375, 0.000034032236123315354, 0.000026601277763539933, 0.000014977186219410008,
            0.000008856152663405564, 0.0000054451832282860165, 0.000003456174823260791, 0.000001496990045380648,
            7.00801667768499e-7, 3.4743950164543247e-7, 1.8063336766562584e-7, 9.600619194880542e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21659161118160403, 0.13247479688936467, 0.07783494957666962, 0.05655940856085776, 0.04501708264559931,
            0.03762081206764487, 0.021254169285473197, 0.011384683274628337, 0.007455376335172197, 0.005272616948655012,
            0.003897853265902468, 0.001159768411745374, 0.00019997005501577847, 0.00005264007465364029,
            0.00001729256106015541, 0.000006527016820613867, 1.4670755479533015e-7, 2.7236023436572054e-9,
            2.0833335057091062e-12, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.23444589412497266, 0.14512645244921746, 0.08618220204270614, 0.06314721009663962, 0.05051291067297597,
            0.042427416004980935, 0.02439552833374664, 0.013418669230201083, 0.009000467837136159, 0.006508641484375764,
            0.004907368553638407, 0.001612217013677341, 0.0003204412787352951, 0.00009374076361989623,
            0.00003299430303553519, 0.000013045121237616046, 3.890603049727659e-7, 1.8468334779944223e-8,
            1.297539853339913e-10, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4169457772412231, 0.28794936524859555, 0.18553889318419517, 0.14140870745777986, 0.11650832043312034,
            0.10034230561231863, 0.06416728241435377, 0.042019162576150626, 0.03251030980346044, 0.02660848811171235,
            0.02243352166495614, 0.01156100149559472, 0.004627607771462675, 0.002347506347667502, 0.001279568571317581,
            0.0007787459063183056, 0.00014720106337140493, 0.000038228897944581064, 0.00001682978499106813,
            0.000008035016867524725, 0.000004043006579079744, 0.0000021329334954911516, 0.0000011595118394325254,
            6.06640965017391e-7, 3.216051425170008e-7, 9.961661362467567e-8, 3.4158881168089295e-8,
            1.3122925079933623e-8, 4.315102164476059e-9, 3.596777320424849e-10, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.39245792049327116, 0.2676583493119632, 0.17064472489559737, 0.12942466169309638, 0.1062943440557935,
            0.09138620556075226, 0.05812491075020921, 0.03761003719324825, 0.02872229569120904, 0.023244314602227898,
            0.0193742217508879, 0.009491731446298201, 0.003491767696730061, 0.0016159936164638186,
            0.0008766581287121943, 0.0005296352976483771, 0.00009351017396582496, 0.00002538303844057399,
            0.000007596451446212349, 0.000003018922341668784, 0.0000012624554257545384, 6.467977169410943e-7,
            2.686559241737996e-7, 1.0892132806805677e-7, 6.229132054613729e-8, 9.867878070224378e-9,
            3.2795399729224073e-10, 1.3876233495579982e-11, 7.920220035373404e-12, 7.920220035373404e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.31107811641792843, 0.20349819341100467, 0.12600203874101004, 0.09437274702857446, 0.07687578788160729,
            0.0656319449970039, 0.040418804131686706, 0.024713574209214526, 0.018077953483561005, 0.014144084445781116,
            0.011466611003239943, 0.005103300728107834, 0.0016368176074935527, 0.0007029014903725132,
            0.00035625976569068457, 0.0002017641920320593, 0.000028381191046292452, 0.000007765189837374862,
            0.000002799681039277369, 0.000001171478554902186, 5.403854407639417e-7, 2.6788326165232624e-7,
            1.3966450169058203e-7, 7.568327548585289e-8, 4.25344772268025e-8, 1.4537427839274688e-8,
            5.244324772131307e-9, 1.8825438315253867e-9, 5.86922410902404e-10, 1.1008105832921181e-10,
            1.3683194228187467e-11, 1.4765720381318537e-12, 1.161107497971139e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5224598266235109, 0.519453131378316, 0.5051202695029793, 0.49147936646965495, 0.4725155623983956,
            0.4520885698106642, 0.3594248581798744, 0.23768404656752679, 0.17194550190563454, 0.1327872385997002,
            0.10714418074536074, 0.052088358903773335, 0.02382279006309395, 0.014604870773018043, 0.01009307266645758,
            0.00744963220120821, 0.00247734613367597, 0.0010982861274063769, 0.0005547497593190941,
            0.00030425408864849805, 0.00017721107214496837, 0.00010803933925792789, 0.00006846881874185297,
            0.00004484574187344943, 0.000030175419233883823, 0.000014621769213643969, 0.000007562540771982841,
            0.000004101953113214416, 0.0000023231094155881613, 0.0000013554025601558582, 8.089895207139751e-7,
            4.893796448439413e-7, 2.941341595955179e-7, 1.7233108983909952e-7, 1.025697518406801e-7,
            2.8567031629123107e-8, 7.518273115181273e-9, 1.489720791170157e-9, 3.1480340556555575e-10,
            2.4830137945741626e-12, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.550258766722722, 0.5480213910249411, 0.5336437793078116, 0.5135425138149088, 0.49352166937502995,
            0.47318554234433663, 0.3786185500520238, 0.25441779811095844, 0.1857586207710941, 0.14417195309620498,
            0.11688673197570544, 0.05777482902103652, 0.026967896812854796, 0.016769089567975345, 0.011735255193810147,
            0.008750814845374908, 0.003039235529041956, 0.0013979976607669806, 0.0007270511179761248,
            0.00041044291848209736, 0.0002451775749170432, 0.00015345467454675648, 0.00009958160897148926,
            0.00006662977642821113, 0.00004576841050965481, 0.000022965345262621906, 0.000012213658032249114,
            0.000006858458543735578, 0.000003996650123951457, 0.000002401741662552975, 0.0000014784563851904767,
            9.404155470097026e-7, 5.925495929633391e-7, 3.7436916903492474e-7, 2.3784499264714817e-7,
            6.955873694787454e-8, 2.5615520371502498e-8, 6.431994492572812e-9, 7.130384060971551e-10,
            1.2192025167223619e-11, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.724244675716172, 0.7224486952315375, 0.7084453646988909, 0.6869327163616169, 0.6686334974311896,
            0.6480399913818703, 0.5520115697235912, 0.4144956405066832, 0.3271296858010384, 0.2683534150377869,
            0.2265488136341806, 0.12497021418435464, 0.06553898556063562, 0.04505132133953049, 0.034489316931403786,
            0.027945947912812064, 0.013831034470473853, 0.008604202003503293, 0.005788601373254396,
            0.004072526671221046, 0.0029935164705367245, 0.0022597174902923367, 0.001754659954374363,
            0.001389096946906279, 0.0011158410672382066, 0.0007491270906072044, 0.0005108190242331932,
            0.000354284351787947, 0.0002508531433657815, 0.0001817208082355032, 0.00013432441540268554,
            0.00010046359145676139, 0.00007630906824379106, 0.000058505922701714123, 0.000045340009365869194,
            0.000025140910140343336, 0.000014395484484764251, 0.000008669877938650316, 0.00000557374554504506,
            0.000002539009214763688, 0.0000012337339608954757, 6.26797786895672e-7, 3.2418411466395014e-7,
            1.670371659878711e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6999812791507412, 0.6980616747610479, 0.6880629711601677, 0.6713589778019081, 0.6511808501923034,
            0.6296307674292106, 0.5309910858017205, 0.3931948042734976, 0.3072840009495028, 0.2502148131499857,
            0.20993407556351806, 0.11440202980356858, 0.0596443132142828, 0.04060310605517084, 0.03083260198627206,
            0.024760073564084297, 0.01153765329670774, 0.006668223701405921, 0.004318785585836039, 0.003005651125115356,
            0.0021333538695389614, 0.0015596382496433252, 0.001176661030466113, 0.0009112000924573146,
            0.0007193655384662862, 0.0004709266527802078, 0.00031550652137864865, 0.0002179625027838498,
            0.00015492460820842288, 0.00011203758892334471, 0.00008248968919770383, 0.0000617203229201795,
            0.00004684628350065063, 0.00003606132795714, 0.000028165001143408172, 0.000015992151777774846,
            0.000009337803908209021, 0.000005564179909166483, 0.0000034340624961437527, 0.00000140210079280223,
            5.841880579282588e-7, 2.614590925986704e-7, 1.1719830439460566e-7, 5.243518419462134e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6214792612593757, 0.6195158352215367, 0.6083684233272632, 0.591153855143679, 0.5712932734981487,
            0.5505737741035195, 0.45418769926892316, 0.32249011258405796, 0.24479753474979568, 0.19531278916754666,
            0.1615557849546292, 0.08462637761792485, 0.04240843770032715, 0.027984907691846663, 0.02063954657064503,
            0.016143204900229104, 0.006842099743037892, 0.0037409944018267235, 0.0022930833231358653,
            0.0015066174646883254, 0.0010385892727661674, 0.000742023324041218, 0.00054516673561972,
            0.00040968993269363123, 0.00031370901314740115, 0.00019248481874341026, 0.00012391051743532413,
            0.00008281860635068846, 0.00005706315487263209, 0.00004032050471468154, 0.000029101292649787953,
            0.000021390420798480987, 0.000015974219479302584, 0.000012097595959082073, 0.000009276451661638385,
            0.00000499729892335363, 0.000002839443747225939, 0.000001684138404402482, 0.0000010353361789869416,
            4.2262752917600953e-7, 1.8603207539376229e-7, 8.624155999110805e-8, 4.057016779909197e-8,
            1.8666774483271887e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5133544497598906, 0.498911320448914, 0.4500066559543509, 0.40194815938294326, 0.358961470271088,
            0.32215704586915944, 0.20600518502343354, 0.11572916168500298, 0.07959102749968952, 0.060336622693908104,
            0.04839852865157603, 0.023555341682026967, 0.01062196582375631, 0.006263773720045911, 0.004120133445952534,
            0.0028776437053203213, 0.0007106776561710404, 0.00023837848866755252, 0.00009503234414889529,
            0.000042685551185503396, 0.0000208172543632375, 0.000010852742111057136, 0.000005975565103032243,
            0.0000034099603152137916, 0.000001997728462423359, 7.301111474048623e-7, 2.8446242806107824e-7,
            1.068562610429729e-7, 4.395811796920495e-8, 1.9385594882397425e-8, 8.018198938566085e-9,
            2.331202009209221e-9, 1.884521427797381e-10, 2.6902924332716793e-11, 3.0088154190366367e-12,
            7.257527911974648e-13, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5439702039939831, 0.5205476371399511, 0.47222454735599406, 0.4226804199221805, 0.37893714202439904,
            0.3413742832700989, 0.2214461255649862, 0.12626018421965046, 0.08753487142984182, 0.0667588452976252,
            0.05381148635321754, 0.026680541779661136, 0.012340197541522784, 0.007424537415379113, 0.004970146225581337,
            0.0035199240283539683, 0.0009291911126471493, 0.0003314454914102789, 0.00014027962453839582,
            0.00006651134224806782, 0.00003431743082527528, 0.000018831855754158558, 0.000010889495958381297,
            0.000006584791903934267, 0.000004086179436474204, 0.0000016635180188633809, 7.420746453925812e-7,
            3.38400958569148e-7, 1.6917556602561078e-7, 8.123693207018334e-8, 3.1923858466399425e-8,
            1.4328609743152754e-8, 6.244298522695146e-9, 2.6369110076799903e-9, 5.734019925540679e-10,
            2.5726087926614127e-12, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7203116402416143, 0.6973641485344323, 0.6515615882667851, 0.6033741838047058, 0.559360823137153,
            0.5197879250766285, 0.3784567009152568, 0.24212404627385484, 0.17780092633633088, 0.14086155687918162,
            0.11687516923941695, 0.06477209218181891, 0.03609102633076956, 0.025247574189178286, 0.019274523471821636,
            0.015431974684496221, 0.006906788952375267, 0.003752710831639662, 0.002291535210956563,
            0.0015072179847868221, 0.0010077162315355061, 0.0006946352350040712, 0.000492252857980641,
            0.0003611370749349024, 0.0002696508793320345, 0.00015961690888993004, 0.00009936897818399084,
            0.00006492097582908982, 0.00004436072653522374, 0.00003138600166929949, 0.000022816060458834367,
            0.000016921774047766533, 0.00001275737258377152, 0.000009749800122360597, 0.000007542539344096433,
            0.000004147825090949908, 0.0000024032507079940757, 0.0000014432165049704793, 8.945301748219947e-7,
            3.617834788416374e-7, 1.4680560522783992e-7, 6.646883687899674e-8, 3.298927819805897e-8,
            1.6900395083396802e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6966415403040054, 0.6805768466702873, 0.6315819607022485, 0.5825962261109148, 0.5378095586743644,
            0.4981322421562092, 0.3579237098354046, 0.22513847061605166, 0.16394837374978416, 0.12928263654605482,
            0.10701005167385524, 0.058952137364351585, 0.03228329831528574, 0.022221120081695415, 0.01668646616935315,
            0.013077616207761072, 0.005215785860075431, 0.0027446484776795757, 0.0016032929901394295,
            0.0010133545989959897, 0.0006851946633356471, 0.0004730110330025379, 0.0003317008150845027,
            0.00023813481232809493, 0.00017484856161037305, 0.00010099841953977794, 0.00006102102819531075,
            0.00003861659093705247, 0.000024838184639992913, 0.000016395162107215873, 0.000011203385185134171,
            0.000007519915059314721, 0.000005346384300719542, 0.000003825038988162, 0.0000027999631145025846,
            0.0000013796619668937637, 7.326979175603654e-7, 3.816308264958934e-7, 2.1868439148420293e-7,
            6.003119068509477e-8, 1.6291123117184725e-8, 3.5108871365707728e-9, 2.7076485498156444e-10,
            5.854872142663226e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6171658215224473, 0.5996838615764111, 0.5509930604787316, 0.5018607535681606, 0.45761044240582155,
            0.4188893625559419, 0.2883936277776768, 0.1740029646350439, 0.12414606403599626, 0.096572357576729,
            0.07912501132826873, 0.0419736089776499, 0.02165622202187707, 0.014220382094619101, 0.010260948639513389,
            0.007796447708693611, 0.0028326035693215963, 0.0013625679707011992, 0.000751676988559132,
            0.000451429291417681, 0.0002876305795294228, 0.00019155192131638075, 0.00013207144235455255,
            0.00009366229776413632, 0.00006799819573915543, 0.000037940108902626316, 0.000022487865125382716,
            0.000013977120514341967, 0.000009024181589918148, 0.0000060134307576841235, 0.000004116471195053466,
            0.0000028846904285184173, 0.000002062491660771055, 0.000001500388914524749, 0.000001107581591428483,
            5.475465462755337e-7, 2.8772958972325776e-7, 1.5840694951495524e-7, 9.042137180115577e-8,
            3.181788921359202e-8, 1.2506871944322583e-8, 5.575722313337648e-9, 2.765344656986623e-9,
            1.425385073615105e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5008565146293997, 0.45623809617862576, 0.3681902552409621, 0.30153867617812025, 0.2530791111526316,
            0.21706642171077456, 0.12566921176919055, 0.06822093914536405, 0.046761632089900765, 0.03546981475643569,
            0.028454329923473656, 0.013718932722627511, 0.00588651295975086, 0.0032534692568521434,
            0.0019919160189955853, 0.0012914015981824764, 0.0002256793152185907, 0.00005814469701642221,
            0.000018895606686580477, 0.000007116440582350222, 0.0000029493514825035798, 0.000001314919206297299,
            6.163176074752788e-7, 2.9318739489386774e-7, 1.4063249011013526e-7, 3.279223703689382e-8,
            7.731291828960707e-9, 1.3414095390018588e-9, 4.601119485414529e-11, 2.0833335057091062e-12,
            9.952039192739903e-13, 3.2795988147427124e-13, 3.2795988147427124e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.522854712394536, 0.47723376796355294, 0.388431134284276, 0.32057170941716967, 0.27077219090513804,
            0.23343255249132117, 0.1370922660701514, 0.0753511829519855, 0.05212907641029128, 0.03979606771555099,
            0.03211159718457098, 0.01584377305985921, 0.0070387718853299475, 0.003990127233067094, 0.002502519539704589,
            0.0016609919940827108, 0.000322052290277359, 0.00009199465947432492, 0.00003302480241905769,
            0.000013492461608200922, 0.0000060238592058939935, 0.0000028824832232082966, 0.0000014526406757628708,
            7.618044389938753e-7, 4.0878654180376846e-7, 1.2915073011043887e-7, 4.7050311589148966e-8,
            1.5160006583414543e-8, 4.726776747787653e-9, 5.026613569825145e-10, 6.13096240442701e-11,
            9.952039192739903e-13, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.69736168463809, 0.6566909831291815, 0.5687601274216694, 0.4966855004645955, 0.4392689566077883,
            0.39298855167283103, 0.2566083796143272, 0.15369880585887558, 0.1117868943963923, 0.08902864801725227,
            0.07472075297029471, 0.04385722595201851, 0.025114105797199592, 0.017424203389360755, 0.01308707166019813,
            0.010258694915039545, 0.0040122859803957064, 0.0020194016839745793, 0.0011260314378682258,
            0.00067911995374279, 0.000437043580011443, 0.0002929268290969191, 0.00020578284565481685,
            0.00014867614257597044, 0.00011059255130428933, 0.00006494305721949103, 0.0000402627502774644,
            0.000026485001247695738, 0.000018121674901577402, 0.000012756647071055066, 0.000009184341207823836,
            0.000006743936916651342, 0.000005031495259322227, 0.000003804512371918811, 0.0000029108296790703037,
            0.0000015602027763073067, 8.405189337867824e-7, 4.7240574585494244e-7, 2.7445968098227524e-7,
            1.0720720777790405e-7, 4.6764826011624905e-8, 2.1167291253121334e-8, 8.845527199596859e-9,
            2.819511957241616e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6825386888744306, 0.6360972470913129, 0.547221616712274, 0.47519547990897193, 0.4181061884688153,
            0.37261129154497474, 0.2402377475321741, 0.14239406896098453, 0.1030580049537902, 0.08182201674677608,
            0.06847857042060068, 0.03948093522843129, 0.02197005906279121, 0.014855411305945289, 0.010758850963158352,
            0.008149626405917768, 0.002925602251632459, 0.001398728580383484, 0.0007617092105400339,
            0.0004410252130293336, 0.0002713267305051731, 0.00017870277564991147, 0.00012257920893473284,
            0.00008267363587355662, 0.000057573942676758714, 0.000028958779787186455, 0.000016683298811785207,
            0.000010190050128721048, 0.000006096950731126138, 0.00000381594251830375, 0.0000024485810871111013,
            0.0000016738878753159206, 0.0000012336226165032826, 8.418456900471938e-7, 5.743979822891987e-7,
            2.28027623644561e-7, 9.859872562856253e-8, 4.305543654936628e-8, 1.532460736086705e-8,
            7.298375237496657e-10, 4.567812794675774e-11, 7.920220035373404e-12, 7.920220035373404e-12,
            7.920220035373404e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6017920531111857, 0.5561719877354421, 0.46704528740775214, 0.39695245832395604, 0.3433501426956122,
            0.3018015329386414, 0.18717193396675266, 0.10757982144474171, 0.07652192463604364, 0.0598890833799648,
            0.04945816635960736, 0.02697056453355468, 0.013875664700088915, 0.008842236361362584, 0.006160296319495762,
            0.004522385744851996, 0.001428980494464921, 0.000621394044277823, 0.0003167227421403853,
            0.0001782957541705551, 0.00010762229839345268, 0.00006846401329780365, 0.00004539216670154143,
            0.00003112451254371702, 0.000021948781875940122, 0.000011679447742214032, 0.0000066761598066859745,
            0.0000040374678236215525, 0.0000025538036986145958, 0.0000016742219572033306, 0.000001130295556901872,
            7.831255462344337e-7, 5.540973945997981e-7, 3.9890067991612853e-7, 2.9187099116679156e-7,
            1.4233636016416366e-7, 7.394353557316602e-8, 4.0679300355856904e-8, 2.3410017593776582e-8,
            9.064513716422954e-9, 3.979864361381228e-9, 1.8203112017401077e-9, 7.817032664856825e-10,
            2.645927915762051e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4673477479445716, 0.3871691790272474, 0.27523917280797444, 0.2106419525596775, 0.17027638907539305,
            0.14293935449591402, 0.0802260794563926, 0.04352946422818893, 0.02993295258700466, 0.022726314921663,
            0.01821669154289691, 0.00859033210329903, 0.0033797785639140604, 0.0016826676444217435,
            0.0009250480171101944, 0.0005402289162857249, 0.00006306969849476495, 0.000012503440611324731,
            0.000003327630968631584, 0.0000010645804953829491, 3.74505226501647e-7, 1.4344945448305708e-7,
            5.177891767363745e-8, 1.625896936567014e-8, 4.917217300004495e-9, 2.1425883289794e-10,
            2.5726087926614127e-12, 4.807265696626928e-13, 3.2795988147427124e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4878875275876391, 0.4078548824567278, 0.29432438715767134, 0.22723264964633244, 0.18474800074188477,
            0.15576450043342244, 0.08847726018115709, 0.04868143637796891, 0.033809931448532116, 0.025857608021316678,
            0.020879283114853632, 0.01014290274245359, 0.004175858186331927, 0.002162172157659835,
            0.0012387105480755804, 0.0007517631216550579, 0.00010397005573632709, 0.00002412443399062362,
            0.000007252348216076478, 0.000002486389325370751, 9.54007663130762e-7, 3.860865360567809e-7,
            1.5952291252771097e-7, 7.330949824968513e-8, 3.322631925151143e-8, 5.9110417671703885e-9,
            1.5226697680503776e-10, 1.7467138846427588e-12, 7.257527911974648e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6682513129053984, 0.592605031176842, 0.4721934221149302, 0.39005631818621767, 0.33176765687972953,
            0.28889540360991983, 0.17820084736531763, 0.10572111126247381, 0.07796481631075983, 0.06310957868902493,
            0.05374403775422183, 0.03244460377939313, 0.018065247672971716, 0.011958824876143057, 0.008575624635729159,
            0.006494729748555241, 0.002180485729156278, 0.001032643554677613, 0.0005788038743431531,
            0.00035519949431923675, 0.0002194791902536934, 0.00014253908690672784, 0.00009632389430922382,
            0.00006723054210684007, 0.00004820382922485322, 0.00002641194423291804, 0.0000154809193172456,
            0.000009533704705577684, 0.000006095353914936652, 0.000004017436363579208, 0.000002714193059194514,
            0.0000018783846091885437, 0.000001327162266751003, 9.576137226519066e-7, 7.004436933799596e-7,
            3.4029258455525735e-7, 1.795989714725721e-7, 9.891202634726426e-8, 5.64552504744853e-8,
            1.850657693758251e-8, 4.963851774064665e-9, 4.930278407755395e-11, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6477791997841544, 0.5703746353016234, 0.45051893959162786, 0.36970271435434493, 0.3130907788994563,
            0.27165890369627477, 0.16588057908832524, 0.09759142225873486, 0.07164115066578351, 0.057735882917916745,
            0.04891583487404755, 0.028725146154826353, 0.015533642179343311, 0.00999228888130355, 0.00691467905350561,
            0.005032294266966356, 0.0014766306679963456, 0.0006225744024923729, 0.0002933589960514191,
            0.00015475997206131808, 0.00009262266529179378, 0.000058823706330915275, 0.00003748493184185797,
            0.000025149867510155206, 0.000017186199833707505, 0.000009626410960361653, 0.000005813030747781121,
            0.0000032512712070387195, 0.0000018725188569712614, 0.0000012591790436999872, 7.594840099045541e-7,
            4.688218950832379e-7, 2.9235619081191544e-7, 1.8443515159560775e-7, 1.2250235592592418e-7,
            4.4166096557773926e-8, 1.1786508657962713e-8, 2.2373507491124656e-9, 1.8405743595906188e-10,
            7.920220035373404e-12, 7.920220035373404e-12, 7.920220035373404e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5678817123751964, 0.4883431218964183, 0.37111006623992354, 0.2969121396219307, 0.24724297326707778,
            0.21201498399326563, 0.12577233666517482, 0.07216939539005118, 0.05192493508811683, 0.041067744902018165,
            0.0341849542760326, 0.018806859403739485, 0.00927662788843872, 0.005593301258817796, 0.0036973666675647505,
            0.0025878238512560075, 0.000680901618370617, 0.00026066948974410654, 0.00012084964171915795,
            0.0000632680676993939, 0.0000360917355463245, 0.0000219557481074216, 0.000014044846366538382,
            0.000009356001371954277, 0.000006442410236979564, 0.0000033068342723676124, 0.0000018405656135141152,
            0.0000010878100652432683, 6.726555564819412e-7, 4.32410858480135e-7, 2.866472841397251e-7,
            1.9497808412937002e-7, 1.3558422933759278e-7, 9.630009547145537e-8, 6.957787402203582e-8,
            3.2729216557414824e-8, 1.6729821016854802e-8, 9.036833827068227e-9, 5.082880137844883e-9,
            1.6567777815349978e-9, 4.691043981109612e-10, 2.711844898400674e-11, 1.0877384663249812e-12,
            1.0877384663249812e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4163169638293348, 0.3121523309072931, 0.2024033881038793, 0.14954146374540156, 0.1190472946681678,
            0.09923345083679005, 0.05544851964536318, 0.030166841010300843, 0.020746053796369936, 0.01570319214699558,
            0.012523044656208626, 0.005607632226096022, 0.0019195216357283855, 0.0008313143853462908,
            0.0004034700310345909, 0.00021167009481698517, 0.000017125831465992716, 0.0000026636729906583154,
            5.730990331752496e-7, 1.4843280220521084e-7, 3.5727540706139154e-8, 8.82081263586798e-9,
            8.679453392453673e-10, 2.8241853300414732e-12, 8.93285445613401e-13, 3.2795988147427124e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.43720824354406246, 0.33257973807523245, 0.21876421654031913, 0.16283541416723912, 0.13029859529481735,
            0.10904887167938845, 0.06172525005904666, 0.034113644700848225, 0.023714004166679285, 0.018127934908993684,
            0.014582216465413349, 0.006794344694889832, 0.0024888642492017633, 0.0011472540558521316,
            0.0005879317515418366, 0.0003263117403636526, 0.00003451382087755104, 0.000006446813690950777,
            0.0000015303877500019575, 4.2294868030090527e-7, 1.2061106591243487e-7, 3.3002175436891434e-8,
            7.69322094917868e-9, 2.532696941059953e-9, 3.250256730424894e-10, 9.952039192739903e-13,
            3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.1663560662309465e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6246293333857912, 0.5190330187655248, 0.383358074790776, 0.303946100392748, 0.2527843974037053,
            0.21722298621090078, 0.13202137559630545, 0.07963679946685803, 0.05982973707916348, 0.049069466280624495,
            0.04213136650169445, 0.025184861806674737, 0.013181099586205764, 0.008147190050550361,
            0.0055044762202974135, 0.00396969312767101, 0.0011920751143439773, 0.0004906398171671669,
            0.00023741848528800865, 0.00012904730350596548, 0.00007631867889514984, 0.00004800572563448308,
            0.00003163935357193143, 0.00002163142748381711, 0.000015395937656759173, 0.000008322428916279346,
            0.000004567024548984833, 0.000002679977971431491, 0.0000016556384231369847, 0.0000010575148431390957,
            6.955254914231546e-7, 4.7150068427370684e-7, 3.25950972546174e-7, 2.28712978445067e-7,
            1.6272613934109614e-7, 7.300575888269378e-8, 3.365449896541861e-8, 1.5084219429084555e-8,
            6.089877713222336e-9, 3.467104381371655e-11, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6008444199577588, 0.49631707200946096, 0.3627362130834662, 0.28594047050950316, 0.2367688990559926,
            0.20279702886005832, 0.12212202472847503, 0.07310492785750072, 0.05462836680557248, 0.04452412382270232,
            0.03790549418364611, 0.02199317175567218, 0.011147650981585957, 0.00667344281916498, 0.004380825101640993,
            0.0030226452517643073, 0.000732566586758215, 0.0002810630238407562, 0.00012339639317189048,
            0.00006006430359827841, 0.000032773754537892093, 0.00002016226714185443, 0.000013281162177802841,
            0.00000886484358068746, 0.0000061392811909460346, 0.0000028279034722800134, 0.000001430908926458585,
            7.38778126230244e-7, 3.753288065899696e-7, 2.0234413056208211e-7, 1.2893512391087825e-7,
            6.688871023730059e-8, 3.251924451941335e-8, 1.508095104352236e-8, 6.625598292231416e-9,
            3.274366333627654e-10, 3.647993018773832e-11, 7.920220035373404e-12, 7.920220035373404e-12,
            7.920220035373404e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5185805613040332, 0.41304595088981944, 0.28889378119852993, 0.2223922744852517, 0.1816223932462435,
            0.15415719152954122, 0.09066617382034643, 0.05278180361545189, 0.03843945629110161, 0.03061258184255043,
            0.025552671475510987, 0.01381258597502741, 0.006329776679151046, 0.003554402162327943,
            0.0022083494173163504, 0.001464329142199599, 0.0003176284759215036, 0.00010814735928272421,
            0.000046525326368791726, 0.00002318692654673559, 0.000012784904034519253, 0.000007588991766290068,
            0.000004764733585608637, 0.0000031245419773197943, 0.0000021211979401137517, 0.0000010567626543155196,
            5.708303079181838e-7, 3.2721176339915694e-7, 1.9653785301378422e-7, 1.2245432994544716e-7,
            7.859417636093475e-8, 5.186427851253017e-8, 3.502190923521685e-8, 2.4101878099439035e-8,
            1.6888973181528674e-8, 7.262853668065552e-9, 3.271179137063704e-9, 1.442516763456332e-9,
            5.928382969570486e-10, 1.3197275762081638e-11, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3291698380058364, 0.21994720367454293, 0.13268905280255103, 0.09635434629926455, 0.0763934153829362,
            0.06368453169666398, 0.03582460123703417, 0.019536682359117343, 0.013352118381031829, 0.009959864840574862,
            0.00778488424407395, 0.003076451653311943, 0.0008133620087073411, 0.00028344904537831045,
            0.0001163916526195123, 0.00005367918937595305, 0.0000027817762755299087, 2.8181979917852604e-7,
            3.319513032555739e-8, 2.431990054334011e-9, 1.0300649222472202e-12, 7.257527911974648e-13,
            7.257527911974648e-13, 3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13, 1.6331380692236053e-13,
            1.6331380692236053e-13, 1.6331380692236053e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.35098103125714925, 0.2376284157722918, 0.14507146301936785, 0.10609386576292318, 0.08457190664758842,
            0.07081915879876391, 0.040385604067301094, 0.0224368321358535, 0.015557078542940509, 0.01176286671503928,
            0.00932344524209663, 0.0039023615925724495, 0.001150539357521299, 0.0004391982374744107,
            0.00019545449462147132, 0.00009596039280346447, 0.000006124970496926742, 7.260749043114245e-7,
            8.91176330679457e-8, 1.3620532257618834e-8, 1.9135939499648202e-9, 1.123949822101622e-10,
            2.263522702605769e-12, 7.257527911974648e-13, 3.2795988147427124e-13, 3.2795988147427124e-13,
            3.2795988147427124e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13, 2.227107387398064e-13,
            2.227107387398064e-13, 2.227107387398064e-13,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5445559608724094, 0.4158464893716396, 0.2836423069516788, 0.2183354596294437, 0.1794564445573879,
            0.15355149471279225, 0.09421114659708717, 0.058801471754074706, 0.04510271861136149, 0.037310591211585406,
            0.03201363779023385, 0.018188032445227598, 0.008524885719968829, 0.004949538433600288,
            0.0030624101266381494, 0.002071702629535436, 0.0004773633472558049, 0.00016985967216660413,
            0.00007471779777692045, 0.00003980872495301826, 0.000024232755739039824, 0.000015632392291298203,
            0.00001039624236054415, 0.000007084467760765101, 0.000004765821986607577, 0.000002212063868300973,
            0.0000011132661045243353, 5.843096486657373e-7, 3.3517365283231015e-7, 2.0069366302166713e-7,
            1.2303795815515883e-7, 7.671197055358903e-8, 4.823584065505315e-8, 3.02935038165586e-8,
            1.8850891625454835e-8, 4.889074700642482e-9, 7.674705315707797e-11, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5205928329497104, 0.3930290663530887, 0.2651335056635099, 0.20279829103771824, 0.16601543769504734,
            0.14167151456987945, 0.08624164935694004, 0.05343830542499871, 0.040622423281920506, 0.0332363877275758,
            0.02820473920027949, 0.015587867579857619, 0.007025452041901753, 0.003850003785890439,
            0.0023840488994512463, 0.0015876975998251126, 0.00032259262315094503, 0.00011287003750323267,
            0.0000444120456282171, 0.000019359153376399057, 0.000010179887189765147, 0.000005816447130143523,
            0.0000037263141574594982, 0.0000024067554256212986, 0.000001414828375345678, 5.179844038138981e-7,
            2.1620024992508036e-7, 9.397486877560368e-8, 3.4878684829209305e-8, 1.1086185303099683e-8,
            2.066033788494792e-9, 2.745000893256133e-10, 5.48673328992777e-11, 8.28415114284553e-12,
            7.920220035373404e-12, 7.920220035373404e-12, 7.920220035373404e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12, 2.5726087926614127e-12,
            2.5726087926614127e-12, 2.5726087926614127e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-41.30~174.78",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.43420360525201607, 0.31322612147198153, 0.20268236243578133, 0.15221232022969164, 0.1232956131712768,
            0.10446420245801946, 0.06223682632539496, 0.03717633810209213, 0.027360011085356455, 0.02180395014367368,
            0.018106360546790726, 0.009233180255176924, 0.0037147378644321093, 0.0018769384680003033,
            0.001071622546809721, 0.0006632729368705832, 0.00011721191266523661, 0.00003657626006454203,
            0.000015081468338980009, 0.000007319587910152351, 0.000003945268067759725, 0.0000022844913971604237,
            0.0000013949443466407782, 8.879950377691993e-7, 5.842262648082766e-7, 2.7288861039778974e-7,
            1.3727375868119667e-7, 7.333854341148207e-8, 4.090910170000979e-8, 2.3763303594237486e-8,
            1.42306646263468e-8, 8.681720801382808e-9, 5.344274270398648e-9, 3.312497619578782e-9,
            2.0488255040741582e-9, 5.310928398692677e-10, 3.718308867337026e-11, 1.1501346305984417e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12, 1.0877384663249812e-12,
            1.0877384663249812e-12, 1.0877384663249812e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3322907715798, 0.3322907715798, 0.33228928011638414, 0.3322216587028928, 0.3320197476315792,
            0.33143338727807053, 0.3201888788212913, 0.265110708973989, 0.21119971694098144, 0.17109883361517564,
            0.14320888965804376, 0.0769714726629346, 0.03680538949241982, 0.021734371097734368, 0.014130333020553788,
            0.009719817357460948, 0.002358991503580397, 0.0008469937940817987, 0.0003646837581407673,
            0.00017732657959386325, 0.0000924488956222632, 0.00004997260161339187, 0.00002853542745071112,
            0.000016827414688073695, 0.000010188472138707982, 0.000004201732163468601, 0.000001866006500349372,
            8.934857574615974e-7, 4.528966649486321e-7, 2.3859007070026195e-7, 1.2874673782636847e-7,
            7.31565238387688e-8, 4.418924959281867e-8, 2.6890709715369467e-8, 1.3851994218327945e-8,
            2.863779435813285e-9, 7.602672935647092e-10, 1.1320155923755237e-10, 6.386136064406855e-11,
            2.0459189897792385e-11, 1.797340054565666e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.3882006655308032e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.36775166584040997, 0.36775165574287516, 0.36773738506526055, 0.36754997725037947, 0.36716200325745507,
            0.3656023175726049, 0.3372102007457153, 0.2933006867998006, 0.23379489296387204, 0.19122612331517197,
            0.1609844809124728, 0.0875915123695601, 0.04189811153429272, 0.02481255175871729, 0.016211465359093468,
            0.01126350749248728, 0.00287593352687407, 0.0010525681887673017, 0.0004632780897613202,
            0.0002285911481991951, 0.00012207951419140073, 0.00006947349974164999, 0.00004198981928301321,
            0.00002626173336287716, 0.000016979494503703686, 0.000007556814012483165, 0.000003651297218243421,
            0.0000018952149329898305, 0.000001022432064345935, 5.621700170393408e-7, 3.219337477687949e-7,
            1.7895905133602687e-7, 1.037446059583058e-7, 5.617719089912043e-8, 3.0768041336948215e-8,
            8.13033584901035e-9, 2.1886232826062724e-9, 4.2045367187881766e-10, 2.3403012860967465e-10,
            6.220091108843917e-11, 2.0459189897792385e-11, 2.0459189897792385e-11, 2.0459189897792385e-11,
            1.7149726083687256e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6125210638605073, 0.6125160556118947, 0.6122964892189159, 0.6120816776153337, 0.6109118013393849,
            0.6104293259178057, 0.6030201828310635, 0.5660101147583302, 0.5151777718909901, 0.4623443505820416,
            0.4149956894217124, 0.2636894881717145, 0.1445870312336123, 0.09594667876373666, 0.06998609135562106,
            0.053925374273762726, 0.02037405848695629, 0.010020254006544726, 0.005542056785901445,
            0.0033559403112618158, 0.002133765575107546, 0.0014233457477713612, 0.0010067785665773838,
            0.0007409904761309414, 0.0005552306980521166, 0.00032743570877136506, 0.00020155858910710317,
            0.00012939912728862486, 0.00008557744481152957, 0.00005739756317187264, 0.00003952526413974596,
            0.000027935724937286778, 0.000020159041098880563, 0.000014656667005924751, 0.000010808934662969742,
            0.000005461181891619767, 0.0000029223708135340035, 0.0000016322452025541168, 9.435151769876526e-7,
            3.418513756514585e-7, 1.3425456302229577e-7, 5.541067693570767e-8, 2.3162831652712157e-8,
            9.638108089404795e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6095671484385028, 0.6095557024693539, 0.6090912902451179, 0.6081831486857937, 0.6071930421138159,
            0.6056040837483411, 0.5936013704530484, 0.5340428799173924, 0.4763636891677267, 0.43071553672845736,
            0.38789724177749546, 0.23799094454109257, 0.12437555372053802, 0.08231163102239603, 0.060217249634305005,
            0.0464849786954235, 0.01771214481501284, 0.008787848351759494, 0.004932277943857235, 0.0030190091769236025,
            0.0019457719384894782, 0.0013050597148475908, 0.0009083483280831152, 0.0006463033706013259,
            0.00047271879541057655, 0.0002663184777318072, 0.00016031342653227012, 0.0001014231094688407,
            0.00006653054150944876, 0.00004499117225232248, 0.00003111810974222779, 0.00002213070653365201,
            0.000015970620377200272, 0.000011700433553474073, 0.000008647518188369969, 0.000004306298793887997,
            0.000002272478557108215, 0.0000012494484063054762, 7.151517384951234e-7, 2.526509815413333e-7,
            9.249559962398735e-8, 3.658098179926128e-8, 1.4682763893603124e-8, 5.5377697982805785e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.48692045312425575, 0.4869188595893567, 0.48683994179320816, 0.48642998910780333, 0.48543485723634155,
            0.48372156059877625, 0.4652638302577865, 0.4071223488241844, 0.3507460147394186, 0.30396985748734145,
            0.2661645495808142, 0.15740565535073398, 0.08096229662900171, 0.05182733828752653, 0.0365844385933602,
            0.027273070329331746, 0.009144902307157246, 0.004113339137438278, 0.0021506431264841173,
            0.001239477410027402, 0.0007657055747911114, 0.0004984299586001715, 0.00033799489725888984,
            0.00023685309110923686, 0.0001705049192745018, 0.00009419104691198602, 0.00005555988141289676,
            0.00003443650852832436, 0.00002218580229697071, 0.00001474625480483753, 0.00001005760639284421,
            0.00000700944758737918, 0.0000049756617907161555, 0.000003588306936877291, 0.000002623719625852981,
            0.000001262800063189696, 6.451094270336237e-7, 3.4540871819203087e-7, 1.922524638459155e-7,
            6.502143755763958e-8, 2.403776506088535e-8, 9.369171295687548e-9, 3.712420651364338e-9,
            1.4692191660312073e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3322907715798, 0.3322907715798, 0.3322907601524334, 0.3322895579464828, 0.3322764617418719,
            0.33218443874262793, 0.3294098933862333, 0.3141934964429132, 0.2874836801412618, 0.2581330892449457,
            0.23355658416514663, 0.1474482071810478, 0.07771044423360737, 0.05086768608553678, 0.03651515826337686,
            0.02756735403595412, 0.009602278356357097, 0.004379768087075865, 0.002299445938881104,
            0.0013060447074494384, 0.0007742194673744098, 0.00048401784227781065, 0.000313618139899902,
            0.00020995113315121355, 0.00014417206317416588, 0.00007161983368929293, 0.00003806499041638845,
            0.000021282304338364755, 0.00001248095296195014, 0.0000075659801571480825, 0.000004735011922125301,
            0.00000300616997303134, 0.0000019334733678544325, 0.000001259149806882917, 8.212917264649498e-7,
            2.964457884724325e-7, 1.1817967160432374e-7, 4.7356197629966346e-8, 2.0791109500706284e-8,
            3.9663612394491565e-9, 4.708249345952709e-10, 6.386136064406855e-11, 6.220091108843917e-11,
            2.0459189897792385e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.36775166584040997, 0.36775166584040997, 0.3677511250562159, 0.3677388067177912, 0.36760229075778245,
            0.3675393208418569, 0.3632851684874087, 0.3342221038180349, 0.3111688797188944, 0.2836788587301207,
            0.25606720405360944, 0.1599991188529323, 0.08686443303821276, 0.05752042435877327, 0.041550502982738026,
            0.03149137867151667, 0.011206795156955711, 0.005218037901984582, 0.0027721643392737637,
            0.0016046833332955702, 0.0009846371052469071, 0.000632989293366898, 0.0004232018140352664,
            0.0002909621148000975, 0.0002054547238293416, 0.00010840346806580657, 0.00006155012905338006,
            0.00003602248103567826, 0.00002195683950016474, 0.000013706173862524044, 0.000008837558895425549,
            0.000005778444642640365, 0.000003855868436657668, 0.00000262570778425989, 0.0000018233529926269259,
            7.888206429420442e-7, 3.5918883426051523e-7, 1.5529098018607357e-7, 6.426420740002214e-8,
            1.1554814735842975e-8, 1.8356222097892783e-9, 2.704516610663177e-10, 1.269212512866602e-10,
            6.220091108843917e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6125211340484336, 0.6125199192371511, 0.6124889907770605, 0.6124017692157911, 0.612153891504757,
            0.6118883218115114, 0.6091721826805939, 0.5969181122601824, 0.5755696231325205, 0.5494502318752282,
            0.5195792923566215, 0.38945177195932906, 0.244045787851955, 0.17551979949427995, 0.1360908272215366,
            0.11059814076126917, 0.053532459731342255, 0.03123120384283714, 0.020277515008505075, 0.013871191387740633,
            0.010030485477345717, 0.007397414981206355, 0.005585195109536977, 0.00424894274504907, 0.003274612458385781,
            0.002111262393656403, 0.0014936649152981374, 0.0010702406130571303, 0.0007451306326112536,
            0.0005282507121492808, 0.0003928718849182841, 0.00028993799079026395, 0.00022446863579338882,
            0.00017245205108337643, 0.00013470096093790742, 0.0000751578854953916, 0.00004461562745262537,
            0.000027650757921130046, 0.00001749973021492046, 0.0000075553232050852264, 0.000003799615375234561,
            0.0000019578009707865718, 0.000001053094246583619, 6.445476309346887e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6095671885303682, 0.6095654749743433, 0.6094761861709375, 0.6090113154606618, 0.6085696023845498,
            0.6081140237830865, 0.6041034074359388, 0.5852824466264712, 0.5477577192533903, 0.5143541219918868,
            0.4850977133150457, 0.3610165454885173, 0.220757121594826, 0.1548619678334387, 0.11773168289184757,
            0.09442912024507426, 0.0449587167408418, 0.02687732942963773, 0.017384367622659484, 0.011879149411350633,
            0.008496435261772482, 0.00623578972585498, 0.004741625413762848, 0.00366397003573549, 0.002840267961154439,
            0.0017760572161337976, 0.001161506888044505, 0.0007896702909011103, 0.0005553040432285022,
            0.0003994700739452465, 0.000293105771923081, 0.00021921783877123575, 0.00016731035062990821,
            0.00012975545974471618, 0.00010380375781213776, 0.000058456551847667346, 0.0000346487583275934,
            0.00002052578736544408, 0.00001309949135591374, 0.000005604143846982268, 0.0000025070899774316557,
            0.0000012068062628101117, 6.584417748811688e-7, 4.191361978600483e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.48692046123101546, 0.4869201274972449, 0.4869078843736015, 0.4868407812961007, 0.48666306493398925,
            0.4863278437997193, 0.4815584255825936, 0.45868695833274015, 0.4276230536343463, 0.39551315068634135,
            0.36521797037258524, 0.2538771637112906, 0.14988042254215658, 0.10366151339340322, 0.07793745830691892,
            0.0615829627658737, 0.02664579907711775, 0.014651478091824502, 0.008965863227348214, 0.00585865158160156,
            0.004010318903542459, 0.0028444331153171347, 0.0020758209307786563, 0.001551049595860902,
            0.001182285729083898, 0.0007215988204785132, 0.0004643349268671965, 0.0003115611984837012,
            0.00021626603371843143, 0.00015438441721065427, 0.0001128332698732967, 0.00008412780148232143,
            0.00006380877311678833, 0.00004912213675491459, 0.00003831199475953256, 0.000021583226951560247,
            0.000012842938755532626, 0.00000797804541971329, 0.000005132180840950206, 0.0000023040570022664268,
            0.0000011247397841648427, 5.85617561401966e-7, 3.2117820317830066e-7, 1.8361298235527853e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3322907715798, 0.3322907715798, 0.3322907715798, 0.3322907263182616, 0.3322893777233471,
            0.3322837486870118, 0.33170134715798655, 0.32440857220159125, 0.3085597294826213, 0.2861847840057202,
            0.26496335393461745, 0.1789538093530381, 0.09876345745802402, 0.06543878091284065, 0.04761042196041278,
            0.03652887558750739, 0.01375624240439783, 0.006718512513922525, 0.0037430069857433707, 0.002259581496121526,
            0.0014393471397932066, 0.0009451027373813749, 0.0006408321604413744, 0.00044280896429702775,
            0.00031315062709449867, 0.0001671939468297922, 0.00009512864390997506, 0.000056307348760145175,
            0.00003519623734314131, 0.00002246845026998782, 0.000014688165892775895, 0.000009887558404907673,
            0.000006777167390897135, 0.000004773273312516757, 0.0000034008798735553166, 0.0000016079099202093161,
            8.111842879261063e-7, 4.283431718210551e-7, 2.3350677891276206e-7, 6.961795206983368e-8,
            1.4326358654948024e-8, 4.9641591948201835e-9, 9.156320146530561e-10, 2.390487807701902e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.36775166584040997, 0.36775166584040997, 0.36775164445739517, 0.3677506736496385, 0.3677425294337616,
            0.36765352960730585, 0.3664222579730644, 0.3517411533691749, 0.3285969848396958, 0.31048169531902714,
            0.28920346422544485, 0.1943877135906674, 0.10822572982366722, 0.0726500998269836, 0.0531987434639569,
            0.040973130712753235, 0.015791161688101466, 0.007912006218583569, 0.004481541039294784,
            0.002717478564649711, 0.001735776575227536, 0.001152458731775793, 0.000794056060182485,
            0.0005625111998846872, 0.0004111885250887298, 0.0002336819490950096, 0.00014072391021319625,
            0.0000883521715871174, 0.000057740453695267864, 0.0000390037538961596, 0.000027034462084254055,
            0.000019071044515391177, 0.000013650001388517483, 0.00000993686514124446, 0.0000072053028451788064,
            0.0000034558364362816178, 0.0000017615015294495739, 8.731484651969268e-7, 4.6870074021931885e-7,
            1.2878822199802092e-7, 3.9753565123319795e-8, 1.2597499376987287e-8, 4.0923856525765245e-9,
            7.281550917781487e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6125211458695579, 0.6125210476064613, 0.6125128313997479, 0.6124852470432474, 0.6124151382056388,
            0.6122570741966782, 0.6107526002801296, 0.604348451572984, 0.5918121373467696, 0.5744773762515087,
            0.554222507064188, 0.4397654792545678, 0.2926464364105803, 0.21238253270621782, 0.16577170479538453,
            0.13490300655242785, 0.0658426235291975, 0.039412441854952275, 0.025834922323579403, 0.0180211235010062,
            0.013134510401684736, 0.009889861595467316, 0.0076119304117441425, 0.005979839503066087,
            0.004775240143931201, 0.003178917907537691, 0.002194879126084518, 0.0015808990003964292,
            0.001184090109568392, 0.0008943457094799891, 0.0006956270897545081, 0.0005523810761657967,
            0.00044471427861550325, 0.0003629262993058444, 0.00029251041196707786, 0.00017192696021927487,
            0.00010625286731125126, 0.00006810262807724582, 0.000044854830999944734, 0.000021494511440795376,
            0.000011014721376878533, 0.000005991840620832935, 0.000003446589482702933, 0.0000020449931590338366,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6095672583199117, 0.6095669702524343, 0.6095465230342045, 0.6093987951342834, 0.6089816403425757,
            0.6085920087493661, 0.6067732616253652, 0.5972944605447945, 0.5749308210497791, 0.5451850857213595,
            0.5193534996545286, 0.4106397637881792, 0.2673872548704006, 0.18966534814357644, 0.1446656111685607,
            0.11601285658096344, 0.05540205634271348, 0.033715321086408435, 0.022651262215228757, 0.016066703594939825,
            0.011820241225535244, 0.008903483944543182, 0.006886240252270643, 0.005424807604478171,
            0.004338737347128449, 0.002866410089967562, 0.001971040244347581, 0.0013990817263634745,
            0.0010181241029286266, 0.0007623147817864629, 0.0005786087135336738, 0.00044719562298767095,
            0.0003527956320490659, 0.0002801934969497442, 0.00022555856283763283, 0.00013605595620290613,
            0.00008525860375044485, 0.00005534836052799008, 0.00003701869627548487, 0.000017725219616238858,
            0.000009088269118495695, 0.0000049153625387064626, 0.000002780703080840185, 0.0000016292827922370634,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.48692046854471555, 0.4869204333189833, 0.48691773492013085, 0.4868988534370746, 0.48684101725794476,
            0.48672045999034935, 0.48460979740656146, 0.47169320995143293, 0.45079042677202064, 0.426565420512045,
            0.40176212762160546, 0.29723392382296027, 0.18332989088170487, 0.12859808934522265, 0.09737071326201444,
            0.07734219307963111, 0.0343835581084406, 0.01952414183837683, 0.012351487577800187, 0.008336194956444772,
            0.005883898853431715, 0.004294935939861696, 0.0032193839307366554, 0.0024660944882998323,
            0.0019237250197764704, 0.0012238919519770232, 0.0008165546756693585, 0.0005656027758177491,
            0.00040387647906636905, 0.00029574855654038775, 0.0002212042082994218, 0.000168458657391295,
            0.0001302954004333471, 0.00010214536748494447, 0.00008102876875960836, 0.00004741508074747016,
            0.000029150514971391577, 0.000018625390634767286, 0.000012274914032797495, 0.000005734562343186382,
            0.0000028861781953378202, 0.0000015378644640305732, 8.578410676861402e-7, 4.964459574328879e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3322907715798, 0.3322907715798, 0.3322907715798, 0.33229063116342117, 0.3322891699358804,
            0.3322830168979971, 0.3316617846027039, 0.32420428159865694, 0.3074252065445293, 0.28608862620205294,
            0.2656052830867313, 0.17697714210787507, 0.0965688550847128, 0.06337724886013052, 0.04572750888208802,
            0.03482779676456201, 0.012872948260435455, 0.006293415233632545, 0.003525039021247772,
            0.0021541166687708314, 0.0013998588977740412, 0.0009495028879208201, 0.0006655922880208861,
            0.0004781701347385693, 0.00035166820512159096, 0.00019932818895701318, 0.00011954347071053311,
            0.00007341742504175566, 0.000046554676434172884, 0.000030567258309362934, 0.00002054279563606813,
            0.00001402756522963265, 0.000009781031604358016, 0.000006958543158153517, 0.0000049699438505745874,
            0.0000022514056671571005, 0.0000010818810286946163, 5.567173778968569e-7, 3.0072249251943003e-7,
            9.650651966452983e-8, 3.3917676889316795e-8, 1.015317774299973e-8, 2.8891547678197922e-9,
            9.634010256220904e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.36775166584040997, 0.36775166584040997, 0.3677516585099543, 0.36775084941813874, 0.3677414769613223,
            0.36768810157635995, 0.36641765587922653, 0.3484009369639454, 0.3276877532025731, 0.3106219984754094,
            0.2883078533861224, 0.19251744347186603, 0.10584467547552714, 0.07039911503242666, 0.051196875089957516,
            0.03921750482741509, 0.014858569205323772, 0.007428755427409673, 0.004227651135474624,
            0.0026081621455857364, 0.0017028370854486252, 0.0011600509643419463, 0.0008164040381254781,
            0.0005897300546493873, 0.0004346537753824339, 0.00024841627634472954, 0.00015093018799770342,
            0.00009572203673006292, 0.00006262555637396839, 0.0000420760082845943, 0.000029101608625505986,
            0.000020512410459619296, 0.000014830333896176641, 0.000010885034579745019, 0.000008134522837712105,
            0.000004014742717708298, 0.0000020716112324719963, 0.0000011310389478325078, 6.402762116053395e-7,
            2.0394140631818658e-7, 7.786181277502635e-8, 2.7556126269701053e-8, 1.107765468244537e-8,
            5.11812689826578e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6125211458695579, 0.6125211163167469, 0.612517694315502, 0.6125007831813192, 0.6124176614627871,
            0.6122014240967216, 0.6105781261989048, 0.6039299247375466, 0.5904506556273448, 0.5719846097047809,
            0.5512392620943148, 0.4325549609990971, 0.2831922527459092, 0.2045675955009205, 0.15778233455160007,
            0.12665121903464613, 0.057300932287074634, 0.033533190033654584, 0.02201450399209366, 0.015414098894658263,
            0.01127223684988838, 0.008507180059811372, 0.006572838470054192, 0.005186718961395322, 0.004159369843325027,
            0.0027771918413031665, 0.0019296047559035174, 0.0013837200125171843, 0.001023152769959444,
            0.0007760845533626208, 0.0006031014445368474, 0.00047725373253787413, 0.0003831762494819334,
            0.0003100274621888704, 0.0002536565984182418, 0.00015920475029659604, 0.00010387883215600679,
            0.00007005073784529507, 0.00004873531811873831, 0.000024815164377824227, 0.000013373908776102574,
            0.000007445979976993186, 0.000004449380465398889, 0.000002751378280780253,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6095672598047956, 0.609567190757694, 0.6095509539625181, 0.6094226282785125, 0.609050517964856,
            0.6085809058400388, 0.6066314024305577, 0.596243132484814, 0.5738484763615628, 0.5427172944150886,
            0.5162259052302924, 0.40746658339834696, 0.26093467394542086, 0.1819653415860404, 0.13761617214106187,
            0.10957681407522903, 0.05046870786313344, 0.029968153657850504, 0.019714619273884345, 0.01382177114613769,
            0.010123587124836261, 0.00766386981767285, 0.0059527176478680515, 0.004715493203380672,
            0.0037949730895235232, 0.0025455628246984665, 0.0017766413988813707, 0.001278562872486959,
            0.000943225438434515, 0.0007107963733587964, 0.0005460385384420467, 0.0004266113052559814,
            0.0003374657095130875, 0.0002702869906022562, 0.00021849118552060167, 0.00013456280860232035,
            0.00008715975028213695, 0.000058131806193073565, 0.00003963989763656979, 0.000019860893175062688,
            0.000010662494650195665, 0.0000059747366699756935, 0.000003525215919064628, 0.000002159629145511595,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4869204686957578, 0.4869204602843347, 0.4869185392803125, 0.4869021083873397, 0.4868482113338071,
            0.48673191733248056, 0.48459813333678714, 0.47132866305937254, 0.44993345780156024, 0.4252620247895326,
            0.40009075124578747, 0.29446188334941903, 0.17967890820490573, 0.12470162881307581, 0.09348114269587501,
            0.0735705225672006, 0.03156915937902219, 0.017541077317269532, 0.010954175550577937, 0.0073394740392250125,
            0.005162450739373479, 0.0037652640677206706, 0.0028254188798150755, 0.002169643191794053,
            0.0016983425705199213, 0.0010901884133318232, 0.0007350210208532518, 0.000514883995032459,
            0.00037192025538182634, 0.0002755074708798904, 0.00020843041996360315, 0.0001605242692642649,
            0.00012553612258432194, 0.00009948674616036791, 0.00007976438077965, 0.00004790600889953933,
            0.000030208992912964592, 0.000019787956652950656, 0.000013367012761569975, 0.00000655460880303074,
            0.000003458238099199328, 0.0000019300282433073897, 0.0000011253043348048885, 6.799793039695262e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3322907715798, 0.3322907715798, 0.3322907715798, 0.3322902310477936, 0.3322849222672656,
            0.3322688767730677, 0.3312123688866362, 0.32127127012816586, 0.30231340349890484, 0.2776914307161497,
            0.25563891592555876, 0.17117982576367294, 0.09157591969424632, 0.059319589615842695, 0.04209809979934988,
            0.031556194708632725, 0.011014212736740104, 0.005215524676702098, 0.0028693958175263147,
            0.0017330870162885115, 0.001116109214017294, 0.0007527688145374156, 0.0005269342262016312,
            0.0003794868273856684, 0.0002793943572111563, 0.00015871049136997127, 0.00009549815102148064,
            0.00005965830079361812, 0.000038258126781715645, 0.000025327508205735205, 0.000017224857998794588,
            0.000011870040688981896, 0.000008326292144949987, 0.000005909134800801263, 0.000004264766974870188,
            0.000001960325057623184, 9.513986958253895e-7, 4.778444172544628e-7, 2.518490785057628e-7,
            7.467058416033012e-8, 2.2314745939944203e-8, 7.134040469480851e-9, 2.4478921089261992e-9,
            1.0527551053129969e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.36775166584040997, 0.36775166584040997, 0.36775163737808403, 0.3677484394292229, 0.3677262087928048,
            0.3676629502839668, 0.3658523147529103, 0.3455528439475133, 0.3224705958132423, 0.30313974414843453,
            0.2812718359730939, 0.18453438656893417, 0.1000787084020251, 0.065294074935541, 0.04671171378565663,
            0.035277954789687496, 0.012702876853217665, 0.00614803436436085, 0.003433074997379538, 0.002094772730235462,
            0.001357184904977997, 0.0009160688308207527, 0.0006395801837764867, 0.0004603768223945321,
            0.0003386741940040943, 0.0001937122928321396, 0.0001177546883489002, 0.00007460290187178682,
            0.000048824624803929364, 0.00003271431463206995, 0.000022636145134247954, 0.000015927234947969342,
            0.000011441434538791686, 0.000008375390457437374, 0.000006224446356769489, 0.000003080976112939024,
            0.0000016236564144709337, 8.891508056395923e-7, 4.955024218711301e-7, 1.655024980395936e-7,
            5.600443520048515e-8, 2.05570834799218e-8, 8.92503404514855e-9, 3.801010617365819e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6125211458695579, 0.612521111145005, 0.612517787444596, 0.6124687640097162, 0.6122837168898672,
            0.6120865962427423, 0.6099849044780986, 0.6003001050724545, 0.5822813635483362, 0.5595504190851825,
            0.5345059553600348, 0.4034324347416804, 0.2564812567242328, 0.18126631511215258, 0.13570585232501467,
            0.10683411589101105, 0.04716750447844764, 0.027331684637839918, 0.01765907827136917, 0.012168991875015994,
            0.00876449947206863, 0.006536685265183484, 0.005005897047052453, 0.00392132490316789, 0.003124872548016396,
            0.002078752501984851, 0.001450164345358932, 0.0010492598493464114, 0.0007831376659894348,
            0.0005984388087924714, 0.00046634004414792857, 0.0003690393960915232, 0.00029612372378581115,
            0.00024046469967412516, 0.00019748053973349386, 0.0001252657995544082, 0.00008276254970461184,
            0.00005634253173428419, 0.00003937727464150345, 0.000020484537668800855, 0.000011467142960121057,
            0.000006738568618819682, 0.0000041212225395666855, 0.0000026036359414005616,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6095672598047956, 0.609567221197814, 0.6095578515893338, 0.6094711368602518, 0.6092071617556316,
            0.6086705653703828, 0.6059703936311861, 0.5907074230026956, 0.5629692118500209, 0.5285021419352259,
            0.49756885393640493, 0.38126900753303916, 0.23420677254984068, 0.1611662019901111, 0.12064819240313986,
            0.09532458578729834, 0.042320032956123396, 0.024387121375356063, 0.015818761118447443, 0.010967084335186054,
            0.00794922996721534, 0.00595568158314818, 0.004580600040277516, 0.0035960117654985353,
            0.0028720019043381386, 0.001909515355183136, 0.0013229458587647092, 0.0009503857652934106,
            0.0007020576258681782, 0.0005304385563655955, 0.0004086065490491817, 0.0003203193121714652,
            0.00025478321908215223, 0.00020543278947010734, 0.00016759091610397917, 0.00010484116472788274,
            0.00006832982384605813, 0.000046126313185908026, 0.000032084520782316895, 0.000016624683723677375,
            0.000009196819127646435, 0.000005328599519294208, 0.0000032252190093062794, 0.0000020167409211251955,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4869204686957578, 0.48692046294290064, 0.48691899818707657, 0.4869018775728718, 0.4868412061511981,
            0.48670537606135716, 0.48409915286731825, 0.46802965169655575, 0.4430226949639279, 0.4150971400038437,
            0.3873317398882934, 0.27637016113989155, 0.16317577058004376, 0.11107513179275541, 0.08203835232638632,
            0.06375079757856335, 0.026093960867463597, 0.014057096897652449, 0.00859485325888681, 0.00567426738665093,
            0.003949825290432893, 0.002859837024680714, 0.002135205209248497, 0.0016341639681627324,
            0.0012765787205173183, 0.0008184055918488253, 0.0005524512279876576, 0.0003880618295300317,
            0.0002813582169978199, 0.00020932388737573832, 0.00015910056652365009, 0.00012312836649743347,
            0.00009676831739257421, 0.00007706986294746239, 0.00006209838731578872, 0.00003774998202247561,
            0.000024084510297686023, 0.000015954831323554985, 0.000010894189145457378, 0.00000545162035116798,
            0.000002932254572232282, 0.0000016664747805652378, 9.889055462369534e-7, 6.076205559510481e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3322907715798, 0.3322907715798, 0.33229071414354094, 0.33228742854984405, 0.3322701088564175,
            0.3322279035487254, 0.3299872577419197, 0.31513636072667855, 0.29013737391873945, 0.26288554887925675,
            0.23905385147047892, 0.1523734587652878, 0.07950829099590966, 0.050321476826833834, 0.035127550633848426,
            0.025939703876977863, 0.00862007623444478, 0.003992818267968736, 0.0021705185523441827,
            0.001301104566684276, 0.0008322112705238233, 0.0005583423461757828, 0.0003891372788700091,
            0.000278680650152257, 0.00020457406598747567, 0.00011609815690772142, 0.00006968889837155893,
            0.00004380433823717309, 0.00002841670943151934, 0.000018823838827310027, 0.000012771283147780049,
            0.00000882701169144822, 0.00000618535266151834, 0.0000044104136887981505, 0.000003185186092037915,
            0.0000014802144500090988, 7.177749082500267e-7, 3.602140469866051e-7, 1.8766705747738875e-7,
            5.309274808684705e-8, 1.5824112353790554e-8, 4.753147431202365e-9, 1.7922447970164512e-9,
            7.731469908733857e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.36775166584040997, 0.36775166584040997, 0.3677510965938898, 0.3677361427745794, 0.36765462386266745,
            0.36747040364411415, 0.3638290037843246, 0.33344075476540264, 0.31072192920846015, 0.287232428893205,
            0.26162916620099186, 0.16534723175487603, 0.0869776835082855, 0.05574347191032153, 0.03934551163429332,
            0.02936289822402953, 0.010136111933120136, 0.00476124250860276, 0.0026056236436935326, 0.001566729391521193,
            0.0010077771134627245, 0.0006789138149427801, 0.0004733453397043449, 0.0003391866523005644,
            0.00024890673049127627, 0.0001423681750484492, 0.00008580667588424787, 0.000054129348226488026,
            0.0000354010141427779, 0.000023650300030314208, 0.000016341194206058117, 0.000011482987317933159,
            0.00000826463566116104, 0.000006015852408708835, 0.0000044277007580032056, 0.0000021713692738098722,
            0.0000011296568053723714, 6.112767940454167e-7, 3.4708481500000943e-7, 1.1787560083575954e-7,
            4.163383993116554e-8, 1.5303465605853717e-8, 5.708275407911856e-9, 2.4114658048191018e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6125211458695579, 0.6125210830698344, 0.6125014065617054, 0.6123394906762725, 0.6120894127673757,
            0.6120031407624349, 0.608917847296711, 0.5935717198936997, 0.5684082582500466, 0.5388896224561024,
            0.5060125023804385, 0.36791206978193336, 0.2272209478589657, 0.15534308278914888, 0.11535917906071586,
            0.09044209665180614, 0.0392062105698756, 0.022179769610397294, 0.014047065651514909, 0.009536725134172726,
            0.0067842036679143635, 0.005012791028462748, 0.0038143659532712346, 0.002974502597122769,
            0.0023624471798541044, 0.0015650431818701412, 0.0010911303382655799, 0.0007910202918415091,
            0.0005904043506523716, 0.00045148825742388613, 0.00035233640850830197, 0.0002794592939187924,
            0.00022483166837080688, 0.00018315179639997068, 0.00015064754122188413, 0.00009588107484590669,
            0.00006374207961382515, 0.00004360476792068941, 0.00003080479536359486, 0.00001636583141374604,
            0.000009265118821075444, 0.0000055011518681665015, 0.0000033969883357068397, 0.0000021642250423636685,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6095672598047956, 0.6095671981821136, 0.6095430864765897, 0.6093738713675416, 0.6088722982694573,
            0.6082475157116914, 0.6035240494295107, 0.580629436945479, 0.5442121971106668, 0.5059508904386022,
            0.47322767170065566, 0.3463418646242092, 0.2044366406372952, 0.13946207207434416, 0.10374975434524569,
            0.08131465971948307, 0.034762826582508746, 0.019583258465743027, 0.012454532694974329, 0.008500381214588825,
            0.0060871438908320615, 0.00451982673158974, 0.0034488543624963544, 0.002687757362103368,
            0.0021348640408775732, 0.0014049229605593578, 0.0009716080865500526, 0.0006985093545290677,
            0.0005170019865107397, 0.00039220711166711343, 0.0003036528783763255, 0.00023939596628400113,
            0.0001919168322574587, 0.000155489107140782, 0.00012706359584766435, 0.00007993232262555944,
            0.00005278033500077205, 0.00003621504451061224, 0.00002555477096422444, 0.000013514190499909848,
            0.000007620009464919221, 0.000004552399321844369, 0.000002785844905317525, 0.0000017254229760510498,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4869204686957578, 0.4869204587180663, 0.48691521158175055, 0.48687065024467197, 0.4867334964591878,
            0.4864520892346806, 0.48194385585256405, 0.4590148118379124, 0.42750019845071424, 0.3948365177790223,
            0.36396007639022987, 0.24994972282918815, 0.14294247570685908, 0.09567336722099294, 0.06976632267436039,
            0.05362944218511997, 0.021103348996375428, 0.01108774938664087, 0.006665426238560672, 0.004348400857104104,
            0.0030011646856480763, 0.0021595951946265447, 0.0016052179956239584, 0.0012246305393766386,
            0.0009545298150729692, 0.000610466532803477, 0.00041183667408513, 0.00028943257755866144,
            0.00021009669904009654, 0.00015655444189832752, 0.00011920990537810173, 0.00009243867695454236,
            0.00007279673756722285, 0.000058099008348296684, 0.000046911469706527625, 0.00002866596714348134,
            0.000018380963394557218, 0.000012236498559298655, 0.000008395530834506592, 0.000004240552732126515,
            0.000002301573420609243, 0.00000131965745753354, 7.900188140016378e-7, 4.894737391809157e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3322907715798, 0.3322907715798, 0.332287219023723, 0.33225223909169893, 0.33203801346921374,
            0.3316806024546534, 0.3239948555738709, 0.29387594579068665, 0.2569418832441961, 0.22563807439003847,
            0.19863565288283258, 0.11675107463590276, 0.05688066938808092, 0.03448498132463678, 0.02329132079030695,
            0.016756629428440025, 0.005137186461691882, 0.0022644921083568303, 0.001187899131030837,
            0.0006937398334674975, 0.00043416714865211113, 0.0002864936684759399, 0.00019580204321832632,
            0.0001380865456743919, 0.0001000126116656975, 0.00005490258673323547, 0.000032308933058791996,
            0.000019883078664284392, 0.000012730151332969156, 0.000008348204597494004, 0.000005601050280077193,
            0.000003831757884237774, 0.0000026630254493415767, 0.0000018816248025602791, 0.000001352301343460205,
            6.130172301953457e-7, 2.823326269953089e-7, 1.3347479856610524e-7, 6.4241212840297e-8,
            1.3834221102015931e-8, 3.1975809777762265e-9, 9.630835626138348e-10, 3.953410931956114e-10,
            2.3403012860967465e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.36775166584040997, 0.3677516633969248, 0.3677303986537155, 0.3675502016303691, 0.3670464290458718,
            0.36632788097637564, 0.35369738686837643, 0.311964184414035, 0.2790903009080336, 0.2460113625463862,
            0.21668449485761682, 0.12691815098399004, 0.0630708094338348, 0.03918453154201844, 0.026936907419865836,
            0.019599386223022357, 0.0061369454663114794, 0.0027322854231977495, 0.0014473291488165927,
            0.0008476458815296818, 0.000531336899772938, 0.00035086121227375565, 0.00024096513075695092,
            0.0001705806562166819, 0.0001238751001958065, 0.00006905825526440024, 0.00004111600544114157,
            0.000025450293543480652, 0.000016279434356816754, 0.000010768250411435227, 0.0000072907552159939826,
            0.000005061649991537742, 0.0000036082089175382137, 0.0000026042651213320045, 0.0000019057458075688003,
            9.043130991910582e-7, 4.575115701843657e-7, 2.400113619690627e-7, 1.3129178568445325e-7,
            4.2464778138295856e-8, 1.6280296999404698e-8, 6.806340935305855e-9, 2.5693939198490057e-9,
            1.04429009883944e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6125211458695579, 0.6125179016058966, 0.6123501957050196, 0.6120583755107881, 0.6115426690278023,
            0.6103578013365618, 0.6020697129142647, 0.5688840565792177, 0.5244989704150756, 0.47835804930945225,
            0.436753354082637, 0.2963496562033489, 0.168124063534014, 0.11294644510572929, 0.0830749005982185,
            0.0644537507488558, 0.026515233351249532, 0.014538184389746417, 0.009007441544256645, 0.006016682772253441,
            0.004235611994527265, 0.00309209354588581, 0.002326970966009529, 0.001794004328880485,
            0.0014122461731887288, 0.0009210860027303662, 0.0006318701854007119, 0.000450533331451906,
            0.00033221170814778906, 0.00025214340719918266, 0.00019500246808238974, 0.00015327398372716645,
            0.00012220061424052315, 0.00009865944862254419, 0.00008054108897837203, 0.000050465853890646916,
            0.00003310697471462719, 0.000022539295746867936, 0.000015784869643709065, 0.000008257098915345972,
            0.000004607039851167727, 0.0000026970467828716272, 0.0000016418633638881832, 0.0000010320727982238154,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6095672598047956, 0.6095637482275402, 0.6093481119231452, 0.6085359766106861, 0.6076903252822412,
            0.6065061073394351, 0.5948501131522635, 0.5482034430383416, 0.49543257039971056, 0.45156999216886734,
            0.41176524357883804, 0.27274523940929263, 0.15252907840182375, 0.10158448360815724, 0.07405715138468912,
            0.057132088960330465, 0.022988112611580343, 0.012333336648375218, 0.007538981119576458, 0.00500109851914335,
            0.0035094138292993205, 0.0025680805672832112, 0.0019395794027273993, 0.001503281610178464,
            0.0011899573975312939, 0.0007875517500683156, 0.0005489776205450822, 0.0003982376398581197,
            0.0002977471483307699, 0.00022740574004492543, 0.0001763626008862671, 0.00013925951359323045,
            0.00011157632368385033, 0.0000905146091089334, 0.00007426848859385486, 0.000046972676827525724,
            0.00003096589095774146, 0.000020964256528100243, 0.000014425186302493487, 0.000007640106282679068,
            0.000004340064151620027, 0.000002565325758041581, 0.0000015688373196098837, 9.900580835164874e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4869204686957578, 0.4869197077887886, 0.4868669373987268, 0.4866014101610566, 0.4859680301331046,
            0.48487812302776756, 0.4726766386671181, 0.43028238048994777, 0.38468256544046486, 0.3437616865641701,
            0.3086405965339739, 0.19647492561224664, 0.10584750385096675, 0.0686272195077018, 0.04887150623954596,
            0.03683580009016937, 0.013527977959610709, 0.006802390530080652, 0.003965358515787562,
            0.0025288835854273763, 0.0017154431366344393, 0.001217844185164636, 0.0008955325687947186,
            0.0006772811724722098, 0.0005241332367586582, 0.00033153250426252296, 0.00022185476711789287,
            0.00015494215198256332, 0.0001118943370719129, 0.00008301192372262492, 0.00006296392063897685,
            0.00004865016254787116, 0.00003818648016294946, 0.000030380740751020683, 0.00002445634621945958,
            0.000014836720090356651, 0.000009449936121089524, 0.000006251708092467757, 0.000004263613904415427,
            0.0000021292956657943076, 0.0000011448237799310686, 6.505512307477027e-7, 3.8606656201150613e-7,
            2.3732345992969715e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3322907715798, 0.3322900848840947, 0.33222770563703297, 0.3316528350921803, 0.3298780804257802,
            0.3272032349602152, 0.3076450092636761, 0.25188078273687636, 0.2068564117748477, 0.17315807594048602,
            0.1480397527383074, 0.08026375648463875, 0.03609016049379616, 0.02095824899805379, 0.01367856934011695,
            0.009533265854162117, 0.0026016149227822277, 0.0010632118568196208, 0.0005290846902402949,
            0.00029568412007482833, 0.00017857757668875032, 0.00011396212751956689, 0.00007577445620579235,
            0.000052136118555434916, 0.00003679559333082836, 0.000019484283366335884, 0.000011089431676637099,
            0.0000066240232323247796, 0.000004108187074283744, 0.000002610146950053705, 0.0000016926368388774394,
            0.0000011214389741965507, 7.566314344911724e-7, 5.207819715192046e-7, 3.5995739322558507e-7,
            1.5280177664781546e-7, 6.421459619598835e-8, 2.5799240103115255e-8, 9.60249696261468e-9,
            1.41111988760656e-9, 3.470453118773864e-10, 1.5019352428424781e-10, 6.386136064406855e-11,
            3.836841955262571e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.36775166584040997, 0.36774493532954555, 0.3674475287462522, 0.36631996651475995, 0.3641329432437083,
            0.35967288648453105, 0.3262516472310134, 0.27294884404680775, 0.22685284053049423, 0.19048827804578586,
            0.16253827817640618, 0.08779333820656123, 0.04119818441015003, 0.02445494032660825, 0.016115821121947285,
            0.011325612109091337, 0.0031852151052281343, 0.0013238795873635164, 0.0006635999680485154,
            0.0003719919352058451, 0.00022507527313187867, 0.00014378663781837314, 0.00009572442391807071,
            0.000065879807265956, 0.00004671937275209293, 0.000024984937161753538, 0.000014318891797691804,
            0.000008661303164204011, 0.000005403385256701521, 0.0000034424611880423604, 0.0000022479456650920954,
            0.0000014931393912961644, 0.0000010136938532764614, 7.010368514958826e-7, 4.945967774166117e-7,
            2.2086036613660281e-7, 1.0037708086940711e-7, 4.5911122040148024e-8, 2.1727312171648805e-8,
            5.675109604474926e-9, 1.6296656246694852e-9, 5.79227554808881e-10, 1.7936385710015657e-10,
            7.43402006619931e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6125207543204954, 0.6124643223194514, 0.6119927296110381, 0.6104005487092885, 0.6086616236441599,
            0.6058298336492108, 0.5825183079043907, 0.513237440136954, 0.4451761072048056, 0.39296791163257244,
            0.34970366813207665, 0.21441251813475062, 0.11521917905308603, 0.07579076853724281, 0.05486348164884579,
            0.0420774909132633, 0.016338796977524585, 0.00843646739260894, 0.005053924163423228, 0.003298388020891174,
            0.0022854453432235135, 0.0016525498123491902, 0.001238470442287109, 0.0009529426985107453,
            0.0007476831047965246, 0.0004835805033104282, 0.0003278043394111876, 0.00023193767380558572,
            0.000170225016444442, 0.0001275323874037476, 0.00009788021549739859, 0.00007644163136731747,
            0.00006032883591123684, 0.00004824058493923084, 0.000039017925093417495, 0.0000238996128654079,
            0.000015341314680533083, 0.000010212686061805863, 0.000006983833448955132, 0.000003520351611885353,
            0.0000019115681729742207, 0.0000010927293487839052, 6.503604845775612e-7, 3.9916932337291655e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6095663581221098, 0.6094629956706359, 0.608424932858373, 0.6065283450524485, 0.6035269341094486,
            0.5996612711640276, 0.5671277497070956, 0.48664005958927875, 0.4213567152037771, 0.36650083399637884,
            0.32291536160252965, 0.19671552721037236, 0.10413248012923447, 0.06721739129764491, 0.047852626558889166,
            0.03615933790154833, 0.01358511990731194, 0.0069418978822126266, 0.004108316198479085, 0.002659055709555313,
            0.0018236285993769353, 0.001307578157001457, 0.0009710198016693627, 0.0007409170062040387,
            0.0005773544588011935, 0.00037017788160959855, 0.000251835297812173, 0.00017854886903153616,
            0.000130889219583162, 0.00009842018866459706, 0.00007575456191889852, 0.00005934262512151777,
            0.00004707513690281574, 0.0000377783763989273, 0.00003063142867339733, 0.00001885006336739685,
            0.000012113505051698768, 0.000008047889481166415, 0.000005490905228753243, 0.0000027256528499775,
            0.0000014463811575105012, 8.087170325632442e-7, 4.708657286300877e-7, 2.833191672380053e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4869203395550272, 0.48689890815270526, 0.48643804360288956, 0.48490457858266256, 0.4820948166513565,
            0.4781062239982748, 0.4474428771087983, 0.37681328378525125, 0.3185761632470604, 0.2735435278984013,
            0.23842280898274765, 0.1401632071674869, 0.07078032704322328, 0.044252729342062216, 0.030678926076078528,
            0.02262261929874136, 0.007705078359074019, 0.0036933691144100476, 0.002081002455221017,
            0.0012934379695126968, 0.0008596853056466914, 0.0006001581743793798, 0.0004350652757102046,
            0.0003249543295561353, 0.0002486834710315185, 0.0001542423852054163, 0.00010144675628810543,
            0.0000697360376057229, 0.000049621064832702213, 0.000036299982958899496, 0.00002716548216569079,
            0.000020719451949565503, 0.000016060117567690076, 0.000012623805992190411, 0.00001004336536813216,
            0.000005926438011528641, 0.0000036777588844773993, 0.0000023738525803647186, 0.0000015823723303637162,
            7.581557160222361e-7, 3.9233522984129995e-7, 2.1510009451666917e-7, 1.2363929279653717e-7,
            7.372443043918104e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.332290227019268, 0.3321636059260933, 0.3297387942968797, 0.3249148558942314, 0.3163800097853113,
            0.30675104826527977, 0.2559111379385785, 0.17643555940469208, 0.13248831341533177, 0.10526891192413615,
            0.08692817556829945, 0.043784954598816324, 0.01825673220246187, 0.009885701244352974, 0.006036632209695713,
            0.003974667880403892, 0.0008831457490237729, 0.00031665684927851076, 0.00014226812186645605,
            0.00007278653104921955, 0.000040296470960737214, 0.000023590734499531755, 0.000014452628569919828,
            0.000009230019400859323, 0.000006089862905820937, 0.000002831967423870121, 0.000001417570999798326,
            7.518180789034545e-7, 4.1859794254434234e-7, 2.3590013342733585e-7, 1.3590553887257958e-7,
            7.841192557028431e-8, 4.521044437702393e-8, 2.5754207175899352e-8, 1.4775212830997475e-8,
            3.3545426436631942e-9, 8.651233063261543e-10, 3.3325564530173324e-10, 1.7976520272355856e-10,
            4.701172784393748e-11, 2.0459189897792385e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3677462831282682, 0.3674809290971467, 0.3632802587708405, 0.3532590656201334, 0.33916063010947106,
            0.32555404813744326, 0.27174125278566874, 0.19224142012551435, 0.1461936660890979, 0.11786482196708564,
            0.0979649889793891, 0.04892105623071601, 0.02102051562989829, 0.011577956194217372, 0.00718238111036967,
            0.004788492594597485, 0.0011004348504023611, 0.000396907683910824, 0.00017793034547434905,
            0.00009069242212283161, 0.000050334060079689226, 0.000029785287481919225, 0.000018502714364441973,
            0.000011975511666508492, 0.000007975046294443159, 0.0000037679921012632034, 0.0000018910649724487265,
            0.0000010168453632686361, 5.64636091910517e-7, 3.238845169641323e-7, 1.9444945753566145e-7,
            1.1794781695773082e-7, 7.375527927407749e-8, 4.5126516958300964e-8, 2.759323148667028e-8,
            8.63055382716027e-9, 3.085676048009134e-9, 1.2134856453016596e-9, 4.336038195162928e-10,
            7.259925993707839e-11, 6.220091108843917e-11, 2.0459189897792385e-11, 2.0459189897792385e-11,
            2.0459189897792385e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6124794941471534, 0.6118312571360416, 0.6082809279227013, 0.602292255586589, 0.5934235889680949,
            0.5814795584970966, 0.5105487777947052, 0.40474675009103733, 0.3275262398019106, 0.2730091420973663,
            0.23255329077294676, 0.13279160441252588, 0.06719172127448124, 0.042635999973483205, 0.029926505556274213,
            0.02207648324020707, 0.007563451733845278, 0.0036919254370449666, 0.0020734183524176142,
            0.0012805686815502964, 0.0008392617806472496, 0.0005808779883057544, 0.00041954451470014854,
            0.000313266854377294, 0.0002395836562769999, 0.00014618263348293515, 0.00009396566141479488,
            0.00006268259062616459, 0.000043405984968815394, 0.00003092073325117429, 0.00002265940898222122,
            0.000016977736990317868, 0.000012827557558967245, 0.000009844442154553699, 0.000007670745804832975,
            0.000004296245143373484, 0.0000025166834811685135, 0.000001553521681851855, 9.982814612473527e-7,
            4.4625954886967634e-7, 2.1537233851898208e-7, 1.0976530262496453e-7, 5.8571671956997307e-8,
            3.2107038805406773e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.609337015626704, 0.6081382624241344, 0.6032269809238857, 0.594657836648966, 0.5835434413126287,
            0.5699766932756084, 0.49676351417509507, 0.3812269837240421, 0.30548561259646473, 0.2545433523470572,
            0.21778719671770308, 0.1234701836104224, 0.06054695710859048, 0.0371088419008085, 0.025618897179233293,
            0.01891521284383775, 0.00638293072494643, 0.0029838785978567634, 0.0016517214846229291,
            0.0010040255061974366, 0.0006565230727729929, 0.00045237258143520623, 0.000324399116030234,
            0.0002399393726502038, 0.00018132927521750526, 0.0001103505736264752, 0.00007114203513547273,
            0.00004796131323998616, 0.00003371830372867091, 0.0000243854132846806, 0.000017985464623026076,
            0.00001349853180939012, 0.000010282198403861895, 0.000007934114830742795, 0.0000061894849349650225,
            0.0000034710685738488714, 0.0000020381681126937557, 0.0000012448610411640027, 7.841644363848488e-7,
            3.35272802298725e-7, 1.5456385274349175e-7, 7.714813676784615e-8, 4.106667361725158e-8,
            2.2664430332497432e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.48689088347863946, 0.4863434852786127, 0.48163026904744766, 0.47240533283076297, 0.46038881916306085,
            0.44697696628856953, 0.37971865996542253, 0.2830227089529141, 0.22333250960007278, 0.1833969482518558,
            0.1548333514035519, 0.08337928554358529, 0.03881082928353086, 0.02307300422948357, 0.015378682686140985,
            0.010965928610648303, 0.0032936251101565524, 0.0014452751893619916, 0.0007608790043225474,
            0.00044755609656017034, 0.00028391361115448266, 0.00019029100008593602, 0.0001330018527435679,
            0.00009608326375955582, 0.00007129240586133631, 0.00004178858245008698, 0.00002610718860818291,
            0.000017110107244387016, 0.000011639125057215441, 0.000008159383367288214, 0.000005863140038285315,
            0.000004301177764457482, 0.0000032120462041369256, 0.0000024354357487705574, 0.000001871041754224132,
            0.0000010165160894934591, 5.840478318697492e-7, 3.508199634349445e-7, 2.1847563445325392e-7,
            9.203904037125702e-8, 4.2397921894428495e-8, 2.082014349702947e-8, 1.0695070582490342e-8,
            5.667943154576902e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.115220181419863, 0.07367779281392194, 0.04610257476325048, 0.034602050426148945, 0.02793297673705108,
            0.023445821665720026, 0.012516460857312913, 0.005315555334014557, 0.002693865866833574,
            0.0014966354480145186, 0.000890577132525257, 0.00012090018207006514, 0.000008515723332615427,
            0.0000012538750998429127, 2.863716518625795e-7, 7.831593573648377e-8, 4.937833475437969e-10,
            2.0459189897792385e-11, 2.0459189897792385e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.130541623486328, 0.08283774682525125, 0.051883299496212704, 0.039202918441500506, 0.03189649162550982,
            0.026939660859115776, 0.014671634958368502, 0.006497747731513822, 0.00342881332160639, 0.001978151890446121,
            0.0012088190192532991, 0.00017982263990703055, 0.000014490291649862547, 0.0000024510673242040724,
            5.957703901075075e-7, 1.8374039678636933e-7, 9.740999118434956e-10, 6.386136064406855e-11,
            5.595179874973155e-11, 2.0459189897792385e-11, 2.0459189897792385e-11, 1.962052742499054e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3241122880380581, 0.22382596869799165, 0.14691785369717336, 0.11266281334663446, 0.09273357342864517,
            0.07937177369602846, 0.04796770591794153, 0.027865269923616688, 0.01945411546003908, 0.014698802209441773,
            0.011616881489333163, 0.0048042867059198595, 0.001434198381485587, 0.0005426878251732661,
            0.0002489781421789932, 0.00014342138322746058, 0.000019432688030840417, 0.000003926872666815129,
            0.000001051599724632979, 3.0049824317757157e-7, 1.1466901250756223e-7, 4.979391610149264e-8,
            1.8408824464799523e-8, 8.018605224258124e-9, 4.138442033507772e-9, 1.0387204429918029e-9,
            5.045704964956599e-10, 2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10, 2.6798807617467446e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.29316577478077566, 0.2037089702055121, 0.13255091941374542, 0.1007682378170036, 0.08253118543972651,
            0.07025348142064461, 0.0420104085539476, 0.023896690103665446, 0.0162930218535254, 0.012005448663051881,
            0.009246080093619723, 0.0035165202067953677, 0.0009042897120080957, 0.0003394792393079556,
            0.00015353224352832344, 0.00008573997872434006, 0.000008767522598041033, 0.000002273742294223169,
            5.944741156005406e-7, 1.681003761744293e-7, 3.8184182926670474e-8, 1.3477952642837465e-8,
            4.92742791013967e-9, 1.8134257429025524e-9, 1.0655109017321251e-9, 6.180721490167684e-10,
            2.6798807617467446e-10, 2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10,
            2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10,
            2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10,
            2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10,
            2.3403012860967465e-10, 2.3403012860967465e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.20507638648473797, 0.1370339463489213, 0.08780788658479105, 0.06672086025485888, 0.054562132712178336,
            0.046477500009591236, 0.02718977317478394, 0.014310747473560585, 0.009089086174214693,
            0.0063012990241133665, 0.004611804960486721, 0.0014709098202986028, 0.00034528467799734057,
            0.00012487524653467742, 0.000055950585452742036, 0.000028637528750438714, 0.000002688773788143993,
            5.220584215143102e-7, 1.406161838973766e-7, 4.572566977792084e-8, 1.683171175611877e-8,
            6.927958046570837e-9, 3.0574413720763138e-9, 1.434067291940402e-9, 7.581951934208124e-10,
            2.424354971603157e-10, 1.360241815618016e-10, 1.0944850731880973e-10, 1.0561734122893042e-10,
            1.0516166157786606e-10, 1.0515973747318062e-10, 1.0515973747318062e-10, 1.0515973747318062e-10,
            1.0515973747318062e-10, 1.0515973747318062e-10, 1.0515973747318062e-10, 1.0515973747318062e-10,
            1.0515973747318062e-10, 1.0515973747318062e-10, 1.0515973747318062e-10, 1.0515973747318062e-10,
            1.0515973747318062e-10, 1.0515973747318062e-10, 1.0515973747318062e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3322756439982321, 0.3311557830217216, 0.32183260117703744, 0.306324273627123, 0.29090498432692713,
            0.2745345094295421, 0.2021639060852439, 0.1292017633339964, 0.09366242935947969, 0.07296236327237304,
            0.05933811771183221, 0.027739073294055984, 0.010608230579499788, 0.005322705931639814,
            0.0030507230847820147, 0.0019033872646446418, 0.00035182896956139124, 0.00011182562351950547,
            0.00004529973929977312, 0.00002107161226805634, 0.000010730216835148765, 0.000005846487607867907,
            0.0000033664832052618804, 0.000002021178476726312, 0.0000012531056554526597, 5.153986569054282e-7,
            2.2847449587275917e-7, 1.0663296748019708e-7, 5.000794311912567e-8, 2.4574188237005042e-8,
            1.2293599582768877e-8, 5.891969689919563e-9, 2.5976508721825553e-9, 1.1712885106263116e-9,
            5.113157675040725e-10, 1.4195578046383162e-10, 4.8131276741969486e-11, 2.0483170715124288e-11,
            2.0459189897792385e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            8.923861649634546e-12, 8.893330516457354e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3675833939395238, 0.36540252585779304, 0.34869856750029726, 0.3256140701155318, 0.30687476336442143,
            0.2892815894060276, 0.2188755218630963, 0.1412981660101319, 0.10442193330683437, 0.08177294091087062,
            0.06624226001815682, 0.031467281789420834, 0.012406280785353453, 0.006382765549869758,
            0.0037268500887658674, 0.0023604597949569784, 0.0004527976900974084, 0.00014430115107919923,
            0.00005817087209641368, 0.00002707006567072903, 0.0000138626294481473, 0.000007591531142554085,
            0.000004383771229616917, 0.0000026384229407590247, 0.0000016498903627759631, 7.031042259567615e-7,
            3.1548425892055403e-7, 1.4672270931548326e-7, 7.068040808421571e-8, 3.559745608516877e-8,
            1.8495769471549295e-8, 9.606954898266906e-9, 5.0114821181779234e-9, 2.6401332231529295e-9,
            1.3646911378728532e-9, 3.1748614848226e-10, 8.012202012963598e-11, 6.386136064406855e-11,
            6.220091108843917e-11, 2.0459189897792385e-11, 2.0459189897792385e-11, 1.7215451286745065e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6120927252710997, 0.6098600373131376, 0.5987441480047921, 0.5801088773748507, 0.5572172394896021,
            0.5331619979941487, 0.4350647319732527, 0.3133507626688198, 0.24239844555774978, 0.19745506046489106,
            0.16710226033701764, 0.09269765331987359, 0.045286439812484446, 0.02769853203501333, 0.018750135505412612,
            0.013701252727448066, 0.004501135572381913, 0.0020054206751029843, 0.0010745419914746294,
            0.000634526540772804, 0.0003975860534478464, 0.0002609715144221031, 0.00017805170048510108,
            0.00012600847169769874, 0.00009205541702459045, 0.00005315974133828156, 0.00003247329530588405,
            0.000020877731502934442, 0.000013917338877986322, 0.000009544081897103223, 0.000006691490901492341,
            0.00000480285124906142, 0.000003552195597444907, 0.0000026860632106329163, 0.000002053798036638632,
            0.0000010632594987791677, 5.754046087380615e-7, 3.1564707592135477e-7, 1.8592471240364006e-7,
            7.402254487143267e-8, 3.2279832140602593e-8, 1.523574333361921e-8, 7.4565260632653e-9, 3.689856753474885e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6085324894590627, 0.6053697422468234, 0.5903126780748881, 0.5687579415890809, 0.5438393132959805,
            0.5192470006999033, 0.4153651667715249, 0.2936903476205324, 0.22848713629037198, 0.18692799740671387,
            0.15746326143522918, 0.08446252556187019, 0.03904921913116527, 0.023768644647200105, 0.01620126315293619,
            0.011766497830004581, 0.003600890352395301, 0.0015792353443304119, 0.0008387234032886326,
            0.00049408843530098, 0.0003112198218181274, 0.00020392311129617646, 0.00013987881628833965,
            0.00009970928886552098, 0.0000735846830521384, 0.00004270089238587958, 0.000026317375859298738,
            0.000016991026593199204, 0.000011278811770723359, 0.000007733629192149908, 0.00000544998520979334,
            0.000003927843812645548, 0.0000028821392682149723, 0.000002149613919755211, 0.00000162671221914934,
            8.418042403937931e-7, 4.578319823256294e-7, 2.5964152428059606e-7, 1.5202708425476885e-7,
            5.6675294124453046e-8, 2.147535305141446e-8, 9.721097704584736e-9, 4.6693577893108795e-9,
            1.767425317211746e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4866324885645974, 0.4837314820424301, 0.468413024986743, 0.4467315050742635, 0.423684200572402,
            0.4013445868832593, 0.3127182061934659, 0.21469619993058872, 0.1626824173231553, 0.13021703506763188,
            0.10792373254994439, 0.05512681128678215, 0.024391212793089288, 0.014033899550369185, 0.009106682283783795,
            0.0063427645558202395, 0.0017426654993418656, 0.0007184305979949197, 0.00036001134837675993,
            0.0002030006994742033, 0.00012397882825287314, 0.00008022936960670782, 0.000054255443882975835,
            0.00003798784424705326, 0.00002735706878011046, 0.000015161050983885363, 0.00000898978156270408,
            0.000005611127737512664, 0.0000036470774072719426, 0.00000244971859561791, 0.00000169024800695825,
            0.0000011927789754955245, 8.576865114656183e-7, 6.273754730111821e-7, 4.6550838939337044e-7,
            2.3273258234899437e-7, 1.2407703033154562e-7, 6.939617236797622e-8, 4.035146520889393e-8,
            1.5067499909330618e-8, 6.153126614030693e-9, 2.697568746930288e-9, 1.2654552884762787e-9,
            6.395882279639613e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3291155087217821, 0.31401469633873846, 0.27245028389696996, 0.2330515882665034, 0.20214884034322284,
            0.1779374605766707, 0.1115943535652864, 0.06503046246354455, 0.04578779957895569, 0.03463590110789669,
            0.027451850247531433, 0.011820832871199017, 0.003854327208414062, 0.0016724467880080095,
            0.0008518525670348473, 0.00048207968068796086, 0.00006359887479742921, 0.000015207971321296153,
            0.00000479507486838572, 0.0000017955194030513094, 7.407056468232663e-7, 3.1974720463435294e-7,
            1.4784255079091214e-7, 7.247604427718102e-8, 3.754125556454271e-8, 9.824802704283797e-9,
            2.716715741257758e-9, 8.340760304648143e-10, 3.008234772394758e-10, 1.1563672241976519e-10,
            6.172695687922669e-11, 2.1226576052413293e-11, 2.0459189897792385e-11, 2.0459189897792385e-11,
            2.0459189897792385e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.36198472408781784, 0.33490932207856977, 0.28964401729962896, 0.25041408550030864, 0.21842172995245537,
            0.1932673947631471, 0.1254396720512948, 0.07445319709111123, 0.051934565142786715, 0.03947809242967659,
            0.031469718429480806, 0.013791140167732665, 0.004660454628852742, 0.0020858776895492292,
            0.0010853255399624047, 0.000621112230942716, 0.00008200369381676007, 0.000019674264531886462,
            0.000006263998752590332, 0.000002387863797964568, 0.000001016494726261404, 4.5487561528540175e-7,
            2.1964931571457586e-7, 1.1165453761563526e-7, 6.017301953197323e-8, 1.8782610022860524e-8,
            6.064848956377489e-9, 1.977971675337642e-9, 7.201854668181795e-10, 2.396103315760456e-10,
            9.55291401538716e-11, 6.410239006271468e-11, 6.386136064406855e-11, 6.220091108843917e-11,
            4.4059089709946875e-11, 2.0459189897792385e-11, 2.0459189897792385e-11, 1.7173595878716696e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6081295070957431, 0.5925358336993067, 0.5380982070546366, 0.48558668585457476, 0.44470150817971715,
            0.40952441017792873, 0.28793320018422214, 0.18296896434863083, 0.13627132021746977, 0.10857434934021075,
            0.08987796312177584, 0.046576023686279173, 0.021126076513587947, 0.012366819356688994, 0.008184186309912482,
            0.005736121407100922, 0.0015651694542403115, 0.0006290910092706407, 0.0002953711821958448,
            0.00015474954606676286, 0.00008626228620400725, 0.00005384593202640759, 0.00003538228019526392,
            0.00002349904906446696, 0.00001560011340296911, 0.000008195524244247743, 0.000004446575952887777,
            0.000002563019129375732, 0.000001593995031412554, 0.000001007067339742207, 6.454719709836354e-7,
            4.306468213144399e-7, 2.961540417345532e-7, 2.0759180115655476e-7, 1.4882793664128258e-7,
            6.562285648215038e-8, 3.270905390984069e-8, 1.664810345136658e-8, 8.922379057807461e-9,
            2.509052965393721e-9, 1.0378895520801734e-9, 3.9525471784429556e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6023941896849706, 0.5798721407188817, 0.5194719531900753, 0.4714511492204195, 0.4254345592298168,
            0.38723961994939937, 0.270964710778471, 0.17302118629216126, 0.12697967296722032, 0.09955139689006465,
            0.08122135513305268, 0.040446796630122496, 0.018239171980525316, 0.010641098986283737, 0.00692404160057322,
            0.004762773853783028, 0.0012149891909928128, 0.00046737100942729115, 0.00022058606876706004,
            0.00011861394383804633, 0.00006895520382853846, 0.00004208023278907462, 0.000026434358605121802,
            0.000018162849172386498, 0.00001274604824137927, 0.000006421587253391259, 0.000003460289699027806,
            0.0000019591003364016757, 0.0000011875304153363686, 7.351025248514276e-7, 4.6959559085735236e-7,
            3.111348391149349e-7, 2.1202225886085557e-7, 1.482821261333811e-7, 1.056971541402163e-7,
            4.557118415959849e-8, 2.0799410194172196e-8, 1.0639557679326117e-8, 5.673041592046957e-9,
            1.7926448103722237e-9, 6.080554948439953e-10, 2.6798807617467446e-10, 2.4536384035656056e-10,
            2.3403012860967465e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.48024633771681563, 0.45686618971826576, 0.4016975125301529, 0.3546629734371575, 0.31703331314237654,
            0.2867102624146219, 0.19525622320132233, 0.12015909500968294, 0.08636582555958171, 0.06685422037317464,
            0.05409942970605701, 0.02589419669214944, 0.010677546894833119, 0.005776999817867077, 0.0035386018802375427,
            0.0023370697621030246, 0.0005230167268146629, 0.00018712858576096716, 0.00008405302982735905,
            0.000043274089873366154, 0.00002441853921793695, 0.000014723065593361813, 0.000009335987272742759,
            0.000006157452603644382, 0.000004193246992865642, 0.0000020987866221731904, 0.0000011361212644888552,
            6.51616333713152e-7, 3.9091181276805047e-7, 2.4308448445098437e-7, 1.558693607445939e-7,
            1.025191114648931e-7, 6.897904404835086e-8, 4.7182585861810856e-8, 3.27752470976439e-8,
            1.4193924349492759e-8, 6.645390804977404e-9, 3.3019041799577356e-9, 1.7140006476676386e-9,
            5.351901582831442e-10, 2.2918659177542975e-10, 1.3891320107406818e-10, 1.1228249765460546e-10,
            1.0685230112936267e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.31544935243381006, 0.2761972560828692, 0.20624870349174299, 0.16583200312170931, 0.13921026584996865,
            0.12007825716856922, 0.07258367953075602, 0.04059589701403411, 0.02764349658558461, 0.020443289205748647,
            0.015826121204160007, 0.006051671316960339, 0.0015874441277086155, 0.0005920358917615067,
            0.00026937417696455197, 0.00013951845953884197, 0.000013433962283156848, 0.000002520536756362404,
            6.095559382023686e-7, 1.7468912705265183e-7, 5.957331106198893e-8, 2.118096426819682e-8,
            7.755682410282392e-9, 3.0328978217752933e-9, 1.2372527447013226e-9, 2.4639101869894375e-10,
            6.386136064406855e-11, 2.475131211099324e-11, 2.0459189897792385e-11, 2.0459189897792385e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.2933543125370761e-11, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3363277033450276, 0.29514558793857115, 0.22977893295755683, 0.18662962621514234, 0.1579106737896761,
            0.13691947350240133, 0.08195272616132952, 0.046191014234360735, 0.031660623178324264, 0.023562712976689597,
            0.018354181637857125, 0.007246470567724043, 0.0020464709034366184, 0.0008009879336297836,
            0.00037520336272358647, 0.00019675246333306486, 0.000018415471749537315, 0.000003391007631170865,
            8.51280885929072e-7, 2.5373420076135744e-7, 9.06220262164581e-8, 3.535010628663571e-8,
            1.4541946630823696e-8, 6.091418369713608e-9, 2.5032572681382703e-9, 5.050648788085255e-10,
            1.403043237147017e-10, 6.386136064406855e-11, 6.220091108843917e-11, 5.595179874973155e-11,
            2.0459189897792385e-11, 2.0459189897792385e-11, 2.0459189897792385e-11, 2.0459189897792385e-11,
            2.0459189897792385e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5932872905624271, 0.5414903285313879, 0.45218386994995813, 0.38885224029971266, 0.33640465029632355,
            0.29752523222265465, 0.19429301411239325, 0.11875844576506726, 0.08641861164225491, 0.06741183290655664,
            0.05487786853796972, 0.0276960927974134, 0.012596497522815412, 0.007219711013621638, 0.004622561013206011,
            0.00319875729897631, 0.0007615869965275845, 0.00026448427501434734, 0.00011568818738388175,
            0.00005719502826760521, 0.00003030872748924689, 0.000017682855747791663, 0.000011034270785630487,
            0.000007070227845309211, 0.000004628860067024121, 0.000002264538291996132, 0.0000012429448529305276,
            6.823364701036283e-7, 4.037972018977598e-7, 2.432742565172674e-7, 1.5977740575046084e-7,
            1.0293194674382278e-7, 6.751967607243614e-8, 4.4214503169826e-8, 2.900218998291848e-8,
            1.0401732142284459e-8, 5.044846318469354e-9, 2.402865684203448e-9, 1.332015386878993e-9,
            6.981693001506528e-10, 2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.583128055758234, 0.5250334586163902, 0.4290166922984256, 0.36192522265196464, 0.3141995742879048,
            0.2783072076296126, 0.18024255843244077, 0.10826740285782847, 0.07728542848951414, 0.0599657103078443,
            0.04885036152298018, 0.024346493217707283, 0.010635608754415032, 0.005892388804669812, 0.003699302069204058,
            0.0024691020410473985, 0.0005744186216285903, 0.00021412426710286408, 0.00008861662246008286,
            0.00004346780053662465, 0.00002314672795137359, 0.000012868799847476921, 0.000007554534729854786,
            0.000004639167780323059, 0.0000029690339535815014, 0.0000013464115227179718, 7.307230411868204e-7,
            3.996617294887983e-7, 2.2029429658232402e-7, 1.320207871913226e-7, 8.192160549924665e-8,
            5.243900957907499e-8, 3.421513028456502e-8, 2.2692502099630474e-8, 1.5276906850658634e-8,
            5.957580651028138e-9, 2.4248500984924704e-9, 1.0699655605961311e-9, 8.664907680255851e-10,
            2.7736146712697973e-10, 2.3535051685286135e-10, 2.3403012860967465e-10, 2.3403012860967465e-10,
            2.3403012860967465e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.45927037166748463, 0.4064655637049982, 0.32420387439574233, 0.2700524691474741, 0.23220960470826527,
            0.2042133816930034, 0.12935169499835844, 0.07551741153897276, 0.05301357542232172, 0.04046977035806359,
            0.03243561569081192, 0.015027170782387321, 0.005820739945551289, 0.0029728371397173222,
            0.0017338056354040173, 0.001098378533772654, 0.0002123804359835542, 0.00006867703160905533,
            0.00002841680336286461, 0.00001364295616911676, 0.000007247121015826905, 0.000004140409356644132,
            0.000002502785401059512, 0.00000158224598350771, 0.0000010370153614191751, 4.841805919272631e-7,
            2.466060021775613e-7, 1.3397921292104048e-7, 7.657470292724054e-8, 4.570985114872287e-8,
            2.8122380615868716e-8, 1.79989472291555e-8, 1.1829880037028064e-8, 7.933711801008207e-9,
            5.41801546566106e-9, 2.2500951095301948e-9, 1.059825636446553e-9, 5.428954544584831e-10,
            3.0130894409388896e-10, 1.4649411630955156e-10, 1.1348994725826841e-10, 1.0515973747318062e-10,
            1.0515973747318062e-10, 1.0515973747318062e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.28420123855987356, 0.2167061496618937, 0.14985062368605837, 0.1157681384636185, 0.0949866023768402,
            0.08115081718113627, 0.04772305361297215, 0.026167411022653422, 0.017338199302228727, 0.012449417229264405,
            0.00934918571740765, 0.0030465102433180967, 0.000629460502691315, 0.00020196364133059225,
            0.00008200568974652275, 0.00003810677693772949, 0.000002294434601650518, 2.682784133672824e-7,
            4.391149921456616e-8, 1.0095856565501308e-8, 2.708652524496813e-9, 8.699295728220591e-10,
            2.5955282367817745e-10, 6.386136064406855e-11, 6.220091108843917e-11, 2.0459189897792385e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.0453082843753236e-11, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.30371214506807254, 0.2428818766009511, 0.17013134766719798, 0.1318513102038172, 0.10769855204991341,
            0.09170846270005045, 0.0542354182324957, 0.029994115527050547, 0.020087745098418455, 0.014565813864698676,
            0.011045226487687129, 0.003810092533242097, 0.0008603903983284847, 0.000289397212191655,
            0.00011915694211676689, 0.00005562453571681239, 0.000003324608981046399, 4.370647858018188e-7,
            8.55760645380722e-8, 2.1637980074373786e-8, 6.0522505888221825e-9, 1.7748467140421553e-9,
            5.512531542350985e-10, 2.6878665959628734e-10, 1.2462531007173538e-10, 6.220091108843917e-11,
            2.0459189897792385e-11, 2.0459189897792385e-11, 2.0459189897792385e-11, 2.0459189897792385e-11,
            2.0459189897792385e-11, 2.0459189897792385e-11, 1.760624979141312e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.561341355469386, 0.48173641405540313, 0.37040604087104595, 0.30573057717604857, 0.26094416086338906,
            0.22848122410360353, 0.14275342478132672, 0.08183775664595472, 0.057858335873165634, 0.04481548087479118,
            0.0366398257991565, 0.01837766909348772, 0.007856459463592185, 0.004347313723537723, 0.002624931573066349,
            0.0016893204066767795, 0.0003246251811784689, 0.00009706091678243643, 0.00004067016339983205,
            0.000019905610415617697, 0.000010604534720185702, 0.00000615259451752781, 0.0000037968314014051,
            0.000002347026080595782, 0.0000015029141823275083, 6.861105220634656e-7, 3.3776922236938844e-7,
            1.8018422986987304e-7, 1.0479131862251023e-7, 6.53085072688242e-8, 4.186888924451182e-8,
            2.652852870710376e-8, 1.520207426608522e-8, 9.480975782238943e-9, 6.636559746198145e-9,
            2.5748405629855142e-9, 1.0771075142912423e-9, 9.112343102302134e-10, 3.9257108674917163e-10,
            2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5367239932752524, 0.45000751024504415, 0.3443304703869324, 0.2775718145672309, 0.23325767786264512,
            0.20222297050234372, 0.12573697688368982, 0.07348929310239247, 0.05204671583833432, 0.04013486239623971,
            0.0325229789250842, 0.01579914287524849, 0.006417678987815262, 0.0033447324078968565, 0.0020076414075742267,
            0.0013041232105738406, 0.00025105170732237647, 0.000070507607733491, 0.00002677406550688488,
            0.000010865110023052525, 0.000005474838445700279, 0.000003047998006877606, 0.0000018275609302564888,
            0.0000010978009153950197, 6.954265910907864e-7, 3.188163759659801e-7, 1.5808747377832333e-7,
            8.286532704460114e-8, 4.529759267679623e-8, 2.5330849640070596e-8, 1.4399338610360246e-8,
            8.649402083449331e-9, 5.289796600749241e-9, 3.2847053965667783e-9, 2.0616706120080153e-9,
            1.0399651140247101e-9, 7.271100388450691e-10, 3.5947378407286124e-10, 2.6798807617467446e-10,
            2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10,
            2.3403012860967465e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.41837559314062717, 0.341451431104956, 0.25115277535803265, 0.20080877216054846, 0.1684129108251188,
            0.14563146642032102, 0.0886349378655336, 0.05038892427709816, 0.03493287608200088, 0.02641005606916987,
            0.020969051916622725, 0.009229221887385026, 0.003243087180651143, 0.001533024143657742,
            0.0008398159349370861, 0.0005046637537409741, 0.00008088494540352686, 0.000023188155945208416,
            0.00000884093328891011, 0.000004004821271889917, 0.000002038244464303768, 0.0000011286084531513707,
            6.647301447421504e-7, 4.1092227534366194e-7, 2.642912647266552e-7, 1.1991308685512946e-7,
            5.983785222039403e-8, 3.2195390893864436e-8, 1.8282160562520595e-8, 1.0834134209776598e-8,
            6.621786338876907e-9, 4.1337225824692396e-9, 2.642588263298624e-9, 1.7287970369282635e-9,
            1.1889349357866245e-9, 5.134927936512533e-10, 2.5082718466802246e-10, 1.6038322281473207e-10,
            1.2619609827997305e-10, 1.05835667676678e-10, 1.0515973747318062e-10, 1.0515973747318062e-10,
            1.0515973747318062e-10, 1.0515973747318062e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.23603655282368374, 0.1675725049338353, 0.10918542020298545, 0.08291315175889485, 0.06779905404944324,
            0.05763378078374031, 0.033619745499406016, 0.017812271902984938, 0.01136596090860258, 0.00784642125173134,
            0.005657919178511533, 0.0015252537898243377, 0.000249922701786676, 0.00006814694841980047,
            0.000023855187879447722, 0.000009760944986865272, 3.4400226095300074e-7, 2.9461424300158967e-8,
            4.578718071357457e-9, 9.52424361599924e-10, 1.830711138239849e-10, 6.386136064406855e-11,
            2.0459189897792385e-11, 2.0459189897792385e-11, 2.0459189897792385e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            9.158673819342766e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2630543703791728, 0.18897199390960587, 0.12492314455265001, 0.09421026020092187, 0.07676561269312622,
            0.06524548011110406, 0.03822702160171652, 0.02060429998104013, 0.01335818654122072, 0.009349937245481043,
            0.006838159115381092, 0.001985011836973927, 0.00035341924783983795, 0.00009927121251775175,
            0.000035603144241307196, 0.000014902063051199941, 6.369725311943319e-7, 6.283179809773998e-8,
            1.0395025729081908e-8, 2.0079288232111026e-9, 4.1602421507747067e-10, 1.2374058038253658e-10,
            6.220091108843917e-11, 5.938305402963806e-11, 2.0690449353821805e-11, 2.0459189897792385e-11,
            2.0459189897792385e-11, 2.0459189897792385e-11, 2.0459189897792385e-11, 1.723465814507108e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5171637153415394, 0.41056443021494077, 0.30267733610854597, 0.24286375852752828, 0.20359912497230603,
            0.17566581658933614, 0.10492865798292252, 0.06006320960830323, 0.04265380023948307, 0.033093427702804816,
            0.02693784146835974, 0.012931547388569092, 0.005086893045224205, 0.0026099253882976388,
            0.0014610879937872756, 0.0008705451040519454, 0.00013945418754805834, 0.000042577850530244395,
            0.000016864785592431446, 0.000007611032044543937, 0.000003807814437095658, 0.0000020472995252429627,
            0.00000119700385992072, 7.267257728926779e-7, 4.6492467065117893e-7, 2.14886856531038e-7,
            1.0805434846883344e-7, 5.7824951715801376e-8, 3.160433470483781e-8, 1.537825944364357e-8,
            8.347456947888077e-9, 5.272274061773885e-9, 3.3606462057633735e-9, 2.1371229230737754e-9,
            1.2882742650432988e-9, 7.448210936900068e-10, 2.8125357598440814e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10, 2.6798807617467446e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.47812264091653384, 0.3841086795882931, 0.2728805313249782, 0.21345414426743936, 0.17728015193007554,
            0.1530600893196151, 0.09377850844016719, 0.053767855969084406, 0.03781052804665525, 0.029064365347954497,
            0.023415516891360785, 0.010894944618439689, 0.004003231444529109, 0.0019412816351573774,
            0.0010741680479275757, 0.0006546313710377961, 0.00009797344755359604, 0.00002326274340908403,
            0.000008561970766044156, 0.000003873079094773857, 0.0000019010324194823625, 9.464176364826216e-7,
            5.317920113157015e-7, 3.149558267523389e-7, 1.929387991594922e-7, 7.876354146674203e-8, 3.52321727348226e-8,
            1.6985337136787848e-8, 8.448191923804416e-9, 4.115032203877433e-9, 1.9326148459342107e-9,
            1.1773559904781905e-9, 1.0378895520801734e-9, 1.0378895520801734e-9, 7.801314039213025e-10,
            3.040935281362067e-10, 2.4885771221505593e-10, 2.3403012860967465e-10, 2.3403012860967465e-10,
            2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10,
            2.3403012860967465e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.36681145282709465, 0.27961192082100966, 0.19425579416971436, 0.15149818728153958, 0.1253386174792603,
            0.10746384987037522, 0.06420733483394817, 0.0360056322621562, 0.02467760200209254, 0.018420253078001485,
            0.014425204909148982, 0.005922592619951328, 0.00186541341106364, 0.0008139280466467496,
            0.0004182842448027182, 0.0002382547570719841, 0.00003198654803510134, 0.000008368822210014147,
            0.0000030294211546722803, 0.000001327530742235434, 6.582116006571824e-7, 3.5586347145376603e-7,
            2.0513901092047598e-7, 1.2464476088424516e-7, 7.905760671376018e-8, 3.494097578599219e-8,
            1.6906425644241476e-8, 8.703165939884062e-9, 4.667205587833499e-9, 2.5840500779368787e-9,
            1.499114517764569e-9, 9.346109108573978e-10, 6.056528154882626e-10, 4.0482425617466646e-10,
            2.7796881862963494e-10, 1.5092589069215683e-10, 1.162556890439655e-10, 1.060305675328372e-10,
            1.0517239572280602e-10, 1.0515973747318062e-10, 1.0515973747318062e-10, 1.0515973747318062e-10,
            1.0515973747318062e-10, 1.0515973747318062e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-43.53~172.63",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.1751364981235373, 0.11610774948570145, 0.07349561080642182, 0.05564995447322275, 0.04526504320925169,
            0.038358447863287425, 0.02183835765895221, 0.010874455074467772, 0.006453945661166371,
            0.0041347786387432375, 0.0027713592410872692, 0.0005612292835152812, 0.00006439443431318169,
            0.00001339257098689407, 0.000003834969046434678, 0.0000013530147844233943, 3.155047856306936e-8,
            1.968586849088183e-9, 1.3893430850231425e-10, 2.0459189897792385e-11, 2.0459189897792385e-11,
            2.0459189897792385e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.6601608976429816e-11, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
            8.893330516457354e-12, 8.893330516457354e-12, 8.893330516457354e-12,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-43.53~172.63",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.1967357293870727, 0.13260279475250597, 0.08326815236826247, 0.06271553775343994, 0.051050231756761204,
            0.043385853838065966, 0.025138812717159065, 0.012799940797984178, 0.00778069196714698, 0.005088145394595183,
            0.0034828845290652798, 0.0007684941821734196, 0.00009679872699762222, 0.000021752532379513488,
            0.000006626918523199471, 0.0000024904695348100264, 6.758894188774178e-8, 3.1797030564106876e-9,
            2.6782609463538165e-10, 6.386136064406855e-11, 6.220091108843917e-11, 3.79443143572189e-11,
            2.0459189897792385e-11, 2.0459189897792385e-11, 2.0459189897792385e-11, 2.0459189897792385e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11, 1.7149726083687256e-11,
            1.7149726083687256e-11, 1.7149726083687256e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-43.53~172.63",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4358335245952359, 0.32672490025351913, 0.22517314068383712, 0.1744121657649501, 0.14358346829269125,
            0.12279983610411803, 0.07355487129284291, 0.042565297749300915, 0.03023974537237484, 0.023308280145676905,
            0.018728866593868222, 0.008472503413165295, 0.0030034193950410314, 0.0013751615036124156,
            0.000735339407128112, 0.00043206096175226334, 0.00006517905011205194, 0.000019385729138732266,
            0.000006476425964851984, 0.000002612859042710291, 0.0000013060601986580878, 6.256437782292679e-7,
            3.502375843789096e-7, 2.1321549614761182e-7, 1.3411486188275035e-7, 5.6566045625316974e-8,
            2.3113253533324496e-8, 8.789539762688037e-9, 4.3708410224496674e-9, 2.2112596198553547e-9,
            1.1320745452181313e-9, 1.0378895520801734e-9, 5.692736282369992e-10, 3.1963176549965056e-10,
            2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10, 2.6798807617467446e-10,
            2.6798807617467446e-10, 2.6798807617467446e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-43.53~172.63",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.40545726805532134, 0.2959461949012965, 0.20003113905784714, 0.15587398010527434, 0.12894966728428747,
            0.11032721069896348, 0.06559062993776987, 0.03742636240662622, 0.02627153606781286, 0.020004001195327508,
            0.01588719033450843, 0.006850997913329911, 0.002250446983465577, 0.0009718136951085092,
            0.0004808917920554951, 0.00027676341941419214, 0.00003353745341883041, 0.000008868876934138827,
            0.0000029952361453119282, 0.0000010436636371791153, 4.651431692037633e-7, 2.384809034694513e-7,
            1.3126665532325887e-7, 7.145541047748338e-8, 3.7627032156706264e-8, 1.1864933036065395e-8,
            4.359055338909457e-9, 1.6262269308953137e-9, 1.0378895520801734e-9, 1.0378895520801734e-9,
            7.206709673468481e-10, 4.154915300702555e-10, 2.6798807617467446e-10, 2.6798807617467446e-10,
            2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10,
            2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10, 2.3403012860967465e-10,
            2.3403012860967465e-10, 2.3403012860967465e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-43.53~172.63",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2934141311472714, 0.2084044933080499, 0.1378465591450595, 0.10561452442306325, 0.08662648863591463,
            0.0739018532974093, 0.043693475203639774, 0.024107770932153882, 0.016163980100916388, 0.011777582920122561,
            0.009004341181348794, 0.0033420291449382006, 0.0009252166406077755, 0.0003706480328315834,
            0.00017861156869567073, 0.00009673104926554671, 0.000011334246974432713, 0.000002799736406769189,
            9.694595047245688e-7, 4.0617805472988785e-7, 1.928016493436044e-7, 9.986375421460761e-8,
            5.530327524910274e-8, 3.2211849642237975e-8, 1.9490129611754623e-8, 7.810978352192523e-9,
            3.350376237174478e-9, 1.512782369011825e-9, 7.905218477923428e-10, 4.4141608672500885e-10,
            2.597422553554135e-10, 1.7622745219224753e-10, 1.388268199945342e-10, 1.2030864202664532e-10,
            1.1072829464226096e-10, 1.0518463858368476e-10, 1.0515973747318062e-10, 1.0515973747318062e-10,
            1.0515973747318062e-10, 1.0515973747318062e-10, 1.0515973747318062e-10, 1.0515973747318062e-10,
            1.0515973747318062e-10, 1.0515973747318062e-10,
          ],
        },
      },
    ],
  },
};

export const arbitraryLatLonResponse = {
  hazard_curves: {
    ok: true,
    locations: [
      {
        lat: -42,
        lon: 173,
        resolution: 0.1,
        code: "-42.0~173.0",
        name: null,
        key: null,
      },
    ],
    curves: [
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5073494525938809, 0.5073480275521132, 0.50725708429906, 0.5071853055006034, 0.5068619814223297,
            0.5063182170935777, 0.4986097348083271, 0.454132064375662, 0.3974382360939082, 0.347965025561908,
            0.3075113505827168, 0.18369926542903053, 0.08615300923165106, 0.0502684080144522, 0.03335230083994818,
            0.023837221675721044, 0.0072558402987395985, 0.0030683911545359116, 0.001496929604103493,
            0.0007975265512002525, 0.0004551670225305057, 0.00027345319238698796, 0.00017144873568851785,
            0.00011045406773005592, 0.00007293329071833569, 0.00003387966632995421, 0.000016654819062902392,
            0.000008632683569868003, 0.000004609247587745048, 0.000002529264200501674, 0.0000014204415422863004,
            8.177421582832812e-7, 4.705508505733346e-7, 2.7028084888829765e-7, 1.5449309052629454e-7,
            3.716076735216234e-8, 7.245985256254528e-9, 1.4149974525423659e-9, 2.9945723678537206e-10,
            2.1154833440562015e-10, 7.62899743378398e-11, 5.156663984706711e-11, 4.0516590082972925e-11,
            4.050149104983802e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5395719260766758, 0.5395719020386788, 0.5395649376501518, 0.539336500752334, 0.5390948021658838,
            0.5384879276972623, 0.52224565976983, 0.4775750806809504, 0.4245559334391827, 0.37398976967834946,
            0.3320461256830749, 0.20163861273340117, 0.09788515109469414, 0.05851512895841904, 0.03931236591061516,
            0.028353475938298362, 0.008983170503271815, 0.003975683440036277, 0.00202978443613526,
            0.0011319890739855403, 0.0006715480110154377, 0.00041593026169698197, 0.00026706464150204085,
            0.0001768833098018927, 0.00012000835795522433, 0.000059271393674588576, 0.00003141219276958155,
            0.000017156528887833055, 0.000009554684833739185, 0.000005523304450234724, 0.000003304686149311551,
            0.000002023389611431186, 0.0000012828320604508835, 8.340161429358418e-7, 5.590610956528319e-7,
            2.1299512376339634e-7, 7.516805389240488e-8, 1.9610166658834755e-8, 4.773224926424291e-9,
            9.66990709727611e-10, 2.125913889372555e-10, 1.9960288977216578e-10, 5.158173888020201e-11,
            5.156663984706711e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7716031877938436, 0.771598402966477, 0.7714585171133375, 0.7712644184839613, 0.7705190080041663,
            0.7696352761830221, 0.7605678440809616, 0.7334038596740232, 0.6955812352726526, 0.6531826064884947,
            0.6102945670525661, 0.442959629190224, 0.26086357841859686, 0.1742799470584522, 0.12589643274661905,
            0.09574247792077485, 0.03608298808956551, 0.01849074771647288, 0.010898793038174285, 0.006994594939978727,
            0.004741864376021804, 0.0033484513975728008, 0.002430177006425581, 0.0018065579343259025,
            0.0013695614266158796, 0.0008233089426668082, 0.0005201833907800796, 0.00034027035926233125,
            0.00023026957802517797, 0.00016078543754412865, 0.00011607865314180282, 0.00008686149030976837,
            0.00006792472097082184, 0.000054312857800076, 0.00004404520294185711, 0.000027269838325238993,
            0.00001763197858764877, 0.000011805552329137303, 0.00000813186921078124, 0.000004119313955164294,
            0.0000022333262005291843, 0.0000012755175244905104, 7.588077616249578e-7, 4.66702931745111e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7647170808310961, 0.7647164214424073, 0.7646651099112396, 0.7641552722952093, 0.7636661999422043,
            0.7629575789684713, 0.7511488125786847, 0.7112044158856639, 0.6625314912482679, 0.6180814711680066,
            0.5782091392947304, 0.41096656366977735, 0.2381668091955582, 0.15674346057531136, 0.11159284047302714,
            0.08388415613608341, 0.030841925669814533, 0.015783418714108266, 0.00934395960278402, 0.006024998227219918,
            0.004094305575471798, 0.0028805789869366426, 0.0020850504043818823, 0.00154098189036095,
            0.0011619436573772292, 0.0006905726742469229, 0.0004313935624233075, 0.0002810670167681589,
            0.00019173857988039397, 0.00013812673957191944, 0.0001043004999681332, 0.00007362489336988124,
            0.00005279974313705188, 0.00003856987711263985, 0.000028622569698568493, 0.000014229527826256978,
            0.0000074701159299593134, 0.0000041055402936219435, 0.00000232889743023712, 8.380582929845559e-7,
            3.457884080978121e-7, 1.5916017415129602e-7, 8.301322940162947e-8, 4.508541240344499e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "PGA",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.654964659524892, 0.6549628943009946, 0.6549029139078667, 0.6546104851294984, 0.6539079240311826,
            0.6526931961651554, 0.6391021135493441, 0.5919402476229919, 0.5403097185507413, 0.49250351462586694,
            0.4499640542198132, 0.3013489167973592, 0.16430026384183818, 0.10474223574851894, 0.07320236565434907,
            0.05429286017043063, 0.01902589134071619, 0.009327813944979346, 0.0053164141589905995,
            0.0033075964396957797, 0.0021811037251267228, 0.001499884223569318, 0.0010649774680490949,
            0.0007756851500126942, 0.000576929628841655, 0.0003356730816397944, 0.00020601284458364485,
            0.00013188214536320134, 0.0000873984576302829, 0.000059626585387115695, 0.000041703002121899306,
            0.000029805175574447362, 0.000021710109908321394, 0.000016083111239892143, 0.000012096561752422898,
            0.000006281519786437545, 0.0000034865286383429847, 0.0000020406674022287983, 0.0000012495395071683536,
            5.219000235169667e-7, 2.424333047466274e-7, 1.2329873493799098e-7, 6.79679535219235e-8,
            3.945679539755267e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5073494614680985, 0.5073491013585784, 0.507333102893718, 0.5072721658626254, 0.5072190550998008,
            0.5071238038619397, 0.5053643304478196, 0.4939226360315431, 0.47195789432938945, 0.4467960411771793,
            0.42211134160509167, 0.31070436439296956, 0.1861183384746679, 0.12330616733546648, 0.08747891041705083,
            0.06529707667463867, 0.023070793418987302, 0.011331537830428893, 0.006385117222248743, 0.003884631325625503,
            0.0024938514143034666, 0.0016610889296505551, 0.0011368472266886948, 0.0007961262452505667,
            0.0005690946863839898, 0.00030614428089411597, 0.00017363486963547047, 0.00010297434206503395,
            0.0000635683554544184, 0.00004052282782941391, 0.000026393482310234218, 0.000017654988054982955,
            0.000011974675632514434, 0.000008102772225539868, 0.000005518428000220004, 0.0000022305453590717437,
            9.371604172026577e-7, 4.137939308692811e-7, 1.788856927031901e-7, 2.925912055425016e-8,
            2.967378787133157e-9, 3.138788118306479e-10, 2.1154833440562015e-10, 2.0203883011049584e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5395719260766758, 0.5395719260766758, 0.5395714080422255, 0.5395641974564342, 0.5394901570275433,
            0.5393707256345399, 0.5377762145022894, 0.51948765018616, 0.49596603680017515, 0.47228427997432976,
            0.4463228158919577, 0.3337070620319716, 0.20432812814582313, 0.13781722983473918, 0.09949040302131504,
            0.07556227585619917, 0.02794297364195867, 0.014050025011843069, 0.008108337878695094, 0.005068271313866258,
            0.003338732169716163, 0.0022835195869769045, 0.0016066980933756433, 0.0011595917019494746,
            0.0008553489076849483, 0.0004840093896711567, 0.0002846954545141278, 0.00017295602771232992,
            0.00010872200769281449, 0.00007010851157561016, 0.00004612052291377002, 0.000030699057335148184,
            0.00002084383862833678, 0.000014416559090184128, 0.000010095273594572584, 0.000004335215809170888,
            0.000001971873177550839, 9.554975152792282e-7, 4.722232989929992e-7, 9.692873248479117e-8,
            2.1533231198134217e-8, 5.36928213001886e-9, 1.0876692879691063e-9, 5.88046389360386e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7716032675480995, 0.7716018987424988, 0.7715631735379187, 0.771398102496508, 0.7712999046936517,
            0.7711743890785462, 0.7684379576799992, 0.7575471470211803, 0.7424069905570106, 0.7232642850950926,
            0.7020855706365111, 0.5904106730221123, 0.4275694405810449, 0.32413156053933023, 0.2551785552244247,
            0.2074309177582739, 0.09615433488580094, 0.0558611417800747, 0.03619066671568095, 0.025052367777650183,
            0.01810866921333354, 0.013530113013818883, 0.010387123429407464, 0.00814321864625691, 0.006513312263076789,
            0.004365113597604043, 0.003056551898382613, 0.0022138000413295454, 0.0016533698406730798,
            0.001265355363695976, 0.000990998219166247, 0.0007952795345136517, 0.0006546610767307835,
            0.0005497320535044103, 0.0004679286385717748, 0.0003283823559775014, 0.0002410704910295166,
            0.0001824371006076131, 0.00014152749357388507, 0.00008979248170071554, 0.00006000779279058399,
            0.00004169268425446848, 0.000029866523835186953, 0.000021932716015804488,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7647170850692967, 0.7647167136066393, 0.7647094623055206, 0.7646692126870409, 0.7644034856470238,
            0.7641535817029949, 0.7613972858391831, 0.7479227217350733, 0.7260498444963973, 0.6994177161337638,
            0.6755244476319769, 0.5605976827725935, 0.39655461096920525, 0.2980916094279735, 0.23320622616121803,
            0.1884064432876252, 0.0843536428929483, 0.04826231136050428, 0.03104964727757145, 0.0213991014275014,
            0.015503744767148083, 0.011649622990420327, 0.008977158294214693, 0.007056853445969786,
            0.005633134570094482, 0.0037225890622427774, 0.0025704147582104883, 0.001848271165805255,
            0.0013861546908210988, 0.0010719831142113787, 0.0008542537733802027, 0.0006998112392538064,
            0.0005539953482881268, 0.0004396674936298883, 0.0003512370717859794, 0.0002115275971681797,
            0.00013337672511639113, 0.00008802808263965097, 0.00006143532344056313, 0.00003246617545401616,
            0.00001885032257894718, 0.000011738658971749416, 0.000007779503183757974, 0.000005361952815530202,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.1)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6549646731161112, 0.6549640496467537, 0.6549523060870955, 0.6549005328562949, 0.6547732775996008,
            0.6545399330375241, 0.6512525802523681, 0.6348222940033882, 0.6109808507760242, 0.584573658751062,
            0.5579233355349255, 0.44213859938727057, 0.29662627910914685, 0.21444697615656422, 0.16322869913447163,
            0.12893392698430675, 0.05438511242118755, 0.029883125518825562, 0.01863059108792249, 0.012520674053348611,
            0.008850887507525234, 0.006491540508799261, 0.004897835558328091, 0.0037798383850932995,
            0.0029716626992532403, 0.0019203270011739553, 0.0013001855647238548, 0.0009128839872644494,
            0.0006600714074048843, 0.0004890969737881698, 0.0003700350876385285, 0.00028505271341321153,
            0.00022309304987409728, 0.00017707928256588732, 0.00014233964604924354, 0.00008647993509015117,
            0.00005561552933828278, 0.00003745531552123976, 0.00002620619549931586, 0.000014054734218351429,
            0.000008271134921179073, 0.000005212915887771971, 0.000003460355626222957, 0.000002393226162993061,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5073494614680985, 0.5073494614680985, 0.507346575360565, 0.507332268432936, 0.50729253733281,
            0.5072294959067205, 0.5065822009668428, 0.5011472276060047, 0.48876643508901274, 0.4697149198281945,
            0.4503632535692546, 0.35268223914587, 0.22324549169721983, 0.154354699212236, 0.11272683910080224,
            0.08580155318852416, 0.03241911645029676, 0.016821054760416582, 0.009980567272178107, 0.0064122031860047906,
            0.004338213078140329, 0.003046438617790684, 0.0022021523882139625, 0.0016274276568961665,
            0.0012239868208575994, 0.0007212051326673125, 0.0004441632872612545, 0.0002831575038190264,
            0.0001859265670537419, 0.00012501316304732742, 0.00008575430862355028, 0.00006008751253216858,
            0.00004283198249514723, 0.00003095820166409909, 0.000022651059962317765, 0.000010791636814099663,
            0.000005443000797343951, 0.0000028204154941046164, 0.0000015113921698823084, 4.580858040845147e-7,
            1.3303525767769653e-7, 3.331403575224101e-8, 7.267623975750945e-9, 1.3597181158786498e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5395719260766758, 0.5395719260766758, 0.5395719169033135, 0.539571191196639, 0.5395658916927129,
            0.5395221410197887, 0.5388039710609837, 0.5314212440820435, 0.5126809692214002, 0.4949740784276429,
            0.475279733284854, 0.3763170568669406, 0.24353607186505316, 0.17062749331560317, 0.1261407030432452,
            0.09737292734290344, 0.03843268495065459, 0.020283571471081466, 0.012266743530200847, 0.00803502664110946,
            0.005541869756480453, 0.0039592544170566485, 0.0029073747354400954, 0.002182014814503316,
            0.001667832054317935, 0.001017150560530616, 0.0006483377567744121, 0.0004272027278256396,
            0.00028956055234947314, 0.00020037504714644273, 0.00014080471779954302, 0.00010066083426938662,
            0.00007297379741378348, 0.0000536191263375572, 0.000039848940115877793, 0.000019828971470903234,
            0.000010347458114519287, 0.000005618814530125328, 0.0000031345424524317217, 0.0000010486726850374987,
            3.6791102830946443e-7, 1.2569603613510338e-7, 4.113425222202264e-8, 1.2538607886068657e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7716032805313504, 0.7716031673915921, 0.7715941801867224, 0.7715388760852665, 0.7714472915565678,
            0.7713078309650793, 0.7703550003135078, 0.7626051869022497, 0.7537405583742456, 0.7407384846267642,
            0.7257852772680804, 0.6356148958315477, 0.480325200622333, 0.374587827695259, 0.30037490346289597,
            0.24687391072281126, 0.11800848196817391, 0.07009943655961992, 0.04659778767647511, 0.033107966222869316,
            0.024579559150136578, 0.01882959807264084, 0.014792056652116891, 0.011847168512069609, 0.00964996340430969,
            0.006660255016521295, 0.004782710222125869, 0.003543981611701702, 0.0026855189815446615,
            0.0020731547802926003, 0.0016276355963534546, 0.0012964022151103994, 0.0010445901192067046,
            0.0008517498273682555, 0.0006996548661003057, 0.0004505094142001032, 0.000313563127879743,
            0.0002309989208773956, 0.00017645665801797303, 0.00011001553048759238, 0.00007271198845590621,
            0.00005002691300071138, 0.00003549950955948, 0.000025832082855004046,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7647170850692967, 0.7647170850692967, 0.7647157177931388, 0.7647096593794083, 0.7646661083561227,
            0.7645292399292968, 0.7633201554611656, 0.7544938832907635, 0.7423032161232027, 0.7239539401651487,
            0.7021950674424856, 0.6055242718910995, 0.44868472324471187, 0.3449353637463356, 0.274824329951802,
            0.22474352281358692, 0.10488746426078664, 0.06121124900056335, 0.04025756116961916, 0.028391081239352075,
            0.021002350545388983, 0.01610111964168284, 0.0126935836881656, 0.010213533482339957, 0.008346208790902766,
            0.0057779570490304145, 0.004145029588198879, 0.0030538383983007974, 0.0023026096949213524,
            0.0017691270956346056, 0.0013823349504865248, 0.0010945594015551118, 0.0008826604358631843,
            0.0007206694156018485, 0.0005969376183625784, 0.0003959846004800215, 0.0002615084540283982,
            0.00017202821349690178, 0.00011678029474617226, 0.00005804831379083056, 0.00003134175855360941,
            0.000018082301645791608, 0.000011247690508420227, 0.000007410405360563921,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.2)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6549646952658631, 0.6549646110013777, 0.6549619236318993, 0.6549470158125035, 0.6549047581410242,
            0.6548194092766486, 0.6533487234267552, 0.6440570346266519, 0.6282330070714686, 0.6088885765404027,
            0.5880074872652469, 0.48672751132543784, 0.3427561652527954, 0.25481578037892627, 0.197601022277737,
            0.15820576591560534, 0.06943078007672569, 0.03928089002649844, 0.025197463651492967, 0.017417050489582992,
            0.012652251019672503, 0.009524070363388986, 0.00736480928329311, 0.005816769983898108, 0.004673437369004667,
            0.0031410971695873435, 0.0022007689016380555, 0.0015917041857193275, 0.0011806647849248344,
            0.000894120147366087, 0.0006890229277202955, 0.000538959337694654, 0.0004270958052588351,
            0.00034235482363025434, 0.00027724847960690504, 0.00017004595574187235, 0.00010921286359956386,
            0.00007285372634779098, 0.000050179899325817296, 0.00002575968126750246, 0.000014388389056039806,
            0.00000859357566706893, 0.00000541745964523547, 0.0000035683263115671924,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5073494614680985, 0.5073494525938809, 0.5073481539458646, 0.5073383294403062, 0.5072990374455532,
            0.5072260198850238, 0.506534727323343, 0.5005291557619125, 0.48629776413236814, 0.46672737412703574,
            0.4463962694403897, 0.34310721787701215, 0.21170199984275506, 0.1442595270714642, 0.10463264376782999,
            0.07950687182522377, 0.030396293177256853, 0.016040620543460834, 0.009729434906043642, 0.006376563402491618,
            0.004379556858145177, 0.003119347548279995, 0.0022834258319567404, 0.001711013328120396,
            0.0013024570136473607, 0.0007904674493686437, 0.0005019029602995403, 0.000329711095588339,
            0.0002229392348060336, 0.00015449534992612827, 0.00010950733522017251, 0.00007898054168543242,
            0.000057929057178466315, 0.00004310010336728391, 0.000032476007713231636, 0.00001660670158432287,
            0.000008896709587937426, 0.000005003441298824772, 0.0000029078394520645565, 0.0000010501418247598063,
            4.019785064792256e-7, 1.6175232686599372e-7, 5.560652260907689e-8, 1.6517036879417475e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5395719260766758, 0.5395719260766758, 0.5395719177828884, 0.5395711283506821, 0.5395654484483261,
            0.5395197870747195, 0.5387246401131023, 0.5298666914872106, 0.511206119154267, 0.49201056578740765,
            0.471034715918788, 0.36619226639567926, 0.23111877435930325, 0.1596975516360973, 0.11716117606652615,
            0.09010045492593811, 0.035510682537708955, 0.018982612347809215, 0.011626952538762364, 0.007707209473100311,
            0.005372220049715231, 0.0038751240254420866, 0.002875178164770366, 0.0021787269192274036,
            0.0016808960206636796, 0.0010471523254446818, 0.000683732168162663, 0.00046273534143404784,
            0.00032265669574209344, 0.00023002418940108244, 0.0001673691709232854, 0.00012391936049806234,
            0.00009297704204054998, 0.00007047714214673698, 0.00005371221265990151, 0.0000284499154847752,
            0.000015847904423718686, 0.000009176552040900595, 0.000005526090677548154, 0.0000021315255463765087,
            9.113409183601046e-7, 4.020117533309886e-7, 1.7285699168783934e-7, 7.351804883628516e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7716032805313504, 0.7716032490005981, 0.771596526981325, 0.7715701399622297, 0.7715071034340426,
            0.7713856534473859, 0.7701352965893932, 0.7619836536665527, 0.7520270326434345, 0.7373122665686166,
            0.7206596503840008, 0.6237066263125194, 0.45941474182091274, 0.350404362621832, 0.2759627101958928,
            0.22388099239447892, 0.10305190742230905, 0.06046327478725849, 0.040018049927720026, 0.028486397462923262,
            0.02127246835768562, 0.016447119450916734, 0.013030556015854114, 0.010540041228914833, 0.008659194322907219,
            0.006064332929225993, 0.004413472409632533, 0.0032986629910629712, 0.0025175015523928386,
            0.001956883669871171, 0.001542108756597305, 0.0012301627642081028, 0.0009916057461203192,
            0.0008069505582692589, 0.0006616730644718837, 0.000418471820114622, 0.00027563886280751785,
            0.00018888111579900378, 0.0001359200235693101, 0.00007866479515951427, 0.00004985691138716887,
            0.000033272316507431804, 0.00002301782152684058, 0.000016381825735048864,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7647170850692967, 0.7647170808310961, 0.7647164717030693, 0.7647077801961919, 0.7646670313046866,
            0.7645239626146709, 0.7633001981044277, 0.754012663838484, 0.7406761150400826, 0.7204731410158185,
            0.6979758875835265, 0.5931198240738764, 0.4287051684032648, 0.32284849082859873, 0.2527860943669343,
            0.20395713529714046, 0.09127175309036627, 0.05267765375918405, 0.03458055205202623, 0.024542517792168563,
            0.01830624045909555, 0.014160188114125694, 0.011240579346743792, 0.009102674071600519, 0.007479650017957701,
            0.0052431266546116816, 0.003809355544630791, 0.0028420743042482824, 0.002162872668765578,
            0.0016726519709996228, 0.0013133967063631248, 0.0010445587576077297, 0.0008404306556741186,
            0.0006817704924198309, 0.0005580791748243095, 0.00035267144105796113, 0.0002354917666654822,
            0.00016461437731346513, 0.00011982667144422975, 0.00005897134847553776, 0.00003130959612738802,
            0.00001755822037316416, 0.000010349455934045047, 0.0000063963891993496905,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.3)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6549646961522514, 0.6549646759863831, 0.6549629656811489, 0.6549502313548032, 0.6549100408124738,
            0.6548245644241982, 0.6532568384891161, 0.6431683774438323, 0.6260783282040361, 0.6053277021782035,
            0.583049346951124, 0.4761639810163642, 0.3274341563655921, 0.23905276995212643, 0.18285689618431267,
            0.1448704760164472, 0.06188507777301419, 0.0347513771060678, 0.022298908735805142, 0.01547226930141387,
            0.011300188428436981, 0.00855762454272433, 0.006658313972697613, 0.0052906312120957946,
            0.004275423523233798, 0.002904325601677378, 0.002053906397003896, 0.0014974840128514973,
            0.0011184807354182682, 0.0008520464546367819, 0.0006599085586031132, 0.0005183830955889711,
            0.0004122504180203499, 0.00033141802944983796, 0.00026901769891765257, 0.00016558234455719785,
            0.00010640845248358633, 0.00007083459382035169, 0.000048566848942947525, 0.0000245477203171514,
            0.000013405086410987389, 0.000007778306781293578, 0.00000474172680193208, 0.0000030135107062235876,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5073494614680985, 0.5073494575703572, 0.5073474298486005, 0.5073268790966373, 0.5072588974260147,
            0.5072089677026993, 0.5060440492987505, 0.49785576137524357, 0.4799446777246586, 0.4579157952222722,
            0.4346299947802359, 0.3240732350792634, 0.19279166759800445, 0.1278729912062493, 0.09150643923179078,
            0.06899440805789715, 0.025757182450314824, 0.013463697721432043, 0.00811609130582209, 0.005293179150329303,
            0.003632815662622686, 0.0025906677165635867, 0.0018994986842958185, 0.0014233671270859572,
            0.001087735019901516, 0.0006636924665596144, 0.00042481243635102367, 0.0002818560840831799,
            0.00019265541450016213, 0.00013512773188451756, 0.00009681430501410997, 0.0000706291175148108,
            0.00005218469993783032, 0.00003912299953652459, 0.000029660994252956567, 0.000015606405757923594,
            0.000008671097866215032, 0.00000500727921768734, 0.0000029882336644543614, 0.0000011666969247995011,
            4.928044873656569e-7, 1.9930988508853737e-7, 8.160751363216416e-8, 2.8903844954778905e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5395719260766758, 0.5395719260766758, 0.5395718431388002, 0.5395695094825375, 0.5395552050734681,
            0.5394816108792035, 0.5381401438613587, 0.525483088092899, 0.5039422623611968, 0.48147666240565173,
            0.4581855581849629, 0.3462765082409655, 0.21015165582280845, 0.1414389383572504, 0.10208654861710721,
            0.07752046181662195, 0.029598479932965018, 0.015652402512946605, 0.009556242987520034, 0.006321485154206336,
            0.004399867918018071, 0.003174594302863255, 0.0023552156017123908, 0.0017867547638823796,
            0.0013804012399912706, 0.0008613737820110751, 0.0005634534858763264, 0.0003824118011410682,
            0.0002674944021030821, 0.00019186013361304117, 0.00014058802029482022, 0.00010488185204302706,
            0.00007938563212233522, 0.00006102499375920857, 0.000047413739577874076, 0.000026348207508752676,
            0.000015350574425365174, 0.000009301762394199327, 0.000005762433020970921, 0.000002330087902349213,
            0.0000010161220673724686, 4.716061099419022e-7, 2.1424916525543252e-7, 9.48917844064696e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7716032805313504, 0.7716032434363477, 0.771598123396064, 0.7715666643404896, 0.7714422696806796,
            0.771281019178851, 0.7693579852042176, 0.7586207132328149, 0.7451917056845283, 0.7260372648298407,
            0.7046675603504554, 0.5884659000229913, 0.4129434037940544, 0.3043310583371058, 0.2346245074644072,
            0.18748688787251355, 0.0829283912148575, 0.04796109980678817, 0.03167639521116194, 0.022556832694578432,
            0.016905426178858568, 0.0131080884750017, 0.010420832203797236, 0.008451897138949846, 0.006969495228784231,
            0.004902920720144637, 0.0035782388897225435, 0.00268549997231691, 0.0020540368554186803,
            0.001595696353304073, 0.0012574856895169236, 0.0010022885937136237, 0.0008082114122389929,
            0.0006570438522528584, 0.0005388397024407612, 0.00033815411765181447, 0.00021957915543585607,
            0.00014722866646347087, 0.00010181717939309618, 0.00005204120142133662, 0.000028999691071840417,
            0.000017722461949195256, 0.000011675127248121, 0.000008015405231707587,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7647170850692967, 0.7647170832077907, 0.7647154870321596, 0.7647008398511619, 0.7646561353426706,
            0.7644171453950374, 0.7626846698045162, 0.7499092266915377, 0.7309456389134522, 0.7060636953939452,
            0.6801841839217371, 0.5590782341285256, 0.38466992265321265, 0.2803907211110191, 0.2144994258453176,
            0.16985616082452049, 0.07332595403392657, 0.04201822359905476, 0.02758926023833448, 0.019603937393254806,
            0.014668739524605574, 0.011381311490087226, 0.009053645156279447, 0.007343293559232839,
            0.006046091603186588, 0.004247271834776413, 0.0030903251634301623, 0.002308407258615886,
            0.001759482291479659, 0.0013650540547453915, 0.0010730949029690029, 0.0008536675712059018,
            0.000686209175090885, 0.0005573158812033634, 0.0004561103223487229, 0.00028485869701547044,
            0.00018477033067442107, 0.00012368397947740768, 0.00008522797777014309, 0.00004441400238141451,
            0.00002600429319197858, 0.000014818709554110428, 0.000008623377366608487, 0.000005180991173365725,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.4)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6549646962016706, 0.6549646840358613, 0.6549631635201694, 0.6549486114014504, 0.6548993697907373,
            0.6547911238220294, 0.6527420341069624, 0.6398288945329893, 0.6187846112062749, 0.5940089898215343,
            0.5680251492423752, 0.44888781705650105, 0.2943087150232237, 0.2082439304829448, 0.15589186638000965,
            0.12161493738118498, 0.05013910980028066, 0.027871840438282572, 0.017834364323896238, 0.012370957317111707,
            0.009040364242106797, 0.0068517993975196366, 0.005335317893375127, 0.004242223563101855,
            0.0034299386123967748, 0.002331294062940845, 0.0016487092978530289, 0.0012015901903433095,
            0.0008968400746065278, 0.0006825472674789419, 0.0005280158312834356, 0.000414219040104215,
            0.0003289167775627901, 0.0002639875606719835, 0.00021390076138328806, 0.00013099041458821648,
            0.00008367914241384344, 0.000055322560376906556, 0.00003763959260785435, 0.000018683446301246574,
            0.000009984555599584526, 0.00000565081070562928, 0.000003350217041871853, 0.000002064238771321027,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5073494614680985, 0.5073494575703572, 0.5073406973967929, 0.5073013224513454, 0.5072163495597626,
            0.507132090803569, 0.5050182276685217, 0.49140178356456826, 0.46614836152301875, 0.4393974235280595,
            0.4109074558154174, 0.29035074172060676, 0.16346001758783196, 0.10539261703879106, 0.07432427449519453,
            0.05562589403061299, 0.020550705249657235, 0.010696463130024175, 0.006427518382512337, 0.004180802888838968,
            0.0028625769291577147, 0.0020329837403116, 0.0014860570932061752, 0.0011107295357695795,
            0.0008453397145842201, 0.000512607182468406, 0.0003260824649445829, 0.00021545836267368825,
            0.00014654888153814698, 0.00010246374337955182, 0.00007320387397475683, 0.00005327751484075891,
            0.000039432021624778456, 0.00002963437481264896, 0.00002250125851034456, 0.000011860492531648292,
            0.000006567663134149969, 0.0000037979474127998025, 0.0000022750788565919583, 8.823603264845432e-7,
            3.771449903537045e-7, 1.620834828378535e-7, 6.923779132473705e-8, 2.5708212679498773e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5395719260766758, 0.5395719260766758, 0.5395713732027442, 0.5395615946055492, 0.5394836143696222,
            0.5392627670172696, 0.5368683936997302, 0.5165528048582727, 0.4903511700195623, 0.462402433848951,
            0.4338399769804909, 0.31147082378677565, 0.17930121572780058, 0.1173775833785622, 0.0834564997545643,
            0.0627781074716578, 0.023610470787390665, 0.012445875618890115, 0.007573173792136418, 0.004987153015645991,
            0.0034592443969327074, 0.00248961719639299, 0.0018434649715888386, 0.0013949711632484261,
            0.0010754343774472947, 0.0006688765142947599, 0.0004355920618602978, 0.0002943908382647532,
            0.00020505404466271827, 0.0001464003402938284, 0.00010687361453139648, 0.00007949446430013978,
            0.00006009040543137849, 0.00004604109869281825, 0.00003572911541722534, 0.00001984027574792471,
            0.000011579881923142385, 0.0000070133077392406705, 0.000004369152235229653, 0.0000018451266432092907,
            8.431546614386252e-7, 4.0549981925741136e-7, 1.834458974783577e-7, 8.014814665274655e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7716032805313504, 0.7716032100508452, 0.7715897734924081, 0.7714805574267752, 0.771283177962461,
            0.7712126436015097, 0.7676208766182949, 0.753032670821044, 0.7326704718657906, 0.7070453393563314,
            0.6796229695322464, 0.5439621670779757, 0.3633986005078355, 0.2603134402806281, 0.19733943151451533,
            0.15568175473507528, 0.06692340143028129, 0.03847603946535934, 0.0253688007079018, 0.018091567419281356,
            0.013565655278520333, 0.010535240183552138, 0.008393460351339432, 0.006824745059216079,
            0.005632364036464291, 0.00397407985814786, 0.0029053872040849943, 0.0021743915714776585,
            0.0016633854453540619, 0.0012931440140578473, 0.0010184702113824843, 0.0008126263618486526,
            0.0006538101952188468, 0.0005315898990124403, 0.000435486991452043, 0.00027224473746336695,
            0.00017671921261033983, 0.00011808236028568404, 0.00008093463501984771, 0.00004058060315452701,
            0.00002166817782372169, 0.000012214402081709196, 0.000007178463358431699, 0.000004382296099303179,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7647170850692967, 0.7647170832077907, 0.7647122869631988, 0.7646763656588712, 0.7644993729410681,
            0.764118914001482, 0.7609062771493809, 0.7426686330758973, 0.7150836372194973, 0.6842859070818079,
            0.6524099622392464, 0.515740174713082, 0.33714049158417003, 0.23875463561774216, 0.1790887713991115,
            0.14013587152662935, 0.05918592761274761, 0.03377549217574506, 0.022194799591224477, 0.015817862881743514,
            0.011855017051561629, 0.00919374676732275, 0.007312287000661808, 0.005926157415222541, 0.00487947499056699,
            0.0034258273646734735, 0.0024866642893932754, 0.0018534661647841128, 0.0014102385682247568,
            0.001091230047114542, 0.000856532125112545, 0.0006804182431550374, 0.000546299969025778,
            0.00044277564403664803, 0.00036174240532221564, 0.00022519260556695626, 0.00014542095431723423,
            0.00009669935401044653, 0.00006605017808707779, 0.00003279261215912175, 0.000017500542427231683,
            0.000009877957319088104, 0.000005966744354377708, 0.000003843664935691321,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.5)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6549646962016706, 0.6549646770414653, 0.6549598852356493, 0.6549222105561393, 0.6548086769515487,
            0.6545778049442209, 0.6508889059402054, 0.6315413822434182, 0.6034404960568233, 0.5725089835302793,
            0.5415063481371883, 0.40989576483489065, 0.25537367402533, 0.17561309552009388, 0.129175878170041,
            0.09962767068954154, 0.04020961392760588, 0.022268633943787284, 0.014240724222640954, 0.009876272133915379,
            0.007214365882655185, 0.005463958999671225, 0.004250429094335978, 0.0033755387683948148,
            0.0027254968725272674, 0.0018469299174675863, 0.0013020098147436792, 0.0009458653055603968,
            0.0007037291046324193, 0.0005339174783627988, 0.0004117901250736742, 0.0003220962989147655,
            0.0002550382809707977, 0.00020412481962642137, 0.00016494468562840405, 0.00010034297940588924,
            0.00006368504544846752, 0.000041831172043580133, 0.00002827308230850414, 0.000013840308083738374,
            0.0000072833094622864174, 0.000004054172965103501, 0.0000023596452544855543, 0.0000014245215763448633,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5073494614680985, 0.5073484863786227, 0.5073052144750188, 0.5071783707082052, 0.506877008190207,
            0.5062456169105032, 0.49992128631294475, 0.4672917937050402, 0.42771943323652817, 0.3878778705201148,
            0.35088878574192933, 0.2226710061452665, 0.11306735448191607, 0.07029775065452798, 0.04869068425935898,
            0.03605142601326568, 0.013090859758436437, 0.0066964516430939, 0.003944318520280545, 0.002512363155892373,
            0.0016876184694446415, 0.0011771398413181746, 0.0008456669720109735, 0.0006223592281719911,
            0.0004673037258499776, 0.0002758118720438237, 0.00017150706483408506, 0.00011087737899565324,
            0.00007405294491817074, 0.000050807401010014627, 0.000035634829631814383, 0.000025436218838391678,
            0.000018516202158860504, 0.000013701125913534612, 0.000010274134174854978, 0.000005266953112471478,
            0.0000028596760593792577, 0.000001618133414652804, 9.466712994991225e-7, 3.4381397428409477e-7,
            1.355200200144323e-7, 4.665723707386604e-8, 1.2921135993554672e-8, 4.811653742109456e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5395719260766758, 0.5395719177828884, 0.53956108540068, 0.5393483133633031, 0.5390165394091415,
            0.5383451168837121, 0.5289464752027194, 0.49099089666649776, 0.450229645210581, 0.4102748842303268,
            0.3728963961065216, 0.24023631128083064, 0.12568972918612753, 0.07881467173675927, 0.05489019273451181,
            0.04089271036833298, 0.015117640147592858, 0.007841569958404446, 0.004683932986039979, 0.003027760232501543,
            0.002060199296763684, 0.0014559627048808966, 0.0010598855315577008, 0.0007893585250682031,
            0.0005994812997954491, 0.0003628071574222276, 0.00023075428438163403, 0.00015271859662596425,
            0.00010428421379780279, 0.00007306170977171563, 0.000052358062387656865, 0.00003829228899066599,
            0.00002845257658234652, 0.00002147049417613328, 0.000016420881059256567, 0.000008822710510079809,
            0.000005018137907465735, 0.0000029880065280197257, 0.0000018509895160915234, 7.506558628492886e-7,
            3.022866535179822e-7, 1.2901155095454353e-7, 5.971695604678473e-8, 2.5563878125467454e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7716032805313504, 0.7716008006136379, 0.7714744151127219, 0.7712482270496469, 0.7707955081957355,
            0.7697424210700794, 0.759930101950457, 0.7315340075694978, 0.6924423437721997, 0.6499639442945286,
            0.6085758944724011, 0.44665842557563584, 0.2704029972397306, 0.1848325850538749, 0.13619152066509976,
            0.10555946948642032, 0.04410696022369908, 0.025225111707926542, 0.016654899468596992, 0.011888665873384264,
            0.008930262202313551, 0.006928306871966077, 0.005510711725052786, 0.004463180601773281,
            0.0036674894256685424, 0.002565213309092124, 0.0018512509529775266, 0.0013724925982883938,
            0.0010381357121010705, 0.0007993341506984176, 0.0006233707479944978, 0.0004916574826331376,
            0.00039172245772460345, 0.0003154637131111528, 0.0002560849073745154, 0.0001571758936153188,
            0.00010029495502981468, 0.00006605764069716925, 0.00004465267583117877, 0.00002175146635944538,
            0.00001132871745082888, 0.000006243241766763318, 0.0000035924421454547356, 0.000002146165751214769,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7647170850692967, 0.7647164103950674, 0.7646704529045376, 0.7642318845320752, 0.7636266957516437,
            0.7629547199595608, 0.7516553384672446, 0.7150623441669584, 0.669443900473964, 0.6241488219849536,
            0.5827212718884215, 0.419584383779559, 0.2490191243595616, 0.16796863697886713, 0.12267805855319253,
            0.09452235493876185, 0.0390325988213367, 0.022284031611441008, 0.014647501467808676, 0.010406421065591694,
            0.007760778413088397, 0.005980744417510788, 0.0047291678690487265, 0.0038080371893429305,
            0.0031105795547727343, 0.0021477044408590115, 0.0015394070647339285, 0.0011324687482874775,
            0.0008512430953615339, 0.0006505706403081378, 0.0005053605307934467, 0.0003977804833778459,
            0.0003164838684627534, 0.00025398434506676657, 0.00020546639811088886, 0.00012508483072442496,
            0.00007931571574745877, 0.00005182913740973008, 0.00003477067007053721, 0.000016793192304720755,
            0.000008711093302445533, 0.000004774257832268054, 0.000002716320344998976, 0.0000015988295286602806,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(0.7)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6549646961522514, 0.6549639372018877, 0.6549178385974215, 0.6546897305119879, 0.6541485211154023,
            0.6532177862456824, 0.6426498218727028, 0.6038283999218155, 0.5585526114449512, 0.5146662270840934,
            0.47435408811632873, 0.3270746612930938, 0.18520309542632304, 0.1216065302057788, 0.08720656374342751,
            0.0662609648247478, 0.026136143594709272, 0.014405179962489823, 0.009168429033909728, 0.006318638717171854,
            0.0045811346811567575, 0.0034411629345410984, 0.002653905159550959, 0.0020892211238764677,
            0.001672156722816533, 0.0011138164272262046, 0.0007723910377359623, 0.0005524458325567942,
            0.00040503083454356334, 0.0003030720866402804, 0.0002307125711379143, 0.00017824183827696037,
            0.0001394848068386005, 0.00011039984599247637, 0.00008826403313085397, 0.00005238123127409752,
            0.00003250133428910716, 0.00002090466649052292, 0.000013853165869269216, 0.000006532372568039577,
            0.0000033165861833305814, 0.0000017813942620863897, 0.0000010004102859140321, 5.821757412953409e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5073492989531858, 0.5073298115055156, 0.5071124971932937, 0.5062305968572054, 0.5048385092843913,
            0.5025931876374238, 0.48172464154693895, 0.4173692490255673, 0.35720423999766465, 0.3072164095784821,
            0.26663878701985855, 0.14872545019922123, 0.06983793061577238, 0.042085508836687345, 0.028739061787091957,
            0.02111761719874216, 0.0073930608125457875, 0.0036137198607704307, 0.002034489188144533,
            0.0012412078424738483, 0.0008009442375874978, 0.0005384732740302616, 0.00037389451780867907,
            0.0002667479065307283, 0.00019435405359385477, 0.00010910424954571346, 0.00006479051013037422,
            0.00004019310161521275, 0.000025841709185104002, 0.00001709129467206222, 0.00001158722072826522,
            0.000008012728510825164, 0.000005641006113055255, 0.000004036842445572211, 0.000002925527667623074,
            0.0000013723093250835202, 6.741345711929291e-7, 3.414494560821717e-7, 1.7737003870889904e-7,
            5.1420964042314665e-8, 1.9117900262877185e-8, 6.33147427169556e-9, 1.5456947943803812e-9,
            8.009608531978074e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5395719260766758, 0.5395683330274992, 0.5392483289302417, 0.5382346680010559, 0.5365470003180961,
            0.5338323827541851, 0.5043954008623381, 0.4394696013061871, 0.3789941710353654, 0.327734749415008,
            0.28531929544617307, 0.16267631559687648, 0.07780663583884122, 0.047471042837247214, 0.03269316125639312,
            0.024165066557669562, 0.008644100474725015, 0.004309443534673842, 0.00246993956107454,
            0.0015335836438523072, 0.001004813655234055, 0.0006850948985399332, 0.0004824605953758394,
            0.0003483823029181301, 0.0002569463012635805, 0.00014698742942697503, 0.00008881261007748582,
            0.000056054390263288404, 0.0000366827462808196, 0.00002473731739395786, 0.00001710580161730677,
            0.000012091076649190192, 0.000008704221524625238, 0.000006363430370913278, 0.000004707944644853315,
            0.0000023251359795519035, 0.0000012165213121789384, 6.543332825915992e-7, 3.5599338005633603e-7,
            1.0901589950822173e-7, 3.979585294633381e-8, 1.4314113439047221e-8, 5.4201921899084056e-9,
            1.4768192336498153e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7716029186271198, 0.7715570803597511, 0.7711930885300176, 0.7698018092769371, 0.7671795310858208,
            0.763795414956165, 0.7426828655622925, 0.6788487514574006, 0.6105465661973533, 0.5512926257746058,
            0.4999534884312279, 0.3272613731632152, 0.1799169417422165, 0.11840926756395899, 0.08565831722619341,
            0.06567015892599772, 0.027036557241807577, 0.015568903869972754, 0.01030972998317838, 0.007352936248911046,
            0.00549337296167505, 0.004240577476407928, 0.0033540687012392593, 0.002703789482442774,
            0.0022042614346019377, 0.001523081704140742, 0.0010932504855862186, 0.000805218275317773,
            0.000604067108011187, 0.0004602392249217834, 0.0003556793602168091, 0.0002781762135644209,
            0.00021990828686834266, 0.00017536529497069462, 0.00014111606131690682, 0.00008469160952360472,
            0.00005284041473501627, 0.000034080630657932126, 0.00002258055311715729, 0.000010580412876931053,
            0.000005310894518717468, 0.0000028100700409439684, 0.0000015464129398965276, 8.82275104641117e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7647169922036323, 0.7646947808610989, 0.7640590786038295, 0.7628238128118148, 0.7603935763342778,
            0.7564712198482241, 0.7290794175758865, 0.6551505946999916, 0.5862944239705069, 0.5255911282000925,
            0.47333269403843875, 0.3040771269385591, 0.16390170667440504, 0.10645253720791684, 0.07644444019415648,
            0.058393222624909175, 0.023993685355334263, 0.013676087600298401, 0.008936927415604012,
            0.006286866364018667, 0.00463663853240935, 0.0035293601799316543, 0.0027545607098977127,
            0.002192273355029512, 0.0017683162089455415, 0.001189409431717976, 0.0008292274563917672,
            0.0005957282977807818, 0.00043713147204903425, 0.00032705574571246105, 0.0002490983325044261,
            0.000192545362434491, 0.0001507242021844085, 0.00011942948312488597, 0.00009556425200791281,
            0.000056946118270468824, 0.00003540089139737051, 0.00002283626770259425, 0.000015118081940429878,
            0.000007107081691148551, 0.0000035964879648655162, 0.0000019188331336073006, 0.0000010573076132223184,
            5.912836481450037e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.0)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6549645613723584, 0.6549452274860083, 0.6545357387385501, 0.6531747551351934, 0.6506693847966981,
            0.6470838652456599, 0.6184864866862315, 0.5460994738057486, 0.47933127649564494, 0.4230028363800201,
            0.376106760242094, 0.23123230874588716, 0.11908535955362771, 0.07537687570164504, 0.053155509881149324,
            0.04005118584926729, 0.015600327714558406, 0.008506428130946833, 0.00533548244679884, 0.0036169053549314034,
            0.0025781088732485273, 0.001904285470361024, 0.0014449000036654055, 0.001119824050421152,
            0.0008829985998693193, 0.0005720459948379692, 0.0003868004543897212, 0.0002703495433685254,
            0.00019405448233596382, 0.00014238803734071471, 0.00010643695176782404, 0.00008084053934120058,
            0.00006225675017182689, 0.00004853366004911723, 0.00003824804713354786, 0.000021957732130435097,
            0.000013219728609260987, 0.000008267648797109074, 0.000005334854154781122, 0.000002392642898255321,
            0.0000011585894400829097, 5.924835103743296e-7, 3.1723878032113954e-7, 1.7658212709103232e-7,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5073276148205585, 0.5071060227396504, 0.5049105181603444, 0.49979815455176274, 0.4915919618688949,
            0.48005842055660725, 0.4147745767943167, 0.30438731877821473, 0.23383962225492622, 0.1875005468825924,
            0.15512783780584094, 0.07728422511475995, 0.0340776631994747, 0.020100453017387495, 0.01349254335940205,
            0.009734205971441194, 0.00304282141350583, 0.0013189725348526063, 0.0006625488861483352,
            0.0003643926937832326, 0.00021409704677400544, 0.00013215182458888643, 0.00008495974219186825,
            0.000056417732349871405, 0.0000384684476600183, 0.00001907147137282682, 0.000010113637732510564,
            0.000005643663385783796, 0.00000328329886225827, 0.0000019658966306375794, 0.000001205896321754054,
            7.5345830215616e-7, 4.789709199896043e-7, 3.0747262520058146e-7, 1.9905459383769336e-7,
            6.458777828312707e-8, 2.267671091696648e-8, 9.133940048755562e-9, 3.848571239473131e-9,
            8.270218954109509e-10, 2.125937204056072e-10, 5.156663984706711e-11, 5.156663984706711e-11,
            4.050149104983802e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5395683062921248, 0.539267403463525, 0.5365757575842532, 0.5294065719584728, 0.5172840118182161,
            0.5038466767891953, 0.43544010317731585, 0.32605984113465886, 0.25454595348055903, 0.20531578329172306,
            0.17014526324887344, 0.08580457313686005, 0.03845405133912538, 0.022925451781127926, 0.015507509556603603,
            0.011268745032114861, 0.0036352947242438813, 0.00161918794214746, 0.0008342297281343011,
            0.000470932777871966, 0.00028334128239666757, 0.00017906236366851166, 0.00011749074906545363,
            0.00007953169135402204, 0.000055210379210506815, 0.00002831708399141716, 0.000015494697673841856,
            0.00000888103976578046, 0.000005282469097495749, 0.0000032524574955390114, 0.000002063827306764531,
            0.0000013437502504011539, 8.876246395161118e-7, 5.883483601238703e-7, 3.924051470779243e-7,
            1.4837448590809641e-7, 5.7411002285512325e-8, 2.5050221674760564e-8, 1.0835609631776322e-8,
            2.4661730435582285e-9, 4.5399439763116334e-10, 2.0203883011049584e-10, 6.221523296545683e-11,
            5.156663984706711e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7715686734392975, 0.770974114965766, 0.7666646201368041, 0.7602702792967847, 0.7527299244453872,
            0.7415465975870272, 0.6754225318053405, 0.5547680390168793, 0.4639530354160107, 0.3936877795268192,
            0.3395532387507936, 0.19628294123845436, 0.09996141361421683, 0.06392198133902136, 0.04533991107643176,
            0.034346109774382606, 0.013984232475018768, 0.007934524938891853, 0.0051366876125160665,
            0.003574359331849542, 0.0026081415058344425, 0.001958984168753548, 0.0015084940557615824,
            0.001183849181703931, 0.0009414571193241932, 0.0006167786814136963, 0.0004168923493183747,
            0.0002892661255511389, 0.00020543360838542188, 0.00014866786997392033, 0.00010921547639191314,
            0.00008154596952465182, 0.00006179160361186445, 0.00004747925277932325, 0.000036725260969966806,
            0.000020163263916926182, 0.000011711842252433252, 0.000007047641297225227, 0.000004434784859436991,
            0.0000018805939870913946, 8.217907622176668e-7, 3.715984633334557e-7, 1.648630088002534e-7,
            7.150848169198294e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7646910164098568, 0.764028222625542, 0.7599200860456781, 0.7519759184418605, 0.7406854231250274,
            0.7281912237496208, 0.6576564570939045, 0.5297441665859852, 0.4369371184363432, 0.3692025928093354,
            0.3180619930254518, 0.1811282891800964, 0.08977128004695085, 0.05646799414125991, 0.03995949238840539,
            0.030338411511011184, 0.01221804321507733, 0.006780733687346317, 0.004293670046598003,
            0.0029180817853804372, 0.0020831052857163104, 0.001529036355097816, 0.0011542031641492664,
            0.0008849610905943099, 0.0006914698241245609, 0.0004407441448004797, 0.0002939043232007065,
            0.00020339714998854637, 0.00014452065239927873, 0.000104416804532279, 0.00007691488182852488,
            0.000057634947505137735, 0.0000438849412139371, 0.00003383735572796273, 0.000026436965509928666,
            0.000014706589209423727, 0.000008463267704921057, 0.000005077686708642485, 0.0000031463295060186406,
            0.0000013070027873484147, 6.181769549584715e-7, 2.734615038108766e-7, 1.1655042830849283e-7,
            5.2423437035820086e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(1.5)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6549378058154235, 0.6544369842857505, 0.6500660448363179, 0.6413290632515578, 0.6296600666450521,
            0.616287596209113, 0.5439763473133787, 0.4246557923619728, 0.34221149111743915, 0.2838157334647325,
            0.24079750039896242, 0.13070613534446887, 0.062192798161846224, 0.038335450872086, 0.0266850073434441,
            0.01993149524380587, 0.007461193217199678, 0.0038815106640195133, 0.002319219637112859,
            0.0015001535432576998, 0.001022945787758829, 0.0007247804383392738, 0.0005288558135077618,
            0.00039507595501837584, 0.00030090340347626405, 0.00018285996026816303, 0.00011668613084377994,
            0.00007734945995380372, 0.00005287071061895649, 0.00003706913804441895, 0.000026554305548624173,
            0.000019377282438956284, 0.000014368030680449636, 0.000010803414116532378, 0.000008223815599050022,
            0.0000043535634926336715, 0.0000024336737057865526, 0.0000014196918845787854, 8.563473185286989e-7,
            3.3720936057003183e-7, 1.4471802829181353e-7, 6.523545340701506e-8, 2.9500830485617997e-8,
            1.295349719841557e-8,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.1924393300528054, 0.11831772892950315, 0.07013183390951766, 0.05114609334773801, 0.04062112035844355,
            0.03382398123723965, 0.018393094852373167, 0.009078097579785855, 0.005591131749341272, 0.003773434281235261,
            0.002673375621349111, 0.0006645034806175399, 0.00008522524553975361, 0.00001775997374948482,
            0.000004879485971788335, 0.0000015495737787359212, 1.1151841672329965e-8, 3.150112393157656e-10,
            5.156663984706711e-11, 4.054645508233534e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 1.5639489703289655e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.20944085968584103, 0.12993322757722728, 0.07788521522997559, 0.057302061658194625, 0.04586456167401106,
            0.038387772675005476, 0.021200700054328475, 0.010680805539674449, 0.006699152748546761,
            0.004606847940054924, 0.003324890331082427, 0.0009039690827371036, 0.00013231735912727071,
            0.00003081649849543603, 0.00000918560279309261, 0.0000032002310825407337, 4.843893635746654e-8,
            1.4979837370532323e-9, 2.0203883011049584e-10, 5.156663984706711e-11, 5.156663984706711e-11,
            5.156663984706711e-11, 4.1885939161545593e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4414921411589088, 0.30550547652156085, 0.1982731809207198, 0.1510345797394028, 0.12351788107071465,
            0.10527135585725439, 0.06299401908900236, 0.036928620238758765, 0.026427465805127174, 0.020461487476403365,
            0.01655185331325773, 0.00793782037443606, 0.003327005819522801, 0.001853854599788243, 0.0011337863964739907,
            0.0007586457444661368, 0.00012333747971936908, 0.0000313372838718351, 0.000011574410876624697,
            0.000004398399016447563, 0.0000018148698095465932, 8.429014979327576e-7, 4.4774335261621445e-7,
            2.457415233525495e-7, 1.386797978675247e-7, 4.7266962455161377e-8, 1.513173231160181e-8,
            5.03348429603534e-9, 2.2468902294292548e-9, 9.661665911764317e-10, 9.661665911764317e-10,
            9.661665911764317e-10, 9.661665911764317e-10, 9.661665911764317e-10, 9.661665911764317e-10,
            9.661665911764317e-10, 9.661665911764317e-10, 9.661665911764317e-10, 9.661665911764317e-10,
            9.661665911764317e-10, 9.661665911764317e-10, 9.661665911764317e-10, 9.661665911764317e-10,
            9.661665911764317e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4052601194114114, 0.2789867741709388, 0.17967611360948701, 0.13582504619422317, 0.11049250021322697,
            0.0937754266907623, 0.055546111575141346, 0.032210086218853844, 0.022746999954784925, 0.017415361664723055,
            0.013931169251729603, 0.006325036174572137, 0.002344684358745783, 0.0011004231157985254,
            0.0005714419880081244, 0.0003254501493915184, 0.00004953576388671832, 0.000012146805551923386,
            0.000003736655316943782, 0.0000014766638002372545, 6.957781445438016e-7, 3.2888377177187067e-7,
            1.1441780167764648e-7, 5.272175684911673e-8, 2.5786969226615497e-8, 4.965053479466519e-9,
            2.0285174651135662e-9, 1.2305334529116863e-9, 9.661665911764317e-10, 8.321231481644986e-10,
            8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10,
            8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10,
            8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10,
            8.321231481644986e-10, 8.321231481644986e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(10.0)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3004363141063765, 0.19768885721641347, 0.1235669459460122, 0.09248864997477162, 0.07489840955728738,
            0.06339878506130556, 0.03699570607238597, 0.020479547280356693, 0.013901332950253753, 0.010290925479787834,
            0.00800497725878189, 0.0032298848321863126, 0.0010196149269557676, 0.0004574583541062219,
            0.000245772428971405, 0.00014776825447756491, 0.00002597580865439525, 0.000007979303707584246,
            0.0000030894572037468837, 0.0000013698827987098105, 6.668408658665443e-7, 3.475203457825967e-7,
            1.9083085843931833e-7, 1.0925617772764691e-7, 6.470048047846997e-8, 2.4714712097154114e-8,
            1.0385939003741083e-8, 4.675315626467851e-9, 2.2055503870163545e-9, 1.1490589469525794e-9,
            7.013217693303074e-10, 5.314259390732596e-10, 4.462888124507458e-10, 3.976298518027286e-10,
            3.7120207396903027e-10, 3.6256262093107313e-10, 3.6256262093107313e-10, 3.6256262093107313e-10,
            3.6256262093107313e-10, 3.6256262093107313e-10, 3.6256262093107313e-10, 3.6256262093107313e-10,
            3.6256262093107313e-10, 3.6256262093107313e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5071901848533087, 0.5058167729463123, 0.49628721337988463, 0.47875952421389534, 0.4578947597935656,
            0.4353400020935172, 0.3370267024924902, 0.22001657178890643, 0.15996194747724288, 0.12394922874544352,
            0.09992411981952397, 0.047374769097576, 0.0201262197906247, 0.011568772741005689, 0.007558865744676247,
            0.005298464050337149, 0.001417171845001034, 0.0005317304291118863, 0.0002362792460865735,
            0.00011733155435012081, 0.00006306994076319608, 0.00003600548787635981, 0.000021581030876827932,
            0.000013424760620745168, 0.000008635197731713445, 0.00000382504882363148, 0.0000018166454005990385,
            9.110881787152352e-7, 4.749353423080701e-7, 2.5603123178846185e-7, 1.393037008510673e-7,
            7.22506264441004e-8, 3.495503134648475e-8, 1.6935092883585412e-8, 7.309008435042793e-9,
            1.5423121668689532e-9, 4.147727716841132e-10, 2.1480983658506148e-10, 2.0266310851724256e-10,
            5.156663984706711e-11, 4.050149104983802e-11, 3.778422019706795e-11, 1.2498002632810312e-11,
            1.009359262837961e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5394365844896635, 0.5377184555135076, 0.5249600806848514, 0.502829463772385, 0.47927791153060756,
            0.4560986733093377, 0.35773410854226406, 0.23928370774496818, 0.17527938003839566, 0.13618038926202922,
            0.11006683337665095, 0.05292396881639252, 0.022930640396463767, 0.01335025160281032, 0.008826813028998397,
            0.006251558223666792, 0.0017578277518294997, 0.0006888100941103195, 0.00031833740140670835,
            0.0001636358413116053, 0.00009093898008451362, 0.000053534268369456574, 0.00003296146744214222,
            0.000021076120175346545, 0.000013866488434033772, 0.000006412785987610317, 0.00000317207336271997,
            0.000001661693787154789, 9.062348166131775e-7, 5.074465153433387e-7, 2.895066222157932e-7,
            1.5719496566771346e-7, 8.57944581888841e-8, 4.907191464287308e-8, 2.8886436354014222e-8,
            8.80409289916173e-9, 3.0119776672776766e-9, 9.661665911764317e-10, 3.965080486167949e-10,
            7.968270487879181e-11, 5.156663984706711e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7712683736641703, 0.7689233939741854, 0.7577520432640834, 0.7400792001373181, 0.7197714340542287,
            0.6963949874339759, 0.5873558136982406, 0.43949644226849693, 0.3443979396857082, 0.2824273577663344,
            0.23878480943311622, 0.1320546444179546, 0.06523981877779018, 0.040854483232700495, 0.028879197730169923,
            0.021994189590131878, 0.009076663888073402, 0.005122092117987388, 0.0033055473297458372,
            0.0022676887939920274, 0.0016159331453178384, 0.0011891653523589286, 0.0008975716425961902,
            0.0006893939607136388, 0.0005368678852838499, 0.0003348078724617548, 0.00021632671902464064,
            0.000143947479261749, 0.00009821529932484585, 0.0000685329661154141, 0.00004883089542307486,
            0.000035757903307441694, 0.000026685418445127753, 0.000020199339723874795, 0.000015440552862749093,
            0.000007931663846888702, 0.000004292236034391135, 0.0000024121809474308975, 0.0000014119566671411121,
            5.31532919012534e-7, 1.8597157769306705e-7, 7.187341566350369e-8, 2.253032249655007e-8,
            9.270430090246862e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7642249653798244, 0.761617682497586, 0.7475574865044335, 0.725525733404733, 0.7024648912704027,
            0.6786084509115408, 0.5655434201314752, 0.4135572835173889, 0.32341369148334587, 0.2642266024059696,
            0.22246217509531352, 0.11990204296485095, 0.057395064183507635, 0.035942145720283265, 0.025470598142036515,
            0.019321923082538422, 0.007658264062609151, 0.004144810018613896, 0.002549294060678718,
            0.0016757823401304312, 0.0011591343163655187, 0.0008326734694955854, 0.0006182678120804599,
            0.00047007764475372687, 0.0003629192147732395, 0.0002256088986504371, 0.00014623906445845034,
            0.00009724530182268677, 0.00006647334535647856, 0.0000465605153845161, 0.00003338108076375107,
            0.00002437960092050235, 0.00001811827453275713, 0.000013599956637505436, 0.0000104468429690137,
            0.000005549454003217491, 0.0000030839524095620806, 0.0000017837857273945246, 0.0000010680218652206398,
            3.9899103398965963e-7, 1.5599701830293355e-7, 5.025027050020725e-8, 1.6323635776416268e-8,
            7.938088297798629e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(2.0)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6546963732134643, 0.6519571248795718, 0.6370719685384303, 0.6151091982913423, 0.5907217933251782,
            0.5660812291249679, 0.45863395215488856, 0.32294267114619346, 0.24565172005191582, 0.19654364612288003,
            0.16277441544827218, 0.08369794697795943, 0.03868059900035432, 0.023606272085139955, 0.016322426869116568,
            0.01211032692071753, 0.004349906058117353, 0.0021661067526244746, 0.0012435421946437107,
            0.0007760971625303245, 0.0005124490209671726, 0.00035261308037014445, 0.00025048000257270293,
            0.00018252835686829584, 0.00013583845349471237, 0.00007914025221586837, 0.00004862688815464615,
            0.00003114292888727258, 0.0000206246346107598, 0.000014040646683522081, 0.000009783209180860475,
            0.000006954322525982771, 0.000005030072391983358, 0.000003694197925011165, 0.0000027497414299398702,
            0.0000013821299268287635, 7.348197108709448e-7, 4.078040654372477e-7, 2.3481563879084024e-7,
            8.461124828193525e-8, 3.144251130987484e-8, 1.1257797527327623e-8, 4.341054561143769e-9,
            2.072745291976513e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5043976728833723, 0.4868600352276587, 0.4322845878783633, 0.3780520977079702, 0.33309935168314897,
            0.29604728260019103, 0.18774080754001501, 0.10683723694728031, 0.07347973913072556, 0.05536185109064211,
            0.04399320317228433, 0.020250642999426464, 0.00827652713357281, 0.004516770466912638, 0.0027620636524374846,
            0.0017922810897718496, 0.00033278685319511136, 0.00009328800548463539, 0.00003292636839971778,
            0.00001348982246077099, 0.00000613455121697608, 0.000003008085505674874, 0.0000015561591754921023,
            8.317767454263852e-7, 4.5215227533715826e-7, 1.3452147443419747e-7, 4.248616800901928e-8,
            1.1951844856531083e-8, 3.7736525015930056e-9, 1.383518150431466e-9, 6.379736738892916e-10,
            2.5856594643158815e-10, 2.1154833440562015e-10, 2.0203883011049584e-10, 1.5763512717370531e-10,
            5.156663984706711e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            1.4013012972213801e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5350665987351446, 0.5106924505890275, 0.4536575051498519, 0.4002838000835801, 0.3551511463160004,
            0.318660069657646, 0.20712164842455763, 0.11840969801397372, 0.08165086123308389, 0.06183959886149689,
            0.049373183858459416, 0.023075525445880977, 0.009645958859002423, 0.005359468341700905,
            0.0033392471349105667, 0.0022152238991856965, 0.00044786677065088886, 0.0001344562777576405,
            0.00004986326355753191, 0.000021326273111103877, 0.000010082383717271764, 0.000005117726861467189,
            0.0000027418466314700635, 0.0000015296973732781458, 8.765682725359536e-7, 2.904444459696826e-7,
            9.797788281981628e-8, 3.3813090327683426e-8, 1.2590164022441286e-8, 5.25497745318404e-9,
            2.650661690140055e-9, 1.1733182203599313e-9, 8.296152653741728e-10, 3.752874677687146e-10,
            2.1822610385413554e-10, 6.9050654083469e-11, 5.156663984706711e-11, 4.452038737667863e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7658528628284632, 0.7501822776137098, 0.6994240568448561, 0.6446761852469477, 0.5962637756936108,
            0.5539259911112723, 0.4011217491424874, 0.2558619986982822, 0.18904771181049948, 0.14998461414720574,
            0.12412240707274724, 0.06434467282597084, 0.030654147262142783, 0.019471850174597204, 0.013901893068009251,
            0.01060547181923591, 0.00421050330862091, 0.0022263029690696265, 0.0013282096140028602,
            0.000841278165072349, 0.0005552370781804098, 0.00037875495893395717, 0.0002645032057200849,
            0.00018842588565479284, 0.00013793790035993616, 0.00007814447978396102, 0.00004400454384204882,
            0.000026996499284076323, 0.000017121364182340657, 0.000010903605880529753, 0.000007230554279881927,
            0.0000049338690196528395, 0.0000034932869841082282, 0.0000024972348455776228, 0.000001812881009755074,
            9.830360792051351e-7, 5.281902639708846e-7, 2.557023321969609e-7, 1.2285278272461397e-7,
            3.346638721701822e-8, 1.2520555148221035e-8, 5.237547506808937e-9, 3.791066571778856e-9,
            2.4248519858716122e-9,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7589067840755492, 0.7367680125898937, 0.6792852833020374, 0.6229483991008231, 0.5723988517531599,
            0.5275812924330278, 0.3772338633905717, 0.23956519017653724, 0.1751785489045446, 0.1373971341827766,
            0.11240940612035812, 0.05699167357142286, 0.02709409657724704, 0.01695450515574585, 0.011974697019037252,
            0.009023103797161975, 0.0033620404382890593, 0.001713999161226017, 0.001006084597144296,
            0.0006322514133118205, 0.00041468896763655267, 0.00027871333107646484, 0.00019260592867100537,
            0.00013713049653793591, 0.00009889838367762023, 0.00005719541450011545, 0.00003384438702053213,
            0.000019928272161767203, 0.0000123593321215143, 0.000008268690814161594, 0.000005665190745740567,
            0.000003961358050029605, 0.000002685354351106284, 0.0000018213226053725507, 0.0000012497158271548726,
            5.149453272679239e-7, 2.3582379626851946e-7, 1.1136747946061121e-7, 5.2038037212653876e-8,
            1.4396707825881094e-8, 4.204385284367618e-9, 3.2325865317872626e-9, 1.3539479537527654e-9,
            9.661665911764317e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(3.0)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6485242513052288, 0.6249955268372027, 0.5648713855341627, 0.5087410945495334, 0.4606775419667611,
            0.4200077336365894, 0.28871388592858716, 0.17588931740574368, 0.12559646627810706, 0.0970491234942314,
            0.0786305548234535, 0.038682188691078666, 0.01756417774089213, 0.010601125180006573, 0.007213748059668411,
            0.0052471373129567385, 0.0016819133228209218, 0.0007532014306640948, 0.0003938500599051998,
            0.00022627378151465, 0.00013872469126838992, 0.00008926279662515949, 0.0000596448907435682,
            0.000041089904637615576, 0.0000290355417282694, 0.000015416514451762765, 0.00000873323294807596,
            0.000005204632724669083, 0.0000032331275306625087, 0.000002077829698034346, 0.0000013728128832501158,
            9.28163399454372e-7, 6.405377036339617e-7, 4.499729156866535e-7, 3.21749649039134e-7, 1.4697621172244065e-7,
            7.094557436415438e-8, 3.5324807591170884e-8, 1.793099907434593e-8, 5.81760656212664e-9,
            2.4689811233376944e-9, 1.261733195861543e-9, 7.480761857629766e-10, 5.096778993650437e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.48956248094927324, 0.4368699057293583, 0.3426325123264135, 0.27780084743267874, 0.23266418573182834,
            0.20000163156559023, 0.11643739358807557, 0.06307791880603843, 0.04275798589431472, 0.031905963390460465,
            0.025143871757006515, 0.011171100986381056, 0.0042321678759120965, 0.0020925747843100018,
            0.0011518521220850982, 0.0006786490276065307, 0.00008581875415101128, 0.000018771551422198776,
            0.000005467774810455489, 0.0000018874449194022963, 7.178628791575325e-7, 2.82967758892915e-7,
            1.1421701633880337e-7, 4.781630724580858e-8, 2.087843723434062e-8, 3.0017021415224757e-9,
            6.757675530266738e-10, 2.281759226008262e-10, 2.0203883011049584e-10, 1.1676215549982771e-10,
            5.156663984706711e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 2.5798807534727075e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5136759195179664, 0.45978128456291634, 0.36626143903005914, 0.30002985396799237, 0.2530539881044404,
            0.218185110536009, 0.1283452181196565, 0.07037384267290644, 0.04806149080900054, 0.0360993711124319,
            0.028632383400050188, 0.012979538295624808, 0.005088196255814557, 0.002604715196256386,
            0.0014877252038755402, 0.0009064412678068064, 0.000132439360138484, 0.00003146082612937427,
            0.000009734231362042343, 0.0000035630993076571023, 0.0000014396064656564766, 6.161261931634565e-7,
            2.7548209862999395e-7, 1.210879194645079e-7, 5.06200952266056e-8, 9.15365871989593e-9, 2.437982149494644e-9,
            9.546424761808225e-10, 5.444141804034075e-10, 2.1154833440562015e-10, 1.328487558515427e-10,
            5.156663984706711e-11, 5.156663984706711e-11, 5.156663984706711e-11, 5.156663984706711e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7515311798901703, 0.7027816583784728, 0.6058376177024134, 0.5264900596318629, 0.46432174314389196,
            0.4149621250575227, 0.2714272924672755, 0.16386110824521885, 0.11777220649895921, 0.09150891173839797,
            0.07466102144803262, 0.03865290083893561, 0.01916825486614764, 0.01228806307134798, 0.008787412054647415,
            0.0066694968510851525, 0.0025526266731659415, 0.0012521446521012213, 0.0006940062052381819,
            0.0004151684939840196, 0.0002496071939199271, 0.00015890502683932876, 0.00010249755746571745,
            0.00006908285224801347, 0.00004816636688725581, 0.00002411967157318724, 0.000013201236145227487,
            0.00000786920455841944, 0.000005360465650916524, 0.0000037544432615055356, 0.0000026893905651359518,
            0.0000019623938424251364, 0.0000013218797484702998, 8.723878351934999e-7, 6.119610886923255e-7,
            2.7035069158565933e-7, 1.2055228693075293e-7, 6.172816957583649e-8, 3.232227840932467e-8,
            9.322713490078627e-9, 3.791066571778856e-9, 3.791066571778856e-9, 1.6250305545639776e-9,
            9.661665911764317e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7404752438963759, 0.6838773951844528, 0.5789631686094202, 0.498919035776793, 0.43740374133158444,
            0.38898720195407877, 0.2514154896870606, 0.14913303386568544, 0.10631403032679593, 0.08254636881628043,
            0.06733955960918285, 0.03439293423545233, 0.016626769536044663, 0.01044992147897923, 0.007342751567881963,
            0.0054718660457777684, 0.0019560635059987525, 0.0009416899474878953, 0.0005050002664910021,
            0.00029533661740070575, 0.000181741955746825, 0.00011527259795970694, 0.00007484893142093939,
            0.000051296838128878086, 0.00003549618999287851, 0.000018797539068149226, 0.000010114609952149856,
            0.000005376128319048569, 0.0000030592209441238083, 0.0000017613256204640493, 0.0000011153048262713128,
            6.670110851425548e-7, 4.3077332401164625e-7, 2.67184958602229e-7, 1.7646811234506288e-7,
            7.270868929154517e-8, 3.21672329883782e-8, 1.6446856876406457e-8, 6.1385181382433984e-9,
            3.791066571778856e-9, 2.168876522823382e-9, 9.661665911764317e-10, 9.661665911764317e-10,
            8.321231481644986e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(4.0)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6273147570865665, 0.5698630690301049, 0.46905259082988754, 0.39564101035939697, 0.3415437034201816,
            0.30037874052631086, 0.1879486829352432, 0.10805355597704923, 0.07562351497770015, 0.05788298057363272,
            0.046670945523267574, 0.02287137009965184, 0.010324875228153937, 0.006119759187087318, 0.004070898347081372,
            0.0028922675181167323, 0.0008350356168284053, 0.0003447491255679394, 0.0001688753112368664,
            0.0000919235175769261, 0.00005384408115617696, 0.000033320892110410405, 0.000021526023753570644,
            0.000014399656015122706, 0.000009916494340493, 0.000005047101361319579, 0.000002766024088232661,
            0.0000016038531160300135, 9.733451291903475e-7, 6.129236625744709e-7, 3.987904143780524e-7,
            2.662899010268095e-7, 1.8177936109019214e-7, 1.262202158079014e-7, 8.895570739324095e-8,
            3.914068426020208e-8, 1.908712970705147e-8, 1.0052676459900473e-8, 5.636832663467639e-9,
            2.156970327852735e-9, 1.0685776797495322e-9, 6.196029185464306e-10, 4.3538928171789204e-10,
            3.661262357953835e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4483118620606675, 0.359639631624311, 0.2511515752019524, 0.19214304640109356, 0.15547552971015086,
            0.13083141122069464, 0.07379079087567698, 0.03955157698992541, 0.026567678336069794, 0.019677819194332608,
            0.015386692199288891, 0.006551146575724687, 0.002195651729651583, 0.0009469798384727793,
            0.00046149271850740655, 0.0002439710484416794, 0.000021770050795730083, 0.000003788893327380277,
            9.020263987992392e-7, 2.4685295835763965e-7, 5.848975548339297e-8, 1.1622476314698815e-8,
            3.249691790359672e-9, 1.0230110092379618e-9, 4.872042769221707e-10, 2.1011958839523004e-10,
            8.026901365809636e-11, 4.202094228134001e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            3.78471698425642e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.471687108268911, 0.38453706881099337, 0.2726058082640339, 0.2098764337393566, 0.1706040909633756,
            0.14393473797353298, 0.08211514065020174, 0.04461191066818223, 0.03028286851967464, 0.02258376225177965,
            0.017768134609278664, 0.007761818418980352, 0.002746964613493462, 0.0012492820236554899,
            0.0006403406869690772, 0.00035558658517393613, 0.00003663709995783285, 0.000006755302597803527,
            0.0000016383392453025619, 4.4372020250704885e-7, 1.2283338975486653e-7, 3.2724890713093693e-8,
            1.1850659342726999e-8, 4.881724109299937e-9, 2.308268021167237e-9, 5.35666511147781e-10,
            2.1154833440562015e-10, 5.156663984706711e-11, 5.156663984706711e-11, 5.156663984706711e-11,
            5.156663984706711e-11, 5.156663984706711e-11, 5.156663984706711e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.7203214763038318, 0.6365520252111573, 0.5021719592171345, 0.41609151700778596, 0.3559176733465662,
            0.31159070879911754, 0.19327748585718157, 0.11087326241043802, 0.07837354587985178, 0.060844135674045466,
            0.04986871005671929, 0.026246499138369003, 0.012842920703415854, 0.008078959983092715, 0.005718824129545688,
            0.004254785732210564, 0.0014225095761987733, 0.0005969101618960263, 0.00029083336383142744,
            0.0001525844545731756, 0.00008668762185576559, 0.00005672044089163558, 0.000035542852856451645,
            0.000023243759642355144, 0.000015446945334729456, 0.000008040137799980585, 0.000004610184596964828,
            0.000002613615698310312, 0.0000015408468011512966, 9.331545580693046e-7, 5.740273875121105e-7,
            3.6479159437607933e-7, 2.4205124860010585e-7, 1.6703514260640873e-7, 1.1630002516582749e-7,
            5.406108882777261e-8, 2.8268893559335595e-8, 1.1880444628076248e-8, 4.222495464389908e-9,
            3.791066571778856e-9, 1.3416651123421275e-9, 9.661665911764317e-10, 9.661665911764317e-10,
            9.661665911764317e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6969120575707035, 0.6036015078650411, 0.4731707862106067, 0.38670025671723285, 0.32722496136387114,
            0.28408334478768027, 0.1741295855116115, 0.10057788987341297, 0.07092433307501649, 0.0548362639764094,
            0.04474942075736656, 0.023072944409206136, 0.010946187678556514, 0.0067129790287186325,
            0.004599209350496471, 0.0033921846678325973, 0.0010850863510188872, 0.0004352292250201788,
            0.00021166439675220314, 0.00010674246643312173, 0.00005996778954303357, 0.00003686426693592695,
            0.000023735119866419296, 0.00001557157520437613, 0.000010205950626006772, 0.000004883128440269502,
            0.0000025144452686642893, 0.0000013671283340954687, 8.472224521938188e-7, 5.069807116342417e-7,
            2.973970936182013e-7, 1.747627180748168e-7, 1.0291778806958973e-7, 6.297600507743084e-8,
            4.0199910955607265e-8, 1.3808943655213568e-8, 5.660401702911599e-9, 3.791066571778856e-9,
            2.5901455424914843e-9, 9.661665911764317e-10, 9.661665911764317e-10, 8.321231481644986e-10,
            8.321231481644986e-10, 8.321231481644986e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(5.0)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5830252442584698, 0.4905815713039905, 0.3677652726486673, 0.29415588473609733, 0.24566028014282296,
            0.21134398398426968, 0.1261858051931561, 0.0709234438146339, 0.049325259380707344, 0.03766978386445898,
            0.03034234852476307, 0.014757087143108862, 0.0064306990758603855, 0.003658644788271231,
            0.0023408311371059132, 0.0016045364473473815, 0.00040213112997376777, 0.0001503372777385827,
            0.00006850712785494206, 0.00003534965204955201, 0.000019893376914997457, 0.00001194638319850828,
            0.00000754693244152217, 0.00000496175030140488, 0.0000033703478968141836, 0.0000016819534884488872,
            9.096421336640906e-7, 5.224592674927002e-7, 3.144276292506832e-7, 1.964650468789635e-7,
            1.2646399550628413e-7, 8.374064617278505e-8, 5.7032688109516495e-8, 3.967078150454628e-8,
            2.8188320541705274e-8, 1.272440513392457e-8, 6.070502821042849e-9, 3.0526044677743823e-9,
            1.7191430249173118e-9, 7.500704468800454e-10, 4.452608424665785e-10, 3.642412289530308e-10,
            3.6256262093107313e-10, 3.6256262093107313e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.38803238852468347, 0.28251639086105446, 0.1822662157254145, 0.13518263209114467, 0.10812390224723657,
            0.0904109470269267, 0.05049913986649569, 0.02676265898027072, 0.017804470324210685, 0.013029168372000167,
            0.010083156535188417, 0.004018032328724525, 0.001139613986669289, 0.00042105677241652267,
            0.00018121318090978306, 0.00008668082305816367, 0.000005270435374597178, 6.309180922700219e-7,
            8.204379114776124e-8, 1.1269164275943274e-8, 2.2123419762820618e-9, 8.321231481644986e-10,
            2.699853673959751e-10, 2.0203883011049584e-10, 5.156663984706711e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            2.9656832545299494e-11, 1.023081619422328e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.41325923011035903, 0.30572196295664356, 0.19937820206456244, 0.1486608952352672, 0.11923761223570387,
            0.10004160477442724, 0.05661168728910523, 0.0305057232067235, 0.020484721498366776, 0.015123987442019305,
            0.011786995362491373, 0.004858540657465059, 0.0014780371328174976, 0.0005825706463529617,
            0.00026458356192797663, 0.00013225086609634443, 0.00000909648534290631, 0.0000012203327606330676,
            2.1594409686668442e-7, 3.7857659118678555e-8, 9.9009829718898e-9, 3.1782034781713264e-9,
            1.0579251918940713e-9, 3.152614835855161e-10, 2.0203883011049584e-10, 5.156663984706711e-11,
            5.156663984706711e-11, 5.156663984706711e-11, 5.156663984706711e-11, 4.0883629814914e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.6735644510801703, 0.5534681071124191, 0.4094227621901898, 0.3272238240135249, 0.2737176923952553,
            0.23585774449133642, 0.1413648773113148, 0.08075405363291628, 0.057365474321282806, 0.04472134992684676,
            0.036754451287440766, 0.01915533740299937, 0.0090074871807743, 0.005501297725336616, 0.003746273045546226,
            0.002706156374852322, 0.0007315490914728462, 0.00027037582826383333, 0.00012121172144760043,
            0.00006347818548183889, 0.000035146037818289955, 0.000020192926369455577, 0.000012132163430544907,
            0.000008189583471263084, 0.000005890896346172347, 0.000003078891950281104, 0.0000015227311678245314,
            7.91558761648048e-7, 4.238239341702865e-7, 2.3134654725254222e-7, 1.296537210926374e-7,
            8.561096365156118e-8, 5.769101307784297e-8, 4.002535147762387e-8, 2.827112721703884e-8,
            1.2367084134545792e-8, 5.51846179863702e-9, 3.791066571778856e-9, 2.1121160376225134e-9,
            9.661665911764317e-10, 9.661665911764317e-10, 9.661665911764317e-10, 9.661665911764317e-10,
            9.661665911764317e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.636594772038988, 0.5223104721804785, 0.3797429402288276, 0.2995441652478735, 0.24848398210475292,
            0.2133392674835815, 0.12807815754578297, 0.07282827333997263, 0.05139556714019479, 0.039897747083169725,
            0.03261338979386617, 0.01656970620679506, 0.007580785233760573, 0.004492841609095067, 0.002991240054640731,
            0.0021168492973061837, 0.0005486615706772124, 0.00018850974986078583, 0.00007694404777495042,
            0.00003870633482329287, 0.00002043971762220875, 0.00001189384437966643, 0.000007303779058664972,
            0.000004675193006620226, 0.0000030857063314515543, 0.0000014428841007552151, 7.200800300477539e-7,
            3.8271862778938015e-7, 2.1921639870026155e-7, 1.3053738179280572e-7, 7.974841442770497e-8,
            4.358053473652035e-8, 2.5737385112023503e-8, 1.769465995948849e-8, 1.1158145518663787e-8,
            3.791066571778856e-9, 1.3659733344439928e-9, 9.661665911764317e-10, 9.661665911764317e-10,
            8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10,
            8.321231481644986e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(6.0)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5226036825053736, 0.408213017672516, 0.2843847474351222, 0.2199549011754718, 0.18043859121258593,
            0.15360267623068646, 0.08998995695883891, 0.05023676346627983, 0.03488383453523672, 0.026596027039055285,
            0.021366404096182613, 0.010150244271237056, 0.0041698163998104805, 0.0022470835974370373,
            0.0013711457452830562, 0.0009017357567768895, 0.00019593145996400462, 0.00006794635731088858,
            0.0000297827442415874, 0.000015065582277961835, 0.000008396554531636266, 0.000005013323112458738,
            0.0000031516682037006942, 0.0000020645180824075656, 0.0000013972959165481328, 6.895182632200515e-7,
            3.6696319384758123e-7, 2.0739899996581422e-7, 1.2311241098592274e-7, 7.573231637546026e-8,
            4.798444578122711e-8, 3.1201120298984976e-8, 2.0703621552977052e-8, 1.4000200919526468e-8,
            9.5969379794888e-9, 3.9381283912795615e-9, 1.7655466941897485e-9, 9.561198788806245e-10,
            6.277505779954655e-10, 3.9972563927821454e-10, 3.6256262093107313e-10, 3.6256262093107313e-10,
            3.6256262093107313e-10, 3.6256262093107313e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-42.0~173.0",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2965745750914664, 0.1959544084758105, 0.11890653084839731, 0.0868451089773713, 0.06912918384748595,
            0.05768625840185275, 0.03203280802315761, 0.01667498132898136, 0.010858254841417354, 0.007775009510107309,
            0.005869084564285825, 0.002026098411016368, 0.00042548898713564483, 0.00012466500526334755,
            0.00004440280648325506, 0.0000180177529480084, 5.220752109452653e-7, 2.6967645228026527e-8,
            2.768418938536854e-9, 3.601423603782905e-10, 9.053124916391653e-11, 5.156663984706711e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 1.0443312881136535e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11, 1.009359262837961e-11,
            1.009359262837961e-11, 1.009359262837961e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-42.0~173.0",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3201616433158517, 0.21397726431929476, 0.13095227364448833, 0.09618928016917153, 0.07688833714098295,
            0.0644666005064566, 0.03641720352273923, 0.019258825182617522, 0.012675961825389435, 0.009180328546493564,
            0.007001710909255105, 0.002538828504786747, 0.000584626980520367, 0.00018400913349222547,
            0.0000696988125132871, 0.00002995227998637003, 0.000001263565543910873, 1.0772606230767088e-7,
            1.2196879728421095e-8, 1.991881548590868e-9, 2.1947188511006743e-10, 2.0203883011049584e-10,
            5.156663984706711e-11, 5.156663984706711e-11, 5.156663984706711e-11, 5.156663984706711e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11, 4.050149104983802e-11,
            4.050149104983802e-11, 4.050149104983802e-11,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-42.0~173.0",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5799950850909422, 0.441927697315288, 0.3034972170425069, 0.23443346770307041, 0.19282408710395962,
            0.16474163154315116, 0.09819249081807138, 0.05658972805698726, 0.040506255264786115, 0.03167597017541593,
            0.02597113887359535, 0.013140753694365502, 0.005897317872970642, 0.0034656650157684995,
            0.0022459324508883856, 0.0015495364174311448, 0.00035342663856947074, 0.00011527438841936466,
            0.00004880531837467243, 0.000024700102585484274, 0.000012737668839690741, 0.000006957449905975466,
            0.000004314788459858221, 0.000002869263839078151, 0.0000019549857819267658, 9.171462078683135e-7,
            4.008085756407383e-7, 1.9707793019740905e-7, 9.496629616023711e-8, 4.351511762035187e-8,
            2.5959281280130142e-8, 1.541244232239336e-8, 9.285221702626245e-9, 5.842824890578413e-9,
            3.791066571778856e-9, 2.314762603816689e-9, 9.661665911764317e-10, 9.661665911764317e-10,
            9.661665911764317e-10, 9.661665911764317e-10, 9.661665911764317e-10, 9.661665911764317e-10,
            9.661665911764317e-10, 9.661665911764317e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-42.0~173.0",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.5464701613221392, 0.4102588256150156, 0.2787924868103064, 0.2145448775559004, 0.17581256780277693,
            0.14968938931946507, 0.08826700045876165, 0.05029443791589929, 0.03571274727992783, 0.027722410075226876,
            0.02255854712578793, 0.011112204368948964, 0.00480756743840367, 0.002717184349825863, 0.0016829393666994196,
            0.0010833215342810811, 0.0002087891818188855, 0.00006430634316753641, 0.000027753833695298364,
            0.000011525873126139174, 0.000005680680820185913, 0.000003120811630896725, 0.0000018235190050175376,
            0.000001108208029676483, 6.992083203538613e-7, 3.05648091836197e-7, 1.438393328889731e-7,
            7.09309472268771e-8, 3.586808416944365e-8, 1.801549542879144e-8, 8.49750014797479e-9, 3.791066571778856e-9,
            3.0364101233359975e-9, 1.7467497448464542e-9, 9.661665911764317e-10, 9.661665911764317e-10,
            8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10,
            8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10, 8.321231481644986e-10,
          ],
        },
      },
      {
        hazard_model: "SLT_v8_gmm_v2_FINAL",
        imt: "SA(7.5)",
        loc: "-42.0~173.0",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.4275370060251128, 0.3056336986017307, 0.1991361564421004, 0.15046251951866255, 0.1221570150842756,
            0.10343487225922489, 0.06018018651886485, 0.033578472571233225, 0.023241900894681294, 0.017606654494300784,
            0.01402660366273987, 0.0063321399723730055, 0.0023635755985124177, 0.0011811185046965865,
            0.0006797573780234412, 0.00042733217816996294, 0.00008364953608353248, 0.000028472648726115833,
            0.000012457213388415485, 0.000006271946851579987, 0.0000034562609256664606, 0.000002029704238112889,
            0.0000012500282034350567, 7.992168557623785e-7, 5.274807366815794e-7, 2.47224830247779e-7,
            1.2480455392683118e-7, 6.66910626462193e-8, 3.71991801443759e-8, 2.1517277549049674e-8,
            1.2808506438821799e-8, 7.84059865675775e-9, 4.914213984219949e-9, 3.1357741102862398e-9,
            2.0318427327745328e-9, 8.299518077740199e-10, 5.152305813486548e-10, 4.1387933064161503e-10,
            3.7084009611383053e-10, 3.6256262093107313e-10, 3.6256262093107313e-10, 3.6256262093107313e-10,
            3.6256262093107313e-10, 3.6256262093107313e-10,
          ],
        },
      },
    ],
  },
};

export const missingLocationResponse = {
  hazard_curves: {
    ok: true,
    locations: [
      {
        lat: -40,
        lon: 180,
        resolution: 0.1,
        code: "-40.0~180.0",
        name: null,
        key: null,
      },
    ],
    curves: [],
  },
};

export const missingOneLocationResponse = {
  hazard_curves: {
    ok: true,
    locations: [
      {
        lat: -37.78,
        lon: 175.28,
        resolution: 0.001,
        code: "-37.780~175.280",
        name: "Hamilton",
        key: "HLZ",
      },
      {
        lat: -40,
        lon: 180,
        resolution: 0.1,
        code: "-40.0~180.0",
        name: null,
        key: null,
      },
    ],
    curves: [
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "PGA",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2875896713192583, 0.2875710674981644, 0.2872840068649054, 0.2863480645980263, 0.2845723241814514,
            0.28195031331159426, 0.26001206791357656, 0.20607127060918362, 0.16211004728822243, 0.12982765287524944,
            0.10618201751188183, 0.049460114573294245, 0.01941165561285385, 0.010416193534365403, 0.006445797800255004,
            0.004334767820780572, 0.001077537902859027, 0.00041895902180444367, 0.0002008996315265854,
            0.000109468161619386, 0.00006513564489663004, 0.00004134952521717139, 0.00002758530461877129,
            0.000019136201362401906, 0.000013698949989260356, 0.000007548362534914865, 0.00000446965049283321,
            0.00000278794801900073, 0.0000018084981544541634, 0.000001209197356799585, 8.283048168467033e-7,
            5.789311106063622e-7, 4.115349587774375e-7, 2.968729402308412e-7, 2.1701721930611484e-7,
            1.0391012317256809e-7, 5.25013489349746e-8, 2.767399653880176e-8, 1.5151242593880454e-8,
            4.896064664805522e-9, 1.6471327635159128e-9, 5.501518129946703e-10, 1.7158230392055884e-10,
            3.172284657182445e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "PGA",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21498202311424597, 0.2149784711151176, 0.2144319096152052, 0.21300156040241647, 0.21090009629028938,
            0.20758098931512703, 0.18769649765481855, 0.13490892349396677, 0.09696742333648622, 0.07309087213201715,
            0.057190157824191235, 0.02360505432176574, 0.008144016342938931, 0.003996278754699212, 0.002311980387278534,
            0.0014685136294618761, 0.0002889984462226547, 0.00009162303031240437, 0.00003616165891295875,
            0.000016057133266689227, 0.000007821805184438091, 0.0000040545971373706635, 0.000002195809241656299,
            0.0000012279997855246805, 7.126611460961385e-7, 2.578761051363898e-7, 1.0011714846047681e-7,
            3.976599638466638e-8, 1.5899844330036217e-8, 6.273309982596231e-9, 2.125566833655057e-9,
            1.8509738186622826e-10, 1.8381518529508867e-11, 1.0252576565505933e-11, 1.0232481528760218e-11,
            3.3140157285060923e-12, 1.613265077082815e-12, 1.613265077082815e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.0582645870726992e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "PGA",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3553744593039615, 0.3553732586611331, 0.35528575060486756, 0.35492654574414007, 0.3539850879314881,
            0.3526986612168337, 0.33616610907744604, 0.2859587763461059, 0.23720399573876982, 0.19721912839966171,
            0.1656181370210037, 0.08270235631290568, 0.03459544700359507, 0.01938496887865271, 0.01242411415638811,
            0.008601027908823111, 0.002364252099510522, 0.0009791639756066006, 0.0004903186205503784,
            0.00027799500220715423, 0.00017485859370214296, 0.00011678120285119054, 0.00008309372534920367,
            0.00006056534869902652, 0.00004697944969633827, 0.000028117766515678433, 0.000017173593254482533,
            0.000011159307180430567, 0.000007703853753260326, 0.00000530994130654161, 0.0000036817014739121134,
            0.0000026954610445883986, 0.000001938282017666104, 0.0000013606299652524356, 9.670410836548626e-7,
            4.731294876991754e-7, 2.488777659515051e-7, 1.373211945265851e-7, 7.80622713136836e-8,
            2.7173602035190925e-8, 1.011521622018563e-8, 3.853679153564826e-9, 1.380780267901116e-9,
            2.587722258695635e-10,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "PGA",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21961263464261382, 0.21956497596894164, 0.2193005979199545, 0.21870137560977676, 0.2173991131423112,
            0.2152240221624584, 0.1983454474041868, 0.14687094190702332, 0.10741763879441657, 0.08185803857311458,
            0.06466593195021308, 0.0275780378876207, 0.009800674114317509, 0.004894889918776402, 0.0028591781964928398,
            0.001836233643123908, 0.0003790094981737102, 0.00012359152903829163, 0.000049519879299131375,
            0.000022455852655811626, 0.000011094610496309443, 0.00000584859402286142, 0.0000032392133838543558,
            0.0000018609558165572082, 0.0000011019205662377018, 4.1798497418810143e-7, 1.719640807529288e-7,
            7.210139818525363e-8, 3.1187377458330445e-8, 1.4070854037484537e-8, 6.065367763596896e-9,
            2.580391900153245e-9, 9.727296745865033e-10, 3.675791893087421e-10, 1.4592682617831088e-10,
            7.914557897947816e-12, 3.223532551999142e-12, 3.148370453232019e-12, 1.613265077082815e-12,
            1.613265077082815e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "PGA",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3518367182144435, 0.35178128671298725, 0.3506957832457781, 0.34804977986805474, 0.3461365903260055,
            0.3440385463496418, 0.3231400988884323, 0.269500781469327, 0.22079034306871814, 0.18178722785936086,
            0.15178574689305524, 0.07444708985047888, 0.030716047436566307, 0.017050499515188, 0.010815492560701245,
            0.007413503553480916, 0.001973907861478774, 0.0008150108332362072, 0.0004126716550144627,
            0.0002353117483137046, 0.00014776020886697605, 0.00009815247068190036, 0.00006853500678039026,
            0.00004953360906057025, 0.00003608213448980102, 0.00002105178601519153, 0.0000128593174414382,
            0.000008381202531548837, 0.000005464106378472167, 0.0000036682283691691353, 0.0000025923352600099037,
            0.0000017884078199470466, 0.0000012611153267227948, 9.007102141911361e-7, 6.551506531726758e-7,
            3.289778192083759e-7, 1.6132834446125344e-7, 8.15996114011952e-8, 4.6378133111701914e-8,
            1.3332984494240918e-8, 3.1403444289423987e-9, 6.589829792957858e-10, 9.095380004708886e-11,
            4.106615048016238e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.1)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2875895820671903, 0.2875755495983462, 0.2874284697077958, 0.28702434954235057, 0.28629863915203846,
            0.28523353923476813, 0.27553902336555447, 0.24536316515553303, 0.21378242100044087, 0.18584012257289106,
            0.16226614200755562, 0.09138051534072578, 0.04221251874682763, 0.02502046914008693, 0.016758458624683392,
            0.012060846989379814, 0.0038578703314358087, 0.0017875734728404202, 0.0009775719169646813,
            0.0005894583331200742, 0.0003797774510831964, 0.00025693393398196207, 0.00018056344381522926,
            0.0001308521701209875, 0.00009727419116689617, 0.00005716971819003103, 0.00003583922420014041,
            0.00002361229813896415, 0.000016179257531390157, 0.000011442031544572906, 0.000008304064396558175,
            0.000006157472929757546, 0.000004648879118773763, 0.0000035642959019810405, 0.000002769398562474379,
            0.0000015453565709311334, 9.092025717860608e-7, 5.570064371962502e-7, 3.5245597551636365e-7,
            1.5201705716449965e-7, 7.09829527378858e-8, 3.520269487022887e-8, 1.832573737203802e-8,
            9.904484232770017e-9,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.1)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21498202311424597, 0.21497904952448166, 0.21468008717277065, 0.21368662735487132, 0.21275734051081063,
            0.21135705053362797, 0.20056421551014225, 0.17244941418077997, 0.14098443015429285, 0.1157708973065974,
            0.09645042952900185, 0.04796190763556396, 0.01979340583105016, 0.010870179736622076, 0.006845205015557521,
            0.004670363051431092, 0.0012231349406270908, 0.0004850896446473296, 0.00023287708213726166,
            0.00012441283419073468, 0.00007127808818885306, 0.00004311660023370578, 0.00002710365719715835,
            0.000017599795135270213, 0.000011687487192602042, 0.0000054674402649723675, 0.00000273610646306377,
            0.0000014470279673384212, 7.891994575626171e-7, 4.4670411414315936e-7, 2.572960288160786e-7,
            1.5007113263187932e-7, 9.013266144553e-8, 5.564994887130581e-8, 3.291754180345663e-8, 8.269739892874384e-9,
            1.3547450938844463e-9, 7.600786666728254e-11, 1.6165624394659517e-11, 3.3140157285060923e-12,
            1.613265077082815e-12, 1.613265077082815e-12, 1.453503983839255e-12, 1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.1)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3553744593039615, 0.3553739479983249, 0.35535069925591534, 0.35522033502229466, 0.3548962293892014,
            0.354574033516768, 0.34871734663795007, 0.3237866064141285, 0.29276143372591346, 0.2633967656217785,
            0.23666181712399026, 0.14493167957858544, 0.07090132791527604, 0.04353006667126691, 0.030090558676717394,
            0.022289640493765916, 0.007896067294193232, 0.003907016203267721, 0.0022382335171498013,
            0.0014026246135644405, 0.0009307303543543144, 0.0006446592909802318, 0.0004637129634073034,
            0.0003441861715035577, 0.0002589045003863477, 0.00015853075335547917, 0.00010583228827609759,
            0.00007302179799162367, 0.00005342027929156057, 0.00003868659344496983, 0.000029635372222336365,
            0.00002255196838774065, 0.000017699705869400617, 0.00001422097855685589, 0.000011658680908688268,
            0.000007234598451866248, 0.000004642294869516839, 0.0000030417797121096157, 0.000002051246843381982,
            9.526219804101288e-7, 4.713889723007014e-7, 2.4206622128986055e-7, 1.289021867023621e-7,
            7.070064356184957e-8,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.1)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21961117535578345, 0.21957131648532113, 0.2193297606694098, 0.21918373788149825, 0.21862023494749128,
            0.21778620024371065, 0.21032205958797912, 0.1833794321953851, 0.15313784363280414, 0.1281366989200804,
            0.10813575178588375, 0.05481339034938293, 0.023086281089939265, 0.012862694132449226, 0.008188120084189787,
            0.005635384940083088, 0.0015332457077724682, 0.0006275889114729649, 0.000305763006894888,
            0.00016503028184799984, 0.00009574557067193457, 0.00005838285926185183, 0.00003708766905330396,
            0.000024342373305286102, 0.000016356403587436397, 0.000007868504809827037, 0.000004061538187216662,
            0.000002209695319277394, 0.0000012515386510481008, 7.245080073658983e-7, 4.280919592147825e-7,
            2.544029242823598e-7, 1.584316416947118e-7, 9.739883222170675e-8, 6.080562575672133e-8,
            1.907785784904803e-8, 5.968719740678807e-9, 1.979453490008609e-9, 6.457308021623476e-10,
            5.110245560047133e-11, 3.3140157285060923e-12, 2.753131056465463e-12, 1.613265077082815e-12,
            1.613265077082815e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.1)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.35183706947352367, 0.351784364883476, 0.3513021111737161, 0.35006066021690707, 0.348185525718832,
            0.3469609943106763, 0.3386991771463289, 0.3089824526093623, 0.2772278181722335, 0.24686970696496258,
            0.21972830515775588, 0.13172781740187822, 0.06435557496127364, 0.03919151700348156, 0.026802854407861454,
            0.01965381796682175, 0.006712215100542873, 0.0032574960383370266, 0.0018565847507771016,
            0.0011606177060877743, 0.0007705839938043635, 0.0005375120528737787, 0.0003886940514871151,
            0.00028825500112217295, 0.00021983814172565896, 0.00013434143294455136, 0.00008681026191093011,
            0.00005821560530017145, 0.0000407388078961235, 0.00002932423437973508, 0.00002149821816677111,
            0.000016046848863360275, 0.000012400777623766679, 0.000009580206819070902, 0.00000765915882938728,
            0.000004491707297882286, 0.00000266839765572513, 0.0000015527368188994473, 9.540476522129637e-7,
            3.876071237307599e-7, 1.8753216579625587e-7, 9.623138907954853e-8, 5.1421281455077406e-8,
            2.8394085305194494e-8,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.2)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2875904232351989, 0.28758976683053694, 0.2875704721867226, 0.2874868220498479, 0.28729365177819266,
            0.28695999792606397, 0.28288890197145466, 0.2655770143554319, 0.24315167826393258, 0.22041104903233977,
            0.19923744647869324, 0.12428028328447527, 0.06133511590182572, 0.037141408609562165, 0.0251647280447147,
            0.018263110758600987, 0.006027922482779502, 0.002876165662113128, 0.0016207167491756325,
            0.0010068105600524735, 0.0006675731867697987, 0.00046406697988921053, 0.0003345074182788954,
            0.0002481891207597453, 0.00018856943434386597, 0.00011522337765557111, 0.00007471413110382574,
            0.00005071961810620973, 0.000035707661564243764, 0.000025892631618784634, 0.000019237889537504493,
            0.000014586484178624737, 0.000011250903153126046, 0.000008806489765533776, 0.000006980674218115546,
            0.000004085001121700849, 0.0000025136784853607708, 0.0000016071785946536465, 0.000001058809961596019,
            4.920623022375281e-7, 2.454218991365664e-7, 1.2919511227504898e-7, 7.104100285815207e-8,
            4.042419099459238e-8,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.2)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21498202311424597, 0.21498201041492881, 0.21497650526091594, 0.21489604969855935, 0.21444754252507447,
            0.21371943232407176, 0.20861791203436575, 0.19272855123929467, 0.1709086510188722, 0.1476282276701968,
            0.12751538926274752, 0.06900594276481853, 0.03034919848715456, 0.0172173671056578, 0.011083074614791744,
            0.007708028226876662, 0.0021733123875107285, 0.0009213170208660637, 0.00046352605711175165,
            0.0002594629244572211, 0.00015605034341525936, 0.0000990036475434497, 0.0000651168462992846,
            0.00004423135167086478, 0.000030896382303358116, 0.000016109645943340745, 0.000008882417462219294,
            0.000005131646337197537, 0.0000030699397053357558, 0.0000018809302856359622, 0.0000011983543185545287,
            7.726040348599028e-7, 5.014487738375095e-7, 3.357371582968227e-7, 2.2614832018152953e-7,
            8.969654385904136e-8, 3.620488220335005e-8, 1.4376515977687632e-8, 5.106556288936304e-9,
            1.9784951454937527e-10, 2.2098656238256353e-11, 3.9080960689830135e-12, 3.223532551999142e-12,
            1.613265077082815e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.2)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3553744593039615, 0.3553744593039615, 0.3553731527820636, 0.35535491550631304, 0.35530203810233996,
            0.3551822044523274, 0.35325841503969313, 0.3407079865367384, 0.32142848853978767, 0.29901463475735124,
            0.2770820121682823, 0.1886551213457599, 0.09982344438244262, 0.062417143395163355, 0.04331753963032714,
            0.03210219491679123, 0.011446771426266045, 0.005761933994572699, 0.0033875401499960933,
            0.0021784905723920556, 0.0014890979217491163, 0.0010633917210036703, 0.0007836022668842313,
            0.0005945965248178586, 0.0004616347708125135, 0.0002934620719832193, 0.00019834797064355314,
            0.00014264548159226287, 0.000106211776247922, 0.00007941100212338625, 0.000060604212675618996,
            0.000047511604836403265, 0.00003825600992546363, 0.00003114952027782891, 0.000025269218465129306,
            0.000016140621012739054, 0.000010495407746446972, 0.000007154443246504272, 0.000005024469823089461,
            0.0000024114648783379877, 0.0000012304285039732576, 6.844322192334218e-7, 3.7929443841289157e-7,
            2.2266527821557247e-7,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.2)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21961419258306347, 0.2196123121243191, 0.21957198483599583, 0.2193843212162775, 0.21930497888603573,
            0.21920072656718748, 0.21615867992576898, 0.2028496646232022, 0.18159358804770243, 0.15957961644274077,
            0.1401004628421264, 0.07845686319256717, 0.03527011964767213, 0.020316882477339315, 0.01322456110727821,
            0.009250936196384951, 0.0026740942411911384, 0.001156832683209541, 0.000597919093627719,
            0.000342354328501715, 0.00020935115214693045, 0.00013450323620900484, 0.00008993255078404427,
            0.00006197218071668509, 0.00004384634170939705, 0.00002335999139280176, 0.000013264924187739169,
            0.000007870587432345921, 0.000004862339535893767, 0.0000030954672433081143, 0.0000020217434729685735,
            0.000001343545474985497, 9.14366489079832e-7, 6.271314580974519e-7, 4.3667565474692083e-7,
            1.8107745236584094e-7, 7.848911653951518e-8, 3.537598602942893e-8, 1.5952823062548305e-8,
            3.132687664830769e-9, 5.460286667258174e-10, 8.341083379548309e-11, 1.0232481528760218e-11,
            3.223532551999142e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.2)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.35184028847464666, 0.35183798046385106, 0.35176853388288243, 0.3513984263083726, 0.3504773254450231,
            0.34873862740384476, 0.3450080020710309, 0.32880977040460735, 0.30692487105998134, 0.2837547770453692,
            0.26108122462220806, 0.17296210432726, 0.09051824189220392, 0.05644151028628808, 0.03902547786976607,
            0.028799496632057164, 0.010117109705137683, 0.005032672629601942, 0.002929117053026009,
            0.0018655611818620965, 0.001265389522841276, 0.0008995691710365072, 0.0006610294164661745,
            0.0004995808275037872, 0.000388362543307319, 0.0002478335251552277, 0.00016668085156001933,
            0.00011626484171856077, 0.00008321572538760869, 0.00006186534176388925, 0.00004761243925255343,
            0.00003732078177620668, 0.0000293990337494332, 0.000023154325672458675, 0.000018580606165219393,
            0.000011371507738111575, 0.000007362874845817657, 0.000004885314721869705, 0.000003298635755766277,
            0.0000015001807293080205, 7.628049765173728e-7, 4.014249553874194e-7, 2.2241822250634868e-7,
            1.2370986768317493e-7,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.3)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.28759043184161137, 0.2875903569063635, 0.28758274130407846, 0.28753848686127936, 0.2874207836677193,
            0.2871990773485408, 0.2840651790199896, 0.2690270294030265, 0.24837655573918305, 0.22680590558462965,
            0.2063232788680437, 0.1313413196140386, 0.06552560804121621, 0.03956446539492742, 0.026634581664456092,
            0.01919252762238588, 0.006147086867198226, 0.0028770422215667057, 0.0016021089450057868,
            0.000988481103548322, 0.0006532771621546862, 0.0004537955474210875, 0.0003274631044254406,
            0.00024355817279086978, 0.00018568613648284327, 0.00011446492942057684, 0.00007498875521239068,
            0.000051464546376611686, 0.00003663978296797321, 0.000026869410032159813, 0.000020188749309091314,
            0.00001547970187198988, 0.000012073625388353548, 0.000009555821076090432, 0.000007659160929929243,
            0.000004609618684536798, 0.0000029192045069015293, 0.0000019216677564770634, 0.0000013039022604965922,
            6.438297909250323e-7, 3.4134732440627147e-7, 1.9087229863323785e-7, 1.1143224176723976e-7,
            6.734574697908613e-8,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.3)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21498202311424597, 0.21498202311424597, 0.21498143109669843, 0.21496561688856997, 0.2147338756975693,
            0.21423470112581244, 0.2107363880887937, 0.19637417301537952, 0.17762237974481554, 0.15555608265037324,
            0.13558250649428116, 0.07481051676265094, 0.033324803514445, 0.01894972166401665, 0.012211775361844923,
            0.008474877643336765, 0.0023666660233585546, 0.001003593470586428, 0.0005129584182165248,
            0.0002916321366701613, 0.00017878777536273205, 0.00011566698539167142, 0.00007767637819122708,
            0.00005399744274847684, 0.00003859057202360017, 0.000020653017643534888, 0.00001179950486529524,
            0.000007052348414249288, 0.000004362278666070196, 0.0000027600850251996434, 0.0000017906358471542205,
            0.0000011991971967661996, 8.085516242095636e-7, 5.556012063756555e-7, 3.845889592346552e-7,
            1.620682398284501e-7, 7.217108999313382e-8, 3.401258164004162e-8, 1.5470833392150496e-8,
            2.8667699325524154e-9, 1.614259836912879e-10, 2.2753243733575346e-11, 4.89386309254769e-12,
            3.223532551999142e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.3)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3553744593039615, 0.3553744593039615, 0.3553740265347315, 0.35536320562206836, 0.3553292479844147,
            0.35525626734096527, 0.35368534800195883, 0.3427461017592208, 0.32493509443543767, 0.3038884909157419,
            0.2826709351642487, 0.1945997713173777, 0.10372883370007535, 0.0646360045678479, 0.04450127165461104,
            0.032666282751671605, 0.01119508157223903, 0.005496287554771828, 0.003185859915648792,
            0.0020342468324656693, 0.001385423136503472, 0.0009909811179892314, 0.0007327949057854832,
            0.000556942549769901, 0.00043376894994628934, 0.00027807353126596546, 0.00018909795898736093,
            0.00013640577247642227, 0.00010270790397615137, 0.00007912001514309708, 0.00006125402108325684,
            0.000049309683966547446, 0.00004021572618084779, 0.00003310394991740129, 0.00002710420646734324,
            0.00001746129831492471, 0.000011371857681741204, 0.000007814985212628578, 0.000005505613752232463,
            0.0000028442974071563043, 0.000001565502738665181, 9.234112147149531e-7, 5.733873422730795e-7,
            3.635219346653784e-7,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.3)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21961420520744546, 0.21961409063049542, 0.21959447329849646, 0.21951369738767856, 0.2193291051763686,
            0.2192939603571573, 0.2173587992888849, 0.20632993593331794, 0.18743911057554674, 0.16677175451683623,
            0.14782361804038413, 0.0846783676266668, 0.03882491224512563, 0.02241137542228666, 0.014585027578225218,
            0.010210768994021624, 0.0029214234543801787, 0.001259778513323595, 0.0006546754939010802,
            0.00037870998500277864, 0.0002355785659537979, 0.00015364189612299128, 0.00010417356920244814,
            0.0000727801648722215, 0.000052011903313720076, 0.000028301125102103697, 0.00001635990664583531,
            0.000009878428232523717, 0.000006201877579603554, 0.000004000652225943924, 0.0000026415984806238413,
            0.0000017887223529022833, 0.000001224796363774594, 8.618671510918219e-7, 6.069286108134619e-7,
            2.6664537533527266e-7, 1.243214340362897e-7, 6.061176915928002e-8, 2.96158280121972e-8,
            7.510132515875512e-9, 1.3894362327349086e-9, 1.839205454601256e-10, 4.106615048016238e-11,
            1.0232481528760218e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.3)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3518403606373244, 0.3518400420690232, 0.3518005504909002, 0.35155526810523774, 0.3509153199953926,
            0.3497523217200691, 0.34584722614806507, 0.33193891633583217, 0.3110417835122812, 0.28889335982228836,
            0.26681081888236213, 0.17942217223937096, 0.09441271821549613, 0.05857624371420611, 0.04019352802133169,
            0.029425335523676432, 0.009981357318049722, 0.004849735784972942, 0.0027807479716559502,
            0.001759270423683179, 0.0011889553352241178, 0.0008430470278565361, 0.0006201724421136534,
            0.00047105130120073113, 0.0003672545479348477, 0.00023636983418628965, 0.0001602873839723351,
            0.00011403157704015232, 0.00008339393784906335, 0.00006251633225873299, 0.00004834643330842159,
            0.00003770944231318296, 0.000030106431226251118, 0.00002424127345213023, 0.000019791983542627456,
            0.00001255731282945316, 0.00000823970014052211, 0.000005560292895001595, 0.000003894687968353239,
            0.0000019216901546714737, 0.000001037604809961401, 5.739299445695067e-7, 3.2983618991622166e-7,
            1.9545158791434147e-7,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.4)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.287590431894301, 0.28759040098950406, 0.2875850001250252, 0.2875476321739747, 0.28744061206267624,
            0.2872289577496825, 0.2839791205227232, 0.2676992052321949, 0.2454699800574488, 0.22264784496760515,
            0.20133486237325238, 0.1257437103967416, 0.061711875428591134, 0.0368700915968041, 0.02458993834156331,
            0.0175672331454797, 0.005436953540884026, 0.0024846025823515028, 0.0013598935811355295,
            0.0008285168723735081, 0.0005425492633311935, 0.00037441677708771604, 0.00026896316148139476,
            0.00019946475445198342, 0.00015182078044018166, 0.00009354500297331647, 0.0000614134084798712,
            0.00004230490020340483, 0.000030260989343666722, 0.000022309551007460016, 0.000016857528774560926,
            0.000013000515447392225, 0.000010199232609364728, 0.000008119420659480348, 0.0000065452555351175334,
            0.00000399456382416119, 0.0000025639448693048905, 0.0000017098456871478263, 0.000001174904387446496,
            5.942932920222077e-7, 3.225686222574353e-7, 1.8462719231226998e-7, 1.1014561096533981e-7,
            6.799567819903984e-8,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.4)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21498202311424597, 0.21498202311424597, 0.21498108115832326, 0.2149648520189027, 0.2148578324930579,
            0.21447279398523222, 0.21090651012555806, 0.19569631703652968, 0.1767673100031112, 0.15626526148013753,
            0.1368115345141664, 0.07565954616355186, 0.03304778014561438, 0.018564899517542366, 0.01179116065023067,
            0.008082652118264977, 0.002161477895683772, 0.0008917032781519119, 0.0004490239073621227,
            0.0002540171369860289, 0.00015512678472973107, 0.00009989145571143165, 0.00006720524559900021,
            0.00004681486812319058, 0.00003337671528880204, 0.00001800022408104951, 0.000010319638092393113,
            0.000006222388233667608, 0.000003870105313441563, 0.0000024788686704413365, 0.00000162593771013686,
            0.0000010936316546805713, 7.513393929237111e-7, 5.201062711623905e-7, 3.6539868142337895e-7,
            1.5722540902540771e-7, 7.20089818884162e-8, 3.378962776245942e-8, 1.6775263511625838e-8, 3.8052793138732e-9,
            3.6244085510617197e-10, 1.6714629680336657e-11, 3.314570840018405e-12, 3.223532551999142e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.4)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3553744593039615, 0.3553744593039615, 0.35537341348921514, 0.35536042610750496, 0.35531855146611735,
            0.3552264395426341, 0.35324180017997997, 0.34008838757831716, 0.3194811696655562, 0.2965039957472817,
            0.2735778385027643, 0.18399088973914024, 0.09663361072913979, 0.059754064984608224, 0.040882111633477325,
            0.029817800996169597, 0.009934123533378414, 0.0047593884378127616, 0.0027057908217306137,
            0.00170063737376569, 0.001144654461073924, 0.0008111376333393983, 0.0005970769119910679,
            0.00045410363763453976, 0.00035311118816894194, 0.00022940899384515934, 0.00015898669347469419,
            0.00011444043991060138, 0.00008600822351345627, 0.00006721313587465527, 0.00005298059082936568,
            0.00004225405441116159, 0.000034262275703578204, 0.000028214052390285183, 0.00002329062073802568,
            0.000014945087330464624, 0.000009971755748794386, 0.000006860022839116908, 0.0000049613923600588805,
            0.0000026542815853014545, 0.000001504479715475071, 8.537913911421668e-7, 4.968398944482644e-7,
            2.9895969733129135e-7,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.4)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21961420520744546, 0.21961416774821796, 0.21960233196671042, 0.21952596227801113, 0.21935308616533722,
            0.2192718025567577, 0.21713032477045136, 0.20523642415719223, 0.18713637206256284, 0.16676092775865947,
            0.14748071286061504, 0.0841246459752133, 0.03791836017558947, 0.021616760677706015, 0.01390328555554754,
            0.009620780465056877, 0.0026566725406108604, 0.001118394549999402, 0.000571440821287128,
            0.00032704056339671883, 0.0002018908349544768, 0.0001316335165064686, 0.00008922970221691973,
            0.00006233703416502756, 0.00004461796047627864, 0.000024388931205931996, 0.000014167863429448424,
            0.000008615983635662516, 0.00000542448644447191, 0.0000035196242503809927, 0.0000023372913520169902,
            0.0000015912440796128635, 0.0000010992250363273115, 7.744869625625483e-7, 5.486184152525553e-7,
            2.4139554755109316e-7, 1.1378710107479151e-7, 5.6716947138824025e-8, 2.809030519301814e-8,
            7.0645597149976425e-9, 1.3642240670463934e-9, 1.8872947649128946e-10, 4.106615048016238e-11,
            1.1004308575479627e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.4)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.35184036187206635, 0.3518402203198998, 0.35181760699630804, 0.3516557713642806, 0.351210052017768,
            0.3503597555031084, 0.3453936597227041, 0.32935760554833604, 0.3054477800740891, 0.2815478153571801,
            0.25840202903622966, 0.16956602390645203, 0.08789790722058, 0.05403850087377182, 0.03679066223276606,
            0.02675712636881522, 0.00880241884445443, 0.004184027904506449, 0.002362591363849309, 0.0014750867773106746,
            0.0009871542165371672, 0.0006957835288231573, 0.0005108528857616923, 0.000385885646012607,
            0.00029960541405382735, 0.0001921835543731154, 0.00013046307224540143, 0.00009249105891606657,
            0.00006829850127954007, 0.00005224422668137496, 0.000040692056193503134, 0.000032034513321343994,
            0.00002577595672292876, 0.00002114959299337471, 0.0000173492881754278, 0.000011036311580170377,
            0.0000072521576274242605, 0.000004892763965536062, 0.000003447621778174259, 0.0000018351317889742091,
            9.75205725395334e-7, 5.470724220302259e-7, 3.275174833561323e-7, 2.067501473357325e-7,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.5)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.287590431894301, 0.2875903937126555, 0.28758154392762125, 0.2875233235295894, 0.2873621872919372,
            0.2870522973076255, 0.28264330004857974, 0.26276843622918, 0.2376957252715649, 0.21320902362432037,
            0.19110915578036147, 0.11660416986338595, 0.056449790395546984, 0.03352158199432076, 0.022243940996093503,
            0.01581532604435565, 0.004792868722034438, 0.002154459653931573, 0.0011640908900176772,
            0.0007020960222170469, 0.00045614316574293934, 0.0003128591924203006, 0.00022368453410803735,
            0.0001652915067188454, 0.0001254725881344454, 0.00007705869798335652, 0.00005052378577452554,
            0.00003480043809689359, 0.00002490966014667695, 0.000018385733168702068, 0.000013912940666549467,
            0.000010747395397969761, 0.000008446799720673859, 0.000006736488316838596, 0.000005440229980835376,
            0.0000033346846727289048, 0.000002149983877486328, 0.0000014401708933187152, 9.940696783505842e-7,
            5.075409441612777e-7, 2.7818159409331855e-7, 1.6080219167324827e-7, 9.69030715669561e-8,
            6.042110145543234e-8,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.5)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21498202311424597, 0.21498202311424597, 0.21497992538279231, 0.2149518823699027, 0.21474531529983643,
            0.21421650173841877, 0.20954367456459355, 0.19208498841948474, 0.17108723534516823, 0.14930078471997366,
            0.12962667942421946, 0.07015966341985902, 0.03013500443635997, 0.016752648814944426, 0.010529689953427734,
            0.0071651566067698624, 0.0018504770314515806, 0.000748704133433753, 0.00037135429857049207,
            0.00020772423826309616, 0.0001259659619525788, 0.00008092595928166357, 0.00005419043127785628,
            0.000037505734344356334, 0.000026693051784598687, 0.000014409296041684172, 0.000008275470067209412,
            0.000004983432114746833, 0.0000030967235442602714, 0.000001981683155793945, 0.0000013025436487179576,
            8.760546736930053e-7, 6.024052584541195e-7, 4.195364486925257e-7, 2.961738481133125e-7,
            1.2912054003777484e-7, 6.002845442942828e-8, 2.9400618939412482e-8, 1.4448292784408068e-8,
            3.4440997831453046e-9, 4.85269380234854e-10, 1.0232481528760218e-11, 3.3140157285060923e-12,
            2.9594104944408173e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.5)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3553744593039615, 0.3553744593039615, 0.355372625266675, 0.3553508857165333, 0.3552816466742059,
            0.3551274340659689, 0.3522055014156831, 0.3347753209385207, 0.31068253636522625, 0.2849919964330113,
            0.26055540409485034, 0.17076510994098193, 0.0880439286870861, 0.054294072408097804, 0.03704748960120141,
            0.02695594367531373, 0.008841972812632326, 0.00416991695591562, 0.002327085483513347, 0.001439889048454157,
            0.0009601951550145538, 0.0006736965040159415, 0.0004937341639957848, 0.00037473487807337946,
            0.00029220800203333397, 0.0001890669058429717, 0.00013028627316324926, 0.0000949491787927137,
            0.00007263283806147047, 0.00005583741149195465, 0.000043998127414623944, 0.000035262925928480904,
            0.00002883411623799681, 0.000023705103107340975, 0.00001968415667163992, 0.000012787898176602752,
            0.000008570694671417023, 0.000005999354017238723, 0.000004314376975345091, 0.0000023623347096268787,
            0.0000013129020772773004, 7.484410461389146e-7, 4.426958688519278e-7, 2.7007183645011423e-7,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.5)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21961420520744546, 0.21961414021092573, 0.2195946268190857, 0.2194978338553606, 0.219308143058925,
            0.21918421048867576, 0.21610372383923204, 0.20171117414783435, 0.18152053026319526, 0.15969600367155168,
            0.13991466323046542, 0.07815822190048105, 0.03478333048004223, 0.019585090618917445, 0.012455134904713727,
            0.008549046042085284, 0.0022861458000047463, 0.0009426208863105856, 0.0004744498151632026,
            0.0002700338157871629, 0.0001658280907878007, 0.000107719253911287, 0.0000729108948982482,
            0.00005096723259656599, 0.000036461681995780815, 0.000019889890803814758, 0.000011593704216106282,
            0.000007075459756311631, 0.000004456909109551432, 0.0000028884048822730435, 0.00000192670638821113,
            0.0000013097581489374122, 9.026509846243869e-7, 6.35207321963982e-7, 4.535234029434676e-7,
            2.0299807823587201e-7, 9.536689959954714e-8, 4.6398904274269626e-8, 2.3416688921251705e-8,
            6.0762085363208485e-9, 1.0086536050835093e-9, 1.4826695426961578e-10, 2.3860469156034014e-11,
            4.686584453850173e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.5)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.35184036187206635, 0.3518401951818928, 0.3518022652043651, 0.3515688827636225, 0.35096389822753793,
            0.34985312077109443, 0.34386581581049347, 0.3234453587109395, 0.2960836824973292, 0.26997598683611546,
            0.24532715612614864, 0.15707843769237262, 0.08030280359264841, 0.04909407862887527, 0.033322938555827464,
            0.0241296629918214, 0.0077829686417140165, 0.0036353646091625835, 0.002019174464883089,
            0.001245541783595705, 0.000827433441488945, 0.0005785983187708288, 0.00042156883878419205,
            0.0003170671496908195, 0.00024519727010363646, 0.0001551000127083535, 0.00010493507905817712,
            0.00007489875896149467, 0.00005583922560381538, 0.00004265903792055603, 0.00003321697449332195,
            0.00002656562914671312, 0.000021368029141655143, 0.000017457848195068237, 0.000014411612610287783,
            0.000009193869101697949, 0.000006124935820639443, 0.0000042517582909962215, 0.000002956060427661278,
            0.0000015127969923423024, 8.234087569558568e-7, 4.694812127947756e-7, 2.934280605160211e-7,
            1.8952913194425491e-7,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.7)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.28759043184072575, 0.28758972622713685, 0.287547104096478, 0.2873403973682036, 0.2868560108489622,
            0.2860318800353594, 0.2770174116567491, 0.24679776907073858, 0.2154380312739631, 0.18809265064893677,
            0.16518105455225085, 0.09566384721554377, 0.04489419164273667, 0.026295761910330118, 0.0172648958056012,
            0.012158014295281738, 0.0035374893526595175, 0.0015403500152753047, 0.0008115461705204829,
            0.000479653010604264, 0.00030655391034939417, 0.00020746578162122997, 0.00014671168974256243,
            0.00010743218960573842, 0.00008093802368402958, 0.00004913209891299619, 0.0000319396595432897,
            0.000021856308543677372, 0.000015560511338330585, 0.00001143225033950479, 0.000008615771543984962,
            0.000006630572119803446, 0.000005192255541630608, 0.000004125668528365267, 0.000003319864694151242,
            0.0000020174481066836947, 0.0000012898161376728723, 8.56741702692787e-7, 5.86814289160742e-7,
            2.9535453227591546e-7, 1.5973679423630927e-7, 9.128110312062176e-8, 5.445608319831763e-8,
            3.36105452358737e-8,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.7)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21498202311424597, 0.21498200947007662, 0.21496170622435817, 0.21475522987476625, 0.2140387476648753,
            0.21307818422476354, 0.2040781019687139, 0.18021866065280057, 0.15385668755703352, 0.12975473811007743,
            0.1101797905474704, 0.056840210604995334, 0.023743713870664873, 0.01292046850935702, 0.007992909969684714,
            0.005335442284923775, 0.0012953693387007803, 0.0005021359189305574, 0.0002411849379014841,
            0.00013187350773991025, 0.00007831239128008871, 0.00004938106124829922, 0.00003252756048521821,
            0.0000221432666237531, 0.00001552327393838926, 0.000008117683573671819, 0.000004547169204172086,
            0.000002674883620157331, 0.0000016292896999337003, 0.0000010212884100635478, 6.569218145013878e-7,
            4.3128068205611214e-7, 2.906675110603274e-7, 1.9942215068180502e-7, 1.3853495850479902e-7,
            5.6856134134086744e-8, 2.5383099511167018e-8, 1.171305485936358e-8, 5.32822330701066e-9,
            1.0008315287635128e-9, 2.1920354420501553e-11, 3.2843727737486006e-12, 2.8199664825478976e-12,
            1.613265077082815e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.7)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3553744593039615, 0.3553744432983189, 0.35535866175898734, 0.3552443795519483, 0.3549408490344319,
            0.35450139735455255, 0.34805849258436994, 0.3178266456896851, 0.2848164858925818, 0.2541829261946935,
            0.22763745496065924, 0.1401166705589978, 0.06979682345969063, 0.04261655906068562, 0.02897245431068285,
            0.020980709746255766, 0.00669349233005978, 0.003072557967291112, 0.0016734284291002322,
            0.001015260486576186, 0.0006647602003394537, 0.0004597357563693727, 0.0003328906797623876,
            0.00024937715736472743, 0.0001919207256687594, 0.0001226817887738374, 0.00008620256707347895,
            0.00006205805929848651, 0.000046467156832541434, 0.00003602482202869073, 0.000028682121569256758,
            0.000022907113502390608, 0.000018551395502108825, 0.000015212305741529875, 0.000012552554532141436,
            0.000008052920585921441, 0.0000054394598829610175, 0.000003783738179219398, 0.000002685762782839163,
            0.0000013871881695548538, 7.443018616992703e-7, 4.165256467514311e-7, 2.527572754429386e-7,
            1.5805112241196184e-7,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.7)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21961420520744546, 0.21961314933304943, 0.21955008978733048, 0.2192953600127019, 0.21908534491891019,
            0.21857038325625666, 0.21240112649901766, 0.1902239764303124, 0.16373408291619496, 0.13951138554961395,
            0.11962177067796442, 0.06388601561234564, 0.027557307508972673, 0.015123004280474417, 0.009436954642527473,
            0.006374090829152523, 0.0015963892513902733, 0.0006300072610593288, 0.0003085298372397238,
            0.00017132549878851044, 0.0001032426767456629, 0.00006596543266779165, 0.000044047041533601394,
            0.0000303402909958006, 0.000021472167368030703, 0.000011474115920395711, 0.000006542609407467204,
            0.000003923990726018012, 0.0000024419393438446235, 0.0000015589165036633545, 0.0000010221431295809325,
            6.871269389030488e-7, 4.718607986564649e-7, 3.2819115913795827e-7, 2.310337965383269e-7,
            1.0188328758431453e-7, 4.632782590885398e-8, 2.1256828630633606e-8, 1.0415673545871584e-8,
            2.0247011844887197e-9, 1.4441370321804925e-10, 2.1035062580665453e-11, 4.118372309847018e-12,
            1.9693136010801027e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(0.7)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3518403606373244, 0.3518365756753966, 0.3516679850459984, 0.3509459439061242, 0.3493964959161546,
            0.3477367349559235, 0.3378425245337555, 0.30445497381049014, 0.2703337869856923, 0.23936037379233455,
            0.21253431512483312, 0.12907081622940675, 0.0638968818523955, 0.03862169716968211, 0.025997050329440663,
            0.018679448218631323, 0.0058112008157151784, 0.002623797104723602, 0.0014188737561252607,
            0.0008580462572271852, 0.0005579969953948272, 0.0003847260344271852, 0.0002777145937674641,
            0.00020716843833268328, 0.0001583093731105656, 0.00009921045865368683, 0.00006705206775270423,
            0.00004748814257993228, 0.00003537346973858657, 0.000027360709616619516, 0.000021270099782033647,
            0.000016813443344920742, 0.000013531186417781171, 0.00001103075582586932, 0.000009021975416967365,
            0.000005720180732970981, 0.000003838087197394202, 0.0000025969135815095967, 0.000001776420158750014,
            8.835899778514289e-7, 4.751461356589104e-7, 2.7388505663417106e-7, 1.677204093386564e-7,
            1.0188526256005304e-7,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(1.0)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2875903566207363, 0.287577670922722, 0.2872986711624853, 0.28635723631114884, 0.28461386551020196,
            0.2821200066375943, 0.26272822274858987, 0.21787831875586028, 0.18107667029430086, 0.15283428561327206,
            0.13100040864172013, 0.07148197304778203, 0.03221891200185478, 0.018459082008352, 0.0119057540657731,
            0.008252679983159927, 0.0022549842007788534, 0.0009378632747842897, 0.00047716118273866126,
            0.0002744625420624258, 0.00017169469998568054, 0.0001142148864442527, 0.00007963922060694184,
            0.00005763850596951503, 0.000042994111988381434, 0.000025675383557910436, 0.00001646606030725195,
            0.00001113037463462696, 0.000007833178076066538, 0.000005692927803280234, 0.000004244677875520075,
            0.000003232063061298085, 0.0000025046509032344844, 0.0000019698123485678565, 0.0000015687714106649153,
            9.299737299128807e-7, 5.807677828650881e-7, 3.773471048962307e-7, 2.5313408957838845e-7,
            1.2267779381236465e-7, 6.397763907539655e-8, 3.533763626162312e-8, 2.040709135009422e-8,
            1.2253031256292957e-8,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(1.0)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21498202311424597, 0.21497827185385265, 0.2147170344116729, 0.21353321523373414, 0.21195025789444633,
            0.20970426184230317, 0.19343466285572886, 0.15797352650075847, 0.12667105461581207, 0.10299153848732212,
            0.0853590165654029, 0.041820688735997424, 0.016654901297913582, 0.008688509534671263, 0.005186659352657608,
            0.0033712311502188674, 0.0007427409782051475, 0.0002687985217576516, 0.00012295095748893203,
            0.00006453088534608131, 0.00003706010720194719, 0.000022599016211155565, 0.000014479849491877417,
            0.000009600772754136955, 0.000006558126435463052, 0.0000032463085261058566, 0.0000017260668727647754,
            9.654352184362835e-7, 5.641631732888897e-7, 3.365298102409042e-7, 2.0729741234681853e-7,
            1.2981626673624902e-7, 8.369927040341452e-8, 5.400870606298014e-8, 3.5831416500542446e-8,
            1.2382130099020117e-8, 5.06402075828305e-9, 1.7272147045943598e-9, 6.935989560474809e-10,
            2.1771806579806707e-11, 3.223532551999142e-12, 1.613265077082815e-12, 1.613265077082815e-12,
            1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(1.0)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3553744593039615, 0.3553700413636861, 0.3552160797256605, 0.3545981779623959, 0.3536962699412478,
            0.35130617063511715, 0.3326447062052639, 0.2851902061055779, 0.24334969062462664, 0.2099253430342134,
            0.18309728849486706, 0.10598163164560315, 0.05121891696643277, 0.03096458956660697, 0.020882634442351278,
            0.015047221311848324, 0.004625225318201531, 0.002048362588279229, 0.0010893967163148055,
            0.0006531357739297805, 0.00042450364336510393, 0.0002917879704870119, 0.00020829837566449516,
            0.0001536626019907894, 0.00011790285331214623, 0.00007525959565779505, 0.00004945789170440751,
            0.00003449593311155574, 0.000025898313307237686, 0.00001967666356894604, 0.000015212033518507972,
            0.000011986021645760125, 0.000009577818817607664, 0.00000777649481498166, 0.000006422326622512742,
            0.0000039437465894076595, 0.0000025793968631004915, 0.0000017697410844563777, 0.0000012323046738593746,
            6.307853951392772e-7, 3.439174323105476e-7, 1.9684405572206742e-7, 1.1709666836168253e-7,
            7.1828416992048e-8,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(1.0)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21961405580662352, 0.21958923360858706, 0.2192849176195517, 0.21881021942942813, 0.2175616005486838,
            0.2157102307455766, 0.20240551302909227, 0.16780019432716542, 0.135704413963891, 0.1115490004811589,
            0.09350753875265028, 0.047270686892270875, 0.01930023712400064, 0.0102276892859684, 0.006184013229800223,
            0.00406213501764241, 0.000923384616219769, 0.0003418514518571669, 0.00015962191990903651,
            0.00008552905424630275, 0.000049856855367735875, 0.00003087219691855214, 0.000020077250356798082,
            0.000013439738861587003, 0.000009278205657659022, 0.00000469255436930105, 0.0000025593668510071055,
            0.0000014637514673676577, 8.651154776995185e-7, 5.285237817398425e-7, 3.3166085866565e-7,
            2.1234395730651556e-7, 1.3780889895897985e-7, 9.107888465642588e-8, 6.078273562248881e-8,
            2.3639769031191804e-8, 8.87471074406676e-9, 3.2719542630843534e-9, 1.1016535461649823e-9,
            6.066114277558654e-11, 5.805800284974794e-12, 1.680211525467712e-12, 1.613265077082815e-12,
            1.613265077082815e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(1.0)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3518399860923226, 0.3517928308281302, 0.35089227358936836, 0.3481361258096526, 0.34573267427038157,
            0.3424545934880051, 0.32149344692657, 0.2711417834108997, 0.22876492817465133, 0.19548299172864259,
            0.16972109402343272, 0.09743748671440888, 0.046549561349930935, 0.027757416890109576, 0.018466370160642454,
            0.013111196830722593, 0.003873763946424469, 0.0016795167574851222, 0.0008779933736969836,
            0.0005172927782228776, 0.00033189839312552927, 0.00022758280567614086, 0.0001616031062592338,
            0.0001190534518153985, 0.00009072533746889988, 0.000056529591970555515, 0.00003793024995590866,
            0.00002666495231495869, 0.00001928421544783454, 0.000014439683857503383, 0.000010962794996238756,
            0.000008539335016322092, 0.00000677939981252873, 0.000005449432660564746, 0.000004396829089703402,
            0.000002665895464937229, 0.0000016807498545157173, 0.0000010925581335197165, 7.292803427816352e-7,
            3.73107866380451e-7, 1.8990093053616874e-7, 1.0266079186749266e-7, 6.054832368818097e-8,
            3.4306133045447496e-8,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(1.5)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2875773147983537, 0.28731255862060456, 0.28488494220120797, 0.2799423778277321, 0.273341140240956,
            0.2658404696189174, 0.2269619631137646, 0.1687074625320789, 0.13185643610190867, 0.10700923294513043,
            0.08922353180841192, 0.045181148657429726, 0.018865208182566096, 0.010229293898450398, 0.006302690794429466,
            0.004199375496950486, 0.0009942974390887427, 0.0003770670941478649, 0.00017983239163921194,
            0.00009862115357661683, 0.00005945600380330429, 0.00003837944216700251, 0.00002608503121470651,
            0.00001845499849273402, 0.000013480065915061346, 0.000007735008412335098, 0.0000047714181992120075,
            0.000003104536999809504, 0.000002103294645228182, 0.0000014706459697810814, 0.0000010547229107871203,
            7.725752377840678e-7, 5.760324263137662e-7, 4.362877826791234e-7, 3.350600535911852e-7,
            1.8175147786791968e-7, 1.043497915853564e-7, 6.248321837709625e-8, 3.8616478792441455e-8,
            1.59545450184595e-8, 7.224340348166436e-9, 3.4785752056620822e-9, 1.7377130845375177e-9,
            8.7642049173553e-10,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(1.5)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21497947165458575, 0.21471815340521005, 0.21238519518265253, 0.2076914695125871, 0.20218997524952265,
            0.1965103820339107, 0.16620009239578515, 0.11803628230249952, 0.08846331796000573, 0.06931682267292627,
            0.05606069674758163, 0.02539435510924859, 0.009084300838824388, 0.004376692991678532, 0.0024483625693050293,
            0.0015078671460011517, 0.00027582542128568033, 0.00008919862667833289, 0.00003695006204795792,
            0.00001760474065148898, 0.000009188384504033031, 0.0000051417671178555224, 0.0000030425231295483357,
            0.0000018585595309428271, 0.0000011724983390859478, 5.030615263246219e-7, 2.3277448180092364e-7,
            1.1318447235009188e-7, 5.759459875687156e-8, 2.9560230596636927e-8, 1.5703641054187756e-8,
            8.810037144257876e-9, 4.415349197373075e-9, 2.4159461098349766e-9, 1.2294303353144187e-9,
            1.939745031265261e-10, 1.6558199256166972e-11, 3.223532551999142e-12, 1.613265077082815e-12,
            1.613265077082815e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.0732525979051388e-12,
            1.0578204978628492e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(1.5)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.35537186244085206, 0.355250758434398, 0.3535766536800411, 0.34985097516688424, 0.3426935574862663,
            0.3353930446914203, 0.2936790334271706, 0.2270297072599362, 0.1821148365886227, 0.15138367143703368,
            0.12873660763958117, 0.06972268720690622, 0.03158199647769011, 0.018239573563022082, 0.011818602708449255,
            0.008203146464472733, 0.0022526945984802094, 0.0009396156167498226, 0.0004779937914518584,
            0.0002781811351700858, 0.00017707919761056345, 0.00011844224378498858, 0.00008294302473543791,
            0.00005925958857533331, 0.00004416840025844859, 0.00002734106870538877, 0.000018166094624239015,
            0.000011692585206390937, 0.000007964650056035971, 0.000005627655716766711, 0.000004173040881405576,
            0.0000030842420203347487, 0.000002365342098853418, 0.0000018622300567150418, 0.000001469468315251632,
            8.48375726780759e-7, 5.152055005952505e-7, 3.2660949500495917e-7, 2.132954222133776e-7,
            9.802149492088574e-8, 4.754233984272105e-8, 2.4136383558470698e-8, 1.2644395752658966e-8,
            6.697335130922966e-9,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(1.5)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2196022787797841, 0.21933751638079346, 0.21798350658245946, 0.21440947650511055, 0.21016940993009492,
            0.20569413543915283, 0.17555726987769094, 0.12670622653178154, 0.09589569444262636, 0.0757004816358735,
            0.061693152305835386, 0.02880144828887954, 0.010647733514821844, 0.00523224077506057, 0.0029798829769691615,
            0.0018603634273697267, 0.00035201712753452075, 0.00011559904089675221, 0.00004894641870623051,
            0.00002402582005434084, 0.000012904405938818364, 0.0000073760123525667964, 0.000004446376937639052,
            0.000002773126224875355, 0.0000017829269405300607, 7.981766294573589e-7, 3.7886558090782785e-7,
            1.88879737295089e-7, 9.715478155314372e-8, 5.093715182979963e-8, 2.6957203025368415e-8,
            1.4514871748971814e-8, 7.99197430456644e-9, 4.192157954108211e-9, 2.039007407361737e-9,
            2.511636454372024e-10, 3.491562594604147e-11, 6.727618462321061e-12, 3.223532551999142e-12,
            1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12, 1.453503983839255e-12,
            1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(1.5)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.351741957266667, 0.35030137220001645, 0.3461363230858534, 0.34047624835071977, 0.33272955550648353,
            0.3246566903437941, 0.2813022550508949, 0.2138837852266019, 0.170005068102976, 0.14020076781971613,
            0.11839843026080443, 0.06320260288756918, 0.02826117653062099, 0.016003466945096023, 0.010207051335549444,
            0.00699728744805117, 0.0017784652333172613, 0.0007025041581849578, 0.0003435429735696438,
            0.00019378767760958038, 0.00012263331649309261, 0.00008227975158825451, 0.00005829676904167691,
            0.000043322831189351874, 0.000033143192804341304, 0.000020547966907424886, 0.00001292280136255819,
            0.000008391769686588724, 0.000005735575138832161, 0.000004080669890771382, 0.0000030185098244261965,
            0.0000022012220481926192, 0.0000016511285210185633, 0.0000012747886581898626, 9.667224893927795e-7,
            5.294778445685822e-7, 3.1068364592101716e-7, 1.8123840561656834e-7, 1.1171420766231677e-7,
            4.796122965444738e-8, 2.2592393733589233e-8, 9.340365814125562e-9, 4.588970092811451e-9,
            2.528446785277083e-9,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(2.0)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2874614472454433, 0.28607321007780384, 0.2783176124368123, 0.26689035348512424, 0.25439850795907093,
            0.2420227755409251, 0.19080906379483997, 0.13103477180165413, 0.09839706156992556, 0.07783799419735038,
            0.06368162105950015, 0.03033171243782251, 0.011744158499357171, 0.006042749585550511, 0.0035766776785696175,
            0.0023077700047353655, 0.0004963953912792141, 0.0001800972079956109, 0.00008394333913952501,
            0.00004540132520935991, 0.00002707874950258482, 0.00001730305725089476, 0.000011633174567537985,
            0.000008129636707931986, 0.0000058564046513387424, 0.000003258781788528786, 0.0000019446272313539126,
            0.0000012213780135050811, 7.978965710364605e-7, 5.377056583322215e-7, 3.7191589952190185e-7,
            2.63000216826903e-7, 1.896570686055199e-7, 1.3899958084540032e-7, 1.0323987253979539e-7,
            5.1629223007942926e-8, 2.7326212181932874e-8, 1.5050709567532294e-8, 8.521944594441777e-9,
            2.846331614847486e-9, 1.0641376668729663e-9, 4.3857095732846574e-10, 1.7262347107305231e-10,
            6.841971433857452e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(2.0)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21488689401508998, 0.21318591560568956, 0.20629759339880915, 0.19762376580387908, 0.18805375189001894,
            0.1787410475900295, 0.1372408084422091, 0.08891800537104677, 0.0637755770902999, 0.04854122546698603,
            0.03835180220894585, 0.015972400940410747, 0.0050569193548331, 0.002224318507944467, 0.001161392362573288,
            0.0006771041919297893, 0.0001042386261044248, 0.00003019982590724002, 0.000011467140718535163,
            0.000005012679375915319, 0.00000240781533633605, 0.0000012378851169669858, 6.695617722485281e-7,
            3.771003199704026e-7, 2.1997932475592563e-7, 8.009941798725606e-8, 3.0615742274697766e-8,
            1.1984181647051173e-8, 4.954541554802461e-9, 2.15556916760562e-9, 7.4631489876964e-10,
            2.152753530992868e-10, 8.318645772220634e-11, 2.593802950201507e-11, 1.0155432050851232e-11,
            1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12,
            1.453503983839255e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(2.0)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.35531574878026173, 0.35449863219845523, 0.34804533351442, 0.33593532787335323, 0.3228630867839215,
            0.3093327242917875, 0.2517296229486068, 0.18061323882538616, 0.14048258347793197, 0.11437787124679433,
            0.09570513778955647, 0.049090086868228244, 0.021189825378362337, 0.01187443264499588, 0.007579423515546524,
            0.005223470087020732, 0.001333588010453357, 0.0005547314969904793, 0.00028042655149118634,
            0.00016050960069480258, 0.0001019966971181896, 0.00006930044581143768, 0.000049837775839556286,
            0.000037252123142761384, 0.00002721467791200194, 0.000015226439103899203, 0.00000897518858722357,
            0.00000585142558851004, 0.0000039637687030413815, 0.0000027176311699905753, 0.000001902806083697861,
            0.0000014064673905522795, 0.0000010421955359829482, 7.356928628521331e-7, 5.292134948042815e-7,
            2.668635374902806e-7, 1.4261992475894658e-7, 8.402803264306868e-8, 5.052715579267897e-8,
            1.9955347596578576e-8, 7.971433291231733e-9, 3.6366769595730375e-9, 1.5969504607582508e-9,
            5.309490624938462e-10,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(2.0)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2194949695088455, 0.2185671414299405, 0.2133201110925801, 0.20628747136634584, 0.19739803902209607,
            0.1879587977145578, 0.1461058548975821, 0.09616803913537542, 0.06957071636497958, 0.05339974864633401,
            0.042592118577547855, 0.01830960497013634, 0.006047386093681495, 0.002741913305649102, 0.001465422282781681,
            0.000867476203792017, 0.00013977501524575775, 0.00004154008728529668, 0.00001598335757890812,
            0.00000712637603994537, 0.0000035092928178936944, 0.000001841974512695188, 0.000001010511349486265,
            5.774819411552556e-7, 3.406274633466211e-7, 1.2570355878427364e-7, 4.948437293261776e-8,
            2.0392404209701454e-8, 9.113181320685726e-9, 3.9341960800243214e-9, 1.3602619031161112e-9,
            4.2053360793659067e-10, 1.5676149267562778e-10, 5.5920601482739585e-11, 1.823341477802387e-11,
            3.223532551999142e-12, 1.8425261316679098e-12, 1.613265077082815e-12, 1.613265077082815e-12,
            1.5698553568199713e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(2.0)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.35110374473891337, 0.3473712560498535, 0.3384691097256074, 0.3252902915762639, 0.31131631035831897,
            0.29729802141139694, 0.23839876187202858, 0.16845964861019314, 0.12923536482699594, 0.10421012618700765,
            0.08671431886618752, 0.04411807070100049, 0.018551354108700235, 0.010114039511545991, 0.0062123421534612255,
            0.004119147462869011, 0.0009560819980535262, 0.0003638162655230648, 0.00017429627950948934,
            0.00009943136982726308, 0.00006250514359362747, 0.000041234823307090274, 0.000028910260619352535,
            0.000021080338868495474, 0.00001595558231493044, 0.000009532672058609748, 0.000005828369585847426,
            0.000003842405559950635, 0.0000025406061022748005, 0.000001706528502176674, 0.0000012264081575841246,
            8.745810191612691e-7, 6.32058458904794e-7, 4.624565355104693e-7, 3.444880576353171e-7,
            1.6406039515803883e-7, 8.563992537347076e-8, 4.704028455648768e-8, 2.6635497363258764e-8,
            7.771230770003967e-9, 3.260111069991467e-9, 1.3965351097766643e-9, 5.179520146114669e-10,
            2.3131085935546025e-10,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(3.0)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.28468118685869015, 0.27367096602157204, 0.24576420313973757, 0.220410047618383, 0.19920211516773656,
            0.18155758750467388, 0.125753431033638, 0.07756220458952445, 0.05517667400689308, 0.04201233774233937,
            0.03332039586863644, 0.014191946576306291, 0.00477374417710319, 0.0022363212782832154, 0.001236370887046423,
            0.0007579035695097769, 0.00014451088028255565, 0.00005116862778642428, 0.00002382581029292119,
            0.00001287734170296595, 0.000007626586054132467, 0.000004805154462772521, 0.0000031685454719987405,
            0.0000021628884452340458, 0.0000015179842572932145, 7.977881308907087e-7, 4.4843911550795923e-7,
            2.6538942721199277e-7, 1.634604309685983e-7, 1.0396935412604336e-7, 6.790374940113253e-8,
            4.546152188034114e-8, 3.1045738202450934e-8, 2.1550246476209622e-8, 1.51135942649816e-8,
            6.363887639082577e-9, 2.6284595611159034e-9, 1.0668153027637572e-9, 3.803325432372162e-10,
            5.947897729896567e-11, 1.957367601335136e-11, 9.355516361608807e-12, 6.0990101857782975e-12,
            5.26856336335868e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(3.0)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21224476009055837, 0.2023969187184368, 0.18190694592166623, 0.16125943535456677, 0.1432913052344874,
            0.12891685874136072, 0.08495029955534339, 0.04912661991115275, 0.03323206280580304, 0.02416815683451845,
            0.018349907575628044, 0.006444684601758843, 0.0016156129537544528, 0.0006127255032671863,
            0.00028779542895196464, 0.00015435699306265427, 0.000017969139229023767, 0.000004020460983578111,
            0.00000116852972609216, 3.978843879925975e-7, 1.5077766502002987e-7, 5.955326132234262e-8,
            2.4110298979529432e-8, 9.786649934895308e-9, 4.245413798287245e-9, 8.878023871616847e-10,
            1.4938927872520935e-10, 2.7655988610320037e-11, 3.223532551999142e-12, 1.613265077082815e-12,
            1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12,
            1.613265077082815e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(3.0)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3536192381043246, 0.3434302675938752, 0.31458304201425835, 0.28627117102090094, 0.2619414867797344,
            0.24089331623506172, 0.1728003214980256, 0.11183967130675165, 0.0820819271952914, 0.06425789043055063,
            0.05231135994778413, 0.025006763700065937, 0.00996998772867963, 0.005200208335132905, 0.003102398796513306,
            0.0020383765114730945, 0.0004655026134616769, 0.00019890925393084569, 0.00010717641146407164,
            0.00006423269613964333, 0.00004111033251008589, 0.00002752623291057965, 0.00001906305513921236,
            0.000013550660797889691, 0.000009838980919996132, 0.000005485112069991693, 0.0000032319998040097886,
            0.00000199276527601544, 0.0000012726102271987116, 8.368463439989426e-7, 5.64042231476769e-7,
            3.8760153120431795e-7, 2.700320350657037e-7, 1.8700356541057062e-7, 1.3052368497135092e-7,
            5.389618829010345e-8, 2.090053397019176e-8, 7.553041747598854e-9, 2.281587141439445e-9,
            3.014924976341149e-10, 9.158440672507595e-11, 3.522082625551093e-11, 1.246969194568237e-11,
            1.0945466755174493e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(3.0)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21799893158006578, 0.21095064339855196, 0.190964837482977, 0.1701413614347469, 0.15274486688896893,
            0.13808703211457563, 0.09190733353660385, 0.05400075734010912, 0.03699484523468577, 0.027186909421315852,
            0.02084304149405758, 0.007642876430214884, 0.0020315715839686055, 0.0007944762376521641,
            0.0003803086985465498, 0.00020771593249058196, 0.000024987315175528657, 0.000005761543334092956,
            0.0000017570390247190204, 6.176988442962994e-7, 2.400521277312606e-7, 9.861624461482421e-8,
            4.236911987920422e-8, 1.8971922477462044e-8, 8.845513210786748e-9, 1.7705777954901691e-9,
            2.3348389888155907e-10, 4.908196071795601e-11, 5.628053578732306e-12, 3.223532551999142e-12,
            3.223532551999142e-12, 3.223532551999142e-12, 3.223532551999142e-12, 3.223532551999142e-12,
            3.223532551999142e-12, 1.6090462295892394e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(3.0)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.3461169413161569, 0.3337583165695197, 0.302461210655375, 0.27364793086536676, 0.24894039061913142,
            0.22802357655216365, 0.1612920919737858, 0.10271326743421827, 0.07496452455231384, 0.05839056716058899,
            0.04732182112436978, 0.021938370486741965, 0.008170758647923382, 0.0040285005294739, 0.0022982937933186953,
            0.001446327460108754, 0.00027957050044113974, 0.00009618668868072167, 0.00004558365889872906,
            0.000025142551751566877, 0.000015226686169267367, 0.00000976319006074089, 0.000006595151598509652,
            0.00000464174899550418, 0.000003333850145281758, 0.0000017497849285064149, 9.749804803504958e-7,
            5.74861360291834e-7, 3.6090705179958604e-7, 2.3413075211919931e-7, 1.5565399280603742e-7,
            1.0566341046569505e-7, 7.334683260751262e-8, 5.138167225027246e-8, 3.641961399125648e-8,
            1.5771357553262533e-8, 6.468863222863774e-9, 3.116271130032544e-9, 1.3882405225373873e-9,
            1.8257506617658237e-10, 5.638411959552059e-11, 1.639477442694215e-11, 1.0945466755174493e-11,
            1.0232481528760218e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(4.0)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.27514007151055864, 0.2493108773120526, 0.205323884364091, 0.17409338325447332, 0.15132274554057856,
            0.13404038428819165, 0.08622891424134316, 0.050108622661090285, 0.03431558752543906, 0.02533196496723622,
            0.019564948988844755, 0.007584709449588556, 0.0023133672331419053, 0.0010341266396682869,
            0.0005589943918600238, 0.00033972337086785043, 0.0000669471704363156, 0.000024770904909043878,
            0.000011809400330742292, 0.000006427878572745627, 0.00000380117008758063, 0.0000023803114156395466,
            0.000001555844098222181, 0.0000010519535047492923, 7.3149184298682e-7, 3.777767273449584e-7,
            2.084610710717527e-7, 1.20980637285939e-7, 7.304515581285642e-8, 4.564509292759311e-8, 2.936047394097585e-8,
            1.9290359865031803e-8, 1.2882939159908346e-8, 8.721183331061866e-9, 5.9364339000111954e-9,
            2.2295886248357988e-9, 7.816135516591771e-10, 1.965192453212694e-10, 5.076050690888678e-11,
            6.023070930893937e-12, 4.936495656693296e-12, 4.906075545818567e-12, 4.906075545818567e-12,
            4.906075545818567e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(4.0)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.20328312291209105, 0.18442283200424747, 0.14951930546086167, 0.12373464009293489, 0.10532897882756387,
            0.09177288953507812, 0.0557465117238799, 0.029826074329255325, 0.01894405706801472, 0.013028721368798624,
            0.009408220156186808, 0.0027402023881596405, 0.0005581356502801782, 0.00018779094434884236,
            0.00008120533938626995, 0.00004074809030840676, 0.000003511879062001455, 5.763930653879257e-7,
            1.2472036003963183e-7, 3.07520353626245e-8, 8.267140527706829e-9, 2.3823407691025977e-9,
            7.064200335804571e-10, 1.9183388211274632e-10, 5.414102499656792e-11, 3.223532551999142e-12,
            1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(4.0)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.34505006864460297, 0.31887487069707054, 0.2686769577242014, 0.23107103472105683, 0.20337992593007004,
            0.18205246773027273, 0.12131194501093845, 0.0739568336691191, 0.05309991886936083, 0.04104179343835612,
            0.03312970035346263, 0.015369050133573703, 0.005754387313726905, 0.002924088197400865,
            0.0016750548666109033, 0.0010791787533114272, 0.0002913299210040021, 0.00013687142843132882,
            0.0000763650186563769, 0.00004643328531095392, 0.000029815239217412248, 0.000019902860313836257,
            0.000013704812325876503, 0.000009674692626138182, 0.000006976421204063499, 0.0000038209008498268915,
            0.0000022134760413461407, 0.0000013399633348454287, 8.404437362719008e-7, 5.288652964452467e-7,
            3.4025474116017307e-7, 2.2327080040351888e-7, 1.4855161845073184e-7, 9.972560899296212e-8,
            6.707884903089933e-8, 2.36954081911378e-8, 7.368072707514273e-9, 1.062953502994901e-9,
            2.1714152698137923e-10, 1.5244028261918174e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(4.0)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.21188047125036047, 0.19419969019350214, 0.15864329796628085, 0.13220709880400705, 0.11313700510191671,
            0.09897360081301165, 0.0611187980436827, 0.03340677187193042, 0.021561589184627805, 0.01505005744039667,
            0.011022182283499715, 0.003390395682767555, 0.0007297775092030001, 0.00025302060899246026,
            0.00011185006800162878, 0.00005751214695981499, 0.000005276690867028755, 9.323016220141866e-7,
            2.2157580648052289e-7, 6.142270303488573e-8, 1.8498792941912257e-8, 5.7542974829516425e-9,
            1.4379996082780622e-9, 3.463215270116393e-10, 9.643408294124356e-11, 4.48741044323242e-12,
            3.223532551999142e-12, 3.223532551999142e-12, 3.223532551999142e-12, 3.223532551999142e-12,
            1.613265077082815e-12, 1.613265077082815e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(4.0)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.33532154673025083, 0.30621115693083956, 0.2559884980852094, 0.2187091372811395, 0.19146543765744928,
            0.17060544981875414, 0.11267446137633796, 0.06809069568603643, 0.048378972287397626, 0.03693792153469977,
            0.029407726604627427, 0.012846531403462369, 0.004388046586316308, 0.00207786333627491, 0.001184119542062212,
            0.0007344476472461725, 0.00013356195707792118, 0.00004540915421091807, 0.000018941641416136967,
            0.00000934101125971143, 0.000005030851298215566, 0.000002935939162784784, 0.000001775949476368055,
            0.0000011581632314294765, 7.719151641216726e-7, 3.870009663842211e-7, 2.0038723391735402e-7,
            1.1443496816809784e-7, 6.85910492848052e-8, 4.24417372357766e-8, 2.559144007818759e-8, 1.56509702975427e-8,
            9.612794116264922e-9, 6.481181369366595e-9, 4.476959247767809e-9, 1.4292764749512799e-9,
            5.976370509586104e-10, 2.5669055769839133e-10, 6.709677258243119e-11, 1.0945466755174493e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(5.0)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.25579942032895686, 0.21582816412788042, 0.16396768967695408, 0.1331376608790109, 0.11272411243545843,
            0.09811564973307285, 0.06036748467412534, 0.03336458764730332, 0.021961611909653733, 0.01567915755577265,
            0.011766698274040976, 0.004129407575663491, 0.0011425450235774681, 0.0004897053618799774,
            0.00026062211682487124, 0.0001582185259543234, 0.00003272589454683672, 0.00001241469220680802,
            0.000005893276962987137, 0.0000031476084509929336, 0.0000018136730909334986, 0.0000011043926538834015,
            7.012305892617476e-7, 4.601761316447295e-7, 3.1028976010816223e-7, 1.504206120772622e-7, 7.8047776574941e-8,
            4.281598897648564e-8, 2.4543312715508137e-8, 1.4483368171447353e-8, 8.705602017045067e-9,
            5.280201165192011e-9, 3.1821929535880145e-9, 1.8638389720493365e-9, 1.1001028976664884e-9,
            2.0912949150186932e-10, 3.33439942323821e-11, 7.34234895105601e-12, 5.211275855288022e-12,
            4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12,
            4.906075545818567e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(5.0)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.18900027995364954, 0.15737127021937158, 0.11447429866003211, 0.09034227040319709, 0.0748934273452202,
            0.06410453952944828, 0.036990391391226396, 0.01820847907060441, 0.010776250903899665, 0.006974379319107382,
            0.004781786190616977, 0.0011677996923413758, 0.00019757410184384483, 0.000059446324454870414,
            0.000023435544738603653, 0.000010762441962852698, 5.730062809794489e-7, 6.062471824552773e-8,
            8.286501262944057e-9, 1.1942306032963756e-9, 1.7447299160977536e-10, 3.111377822051509e-11,
            3.407607529481993e-12, 1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.2064793608601576e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(5.0)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.32681272780503645, 0.2827814781591371, 0.22127528986011546, 0.1822922679048975, 0.15580189387991483,
            0.1366656689700274, 0.08708027425415044, 0.051574780759785055, 0.03645501161409437, 0.027780801832158364,
            0.022101151287611365, 0.009897483603721446, 0.0032779327147524073, 0.001515958588181654,
            0.000875859963393788, 0.0005832890954891035, 0.00018703700450128924, 0.00009048464373850873,
            0.000049672857902804957, 0.000029184270428861403, 0.000018002718393139716, 0.000011536128252886968,
            0.000007624236856851319, 0.000005174583458611437, 0.0000035910736096989027, 0.0000017851472502439236,
            9.252047050711809e-7, 5.052224144597162e-7, 2.8686399156097053e-7, 1.672385873119353e-7,
            9.895500407974822e-8, 5.885800458127477e-8, 3.428449157905078e-8, 1.8933220657935124e-8,
            1.0583732890090403e-8, 1.164392027241945e-9, 1.7794421491856838e-10, 3.5503600059882956e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(5.0)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.1988755666086497, 0.16707636665119696, 0.12283149728331366, 0.09757738062161692, 0.08145174999654936,
            0.07013344960892332, 0.04121752739664308, 0.020823792842089905, 0.012604912272944246, 0.008325640841703952,
            0.005812261076051262, 0.0015058735729454042, 0.0002709367104188898, 0.00008508047419208964,
            0.00003446182864585978, 0.00001606212014582109, 0.0000010440535493927428, 1.5045267065527668e-7,
            2.8233193893889563e-8, 4.727374491864111e-9, 4.023754662796364e-10, 5.7562288269252804e-11,
            1.0232481528760218e-11, 3.223532551999142e-12, 3.223532551999142e-12, 3.223532551999142e-12,
            1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(5.0)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.31406425565942564, 0.26956405968177155, 0.20811164390608639, 0.1707430318993326, 0.14579917262436382,
            0.12772269210610643, 0.08068165838646268, 0.04708351636058716, 0.032582510580138946, 0.024267472583130223,
            0.01893169592981725, 0.007546756832777057, 0.002351773036388116, 0.0010785493453905337,
            0.0005572254424419665, 0.00032924184129301537, 0.0000645458577246405, 0.00002102706113293884,
            0.000007680798532527788, 0.0000034279597816988527, 0.0000016604278735288247, 9.459841976422467e-7,
            5.715230599889765e-7, 3.6029008676496943e-7, 2.328372659121669e-7, 1.0354902713061165e-7,
            5.041688666551636e-8, 2.671349186300631e-8, 1.5587511725811964e-8, 8.931938744183299e-9,
            4.461897074037324e-9, 2.3730768461405205e-9, 1.5397416675000386e-9, 1.0153058394024583e-9,
            6.775340288811549e-10, 2.377733565595008e-10, 4.861078206630509e-11, 1.1310508085671245e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(6.0)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.22973148118033426, 0.18119213628696873, 0.1294509535249706, 0.10235169341541117, 0.08538621582493244,
            0.07356967104972467, 0.04375222144335278, 0.022957154537597946, 0.014490024510218968, 0.009993102507968032,
            0.0072852239891999515, 0.002325233547791239, 0.0005930501170475777, 0.0002481720441438462,
            0.0001321186472936775, 0.00008098519210486543, 0.000017301147678949746, 0.000006399418756353192,
            0.0000028927355937291566, 0.000001463810197277482, 7.9858558688084e-7, 4.604278134312523e-7,
            2.7719839545969904e-7, 1.7275791464044232e-7, 1.1093926477467875e-7, 4.9170575500134817e-8,
            2.3479674871040856e-8, 1.174749020282917e-8, 6.0240564758728965e-9, 3.0669434769592385e-9,
            1.520541914601381e-9, 7.301456106389992e-10, 3.1243929665691894e-10, 1.1635115093611148e-10,
            4.625222427279141e-11, 5.537015290713043e-12, 4.943823128655822e-12, 4.906075545818567e-12,
            4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12,
            4.906075545818567e-12, 4.906075545818567e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(6.0)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.16836138679434776, 0.12797299536890516, 0.08715282215825548, 0.06698958521823917, 0.05472658783368756,
            0.046295593242398736, 0.025166252034400594, 0.011225197692243616, 0.006150526944368351,
            0.0037514204102928606, 0.002448480270007325, 0.0005068848390259451, 0.00007302550742205316,
            0.00001958421939851629, 0.00000683160461234511, 0.0000027759598271170915, 8.632149028908742e-8,
            5.743656217305215e-9, 3.4040481544650447e-10, 3.4975689011673694e-11, 3.223532551999142e-12,
            1.613265077082815e-12, 1.613265077082815e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.0951239914902544e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(6.0)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.29948909954374714, 0.2433340184246361, 0.1785157615524986, 0.1427779732654959, 0.12030987666508186,
            0.10474218375121636, 0.06530571831985121, 0.03791558416741336, 0.026233561453852028, 0.019555609489189996,
            0.01536368828628365, 0.006505951793009057, 0.0018584656327238491, 0.0008313711828350501,
            0.0005029581418456441, 0.00035779635640420526, 0.00012414463745524795, 0.000057246162055046845,
            0.000029198191719759237, 0.000015944582726912238, 0.000009178821934185954, 0.0000055169100403595905,
            0.0000033156445531368206, 0.0000020605554198471765, 0.0000013185699743178958, 5.786642253147178e-7,
            2.720436901304524e-7, 1.3327644932115135e-7, 6.645365668855874e-8, 3.2188592569148966e-8,
            1.4772581713451416e-8, 6.325404089402298e-9, 2.1233261815467586e-9, 4.0918002319756397e-10,
            2.0283430490763976e-10, 1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(6.0)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.17849292422944352, 0.13706224705773873, 0.09433898700859278, 0.07322420873030266, 0.060198461135035464,
            0.05119582520476518, 0.02846765345836888, 0.013156618495677397, 0.0074236124049236185, 0.00462329342106893,
            0.003077199540829856, 0.0006809029497475416, 0.0001043946584237121, 0.000028934982241102247,
            0.000010509628807886529, 0.0000044873426008340545, 1.970529328598758e-7, 1.9353477820338583e-8,
            2.0355823693307684e-9, 7.475176033722164e-11, 5.845657291558837e-12, 3.223532551999142e-12,
            2.1873614031164834e-12, 1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12,
            1.613265077082815e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(6.0)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.2856448518242004, 0.22978984456835871, 0.1669777135952183, 0.13356700189540938, 0.11223592185620646,
            0.09738418193136278, 0.06014089264752431, 0.03400051194217002, 0.022767608514854598, 0.01656104617302956,
            0.012636266897303239, 0.004632173305135323, 0.0013444607488974913, 0.0005973078091248141,
            0.00029024211416028844, 0.00017145391590267511, 0.000032329201511527295, 0.000008617432131430292,
            0.0000028362841731910393, 0.0000011126608695510853, 5.02401007906883e-7, 2.6261042762598663e-7,
            1.4767717004815495e-7, 8.448137833738656e-8, 5.279095460775096e-8, 2.138651922223289e-8,
            8.683387342500737e-9, 3.816145621726719e-9, 2.073942351188407e-9, 1.1077444517226809e-9,
            5.802884839312128e-10, 3.037159412855317e-10, 1.562507900843002e-10, 7.778488964049757e-11,
            4.122568952880101e-11, 1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(7.5)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.19053169703636896, 0.13978598635705686, 0.09501796454425249, 0.07371890596799535, 0.0607561075732207,
            0.051818449398440825, 0.029505935671003014, 0.014427398465806984, 0.00862537815960307, 0.005702063491411891,
            0.004019281524690332, 0.0011640167004784985, 0.00028173979853496345, 0.00011913718061373757,
            0.00006499865614340017, 0.00004069899709069347, 0.00000869844187445512, 0.0000029662910271399667,
            0.0000012195913923207513, 5.612659026432709e-7, 2.7979113403908684e-7, 1.482763281046573e-7,
            8.256033157127973e-8, 4.78463678676988e-8, 2.8613156066725765e-8, 1.0920433335392943e-8,
            4.3107599712044475e-9, 1.6426082716236579e-9, 6.012073061612e-10, 1.5322776381054837e-10,
            4.549716159374384e-11, 1.181121866977719e-11, 4.906075545818567e-12, 4.906075545818567e-12,
            4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12,
            4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12,
            4.906075545818567e-12, 4.906075545818567e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(7.5)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.13493211131459248, 0.09456938185267605, 0.06147230458303243, 0.046232653206715435, 0.03698242334509727,
            0.030622728987031778, 0.015159425876162147, 0.005880799962190486, 0.0029135222608713818,
            0.0016375912633967982, 0.0010025642948648938, 0.00017027227721266858, 0.00001940142850831439,
            0.00000407626030329844, 0.0000011333968240601067, 3.734709559388705e-7, 4.3395119719846775e-9,
            1.1262335508632759e-10, 3.223532551999142e-12, 1.613265077082815e-12, 1.613265077082815e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(7.5)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.25623019236859057, 0.19320699135182884, 0.134008928171104, 0.10540917257947369, 0.08810012396313138,
            0.07623990063905295, 0.046953377483752945, 0.02649399514744688, 0.017644505169977842, 0.012884629747216048,
            0.009963925846598665, 0.003881183238549024, 0.0009879669441251737, 0.000459984074703379,
            0.00030933346321226907, 0.00023168000884488116, 0.00007861759306437843, 0.000031938765731154284,
            0.000014392869597146252, 0.000006771098390756514, 0.0000033545274107371625, 0.0000017643491390106902,
            9.73825007632989e-7, 5.582590295594159e-7, 3.2958326368071056e-7, 1.2171377317127252e-7,
            4.555477117751394e-8, 1.5679081477593115e-8, 4.89906815115404e-9, 5.57271340184684e-10,
            1.544256944541189e-10, 5.483724585531036e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(7.5)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.14437174706854206, 0.1022049176041977, 0.06732265582782404, 0.05110328772705641, 0.04119567050267259,
            0.034384136370270824, 0.017609103857654462, 0.007165751626819428, 0.0036749680288017395,
            0.0021208492239019883, 0.001323497428877607, 0.00023973524545239933, 0.000028813220786183535,
            0.000006495504027204113, 0.0000019547653019591493, 7.061409188491297e-7, 1.1166264357598266e-8,
            3.86138343522191e-10, 6.348366277109108e-12, 3.223532551999142e-12, 1.9386714456004484e-12,
            1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12, 1.613265077082815e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(7.5)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.24183474470375532, 0.18062100430106387, 0.12495521615476457, 0.0979454087758369, 0.08159821220220653,
            0.07040942160097308, 0.04265247073801415, 0.02299381069897033, 0.014903852829867503, 0.010514111304408269,
            0.00777284464178829, 0.0026275562419340037, 0.0007218545855822711, 0.00030417473645627524,
            0.0001475122513182603, 0.00009307524350943286, 0.000013399111735590274, 0.0000026862027461271865,
            7.091733866815275e-7, 2.3481193589525162e-7, 9.860965854979753e-8, 3.6406127890131756e-8,
            1.829387308305286e-8, 1.0292120045107822e-8, 5.4028612694878575e-9, 1.5046388579520453e-9,
            6.141177566476586e-10, 2.6101654171384325e-10, 9.391898370125773e-11, 4.106615048016238e-11,
            4.106615048016238e-11, 2.1957546891826496e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(10.0)",
        loc: "-37.780~175.280",
        agg: "mean",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.1381956568020184, 0.09513073042573461, 0.06232375064335516, 0.047389725367925295, 0.038395841540804665,
            0.03223665904313777, 0.017139752196368763, 0.0075431753752760455, 0.004183547514879038,
            0.002617922092637337, 0.001770755163504556, 0.00046369196341811136, 0.00011100929921503688,
            0.000048496970277422946, 0.000026705814184957433, 0.000016392368206608054, 0.0000025670444040448714,
            6.001790463505685e-7, 1.733298659045701e-7, 5.8060053653719024e-8, 2.148557076697699e-8,
            8.391643269156646e-9, 3.2898099799894e-9, 1.2591186981936175e-9, 4.380645846069342e-10,
            2.9043434324194095e-11, 4.9072967911456544e-12, 4.906075545818567e-12, 4.906075545818567e-12,
            4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12,
            4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12,
            4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12,
            4.906075545818567e-12, 4.906075545818567e-12, 4.906075545818567e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(10.0)",
        loc: "-37.780~175.280",
        agg: "0.05",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.09343910857175775, 0.061488145597584976, 0.03788456357450576, 0.027161364987968617, 0.020806740286700154,
            0.01658292224608371, 0.006959009155160789, 0.002135415902087323, 0.0009080054526625636,
            0.00045876915478104685, 0.00025771897076032335, 0.00003329582392952446, 0.0000023970698570519033,
            3.380630936300477e-7, 6.357254378475119e-8, 1.4745125342940923e-8, 2.771349816299562e-11,
            1.613265077082815e-12, 1.613265077082815e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
            1.0578204978628492e-12, 1.0578204978628492e-12, 1.0578204978628492e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(10.0)",
        loc: "-37.780~175.280",
        agg: "0.95",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.19230551769235948, 0.1350898207453326, 0.09093087150596679, 0.07104667506627627, 0.05924619669210074,
            0.05120840587270126, 0.031235687344976726, 0.016314141958787998, 0.01043628579981526, 0.007389422303423432,
            0.005561704719012739, 0.001928083286800053, 0.0004478186145857599, 0.00026024936115476294,
            0.0001862552117278815, 0.00013573343291140016, 0.000030122930407050497, 0.000007094249093508864,
            0.0000020078922660093923, 6.538639757414089e-7, 2.3407325266955326e-7, 8.749821256426316e-8,
            3.185840613539881e-8, 1.0865364052925486e-8, 3.0232050196588034e-9, 1.3140388777088674e-10,
            1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11, 1.0945466755174493e-11,
            1.0945466755174493e-11, 1.0945466755174493e-11,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(10.0)",
        loc: "-37.780~175.280",
        agg: "0.1",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.10085999176932081, 0.06737607478371666, 0.04221019125818548, 0.030753737976734086, 0.023887421629586236,
            0.01923986181820314, 0.008510361827599455, 0.0027872940228689247, 0.001224301425976848,
            0.0006343534218318236, 0.00036594106611109734, 0.00005167177120268818, 0.000004303962037210951,
            7.418887824073295e-7, 1.7139822394707238e-7, 4.818344778723116e-8, 4.8698378662948016e-11,
            3.1200597661040774e-12, 1.6185941476010157e-12, 1.613265077082815e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
            1.453503983839255e-12, 1.453503983839255e-12, 1.453503983839255e-12,
          ],
        },
      },
      {
        hazard_model: "NSHM_v1.0.4",
        imt: "SA(10.0)",
        loc: "-37.780~175.280",
        agg: "0.9",
        vs30: 400,
        curve: {
          levels: [
            0.0001, 0.0002, 0.0004, 0.0006, 0.0008, 0.001, 0.002, 0.004, 0.006, 0.008, 0.01, 0.02, 0.04, 0.06, 0.08,
            0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 4.5,
            5, 6, 7, 8, 9, 10,
          ],
          values: [
            0.17864972353241604, 0.12533265910544333, 0.08389379644126571, 0.06524680648705561, 0.05416112958178154,
            0.04658431985276579, 0.027403865540662875, 0.013892091615714253, 0.008410306291737113,
            0.0055130186899117195, 0.0039427333783699225, 0.0011982749994914332, 0.00032833653644581684,
            0.00011773579228124831, 0.00005853754967555247, 0.000028300029768724144, 0.0000015752087048070962,
            1.9420604102649008e-7, 3.491284417123097e-8, 1.0350746038056968e-8, 3.203928677919521e-9,
            9.984195692425146e-10, 4.1485670454477486e-10, 1.7327650425613683e-10, 6.983547073957652e-11,
            4.106615048016238e-11, 1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
            1.0232481528760218e-11, 1.0232481528760218e-11, 1.0232481528760218e-11,
          ],
        },
      },
    ],
  },
};
